import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const PolicyMasterEdit = (props) => {
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  
  return (
    <Edit {...propsObj}  title="Policy Master Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>
      </SimpleForm>
    </Edit>
  );
};
