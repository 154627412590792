import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Create, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const BeneficieriesCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        // /    realizOrdId: props.realizOrdId
    }
    const handleSubmit = (formdata) => {
        console.log(formdata);

        let user = localStorage.getItem("userId");
        if (user) {
            user = parseInt(user);
        }

        let data = {
            orderId: parseInt(props.ordId),
            beneficieryName: formdata.beneficieryName,
            beneficieryRelId: parseInt(formdata.beneficieryRelId),
            sharePercentage: formdata.sharePercentage,
            appointeeName: formdata.appointeeName,
            appointeeRelId: parseInt(formdata.appointeeRelId),
            remarks: formdata.remarks,
            createdBy: user,
            modifyBy: user,
            createdDate: moment().toISOString(),
            modifyDate: moment().toISOString(),
        }
        apiClient.addEntiyRecord("life-order-beneficieries", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });


    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">

                <TextInput source="beneficieryName" fullWidth={ true } />
                <NumberInput source="sharePercentage" fullWidth={ true } />
                <ReferenceInput label="Beneficiery Rel"
                    perPage={ 5000 } source="beneficieryRelId"
                    filter={ { type: 'REL' } }
                    sort={ { field: 'name', order: 'ASC' } }
                    reference="lookups" fullWidth={ true } >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="appointeeName" fullWidth={ true } />
                <ReferenceInput label="Appointee Rel"
                    perPage={ 5000 } source="appointeeRelId"
                    filter={ { type: 'REL' } }
                    sort={ { field: 'name', order: 'ASC' } }
                    reference="lookups" fullWidth={ true } >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="remarks" label="Remarks" fullWidth={ true } />

            </SimpleForm>
        </Create>
    );
}
export default BeneficieriesCreate;