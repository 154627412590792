import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import {
  useHistory
} from "react-router-dom";

const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="SMS Type" source="smsType~like" alwaysOn />
    <SearchInput variant="outlined" label="subject" source="subject~like" alwaysOn />
    <SearchInput variant="outlined" label="Phone" source="toAddress~like" alwaysOn />
  </Filter>
);
export const SmsLogList = (props) => {
  let location = useLocation();
  let history = useHistory();
  const values = queryString.parse(location.search);
  //console.log(values, "====values======");
  let hid = (values.hid) ? values.hid : '';
  var filter: any = { smsLogHdid: -1 };
  if (hid != "") {
    filter.smsLogHdid = hid;
  }
  return (
    <>
    <div style={{marginTop:10, marginBottom:10}}>
    <Button variant="contained" color="primary" onClick={() => {
      history.goBack();
    }}>
      Go Back
    </Button>
    </div>
    <List {...props}  filter={filter} bulkActionButtons={false} title="SMS Send List" filters={<Filters />}  >
      <Datagrid rowClick={false}>
        {/* <TextField source="id" label="SNo" /> */}
        <TextField source="smsType" label="Type" />
        <TextField source="subject" label="Subject" />
        <TextField source="body" label="SMS Text" />
        <TextField source="toAddress" label="Customer Phone" />
        <DateField source="createdAt" label="Date" />
        {/* <BooleanField source="isPending" label="Is Pending"/> */}
        {/* <TextField source="errorMessage" label="Error Message"/> */}
      </Datagrid>
    </List>
    </>
  );
};
