import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';

import { Timeline } from './screens/student/Timeline';

import {ChangePassword} from "./screens/changepassword/ChangePassword";
import {MasterReport} from "./screens/reports/MasterReport";
import {MasterReport2} from "./screens/reports/MasterReport2";
import {MasterReport3} from "./screens/reports/MasterReport3";
import {LeaveReport} from "./screens/reports/LeaveReport";
import {SalaryReport} from "./screens/reports/SalaryReport"; 
import {AttedanceSummaryReport} from "./screens/reports/AttedanceSummaryReport";
import { EmployeeSalaryView } from './screens/employeesalary/EmployeeSalaryView';
import { MasterReport4 } from './screens/reports/MasterReport4';
import { MasterReport5 } from './screens/reports/MasterReport5';
import { ReportMaker } from './screens/reportMaker/ReportMaker';

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/timeline" render={(props) => <Timeline {...props}/>} />,
    <Route exact path="/reportmaker" render={(props) => <ReportMaker {...props} />} />,
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    <Route exact path="/generalquery" render={(props) => <MasterReport  rptTtile="General Query Report" rpt="GQ" {...props} />} />,
    <Route exact path="/hbr" render={(props) => <MasterReport rptTtile="Hospital Bill Report" rpt="HBR" {...props} />} />,
    <Route exact path="/hir" render={(props) => <MasterReport rptTtile=" Invoice Report" rpt="IVR" {...props} />} />,
    <Route exact path="/hrc" render={(props) => <MasterReport rptTtile="Hospital Reclaim Report" rpt="HRC" {...props} />} />,
    <Route exact path="/dsrreport" render={(props) => <MasterReport2 rptTtile="Conveyance Report" rpt="ConvRPT" {...props} />} />,
    <Route exact path="/invreport" render={(props) => <MasterReport3 rptTtile="Invoice Report" rpt="HRC" {...props} />} />,
    <Route exact path="/leavereport" render={(props) => <LeaveReport rptTtile="Leave Report" rpt="HRC" {...props} />} />,
    <Route exact path="/empsalreport" render={(props) => <SalaryReport rptTtile="Salary Report" rpt="HRC" {...props} />} />,
    <Route exact path="/attendreport" render={(props) => <AttedanceSummaryReport rptTtile="Attendance Report" rpt="HRC" {...props} />} />,
    <Route exact path="/salaryslip" render={(props) => <EmployeeSalaryView {...props} />} noLayout/>,
    <Route exact path="/br" render={(props) => <MasterReport3 rptTtile="Biz Report" rpt="br" {...props} />} />,
    <Route exact path="/ppr" render={(props) => <MasterReport3 rptTtile="Pending Policy Report" rpt="ppr" {...props} />} />,
    <Route exact path="/rdr" render={(props) => <MasterReport3 rptTtile="Renewal Due report" rpt="rdr" {...props} />} />,
    <Route exact path="/idr" render={(props) => <MasterReport3 rptTtile="Installment Due Report" rpt="idr" {...props} />} />,
    <Route exact path="/er" render={(props) => <MasterReport3 rptTtile="End Report" rpt="er" {...props} />} />,
    <Route exact path="/cust" render={(props) => <MasterReport5 rptTtile="Customer Report" rpt="cust" {...props} />} />,
    <Route exact path="/leadmrktngsummary" render={(props) => <MasterReport4 rptTtile="Lead Marketing Report" rpt="lmr" {...props} />} />,
    
    
];
