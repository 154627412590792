import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,AutocompleteInput,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput, NumberInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { Addressfields } from "../../customer/Addressfields";
import { toast } from 'react-toastify';

const CustomerCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();

  let defaultValues = {

    isActive: true,
    isMultiBranch: false,
    pin: "",
    gstNo: "",
    countryId: 0,
    phone: "",
    mobile: "",
    industryTypeId: 0,
    paidCapitals: 0,
    rmid: 0,
    tcid: 0,
    cscid: 0,
    refCustomerId: 0,
    statusId: 0,
    stateId: 0,
    address1: "",
    address2: "",
    education: "",
    cityId: 0,
    gender: "",
    website: "",
    districtId: 0,
    pan: "",
    adhaarNo:""
  };

  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };

     if (data.dob != null) {
       data = apiClient.padDate(data, "dob");
     }

    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();
    apiClient.addRecord(data, "customers").then(res => {
      notify("Customer is added succesffully")
      props.onClose();

    }).catch(err => {
      notify("unable to add Customer", "warning")
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
      console.log(err);
    });
  }
  return (
    <React.Fragment>
      <h5>Add Customer </h5>
      <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >

        <ReferenceInput perPage={2000} fullWidth={true} label="Customer Type" source="entryTypeId" reference="entry-types" formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>

        <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
        <TextInput source="shortName" label="Short Name" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />



        <TextInput source="gender" label="Gender" fullWidth={true} formClassName={classes.one_three_input} />
        <DateInput source="dob" label="Dob" fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="pin" label="Pincode" fullWidth={true} formClassName={classes.last_three_input} />
        <TextInput multiline source="address1" fullWidth={true}  validate={[required()]} label="Address 1" formClassName={classes.one_three_input} />
        <TextInput multiline source="address2" fullWidth={true} label="Address 2" formClassName={classes.two_three_input} />
        <TextInput source="phone" label="Phone" validate={[required()]}  fullWidth={true} formClassName={classes.last_three_input} />



        <FormDataConsumer fullWidth={true} >
          {formDataProps => (
            <Addressfields {...formDataProps} />
          )}
        </FormDataConsumer>
        <TextInput source="mobile" label="Mobile" validate={[required()]}  fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="email" label="Email" validate={[required()]}  fullWidth={true} formClassName={classes.two_three_input} />


        <TextInput source="pan" label="Pan" fullWidth={true} formClassName={classes.last_three_input} />



        <ReferenceInput perPage={2000} label="Industry Type" fullWidth={true}
          source="industryTypeId" reference="industry-types" formClassName={classes.one_three_input}
          sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>

        {/* <ReferenceInput perPage={2000} label="Form Organization " fullWidth={true}
          source="formOrganizationId" reference="lookups" formClassName={classes.two_three_input}
          sort={{ field: 'name', order: 'ASC' }} filter={{ type: 'FO' }} >
          <SelectInput optionText="name" />
        </ReferenceInput> */}

        <ReferenceInput label="RM" source="rmid" reference="employees" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="TC" source="tcid" reference="employees" fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="CSC" source="cscid" reference="employees" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="Ref Customer" source="refCustomerId" reference="customers" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput source="gstNo" label="Gst No"  fullWidth={true}  formClassName={classes.one_three_input} />
          <TextInput source="adhaarNo" label="Adhaar No"  fullWidth={true}  formClassName={classes.two_three_input} />
          
          <BooleanInput source="isMultiBranch" label="Is Multi Branch" initialValue={true} formClassName={classes.last_three_input} />
          <BooleanInput source="isActive" label="Is Active" initialValue={true} formClassName={classes.one_three_input} />
          
          <ReferenceInput perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
          filterToQuery={ searchText => ({ 'name~like': searchText }) }
          label="Customer Group" source="custGroupId" reference="cust-groups" fullWidth={ true }
        >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
        </ReferenceInput>

      </SimpleForm>

    </React.Fragment>
  );
};
export default CustomerCreate;