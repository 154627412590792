import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const EndorsementEdit = props => {
    const classes = useStyles({});
    const initialValues = {
    // /    realizOrdId: props.realizOrdId
    }
    const handleSubmit = (formdata) => {
        console.log(formdata);
//debugger
        apiClient.UploadFile("docUrl", formdata.docUrl).then(res => {
            let user = localStorage.getItem("userId");
            if (user) {
            user = parseInt(user);
            }
            let documentUrl = res;
            let data = {
                id:parseInt(props.record.id),
                ordId: parseInt(props.ordId),
                documentTypeId: parseInt(formdata.documentTypeId),
                typeId: parseInt(formdata.typeId),
                endorsementId: parseInt(formdata.endorsementId),
                remarks: formdata.remarks,
                docUrl: documentUrl, 
                createdBy: parseInt(formdata.createdBy),
                modifyBy: user,
                statusId:parseInt(formdata.statusId),
                createdDate: formdata.createdDate,
                modifyDate: moment().toISOString(),
            }
            apiClient.updateRecord("life-order-endorsements", data).then(res => {
            
                //console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });

        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Edit {...props} id={props.record.id} resource="order-endorsements" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
        
            
                
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                <FileInput fullWidth={true} source="docUrl" label="Document">
                    <FileField source="docUrl" title="File" />
                </FileInput>
                <ReferenceInput label="Endorsement Type" 
                 sort={{ field: 'name', order: 'ASC' }} 
                    perPage={5000} source="endorsementId" 
                    reference="endorsement-types" fullWidth={true} >
                    <SelectInput optionText="name" />
                </ReferenceInput> 
                <ReferenceInput label="Document Type" 
                 sort={{ field: 'name', order: 'ASC' }} 
                    perPage={5000} source="documentTypeId" 
                    reference="document-types" fullWidth={true} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Type"
                    perPage={5000} source="typeId"
                    filter={{ type: 'DT' }}
                    sort={{ field: 'name', order: 'ASC' }} 
                    reference="lookups" fullWidth={true} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Status "
                    perPage={5000} source="statusId"
                    filter={{ type: 'EST' }}
                    sort={{ field: 'name', order: 'ASC' }} 
                    reference="lookups" fullWidth={true} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="remarks" label="Remarks" fullWidth={true} />
                
                
                
            </SimpleForm>
        </Edit>
    );
}
export default EndorsementEdit;