import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="User Name"
      source="userName~like"
      alwaysOn
    />
  </Filter>
);
export const UsersList = (props) => {
  const propsObj = Authorizer(props);
  return (
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="User List"  filters={<Filters />}  >
     <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}

        <TextField source="code" label="User Id" />
        <TextField source="userName" label="User Name " />
        <ReferenceField source="approverId" reference="users" label="Approver"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        
        <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Modified By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DateField  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
