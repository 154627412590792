import React, { useState, useCallback, useEffect } from 'react';
import { PDFViewer, Document, Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../../common/apiClient";
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import logo from '../../../images/logo.png'
import sign from '../../../images/hd-signature.jpg'


const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingBottom: 50,
    paddingHorizontal: 5,
    paddingRight: 15,
    paddingLeft: 30,

  },
  tableHeader: {
    fontSize:11,
    height:20,
    textAlign: 'left',
    fontWeight:"bold"
    
  },
  tableRow: {
    fontSize:10,
    height:20,
    textAlign: 'left',  
  },

  headercontainer: {
    
    flexDirection: "row",
  },
  headercontainer1: {
    height: 50,
    flexDirection: "row",
  },
  logocontainer: {
    height: 50,
    width: "20%",

  },
  logocontainer1: {
    height: 0,
    width: "0%",

  },
  companyaddress: {
    height: 100,
    width: "80%",
    textAlign: 'left',
    fontSize: 10,
    marginLeft: 30,

  },
  companyaddress1: {
    height: 50,
    width: "100%",
    textAlign: 'center',
    fontSize: 10,
    marginLeft: 10,

  },
  invoiceHeader: {
    fontSize: 12,
    height: 20,
    textAlign: 'center',
    fontWeight: "bold",
    // borderTop: 1,
    // borderLeft: 1,
    // borderRight: 1,
    flexDirection: "column",

  },
  partyBox:
  {
    flexDirection: "row",
    //height: "100",
    //border: 1,
    paddingLeft: 10,
    fontSize: 11
  },
  partyBoxAddress:
  {
    flexDirection: "column",
    height: "100",
    width: "70%",


  },
  partyBoxAddress1:
  {
    height: "70%",
    width: "100%",
    fontSize: 12,
    paddingTop: 10,
  },
  billingMonth:
  {
    height: "30%",
    width: "100%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 10

  },
  invoiceDetail:
  {
    flexDirection: "column",
    height: "100",
    borderLeft: 1,
    width: "30%",
    fontSize: 11
  },
  invoiceDetailbox1:
  {
    height: "20%",
    borderBottom: 1,
    textAlign: "center",
  },
  invoiceDetailbox2:
  {
    height: "20%",
    textAlign: "center",
  },
  invoiceDetailDate:
  {
    height: "30%",
    textAlign: "center",
  },

  tableHeaderStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    height: "5%",
    width: "100%",
    fontSize: 11,
    borderBottom: 1,
    borderLeft: 1

  },
  tableHeaderStylebox1:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableHeaderStylebox2:
  {
    paddingTop: 3,
    textAlign: "center",
    height: "10%",
    width: "70%",

  },

  tableHeaderStylebox3:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "20%",
    borderLeft: 1,
    borderRight: 1
  },
  tableRow:
  {

    flexDirection: "column",
    width: "100%",
    borderBottom: 1,

  },
  tableRowStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: "3%"

  },
  tableRowStylebox1:
  {
    paddingRight: 5,
    paddingTop: 5,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableRowStylebox2:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "left",
    width: "70%",

  },
  tableRowStylebox3:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    paddingRight: 3,
    paddingTop: 5
  },
  tableRowStylebox2Total:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox3TotalAmount:
  {
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    paddingRight: 3,
    paddingTop: 5,
    fontSize: 13

  },
  tableRowStylebox2Tax:
  {
    flexDirection: "row",
    paddingRight: 0,
    paddingTop: 0,
    paddingLeft: 0,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2TaxEmptyBox:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2Taxdetail:
  {
    paddingRight: 3,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 11,
    borderLeft: 1,
    borderRight: 0,
    borderTop: 1,
    borderBottom: 1,


  },
  tableRowStylebox3Taxamount:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    paddingRight: 3,
    paddingTop: 50
  },
  tableRowStylewith5height:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: 5

  },
  tableHeaderStylebox2GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    height: "10%",
    width: "70%",
    fontSize: 13

  },
  tableHeaderStylebox3GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    fontSize: 13
  },
  boxAmountinWords:
  {
    flexDirection: "row",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    height: 30,
    fontSize: 11
  },
  bankDetailBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 30,
    fontSize: 11
  },
  bankAccountDetailBox:
  {
    flexDirection: "row",

    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 15,
    fontSize: 11
  },
  bankAccountDetailBox1:
  {

    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "35%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox2:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox3:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "25%",
    borderRight: 1,
    borderTop: 0,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox4:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  signatureBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 100,
    fontSize: 11
  },

});


const InvoicePrint2 = props => {

  const [isLoading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState({ partyName: "RedPlus Society,", address: "Baghanjatin Station Road Kolkata-700032 West Bengal", invoiceNo: "HDFC/20-21/JAN/0392R", invoiceDate: "11.11.2021", billMonth: "Month Of December 2020", taxableAmount: "1000.00" });
  const [detailData, setDetailData] = useState([{ particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims .", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }]);
  const [footerDetail, setFooterDetail] = useState("");
  const [blankRow, setBlankRow] = useState([]);
  const [footerDetail1, setFooterDetail1] = useState("");
  useEffect(() => { LoadData(props.invoiceId); }, []);
  const LoadData = async (id) => {

    //return
    await apiClient.getRepoClaimInvstPrint(id).then(res => {

      console.log(res)
      let data = [];


      setHeaderData(res[0]);
      if (res[0].InvoiceDetails) {
        setDetailData(JSON.parse(res[0].InvoiceDetails));
      }

      // setDetailData(res.detailData);
      setLoading(false);

    }).catch(err => {
      setLoading(false);
      console.log(err);
    });

  }

  return (
    <React.Fragment>
      <div>
        <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
      </div>
      {isLoading === false ?
        <PDFViewer width="100%" height="600" >
          <Document title="report" >
            <Page size="A4" style={styles.page}>
              <View>

                <View style={styles.headercontainer}>
                  <View style={styles.logocontainer}>
                    {/* <Image src={logo} /> */}
                  </View>
                  <View style={styles.companyaddress}>
                    

                  </View>
                  {/* <Image src={logo} /> */}
                </View>

                <View style={styles.invoiceHeader} >
                  <Text>{headerData.ReportName}</Text>
                </View>
                <View style={styles.partyBox} >
                  <View>
                    <Text >
                      To
                    </Text>
                    <Text >
                      {" "}
                    </Text>
                    <Text>
                      The Claim Manager
                    </Text>
                    <View style={{ minHeight: 3 }} ></View>
                    <Text>
                      Source Name : {headerData.CourceCategory}
                    </Text>
                    <Text>
                      Subject: Submission of investigation report of {headerData.TypeOfLOB}, TPA CLAIM NO.{headerData.claimno} ,INSURANCE CLAIM NO. {headerData.Iclaimno} and VID:{headerData.VID}
                    </Text><Text >
                      {" "}
                    </Text>
                    <Text>
                      Dear Sir/Madam,
                    </Text><View style={{ minHeight: 3 }} ></View>
                    <Text>
                      We are here to submit the investigation report of subject claim which was alloted to us on {headerData.InitiateOn} {" "}{headerData.InitiateAt} {" via "} {headerData.InitiatedViaID} with following recommendation:
                    </Text>
                    <View style={{ minHeight: 2 }} ></View>
                    {headerData.GrossFinding != undefined && headerData.GrossFinding.length > 0 && <Text>
                      As Gross findings: {headerData.GrossFinding}
                      {" "}
                    </Text>}
                    <View style={{ minHeight: 1 }} ></View>

                    {headerData.RecommendationUnder != undefined && headerData.RecommendationUnder.length > 0 && <Text>
                      Recommendation Under:  {headerData.RecommendationUnder}
                    </Text>}
                    <View style={{ minHeight: 1 }} ></View>
                    <Text>
                      Reason 1:-	{headerData.Reason1}
                    </Text>

                    <Text>
                      Reason 2:-	{headerData.Reason2}
                    </Text>

                    <Text>
                      Reason 3:-	{headerData.Reason3}
                    </Text>




                    <View style={{ minHeight: 20 }} ></View>
                    <Text>
                      Thanking you.
                    </Text>
                    <Text>
                      For Vertias Invento Services
                    </Text>
                    <View style={{ minHeight: 5 }} ></View>

                    <Text>
                      Trigger Details
                      {" "}
                    </Text>
                    {headerData.TrigDetails.length > 0 && JSON.parse(headerData.TrigDetails).map((item, index) => {
                      return <Text style={{ marginTop: 4, marginLeft: 20 }}  >
                        {index + 1} {". "} {item.TrigDocs}
                      </Text>
                    })}
                    <View style={{ minHeight: 5 }} ></View>
                    <Text>
                      Trigger Asked:
                      {" "}
                    </Text>
                    {headerData.AskTrigDetails.length > 0 && JSON.parse(headerData.AskTrigDetails).map((item, index) => {
                      return <Text style={{ marginTop: 4, marginLeft: 20 }}  >
                        {index + 1} {". "} {item.AskDocs}
                      </Text>
                    })}
                    <View style={{ minHeight: 5 }} ></View>
                    <Text>VIS reply on those triggers:{" "}
                    </Text>


                    {headerData.AskTrigDetailsRep.length > 0 && <View style={styles.headercontainer} >

                      <Table data={JSON.parse(headerData.AskTrigDetailsRep)} >
                        <TableHeader>
                          <TableCell weighting={.1} style={styles.tableHeader} > SNO </TableCell>
                          <TableCell weighting={.4} style={styles.tableHeader} > Docs </TableCell>
                          <TableCell weighting={.4} style={styles.tableHeader}> Verify Remarks </TableCell>
                        </TableHeader>
                        <TableBody>
                          <DataTableCell weighting={.1} style={styles.tableHeader} getContent={(r) => r.slno} />
                          <DataTableCell weighting={.4} style={styles.tableHeader} getContent={(r) => r.TrigDocs} />
                          <DataTableCell weighting={.4} style={styles.tableHeader} getContent={(r) => r.InvVerifyRemarks} />

                        </TableBody>
                      </Table>


                    </View>
                    }
                    <View style={{ minHeight: 5 }} ></View>
                    <Text>
                      Ask Documents
                      {" "}
                    </Text>
                    {headerData.AskDocuments.length > 0 && JSON.parse(headerData.AskDocuments).map((item, index) => {
                      return <Text style={{ marginTop: 4, marginLeft: 20 }}  >
                        {index + 1} {". "} {item.DocName}
                      </Text>

                    })}
                    <View style={{ minHeight: 5 }} ></View>
                    <Text>
                      Ask Documents
                      {" "}
                    </Text>
                    {headerData.SharedDocuments.length > 0 && JSON.parse(headerData.SharedDocuments).map((item, index) => {
                      return <Text style={{ marginTop: 4, marginLeft: 20 }}  >
                        {index + 1} {". "} {item.DocName}
                      </Text>

                    })}
                    <View style={{ minHeight: 5 }} ></View>

<Text>
                      This report is submitted as unprejudice which is solicited for ultimate decision by insurance company.
                      {" "}
                    </Text>
                  </View>
                </View>



              </View>
            </Page>
          </Document>
        </PDFViewer> : null}
    </React.Fragment>
  );
}

export default InvoicePrint2;