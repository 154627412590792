import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const HolidayListEdit = (props) => {
  return (
    <Edit title="Machine Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <DateInput variant="outlined"  label="Holiday Date" source="date" fullWidth={true}  />
       <TextInput source="name" label='Holiday Name' fullWidth={true} validate={[required()]} />
       <TextInput source="description" label='Description ' fullWidth={true} validate={[required()]}  /> 
       <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true}/>
        
        
        </SimpleForm>
    </Edit>
  );
};
