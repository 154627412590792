import React from 'react';
import { Button, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";
import EventNoteIcon from '@material-ui/icons/EventNote';

export const useStyles = makeStyles(theme => ({
    cancel_button: {
        marginLeft: 20,
        marginRight: 20,
    },
    main: {
        display: "flex", width: "100%",
        justifyContent: "space-between",
    },
    btnparent: {
        flex: 1,
    },
}));


export const CreateToolbar = props => {
    const classes = useStyles({});
    const history = useHistory();
    let showSaveButton = false;

    const getResourceName = (url) => {
        if(url.indexOf("?") == -1)
            return url;
        const regex = /\/(.*?)\?/;
        const match = url.match(regex);
        if(match && match.length > 0) {
            return '/' + match[1];
        }
        return url;
    }
    if (props.permissions) {
        let resourcename = `/${props.resource}`;
        let myPermissions = props.permissions.filter(item => getResourceName(item.NavigateUrl.toLowerCase().trim()) == resourcename);
        if (myPermissions.length > 0) {
            let p = myPermissions[0];
            if ((p.Edit == 1 && props.record.id) || (p.Create == 1 && !props.record.id)) {
                showSaveButton = true;
            }
        }
    }
    const cancelForm = () => {
        history.goBack();
    }
    return (
        <Toolbar { ...props } >
            <div className={ classes.main }>
                <div className={ classes.btnparent }>
                    { showSaveButton && <SaveButton  { ...props } /> }
                    <Button
                        className={ classes.cancel_button }
                        variant="contained" color="primary"
                        label="Back" size="medium"
                        startIcon={ <CancelIcon /> }
                        onClick={ cancelForm }
                    />

                </div>
                { props.showdelete && <DeleteButton  { ...props } /> }
            </div>
        </Toolbar>
    )
};