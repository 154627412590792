import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const AllotmentCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        calimOrdId: props.calimOrdId
    }
    const handleSubmit = (formdata) => {
        let user = localStorage.getItem("userId");
        if (user) {
            user = parseInt(user);
        }
        //console.log(formdata);
        let m = moment(new Date());

        let data = {
            calimOrdId: parseInt(formdata.calimOrdId),
            employeeId: formdata.employeeId,
            otherDetails: formdata.otherDetails,
        }
        data.modifyBy = user;
        data.createdBy = user;
        data.modifyDate = m.toISOString();
        data.createdDate = m.toISOString();
        apiClient.addEntiyRecord("claim-ord-allots", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">

                <ReferenceInput
                    perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
                    filterToQuery={ searchText => ({ 'name~like': searchText }) }
                    validate={ [required()] } label="Employee Name" source="employeeId" reference="employees" fullWidth={ true }>
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
                </ReferenceInput>

                <TextInput multiline source="otherDetails" label="Remarks" fullWidth={ true } />
                {/* <BooleanInput source="isInsured" label="Insured" fullWidth={ true } formClassName={ classes.one_three_input } />
                <BooleanInput source="isHospitalised" label="Hospitalised" fullWidth={ true } formClassName={ classes.two_three_input } />
                <BooleanInput source="isCompleted" label="Completed" fullWidth={ true } formClassName={ classes.last_three_input } />

                <BooleanInput label="Reallotment 1" source="IsReallotment1" fullWidth={ true } formClassName={ classes.one_three_input } />
                <DateInput label="Reallotment 1 Date" source="Reallot1Date" fullWidth={ true } formClassName={ classes.two_three_input } />
                <TextInput label="Reallotment 1 Reason" multiline source="Reallot1Reason" fullWidth={ true }  />
                
                <BooleanInput label="Reallotment 2" source="IsReallotment2" fullWidth={ true } formClassName={ classes.one_three_input } />
                <DateInput label="Reallotment 2 Date" source="Reallot2Date" fullWidth={ true } formClassName={ classes.two_three_input } />
                <TextInput label="Reallotment 2 Reason" multiline source="Reallot2Reason" fullWidth={ true } /> */}


            </SimpleForm>
        </Create>
    );
}
export default AllotmentCreate;