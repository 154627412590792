import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, Create,AutocompleteInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";
import moment from 'moment';

const AllotmentEdit = props => {
    const classes = useStyles({});
    console.log(props);
    const handleSubmit = async (formdata) => {
        //console.log(formdata);
        let m = moment(new Date());
        let data = props.record;
        data.employeeId = formdata.employeeId;
        data.otherDetails = formdata.otherDetails;
        

        if(!data.createdBy) data.createdBy = 1;
          if(!data.createdDate) data.createdDate = m.toISOString();
          data.modifyBy = 1;
          data.modifyDate = m.toISOString();

        apiClient.updateEntiyRecord("claim-ord-allots", data).then(res => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit { ...props } id={ props.record.id } resource="claim-ord-allots" redirect={ false }>
            <SimpleForm
                onSubmit={ handleSubmit }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">
                <ReferenceInput
                    perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
                    filterToQuery={ searchText => ({ 'name~like': searchText }) }
                    validate={ [required()] } label="Employee Name" source="employeeId" reference="employees" fullWidth={ true }>
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
                </ReferenceInput>

                <TextInput multiline source="otherDetails" label="Remarks" fullWidth={ true } />
                {/* <BooleanInput source="isVerified" label="Verified" fullWidth={ true } />
                <TextInput source="foVerifyRemarks" label="FO Remarks" fullWidth={ true } />

                <BooleanInput source="isInsured" label="Insured" fullWidth={ true } formClassName={ classes.one_three_input } />
                <BooleanInput source="isHospitalised" label="Hospitalised" fullWidth={ true } formClassName={ classes.two_three_input } />
                <BooleanInput source="isCompleted" label="Completed" fullWidth={ true } formClassName={ classes.last_three_input } />

                <BooleanInput label="Reallotment 1" source="IsReallotment1" fullWidth={ true } formClassName={ classes.one_three_input } />
                <DateInput label="Reallotment 1 Date" source="Reallot1Date" fullWidth={ true } formClassName={ classes.two_three_input } />
                <TextInput label="Reallotment 1 Reason" multiline source="Reallot1Reason" fullWidth={ true } />

                <BooleanInput label="Reallotment 2" source="IsReallotment2" fullWidth={ true } formClassName={ classes.one_three_input } />
                <DateInput label="Reallotment 2 Date" source="Reallot2Date" fullWidth={ true } formClassName={ classes.two_three_input } />
                <TextInput label="Reallotment 2 Reason" multiline source="Reallot2Reason" fullWidth={ true } /> */}

            </SimpleForm>
        </Edit>
    );
}
export default AllotmentEdit;