import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput, NumberInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { toast } from "react-toastify";

const RefCustomerCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  let defaultValues = {
    pan: "",
    adharNo: '',
    bankName: "",
    bankAcNo: "",
    ifscCode: "",
    basicSalary: 0,
    monthlyTax: 0,
    isAbry: false,
    isPf: false,
    uan: "",
    isActive: true
  };
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };

    if (data['doj'] && data['doj'] != "") {
      let m = moment(data['doj']);
      data['doj'] = m.toISOString();
    }
    if (data['dol'] && data['dol'] != "") {
      let m = moment(data['dol']);
      data['dol'] = m.toISOString();
    }
    
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();

    apiClient.addRecord(data, "ref-customers").then(res => {
      notify("Ref Customers is added succesffully")
      props.onClose();

    }).catch(err => {
      notify("unable to add Ref Customer", "warning");
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      <h5>Add Ref Customer </h5>
      <SimpleForm variant="outlined"
        toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />}
        redirect="list"
        onSubmit={handleSubmit} initialValues={defaultValues} >

        <TextInput source="code" fullWidth={true} validate={[required()]} formClassName={classes.first_inline_input} />
        <TextInput source="name" label="Ref Customer Name" fullWidth={true} validate={[required()]} formClassName={classes.last_inline_input} />
        <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} formClassName={classes.first_inline_input}
          validate={[required()]} sort={{ field: 'userName', order: 'ASC' }}>
          <SelectInput optionText="userName" />
        </ReferenceInput>
        <ReferenceInput label="Type" fullWidth={true} validate={[required()]}
          formClassName={classes.last_inline_input}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={5000} source="typeId"
          filter={{ type: 'ET' }}
          reference="lookups" >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>


        <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} formClassName={classes.first_inline_input} />
        <TextInput source="designation" label="Designation" fullWidth={true} formClassName={classes.last_inline_input} />
        <TextInput source="pan" label="PAN" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput source="adharNo" label="Adhaar No" fullWidth={true} formClassName={classes.last_inline_input} />
        <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput source="bankName" label="Bank Name" fullWidth={true} formClassName={classes.last_inline_input} />
        <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} formClassName={classes.first_inline_input} />
        <TextInput source="uan" label="UAN" fullWidth={true} formClassName={classes.last_inline_input} />
        <DateInput source="dol" label="Date of Leaving" fullWidth={true} formClassName={classes.first_inline_input} />
        <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true} formClassName={classes.last_inline_input} />
        <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} formClassName={classes.first_inline_input} />
        <BooleanInput source="isAbry" label="is Abry" fullWidth={true} validate={[required()]} formClassName={classes.last_inline_input} />
        <BooleanInput source="isPf" label="Is Pf" fullWidth={true} validate={[required()]} formClassName={classes.first_inline_input} />
        <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} formClassName={classes.last_inline_input} />

      </SimpleForm>

    </React.Fragment>
  );
};
export default RefCustomerCreate;