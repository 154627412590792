import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const AreasCreate = (props) => {
  return (
    <Create title="Area Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
       <TextInput source="name"  fullWidth={true}  validate={[required()]}/>
        <TextInput source="location" fullWidth={true}  />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};
