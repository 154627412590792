import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField
}
    from 'react-admin';
import { Authorizer } from "../../common/Authorizer";

export const RoleTypesList = props => {
    const propsObj = Authorizer(props);
return(
<List bulkActionButtons={false} {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} filter={{ 'id~neq': 11 }}>
        <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
            <TextField source="roleName" label="Role Name"/>
            <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date"/>
        </Datagrid>
    </List>
)};