import React, { useState, useEffect } from 'react';
import GenericUploader from '../upload/GenericUploader';
import {
  Edit, TabbedForm, FormTab,
  ReferenceField,
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
  useRefresh,
  NumberField,
  BooleanInput,
  TextInput, DateInput, NumberInput, required, ReferenceInput, AutocompleteInput, SelectInput, FormDataConsumer
} from 'react-admin';
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import * as apiClient from "../../common/apiClient";
import { Drawer } from '@material-ui/core';
import { FormToolbar } from "../../common/FormToolbar";
import PolicyMap from './PolicyMap';
import OtherFields from './OtherFields';
import DocumentsCreate from './DocumentsCreate';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 200,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 0,
    marginRight: 5,
    width: 1350,
  },
}));


const PolicyCustomer = (props) => {
  const [customer, setCustomer] = useState(null);
  //console.log(props, "=== PolicyCustomer PROPS===");
  let policyID = props.record.policyId;

  const getCustomer = () => {
    apiClient.getCustomerByPolicyId(policyID).then(res => {
      //console.log(res, "==getOrderHeaderData==");
      if(res.length > 0){
        setCustomer(res[0]);
      }
    }).catch(err => console.log(err));
  }

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <div>
      {customer && customer.name }
    </div>
  )
}

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}

export const InvoiceEdit = props => {
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  const myclasses = myStyles({});
  const classes = useStyles({});
  const [yearData, setYearData] = useState([{ id: 2020 }, { id: 2021 }, { id: 2022 }, { id: 2023 }, { id: 2024 }, { id: 2025 }, { id: 2026 }, { id: 2027 }, { id: 2028 }, { id: 2029 }, { id: 2030 }])
  const [invoiceTypeData, setInvoiceTypeData] = useState([{ id: 2020, name: "Free Hand" }, { id: 2021, name: "Policy Claim" }])
  const [isPolicyMap, setIsPolicyMap] = useState(false);
  const [isaddDocuments, setIsaddDocuments] = useState(false);
  
  const [invoiceId, setinvoiceId] = useState(0);
  const [insurerId, setinsurerId] = useState(0);
  const refresh = useRefresh();
  const closeEdit = () => {
    setIsaddDocuments(false);
    setIsPolicyMap(false)
    refresh();
  }
  const onCancel = () => {
    setIsaddDocuments(false);
    setIsPolicyMap(false)
  }
  return (
    <React.Fragment>
      <Edit { ...props }> 
        <TabbedForm redirect="list" 
          variant="outlined" toolbar={ <FormToolbar { ...props } showDelete={ false } /> }>
          <FormTab label="Invoice Details">
            {/* <ReferenceInput perPage={ 25 } sort={ { field: 'name', order: 'ASC' } } validate={ [required()] }
            
              formClassName={ classes.first_inline_input }
              filterToQuery={ searchText => ({ 'name~like': searchText }) }
              label="Insurer" source="insurerId" reference="insurers" fullWidth={ true } >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
            </ReferenceInput>
            <ReferenceInput perPage={ 25 } sort={ { field: 'taxName', order: 'ASC' } }
              validate={ [required()] } formClassName={ classes.last_inline_input }

              label="Tax Type" source="taxTypeId" reference="tax-heads" fullWidth={ true } >
              <AutocompleteInput optionText="taxName" variant="outlined" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
            </ReferenceInput> */}
            <FormDataConsumer  >
                {formDataProps => (
                  
                  <OtherFields {...formDataProps}    />
                )}
                </FormDataConsumer>
                <ReferenceInput label="Branch" fullWidth={true} link={false}
                    perPage={5000} source="branchId"
                    validate={[required()]}    
                    reference="configs" 
                    formClassName={classes.one_three_input}
                    >
                    <SelectInput optionText="mconame" variant="outlined"  fullWidth={true} />
                </ReferenceInput>        
                <ReferenceInput label="placeOfSupply" fullWidth={true} link={false}
                    perPage={5000} source="placeOfSupply"
                    validate={[required()]}    
                    reference="states" 
                    formClassName={classes.two_three_input} 
                     sort={{ field: 'name', order: 'ASC' }}
                    >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} />
                </ReferenceInput>        
                <TextInput label="HSN No" source="hsnCode" fullWidth={true} initialValue={""} formClassName={classes.last_three_input} validate={[required()]} />
            <NumberInput disabled label="Tax Per" source="taxPercentage" validate={ [required()] }
              fullWidth={ true } formClassName={ classes.one_three_input } />

            <SelectInput optionText="id" label="Bill Year From" source="billYearFrom" fullWidth={ true } formClassName={ classes.two_three_input } choices={ yearData } />
            <SelectInput optionText="id" label="Bill Year To" source="billYearTo" fullWidth={ true } formClassName={ classes.last_three_input } choices={ yearData } />
            <DateInput validate={ [required()] } label="Invoice Date" source="invoiceDate" fullWidth={ true } formClassName={ classes.one_three_input } />
            <DateInput validate={ [required()] } label="From Date" source="fromDate" fullWidth={ true } formClassName={ classes.two_three_input } />
            <DateInput validate={ [required()] } label="To Date" source="toDate" fullWidth={ true } formClassName={ classes.last_three_input } />
            <SelectInput optionText="name" label="Invoice Type" source="invoiceTypeId" fullWidth={ true } formClassName={ classes.one_three_input } choices={ invoiceTypeData } />

            <ReferenceInput label="Brokerage Type" fullWidth={ true } link={ false }
              perPage={ 5000 } source="brokTypeId"
              filter={ { type: 'INVBT' } }
              reference="lookups"
              formClassName={ classes.two_three_input }
            >
              <SelectInput optionText="name" variant="outlined" fullWidth={ true } />
            </ReferenceInput>
            <NumberInput label="Amount" source="grossInvoiceAmount"
              fullWidth={ true } formClassName={ classes.last_three_input } />
            <TextInput multiline source="particulars" fullWidth={ true } validate={ [required()] } />

            <BooleanInput source="paymentRcvd" label="Payment Rcvd" initialValue={ true } />
            <DateInput label="Payment Rcv dDt" source="paymentRcvdDt" fullWidth={ true } />
            <TextInput multiline source="neftDetails" fullWidth={ true } />

          </FormTab>
          <FormTab label="Reconcile">
            <div style={ { flex: 1 } }>
              <Button style={ { marginRight: "10" } } onClick={ () => { setIsPolicyMap(true); } }>Add Policy</Button>
            </div>
            <ReferenceManyField fullWidth={ true }
              addLabel={ false }
              sort={ { field: 'id', order: 'ASC' } }
              reference="policy-inv-dets"
              perPage={5000}
              target="policyInvId">
              <Datagrid >

                <CDeleteButton { ...props } />
                <ReferenceField label="Policy Number" source="policyId"
                  reference="orders" link={ false }>
                  <TextField source="policyNo" />
                </ReferenceField>

                <PolicyCustomer label="Customer" />
                <NumberField source="recoAmount" />
                <TextField source="remarks" />
                <ReferenceField source="createdBy" reference="users" label="Created By" link={ false }>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={ false }>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
            <FormDataConsumer >
              { formDataProps => ( 
                //alert(formDataProps.formData.insurerId)
                setinsurerId(formDataProps.formData.insurerId)
              ) }
            </FormDataConsumer>

            
          </FormTab>
          <FormTab label="Documents">
            <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsaddDocuments(true); }}>Add  Document</Button>

            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="policy-inv-docs"
              target="policyInvId">
              <Datagrid>
                {isAdmin && <CDeleteButton {...props} />}

                {/* <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField> */}
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />
                
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
      <Drawer
        anchor="right"
        onClose={ onCancel }
        classes={ { paper: myclasses.drawerPaper, } }
        open={ isPolicyMap  || isaddDocuments }>

        { isPolicyMap && insurerId>0 && <PolicyMap
          onClose={ closeEdit }
          onCancel={ onCancel }
          invoiceId={ props.id }
          insurerId={ insurerId }
          isOpen={ isPolicyMap }
          { ...props } /> }

{isaddDocuments && <DocumentsCreate
          onClose={closeEdit}
          onCancel={onCancel}
          policyInvId={props.id}
          {...props} />}
      </Drawer>
    </React.Fragment>
  );
}