import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,AutocompleteInput,
  ReferenceInput,
  NumberInput,
  ImageField,
  SimpleForm,
  FormTab,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";
import { Addressfields } from "./Addressfields";


export const CustomerCreate = (props) => {


  const classes = useStyles({});
  let defaultValues = {

    isActive: false,
    isMultiBranch: false,
    pin: "",
    gstNo: "",
    countryId: 0,
    phone: "",
    mobile: "",
    industryTypeId: 0,
    paidCapitals: 0,
    rmid: 0,
    tcid: 0,
    cscid: 0,
    refCustomerId: 0,
    statusId: 0,
    stateId: 0,
    address1: "",
    address2: "",
    education: "",
    cityId: 0,
    gender: "",
    website: "",
    districtId: 0,
    adhaarNo: "",
    pan: ""
  };
  return (
    <Create title="Customer Create" { ...props }>
      <SimpleForm variant="outlined" redirect="list" toolbar={ <CreateToolbar { ...props } /> } initialValues={ defaultValues }>

        <ReferenceInput perPage={ 2000 } fullWidth={ true } label="Customer Type" source="entryTypeId" reference="entry-types" formClassName={ classes.one_three_input } sort={ { field: 'name', order: 'ASC' } } >
          <SelectInput optionText="name" validate={ [required()] } />
        </ReferenceInput>

        <TextInput source="name" label="Name" fullWidth={ true } validate={ [required()] } formClassName={ classes.two_three_input } />
        <TextInput source="shortName" label="Short Name" fullWidth={ true } validate={ [required()] } formClassName={ classes.last_three_input } />



        <TextInput source="gender" label="Gender" fullWidth={ true } formClassName={ classes.one_three_input } />
        <DateInput source="dob" label="Dob" fullWidth={ true } formClassName={ classes.two_three_input } />
        <TextInput source="pin" label="Pincode" fullWidth={ true } formClassName={ classes.last_three_input } />
        <TextInput multiline source="address1" fullWidth={ true } label="Address 1" formClassName={ classes.one_three_input } />
        <TextInput multiline source="address2" fullWidth={ true } label="Address 2" formClassName={ classes.two_three_input } />
        <TextInput source="phone" label="Phone" fullWidth={ true } formClassName={ classes.last_three_input } />



        <FormDataConsumer fullWidth={ true } >
          { formDataProps => (
            <Addressfields { ...formDataProps } />
          ) }
        </FormDataConsumer>
        <TextInput source="mobile" label="Mobile" fullWidth={ true } formClassName={ classes.one_three_input } />
        <TextInput source="email" label="Email" fullWidth={ true } formClassName={ classes.two_three_input } />


        <TextInput source="pan" label="Pan" fullWidth={ true } formClassName={ classes.last_three_input } />



        <ReferenceInput perPage={ 2000 } label="Industry Type" fullWidth={ true }
          source="industryTypeId" reference="industry-types" formClassName={ classes.one_three_input }
          sort={ { field: 'name', order: 'ASC' } } >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput perPage={ 2000 } label="Industry Class" fullWidth={ true }
          source="industryClassId" reference="industry-classes" formClassName={ classes.two_three_input }
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="RM" source="rmid" reference="employees" fullWidth={ true } formClassName={ classes.last_three_input } sort={ { field: 'name', order: 'ASC' } } >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="TC" source="tcid" reference="employees" fullWidth={ true } formClassName={ classes.one_three_input } sort={ { field: 'name', order: 'ASC' } } >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="CSC" source="cscid" reference="employees" fullWidth={ true } formClassName={ classes.two_three_input } sort={ { field: 'name', order: 'ASC' } } >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="Ref Customer" source="refCustomerId" reference="customers" fullWidth={ true } formClassName={ classes.last_three_input } sort={ { field: 'name', order: 'ASC' } } >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput source="gstNo" label="Gst No" fullWidth={ true } formClassName={ classes.one_three_input } />
        <TextInput source="adhaarNo" label="Adhaar No" fullWidth={ true } formClassName={ classes.two_three_input } />

        <BooleanInput source="isMultiBranch" label="Is Multi Branch" initialValue={ true } formClassName={ classes.last_three_input } />
        <BooleanInput source="isActive" label="Is Active" initialValue={ true } formClassName={ classes.one_three_input } />

        <ReferenceInput perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
          filterToQuery={ searchText => ({ 'name~like': searchText }) }
          label="Customer Group" source="custGroupId" reference="cust-groups" fullWidth={ true }
        >
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
        </ReferenceInput>

      </SimpleForm>

    </Create>
  );
};
