import React, { useCallback, useState,useRef,useEffect } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput, FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PaymentFields from '../components/PaymentFields';


const PremiumPaymentCreate = props => { 
    const scrollEndRef = useRef(null);
    const classes = useStyles({});
    const [brokrageData, setBrokrageData] = useState([]); 
    const initialValues = {} 
    const onBrokrageDataChange = (data) => {
        setBrokrageData(data);
    }
    const handleSubmit = (formdata) => {
        console.log(JSON.stringify(formdata));
        let m = moment(new Date());let user = localStorage.getItem("userId");
        if (user) {
            user = parseInt(user);
        }
        let data =   
        { 
            id: parseInt(formdata.id),
            orderId: parseInt(formdata.orderId),
            orderPremiumId: parseInt(formdata.orderPremiumId),
            premiumTypeId:parseInt(formdata.premiumTypeId),
            paymentDueDate: formdata.paymentDueDate,
            paymentNo: formdata.paymentNo,
            odPremiumAmt: parseFloat(formdata.odPremiumAmt),
            tpPremiumAmt: parseFloat(formdata.tpPremiumAmt),
            taxTypeId: parseInt(formdata.taxTypeId),
            taxPerc: parseFloat(formdata.taxPerc),
            taxAmt: parseFloat(formdata.taxAmt),
            paymentDate:formdata.paymentDate,
            paidModeId: formdata.paidModeId,
            paymentModeId:parseInt(formdata.paymentModeId),
            bankId: formdata.bankId,
            bankBranch: formdata.bankBranch,
            payRemarks: formdata.payRemarks,
            refNo: formdata.refNo,
            createdBy: parseInt(formdata.createdBy),
            createdDate:  formdata.createdDate,
            modifyBy: user,
            modifyDate: m.toISOString(), 

        } 
        if (data.refNo==null)
        {
            data.refNo="";
        }
        if (data.bankBranch==null)
        {
            data.bankBranch="";
        }
        if (data.payRemarks==null)
        {
            data.payRemarks="";
        }
        
        apiClient.updateRecord("order-payments", data).then(res => { 
            if(brokrageData.length>0)
            { 
                apiClient.updatebrokrages({data:brokrageData}).then(res => { 
                    props.onClose();
                }).catch(err => {
                    console.log(err);
                }); 
            }
            else
            {
                props.onClose();
            }
            
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Edit {...props} id={props.record.id} resource="order-payments" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={initialValues}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">  
                <FormDataConsumer  >
                {formDataProps => (
                  
                  <PaymentFields {...formDataProps} onBrokrageDataChange={onBrokrageDataChange }  />
                )}
                </FormDataConsumer>
                
            </SimpleForm>
        </Edit>
    );
}
export default PremiumPaymentCreate;

