import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const StateEdit = (props) => {
  return (
    <Edit title="State Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <ReferenceInput source="countryId" reference="countries"  fullWidth={true}  link={false} label="Country" >
                        <SelectInput source="name" />
                    </ReferenceInput>
        <TextInput source="stateCode" fullWidth={true} validate={[required()]} />
        <TextInput source="stateName" fullWidth={true} validate={[required()]} />
        
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true}/>
        </SimpleForm>
    </Edit>
  );
};
