import React from 'react';
import { List, Datagrid, TextField,EditButton,ShowButton, ReferenceField,DateField,NumberField} from 'react-admin';
import { DisplayDate } from '../../common/DisplayDate';

export const HolidayListList = props => {
    let propsObj = { ...props };
    
    if (propsObj.permissions) {
      let resourcename = `/${props.resource}`;
      let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
      if (myPermissions.length > 0) {
        let p = myPermissions[0];
        propsObj.hasList = (p.View == 1) ? true : false;
        propsObj.hasShow = (p.View == 1) ? true : false;
        propsObj.hasEdit = (p.Edit == 1) ? true : false;
        propsObj.hasDelete = (p.Delete == 1) ? true : false;
        propsObj.hasCreate = (p.Create == 1) ? true : false;
        propsObj.hasExport = (p.Export == 1) ? true : false;

      }
      if(!propsObj.hasDelete)
      {
        propsObj.bulkActionButtons=false;
      }
    }
    return (
        <React.Fragment>
            <List {...propsObj} exporter={propsObj.hasExport} title="List of Holiday" {...props} > 
            <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
                    {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
                    <TextField source="name" label="Holiday Name" />
                    <DisplayDate source="date" label="Holiday Date" locales="fr-FR" />
                    
                    <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifydate" label="Updated Date" locales="fr-FR"/>
        
        
                </Datagrid>
            </List>
        </React.Fragment>
    );
}