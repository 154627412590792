import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    first_inline_input:{
        display:'inline-flex',
        width:'48%'
    }, 
    last_inline_input: {
        display:'inline-flex',
        marginLeft: '4%',
        width:'48%'
    },
    one_three_input:{
        display:'inline-flex',
        width:'30%'
    },
    two_three_input:{
        display:'inline-flex',
        marginLeft: '5%',
        width:'30%'
    },
    last_three_input:{
        display:'inline-flex',
        marginLeft: '5%',
        width:'30%'
    },
    start_twice_4_input:{
        display:'inline-flex',
        marginRight: '1%',
        width:'48%'
    },
    start_thrice_4_input:{
        display:'inline-flex',
        marginRight: '1%',
        width:'73%'
    },
    one_4_input:{
        display:'inline-flex',
        width:'24%'
    },
    two_4_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'24%'
    },
    twice_4_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'49%'
    },
    thrice_4_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'75%'
    },
    three_4_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'24%'
    },
    three_4_half_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'12%'
    },

    three_4_input_with_add:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'20%'
    },
    last_4_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'24%'
    },
    last_4_half_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'12%'
    },

    one_5_input:{
        display:'inline-flex',
        width:'19%'
    },
    two_5_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'19%'
    },
    three_5_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'19%'
    },
    four_5_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'19%'
    },
    last_5_input:{
        display:'inline-flex',
        marginLeft: '1%',
        width:'19%'
    },


    richtexteditor_input:
    {
        
        height:'223%!important'
    },
    one_four_input:{
        display:'inline-flex',
        width:'30%',
        marginLeft: '5%',
    },
    two_four_input:{
        display:'inline-flex',
        marginLeft: '500%',
        width:'30%'
    },
    three_four_input:{
        display:'inline-flex',
        marginLeft: '5%',
        width:'30%'
    },
    last_four_input:{
        display:'inline-flex',
        marginLeft: '5%',
        width:'30%'
    },
    customtable: {
        width: "100%",
        padding: "0px 3px 2px 16px !important"
      },
      customtableTh: {
        width: "100%",
        fontSize:"8px !important"
      }, 
    customInput: {
        paddingTop: "0 !important",
        paddingBottom:"0 !important",
     },
     customdateInput: {
        width: "143px",
        fontSize: "12px",
     },

     customtable1: {
        width: "247%",
        padding: "0px 3px 2px 16px !important"
      },
      customtableTh1: {
        width: "100%",
        fontSize:"8px !important"
      }, 
    customInput1: {
        paddingTop: "0 !important",
        paddingBottom:"0 !important",
     },
     customdateInput1: {
        width: "143px",
        fontSize: "12px",
     },
     
     suggestionsContainerOpen: {
        zIndex: 2100,
      }  

}));