import React, {useEffect, useCallback,useState } from 'react';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";
import CustomEmail from '../components/CustomEmail';

const OrderEmail = props => {
    const classes = useStyles({});
    
    
     
    const handleSubmit = (formdata) => {
        console.log(formdata);
        let m = moment(new Date());
        // let data = {
        //     orderId: parseInt(formdata.ordId),
        //     insurerId: parseInt(formdata.insurerId),
        //     policyDetailId: parseInt(formdata.policyDetailId),
        //     odSumInsuredAmt: parseFloat(formdata.odSumInsuredAmt),
        //     odPremiumAmt: parseFloat(formdata.odPremiumAmt),
        //     tpSumInsuredAmt: parseFloat(formdata.tpSumInsuredAmt),
        //     tpPremiumAmt: parseFloat(formdata.tpPremiumAmt),
        //     netPremiumAmt:parseFloat(formdata.odPremiumAmt)+parseFloat(formdata.tpSumInsuredAmt),
        //     netSumInsuredAmt:parseFloat(formdata.odSumInsuredAmt)+parseFloat(formdata.tpSumInsuredAmt),
        //     remarks: formdata.remarks,
        //     isTaxable: formdata.isTaxable,
        //     createdBy: 0,
        //     modifyBy: 0,
        //     createdDate: m.toISOString(),
        //     modifyDate: m.toISOString(),

        // }
        formdata.emailType="NON_LIFE_ORDER_EMAIL"

        formdata.linkId=parseInt(props.ordId);
        if ( formdata.ccAddress==null)
        {
            formdata.ccAddress="";
        }
        formdata.createdAt= m.toISOString();
        formdata.isPending= true;
        formdata.errorMessage="";
        apiClient.addEntiyRecord("email-logs", formdata).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        
        
          <Create {...props} >
           <SimpleForm
                onSubmit={handleSubmit}
                
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                 <FormDataConsumer  >
                {formDataProps => (
                  
                  <CustomEmail {...formDataProps} ordId={props.ordId}   />
                )}
                </FormDataConsumer>
            </SimpleForm>
            
        </Create>
    
        
    );
}
export default OrderEmail;