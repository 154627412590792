import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput, NumberInput, BooleanInput, required, maxLength, minLength, DateInput } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../common/apiClient";

const initialValues = {
    pan: "",
    adharNo: '',
    bankName: "",
    bankAcNo: "",
    ifscCode: "",
    basicSalary: 0,
    monthlyTax: 0,
    dol: null,
    doj: null,
    isAbry: false,
    isPf: false,
    uan: "",
    isActive:true,

}

export const RefCustomerCreate = props => {
    // const defaultValues = { is_active: true, faculty_type: 'Teacher' }
    const transformData = (data: any) => {
        // Do all the Transformations Here //
        console.log(data, "=====data=====");
        if (data.doj)
            data = apiClient.padDate(data, "doj");
        if (data.dol)
            data = apiClient.padDate(data, "dol");
        return data;
    }
    return (
        <React.Fragment>
            <Create title="Create Ref Customer" {...props} transform={transformData}>
                <SimpleForm warnWhenUnsavedChanges redirect="list" toolbar={<FormToolbar {...props} />} initialValues={initialValues}
                    variant="outlined" >
                    <TextInput source="code" disabled={false} fullWidth={true} validate={[required()]} />
                    <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
                    <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} validate={[required()]} sort={{ field: 'userName', order: 'ASC' }}>
                        <SelectInput optionText="userName" />
                    </ReferenceInput>
                    <ReferenceInput label="Type" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}
                        perPage={5000} source="typeId"
                        filter={{ type: 'ET' }}
                        reference="lookups" >
                        <SelectInput optionText="name" variant="outlined" />
                    </ReferenceInput>
                    <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
                    <TextInput source="designation" label="Designation" fullWidth={true} />
                    <TextInput source="pan" label="PAN" fullWidth={true} />
                    <TextInput source="adharNo" label="Adhaar No" fullWidth={true} />
                    <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true} />
                    <TextInput source="bankName" label="Bank Name" fullWidth={true} />
                    <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
                    <TextInput source="uan" label="UAN" fullWidth={true} />
                    <DateInput source="dol" label="Date of Leaving" fullWidth={true} />
                    <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true} />
                    <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} />
                    <BooleanInput source="isAbry" label="is Abry" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isPf" label="Is Pf" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} />
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}