import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput, NumberInput, AutocompleteInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { toast } from "react-toastify";

const MotorClassDetails = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  let defaultValues = {
    isActive: true,
  };
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();

    apiClient.addRecord(data, "motor-models").then(res => {
      notify("Motor model is added succesffully");
      props.onClose();

    }).catch(err => {
      notify("unable to add Motor model ", "warning");
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      <h5>Add Motor Details </h5>
      <SimpleForm variant="outlined" basePath="motor-classes" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >

        <TextInput source="make" label="Motor Name" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />

        <NumberInput label="Seating Capacity" fullWidth={true} validate={[required()]} source="seatingCapacity" formClassName={classes.two_three_input} />
        <NumberInput label="CC" fullWidth={true} validate={[required()]} source="cc" formClassName={classes.last_three_input} />

        <ReferenceInput perPage={25} sort={{ field: 'class', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'class~like': searchText })}
          label="Motor Class" source="motorClassId" reference="motor-classes" fullWidth={true} formClassName={classes.one_three_input}>
          <AutocompleteInput optionText="class" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <ReferenceInput perPage={25} sort={{ field: 'make', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'make~like': searchText })}
          label="Motor Make" source="motorMakeId" reference="motor-makes" fullWidth={true} formClassName={classes.two_three_input}>
          <AutocompleteInput optionText="make" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <ReferenceInput perPage={25} sort={{ field: 'model', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'model~like': searchText })}
          label="Motor Model" source="motorModelId" reference="motor-models" fullWidth={true} formClassName={classes.last_three_input}>
          <AutocompleteInput optionText="model" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <ReferenceInput perPage={25} sort={{ field: 'variant', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'variant~like': searchText })}
          label="Motor Variant" source="motorVariantId" reference="motor-variants" fullWidth={true} formClassName={classes.one_three_input}>
          <AutocompleteInput optionText="variant" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <ReferenceInput perPage={25} sort={{ field: 'fuel', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'fuel~like': searchText })}
          label="Motor Fuel" source="motorFuelId" reference="motor-fuels" fullWidth={true} formClassName={classes.two_three_input}>
          <AutocompleteInput optionText="fuel" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <NumberInput label="GVW" fullWidth={true} validate={[required()]} source="gvw" formClassName={classes.last_three_input} />
        <TextInput label="RTO Location" fullWidth={true} source="rtoLoc" formClassName={classes.one_three_input} initialValue={""} />

        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} formClassName={classes.two_three_input} />

      </SimpleForm>

    </React.Fragment>
  );
};
export default MotorClassDetails;