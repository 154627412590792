import React, { useState, useCallback, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import { containerStyles } from "../../common/formStyles";
export const CalcFields = ({ formData, ...rest }) => {
    const form = useForm();
    const [grossAmt, setGrossAmt] = useState(0);
    const [taxPer, setTaxPer] = useState(0);
    useEffect(() => {
        apiClient.getdata_v1("tax-heads/" + formData.taxTypeId).then(res => {
            setTaxPer(parseFloat(res.taxpc));
        }).catch(err => {
            console.log(err);

        });
    }, []);
    const handleChange = async (event, identfier) => {

        if (identfier === 'taxTypeId') {
            await apiClient.getdata_v1("tax-heads/" + event.target.value).then(res => {
                setTaxPer(parseFloat(res.taxpc));
                let amt = formData.netPremium;
                if (isNaN(amt)) {
                    amt = 0;

                }
                let taxPer = parseFloat(res.taxpc);
                if (isNaN(taxPer)) {
                    taxPer = 0;

                }
                form.change("grossAmt", (amt + (amt * taxPer / 100)))

            }).catch(err => {
                console.log(err);

            });
            //
        }
        else {

            //debugger
            let amt = parseFloat(event.target.value);
            if (isNaN(amt)) {
                amt = 0;

            }

            if (isNaN(taxPer)) {

                form.change("grossAmt", (amt + (amt * 0 / 100)))
            }
            else { form.change("grossAmt", (amt + (amt * taxPer / 100))) }

        }

        //form.change("grossAmt",event.target.value)
        // if(rest.dependentFields && rest.dependentFields.length>0)
        // {
        //     for (let i = 0; i < rest.dependentFields.length; i++) {
        //         const innerElement = rest.dependentFields[i]; 
        //         form.change(innerElement , 0);
        //     }  
        // } 
    }
    const classes = containerStyles({});
    return (
        <React.Fragment>
            <div className={classes.one_four_input}>
                <NumberInput source="sumInsAmt" label="Sum Insured " variant="outlined" />
            </div>

            <div className={classes.two_four_input}>
                <NumberInput variant="outlined" source="netPremium" label="Net Premium "
                    onChange={(event) => handleChange(event, "netPremium")} />
            </div>
            <div className={classes.three_four_input}>
                <NumberInput variant="outlined" source="tpPremium" label="TP Premium "
                     />
            </div>

            <div className={classes.four_four_input}>
                <ReferenceInput variant="outlined" label="GST" source="taxTypeId" reference="tax-heads" formClassName={classes.three_four_input} sort={{ field: 'taxName', order: 'ASC' }} onChange={(event) => handleChange(event, "taxTypeId")}>
                    <SelectInput optionText="taxName" />

                </ReferenceInput>
            </div>
            <div className={classes.one_four_input}>
                <NumberInput variant="outlined" source="grossAmt" label="Gross Amt" initialValue={grossAmt} />
            </div>

        </React.Fragment>

    )
}
