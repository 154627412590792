import React from "react";
import {
  
  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const UsersEdit = (props) => {
  const classes = useStyles({});
  const propsObj = Authorizer(props);
  return (
    <Edit title="User Edit" {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <TextInput source="code"  validate={[required()]} formClassName={classes.one_three_input}/>
      <TextInput source="userName" formClassName={classes.two_three_input}  />
      <PasswordInput source="password" formClassName={classes.last_three_input}  />

      <ReferenceInput label="Role" source="roleTypeId" reference="role-types" formClassName={classes.one_three_input}>
              <SelectInput optionText="roleName" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput label="Type" formClassName={classes.two_three_input} 
                    perPage={5000} source="typeId"
                    filter={{ type: 'USR' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput>
       
        <DateInput source="startDate" formClassName={classes.last_three_input}/>
        <DateInput source="endDate" formClassName={classes.one_three_input}  />
        <NumberInput source="allowEditDays" label="Allow Editing For Days " formClassName={classes.two_three_input}  />
        <NumberInput source="allowAddDays" label="Allow Deletion For Days " formClassName={classes.last_three_input}/>
        <FormDataConsumer formClassName={classes.one_three_input}>
          {({ formData, ...rest }) =>
            formData.id && 
            <ReferenceInput  perPage={2000} label="Approver" source="approverId" reference="users" variant="outlined" formClassName={classes.one_three_input} filterToQuery={(id) => ({ 'id~neq': formData.id })} sort={{ field: 'userName', order: 'ASC' }}v>
            <SelectInput optionText="userName" formClassName={classes.one_three_input} variant="outlined" validate={[required()]} />
          </ReferenceInput> 
          }
        </FormDataConsumer>
        
        
      
        {/* <BooleanInput source="isSuperUser" validate={[required()]}  label="Super User" formClassName={classes.two_three_input}  />  */}
        <BooleanInput source="isActive" validate={[required()]}  label="Active"  initialValue={true} formClassName={classes.two_three_input}  />
        
        <FormDataConsumer formClassName={classes.last_three_input}>
          {({ formData, ...rest }) =>
            formData.typeId &&
            formData.typeId === 10 &&
             (
              <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" formClassName={classes.last_three_input} variant="outlined" >
                <SelectInput optionText="name"  validate={[required()]}  variant="outlined" formClassName={classes.last_three_input} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        
      </SimpleForm>
    </Edit>
  );
};
