import React, { useState, useEffect } from "react";
import moment from "moment";

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as apiClient from "../../common/apiClient";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  useNotify,
  BooleanInput,
  DateInput, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, useRedirect
} from "react-admin";
import Customfields from "./Customfields";
import { FormToolbar } from "../../common/FormToolbar";
import CustomSelectInput from "./CustomSelectInput";
import TEST from "./TEST";


export const RateMasterEdit = (props) => {
  const initialValues = {
   // policyTypId: [9]
  }
  const [isAllInsurer, setIsAllInsurer] = useState(false);
  const [isAllPolicyType, setIsAllPolicyType] = useState(false);
  const [insurerData, setInsurerData] = useState<any>([]);
  const [selectedInsurerData, setSelectedInsurerData] = useState<any>([]);
  const [policyTypeData, setPolicyTypeData] = useState<any>([]);
  const [selectedPolicyTypeData, setSelectedPolicyTypeData] = useState<any>([]);
  const [isLoadingInsurer, setIsLoadingInsurer] = useState(false);
  const [isLoadingPolicyType, setIsLoadingPolicy] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  useEffect(() => {
    console.log("calling fetch subjects");
    lodaData('insurer');

  }, []);
  useEffect(() => {

    lodaData('policy-types');
  }, []);

  const lodaData = async (resource) => {
    if (resource == 'insurer') {
      setIsLoadingInsurer(true);
      let data = `/rate-masters/getInsurer/${props.id}`
      await apiClient.getdata_v1(data).then(res => {
        setSelectedInsurerData(res);
        setIsLoadingInsurer(false);
      }).catch(err => {
        console.log(err);
        setIsLoadingInsurer(false);
      });
    }
    else {
      setIsLoadingPolicy(true);
      let data = `/rate-masters/getpolicytype/${props.id}`
      await apiClient.getdata_v1(data).then(res => {
        setSelectedPolicyTypeData(res);
        setIsLoadingPolicy(false);
      }).catch(err => {
        console.log(err);
        setIsLoadingPolicy(true);
      });
    }

  }

  const handleSubmit = async (formdata) => {
   
    console.log(formdata);
    let policyTypes: any = [];
    let insurers: any = [];
    let m = moment();
 
    for (let index = 0; index < selectedPolicyTypeData.length; index++) {
      const element = selectedPolicyTypeData[index];
      policyTypes.push(element.id)
    }

    for (let index = 0; index < selectedInsurerData.length; index++) {
      const element = selectedInsurerData[index];
      insurers.push(element.id)
    }

    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let data = { ...formdata }; 
      data.policyTypeId = 0;  
      data.insurerId = 0;
     
    if (data.hasOwnProperty("effectiveFrom") && data.effectiveFrom != null) {
      if (!data.effectiveFrom.toString().includes('T03:09:32.033Z')) {
        data.effectiveFrom = data.effectiveFrom + 'T03:09:32.033Z';
      }

    }
    if (data.hasOwnProperty("effectiveFrom") && data.effectiveFrom == null) {
      data.effectiveFrom = "1900-01-01T03:09:32.033Z";
    }
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString(); 
    data.remarks="";
    await apiClient.getdata_v1(`rate-masters/deletePolicyType/${props.id}`).then(ires => {  
    }).catch(err => {
      console.log(err);
    });
    await apiClient.getdata_v1(`rate-masters/deleteInsurer/${props.id}`).then(ires => { }).catch(err => {
      console.log(err);
    });
    await apiClient.updateRateMaster(data).then(res => {
      //{"id":14,"name":"ass","rateTypeId":101,"policyDepartmentId":12,"policyTypeId":0,"insurerId":0,"tpRatePercentage":1,"brokRatePercentage":1,"effectiveFrom":"2022-12-17T03:09:32.033Z","createdBy":1,"createdDate":"2022-12-18T08:53:53.500Z","modifyBy":1,"modifyDate":"2022-12-18T08:53:53.500Z","isAllPolicyType":true,"isAlInsurer":true,"isActive":true}
       
      
      if (policyTypes.length == 0 && insurers.length == 0) {
        redirect("/rate-masters");
        notify("Update Succesfully");
      }
      else {

        

        
        insurers.forEach(element => {
          let _data =
          {
            "rateId": parseInt(props.id),
            "insurerId": element,
            "createdBy": user,
            "createdDate": m.toISOString(),
            "modifyBy": user,
            "modifyDate": m.toISOString(),
            "isActive": true
          }
          apiClient.addEntiyRecord("rate-insurers", _data).then(ires => { }).catch(err => {
            console.log(err);
          });
        });



        policyTypes.forEach(element => {
          let _data =
          {
            "rateId": parseInt(props.id),
            "policyTypeId": element,
            "createdBy": user,
            "createdDate": m.toISOString(),
            "modifyBy": user,
            "modifyDate": m.toISOString(),
            "isActive": true
          }
          apiClient.addEntiyRecord("rate-policy-types", _data).then(ires => { }).catch(err => {
            console.log(err);
          });
        })

        redirect("/rate-masters");
        notify("Update Succesfully");
      }

    })
      .catch(err => {
        console.log(err);
      });
 

  }
  const handleInputChange = async (event, newValue, type, resource) => {
    if (type === 'input') {
      if (resource == 'insurer') {
        let data = `/insurers?filter[offset]=0&filter[limit]=25&filter[order]=name%20ASC&filter[where][name][like]=%${newValue}%`
        await apiClient.getdata_v1(data).then(res => {
          setInsurerData(res);
        }).catch(err => {
          console.log(err);
        });
      }
      else {
        let data = `/policy-types?filter[offset]=0&filter[limit]=25&filter[order]=name%20ASC&filter[where][name][like]=%${newValue}%`
        await apiClient.getdata_v1(data).then(res => {
          setPolicyTypeData(res);
        }).catch(err => {
          console.log(err);
        });
      }

    }
    else if (type === 'select') {
      if (resource == 'insurer') {
        setSelectedInsurerData(newValue)
      }
      else {
        setSelectedPolicyTypeData(newValue)
      }
    }

  }
  return (
    <Edit {...propsObj} title="Rate Master Edit">
      
      <SimpleForm  onSubmit={handleSubmit}
      //  toolbar={<FormToolbar {...propsObj} />}
      toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
       variant="outlined"
        initialValues={initialValues}
      >
        <TextInput source="name" fullWidth={true} label="Code" validate={[required()]} />
        <ReferenceInput label="Rate Type" validate={[required()]} fullWidth={true} source="rateTypeId" reference="lookups" perPage={5000}
          filter={{ type: 'RT' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer fullWidth={true} >
          {formDataProps => (
            <CustomSelectInput reference="policy-departments" selectedText={"--All--"} source={"policyDepartmentId"} optionText={"name"} label={"Policy Department"} fullWidth={true} {...formDataProps}

              dependentFields={["policyTypeId"]}
            />
          )}
        </FormDataConsumer>
        <BooleanInput
          onChange={(data) => {
            setIsAllPolicyType(data);
          }}
          source="isAllPolicyType" label="All Policy Type" fullWidth={true} initialValue={false} />

        {!isAllPolicyType && !isLoadingPolicyType &&
          <Autocomplete
            fullWidth={true}
            multiple
            options={policyTypeData}
            onChange={(event, newValue) => {
              handleInputChange(event, newValue, "select", 'policy-types')
            }}
            getOptionLabel={(option: any) => option.name}
            defaultValue={[...selectedPolicyTypeData]}
            onInputChange={(event, newInputValue) => {
              handleInputChange(event, newInputValue, "input", 'policy-types')
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Policy Type" placeholder="Policy Type" />
            )}
          />
        }

        <BooleanInput
          onChange={(data) => {
            setIsAllInsurer(data);
          }}
          source="isAlInsurer" label="All Insurer" fullWidth={true} initialValue={false} />



        {!isAllInsurer && !isLoadingInsurer &&
          <Autocomplete
            fullWidth={true}
            multiple
            options={insurerData}
            onChange={(event, newValue) => {
              handleInputChange(event, newValue, "select", 'insurer')
            }}
            getOptionLabel={(option: any) => option.name}
            defaultValue={[...selectedInsurerData]}
            onInputChange={(event, newInputValue) => {
              handleInputChange(event, newInputValue, "input", 'insurer')
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="insurers" placeholder="insurers" />
            )}
          />
        }


        <NumberInput variant="outlined" label="TP Rate Percentage" source="tpRatePercentage" fullWidth={true} />
        <NumberInput variant="outlined" label="Brok Rate Percentage" source="brokRatePercentage" fullWidth={true} />
        <DateInput label="Effective From" source="effectiveFrom" fullWidth={true} />
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} />

      </SimpleForm>
    </Edit>
  );
};
