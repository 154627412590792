import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,FormDataConsumer,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";
import RefPayment from '../components/RefPayment';

const RefPaymentEdit = props => {
    const classes = useStyles({});
    const initialValues = {
    // /    realizOrdId: props.realizOrdId
    }
    const handleSubmit = (formdata) => {
        console.log(formdata);

        let user = localStorage.getItem("userId");
            if (user) {
            user = parseInt(user);
            } 
            formdata.id=parseInt(props.record.id); 
            formdata.modifyBy= user;   
            formdata.modifyDate= moment().toISOString();
            formdata.orderId= parseInt(props.ordId);
            // if (formdata.hasOwnProperty("paymentDate") && formdata.paymentDate != null) {
            //     let m = moment(formdata.paymentDate );
            //     formdata.paymentDate = m.toISOString();  
            //   }
              // else
              // {
              //   let m = moment('1900-01-01T18:38:50.000ZT03:09:32.033Z');
              //   formdata.paymentDate=m.toISOString();  
              // }
              
              
              if (formdata.hasOwnProperty("taxPerc") && formdata.taxPerc != null) {
                
                formdata.taxPerc = parseFloat(formdata.taxPerc);  
              }
              if (formdata.hasOwnProperty("taxAmt") && formdata.taxAmt != null) {
                
                formdata.taxAmt = parseFloat(formdata.taxAmt);  
              }
              if (formdata.hasOwnProperty("refAmount") && formdata.refAmount != null) {
                
                formdata.refAmount = parseFloat(formdata.refAmount);  
              }
              debugger
              if (formdata.paymentDate === null) {
                
                
                formdata.paymentDate='1900-01-01';  
              }
                
              
              
              
            
                apiClient.updateRecord("order-ref-payments", formdata).then(res => {
            
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
      
    }
    return (
        <Edit {...props} id={props.record.id} resource="order-ref-payments" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit} 
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                    <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
              Ref Payment Detail
            </div>
            
            <FormDataConsumer>
              {formDataProps => 
             <RefPayment {...formDataProps} isEdit={true} {...props} />
              }
            </FormDataConsumer>
                
            </SimpleForm>
        </Edit>
    );
}
export default RefPaymentEdit;