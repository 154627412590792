import React, { useState } from "react";
import {
  NumberInput,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../common/apiClient";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const PolicyMap = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const [data, SetData] = useState([])
  const [fromDate, SetFromDate] = useState(null)
  const [toDate, SetToDate] = useState(null)
  const [selectAll, SetSelectAll] = useState(false)
  const [pageSize, setPageSize] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [serachText, setSerachText] = useState("")
  const [selectValue, setSelectValue] = useState("PolicyNo")
  const [filterData, setFilterData] = useState([{ id: "PolicyNo", name: "Policy No" }, { id: "CustomerName", name: "Customer Name" }])
  const [slicedData, SetSlicedData] = useState([])
  const [isLoading, SetIsLoading] = useState(false)
  
  const handleChange = async (index, event, identifier,item) => {
    debugger
    let _data = [...data];
    let _slicedData = [...slicedData];
    if (identifier === "isSelected") {
      _slicedData[index][identifier] = event.target.checked;
    }
    else {
      _slicedData[index][identifier] = event.target.value;
    }


    let element=_slicedData[index];
    let    mainIndex=_data.indexOf(item);  
    if (identifier === "isSelected") {
      _data[mainIndex][identifier] = event.target.checked;
    }
    else {
      _data[mainIndex][identifier] = event.target.value;
    }
    SetSlicedData(_slicedData);
    SetData(_data);
    //SetSelectAll(event.target.value);
  }
  const handleSelectAll = async (event) => {
    SetSelectAll(event.target.checked);
    let _data = [...data];
    for (let index = 0; index < _data.length; index++) {
      _data[index]["isSelected"] = event.target.checked;
    }
    SetData(_data);

    let sliceddata = [];
        for (let index = 0; index < _data.length; index++) {
          if (index < (pageSize * currentPage)) {
            const element = _data[index];
            sliceddata.push(element);
          }
        }
        SetSlicedData(sliceddata);
  }
  const loadData = async () => {
    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    //debugger
    let invoiceId = parseInt(props.invoiceId); 
    
    let fDate = fromDate;
    let tDate = toDate;

    //"modifyBy":1,"createdBy":1,"modifyDate":"2021-02-28T04:49:33.182Z","createdDate":"2021-02-28T04:49:33.182Z"
    apiClient.getPolicy(invoiceId, fDate, tDate).then(res => { 
      SetData(res)
      let sliceddata = [];
      for (let index = 0; index < res.length; index++) {
        if (index < (pageSize * currentPage)) {
          const element = res[index];
          sliceddata.push(element);
        }
      }
      SetSlicedData(sliceddata);
      setTotalPage(Math.ceil(res.length/pageSize));

    }).catch(err => {
      notify("unable to add succesffully", "warning");
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.log(err);
    });
  }

  const goto = async (direction) => {
    //debugger
    if (direction === "P") {
      let curr_page = currentPage;
      let curr_page_size = pageSize;
      let total_page = totalPage;
      SetSlicedData([]);
      let _data = [];
      let j = pageSize;
      let toRow = pageSize * (currentPage - 1);
      if ((currentPage - 1) <= 1) {
        j = 0;
      }
      else {
        j = pageSize * ((currentPage - 2))
      }


      let i = 0;
      for (i = j; i < data.length; i++) {
        if (i < toRow) {
          const element = data[i];
          _data.push(element);
        }
        SetSlicedData(_data);
        setCurrentPage(currentPage - 1)
      }

    }
    else {
      let curr_page = currentPage;
      let curr_page_size = pageSize;
      let total_page = totalPage;
      SetSlicedData([]);
      let _data = [];
      let i = 0;
      for (i = (pageSize * currentPage); i < data.length; i++) {
        if (i < (pageSize * (currentPage + 1))) {
          const element = data[i];
          _data.push(element);
        }
        SetSlicedData(_data);
        setCurrentPage(currentPage + 1)
      }
    }

  }

  const handleSubmit = async (formdata) => {

     let IsValid=true;      let filterData=data.filter(x=>x.isSelected==true);
     if(filterData==undefined || filterData==null || filterData.length===0)
     {
  
      toast.error("Validation issue : Please select row to save" , {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
     }
 
     for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if(element.isSelected==true)
      {
        if (element.RecoAmount==undefined || element.RecoAmount==null || element.RecoAmount<=0)
        {

          toast.error("Validation issue : Please Enter valid Reco Amount for Policy No:-"+ element.PolicyNo , {
            position: "bottom-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          })
          IsValid=false;
          break;
        }
      }
      
     }
    
     if(!IsValid)
     {
       
      return;
    }

    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
     SetIsLoading(true)
    let _data = {};
    _data.policydata = [...filterData];
    _data.userId = user;
    _data.invoiceid=parseInt(props.invoiceId); 
    apiClient.mappolicy(_data).then(res => {
      if(res[0].result)
      {
        notify("Policy is added succesffully")
        props.onClose();
      }
      else
      {
        
      toast.error("Validation issue : " + res[0].message, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      }
      SetIsLoading(false)

    }).catch(err => {
     
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      SetIsLoading(false)
      console.log(err);
    });
  }
  const filterGrid = async (event) => {
//debugger
    setSerachText(event.target.value);
    if (event.target.value && event.target.value.length > 0) {
      let _data = [];
      {
        data.filter(item => item[selectValue].toLowerCase().includes(event.target.value.toLowerCase())).map(filteredName => (

          _data.push(filteredName)
        ))
      }

      SetSlicedData([..._data])
    }
    else {
      let _data = [];
      for (let index = 0; index < data.length; index++) {
        if (index < (pageSize * 1)) {
          const element = data[index];
          _data.push(element);
        }
        SetSlicedData([..._data]);
        setCurrentPage(1);



      }
    }


  }

  return (
    <React.Fragment>
      <h5>Policy Map </h5>
      {/* <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onCancel} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit}  > */}
      <SimpleForm variant="outlined" toolbar={null} redirect="list" onSubmit={null}  >
        <div style={{ display: "flex", width: "100%",marginTop:"-32px" }}>
          <div style={{ display: "flex", width: "40%" }}>
            <DateInput variant="outlined" source="fromDate" label="From Date"
              onChange={(data) => {
                SetFromDate(data.currentTarget.value);
              }}
              fullWidth={true} initialValue={fromDate}  ></DateInput>
          </div>
          <div style={{ display: "flex", width: "40%", marginLeft: "2%" }}>
            <DateInput variant="outlined" source="toDate" label="To Date"
              initialValue={toDate}
              onChange={(data) => {
                SetToDate(data.currentTarget.value);
              }}
              fullWidth={true}></DateInput>
          </div>
          <div style={{ display: "flex", width: "16%", height:"30px", marginLeft: "2%",marginTop:10 }}>
            <Button variant="contained" color="primary" onClick={loadData}>Load Policy</Button>
          </div>

        </div>
        

        {data.length > 0 &&
          <div style={{ display: "flex", fontSize: "19px", marginTop: "0px", width: "100%" }} >
<div style={{ width: "100px" ,textAlign:"center",marginTop: "12px",verticalAlign:"middle"}}>
            Search  {"  "}
            </div>


            <div style={{ width: "300px" }}>
              <SelectInput variant="outlined" optionText="name" fullWidth={true} label="Field" source="billYearFrom" onChange={(event) => setSelectValue(event.target.value)} choices={filterData} initialValue={selectValue} />
            </div>
            <div style={{ marginLeft: "12px",width: "300px" }}>
              <TextInput variant="outlined" source="a" fullWidth={true} label="Search Text" onChange={(event) => filterGrid(event)} initialValue={serachText} />

            </div>
          </div>

        }
        <div style={{ width: "100%",minHeight:10 }} >
          
        </div>

        
        <Table className={classes.customtable} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell ><input type="checkbox" checked={selectAll} title="Select All" onChange={handleSelectAll} /></TableCell>
              
              <TableCell >Order_No</TableCell> 
              <TableCell style={{width:"200px !important"}} >Customer_Name	</TableCell>
              <TableCell >Brokrage_Type	</TableCell>
              <TableCell >Policy_Type	</TableCell>
              <TableCell >Policy No	</TableCell>
              <TableCell >Policy_Issue_Date	</TableCell>
              <TableCell >Policy_End_Date	</TableCell>
              <TableCell >End.No	</TableCell>
              <TableCell >OD.Prem.Amt	</TableCell>
              <TableCell >TP.Premium.Amt	</TableCell>
              <TableCell >Rate%	</TableCell>
              <TableCell >Comm. Amount	</TableCell>
              <TableCell >Already_Reco_Amt	</TableCell> 
              {/* <TableCell >OrderBrokrageid	</TableCell>
              <TableCell >GrossSumInsuredAmt	</TableCell>
              <TableCell >RateCode</TableCell> */}
              <TableCell >Reco Amount	</TableCell>
             

            </TableRow>
          </TableHead>
          <TableBody >
            {slicedData.map((item, index) => {
              return <tr>
                <TableCell>
                  <input type="checkbox" checked={item["isSelected"]} value={item["isSelected"]} title="Select All" onChange={(event) => handleChange(index, event, "isSelected",item)} />
                </TableCell>
                <TableCell>{item.OrderNo	}</TableCell>
                <TableCell>{item.CustomerName	}</TableCell>
                <TableCell>{item.BrokrageType	}</TableCell>
                <TableCell>{item.PolicyType	}</TableCell>
                <TableCell>{item.PolicyNo	}</TableCell>
                <TableCell>{item.PolicyIssueDate	}</TableCell>
                <TableCell>{item.PolicyEndDate	}</TableCell>
                <TableCell>{item.EndNo	}</TableCell>
                <TableCell>{item.ODPremAmt	}</TableCell>
                <TableCell>{item.TPPremiumAmt	}</TableCell>
                <TableCell>{item.RatePercentage	}</TableCell>
                <TableCell>{item.CommissionAmount	}</TableCell>
                {/* <TableCell>{item.AlreadyReconcAmt	}</TableCell>
                
              <TableCell>{item.OrderBrokrageid}</TableCell>
              <TableCell>{item.GrossSumInsuredAmt	}</TableCell> */}
              <TableCell>{item.AlreadyReconcAmt}</TableCell>
                  
                <TableCell>
                  <input type="number" value={item.RecoAmount} onChange={(event) => handleChange(index, event, "RecoAmount",item)} />
                </TableCell>
              </tr>
            })}
          </TableBody>
        </Table>
        
       
        {serachText.toString().trim().length == 0 && slicedData.length > 0 && <div style={{ textAlign: "center", minWidth: "100%", marginBottom: "50px" }}>
          <div style={{ display: "flex", height: "30px", width: "100%", padding: "2px" }}  >
            <div style={{ width: "200px" }} >

              <h6> Page Size: {pageSize}</h6>

            </div>
            <div style={{ width: "200px" }} >

              <h6>Current Page: {currentPage}</h6>
            </div>
            <div style={{ width: "200px" }} >
              <h6>Total Pages:{totalPage}</h6>
            </div>
            <div style={{ marginLeft: 40, marginTop: "10px" }}  >
              <button type="button" onClick={() => goto("P")} disabled={currentPage === 1}>Previous Page</button>
            </div>
            <div style={{ marginLeft: 40, marginTop: "10px" }}  >
              <button type="button" onClick={() => goto("N")} disabled={(currentPage === totalPage || 0 === totalPage)} >Next Page</button>
            </div>
          </div>

        </div>
        }
  <div style={{ display: "flex", width: "100%", height:"30px", marginLeft: "2%",marginTop:10 }}>
    <div>
 {slicedData.length>0 &&    <Button variant="contained" disabled={isLoading} color="primary" onClick={handleSubmit}>{isLoading?"Saving": "Save"}</Button>}
    </div> 
    <div style={{  marginLeft: "2%" }}>
    <Button variant="contained" color="primary" onClick={()=>props.onClose()}>Back</Button>
    </div>
  </div>

      </SimpleForm>

    </React.Fragment>
  );
};
export default PolicyMap;