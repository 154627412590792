import { Button } from '@material-ui/core';
import React from 'react';
import { List, Datagrid, TextField, BooleanField, EditButton, ReferenceField, DateField, NumberField,useRedirect } from 'react-admin';
import { Authorizer } from '../../common/Authorizer';
import { DisplayDate } from '../../common/DisplayDate';
  
export const EmployeeLeaveList = props => {
    const redirect = useRedirect();
    const propsObj = Authorizer(props);
    let employeeId: any
    employeeId = localStorage.getItem("userId");
    if (employeeId) {
        employeeId = parseInt(employeeId);
    }
    
    const CustomEditButton = (props) => {
        let { record } = props;
        let leavebutton:any;
        leavebutton="";
        if(!record.isApproved && !record.isReject)
        {
            leavebutton=<Button onClick={()=>redirect(`employee-leaves-approve/${record.id}`)} >Approve/Reject</Button>
        } 
        return leavebutton;
      }
    
    return (
        <React.Fragment>
            <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="List of Leaves" {...props} filter={{ approverId: employeeId }}   sort={{ field: 'approvedFromDate', order: 'desc' }}  >
                <Datagrid rowClick={false}> 
                    {propsObj.hasEdit &&  <CustomEditButton label="" source="id"/>}
                    <ReferenceField source="employeeId" reference="employees" label="Employee" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="leaveTypeId" reference="leave-types" label="Leave Type" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <DisplayDate source="fromDate" label="From Date" locales="fr-FR" />
                    <DisplayDate source="toDate" label="To Date" locales="fr-FR" />
                    <TextField label="Total Days" source="totalDays"/>
                    <DisplayDate source="fromDate" label="From Date" locales="fr-FR" />
                    <DisplayDate source="toDate" label="To Date" locales="fr-FR" />
                    <TextField  label="Total Days" source="totalDays"/>
                    <DisplayDate label="Approved From Date" source="approvedFromDate" locales="fr-FR" />
                    <DisplayDate label="Approved To Date" source="approvedToDate" locales="fr-FR"/>
                    <TextField label="Total Approved Days" source="totalApprovedDays"  />
                    <TextField source="approverRemarks" label='Approver Remarks' fullWidth={true}/>   
                    <BooleanField source="isApproved" label="Is Approved"/>
                    
                    <ReferenceField source="approverId" reference="users" label="Approver" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <BooleanField source="isReject" label="Is Rejected"/>
                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />  
                </Datagrid>
            </List>
        </React.Fragment>
    );
}