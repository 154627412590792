import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput,NumberInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { toast } from "react-toastify";

const PolicyDepartmentCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  let defaultValues = {
    isUnderIrda: false,
    isActive: true,
    code:"-",
    isHealthType:false,
    isMotorDetails:false,
    maxTpRewardRate:0,
    maxOdRewardRate:0,
    maxTpBrokRate:0,
    maxOdBrokRate:0,
    policyExpiryDays:0 
  };
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();

    apiClient.addRecord(data, "life-policy-departments").then(res => {
      notify("Policy Department is added succesffully");
      props.onClose();

    }).catch(err => {
      notify("unable to add Policy Department", "warning");
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      <h5>Add Policy Department </h5>
      <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >

        <TextInput source="code" fullWidth={true} validate={[required()]}  formClassName={classes.first_inline_input} />
        <TextInput source="name"  fullWidth={true}  validate={[required()]}  formClassName={classes.last_inline_input} /> 
       <NumberInput  variant="outlined" label="Max TP Reward Rate" source="maxTpRewardRate" fullWidth={true}   formClassName={classes.first_inline_input} />
       <NumberInput  variant="outlined" label="Max Od Reward Rate" source="maxOdRewardRate" fullWidth={true}   formClassName={classes.last_inline_input} />
       <NumberInput  variant="outlined" label="Max TP Brok Rate" source="maxTpBrokRate" fullWidth={true}   formClassName={classes.first_inline_input} />
       <NumberInput  variant="outlined" label="Max OD Brok Rate" source="maxOdBrokRate" fullWidth={true}   formClassName={classes.last_inline_input} />
       <NumberInput  variant="outlined" label="Policy Expiry Days" source="policyExpiryDays" fullWidth={true}    />
       
       <BooleanInput source="isHealthType" validate={[required()]}  label="Is Health Type"  formClassName={classes.one_three_input}/>
       <BooleanInput source="isUnderIrda" validate={[required()]}  label="Under IRDA" formClassName={classes.two_three_input}/>
       {/* <BooleanInput source="isMotorDetails" validate={[required()]}  label="Is Motor Details" formClassName={classes.last_three_input}/> */}

        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} formClassName={classes.last_three_input}/>
        
        
      </SimpleForm>

    </React.Fragment>
  );
};
export default PolicyDepartmentCreate;