import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput, 
  SearchInput
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";


const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Tax Code" source="taxCode~like" alwaysOn />
    <SearchInput variant="outlined" label="Tax Name" source="taxName~like" alwaysOn />
  </Filter>
);
export const TaxheadList = (props) => {
  const propsObj: any = Authorizer(props);
  return (
    <List {...props} title="Taxhead List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
        <TextField source="taxCode" label="Tax Code" />
        <TextField source="taxName" label="Tax Name" />
        <NumberField source="taxpc" label="Tax %" />
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="createdDate" label="Created Date" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="modifyDate" label="Updated Date" />


      </Datagrid>
    </List>
  );
};
