import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
  FormDataConsumer, AutocompleteInput,
  Edit, TabbedForm, TextInput, DateInput, ReferenceInput, SelectInput, required, BooleanInput, FormTab, useRefresh, ReferenceManyField, ReferenceField, Datagrid, NumberInput, TextField,
  DateField
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CDeleteButton from "../../common/CDeleteButton";



import DocumentsCreate from "./DocumentsCreate";

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));



const OuterComponent = (props) => {

  return (
    props.children
  )
}
export const userStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: 100,
  },
}));

// export const EditButton = props => {

//   const setHospitalDetId = () => {
//       props.setHospitalDetEdit(props.record.id);
//   }
//   return (
//       <Button
//           color="secondary" disableElevation
//           size="medium"

//           onClick={setHospitalDetId}
//           startIcon={<EditIcon />}>Edit</Button>
//   )
// };

export const AcitivityEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const classes = useStyles({});

  const [isAddDocument, setIsAddDocument] = useState(false);
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(null);

  const myclasses = myStyles({});
  const refresh = useRefresh();

  const DisplayFileLink = (props) => {
    let { record } = props;

    if (record.docUrl && record.docUrl.length > 0)
      return (
        <a href={record.docUrl}>View File</a>
      )
    else {
      return null
    }
  }

  const EditButton = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<EditIcon />}>Edit</Button>

  }
  const closeEdit = () => {
    setIsAddDocument(false);
    setIsEditDocumentRecord(null);
    refresh();
  }


  const onCancel = () => {
    setIsAddDocument(false);
    setIsEditDocumentRecord(null);

  }

  const handleClose = useCallback(() => {
    setIsAddDocument(false);
    setIsEditDocumentRecord(null);

  }, []);

  return (
    <React.Fragment>
      <Edit title="Conv Order Edit" {...propsObj}>

        <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}>
          <FormTab value="DETAILS" label=" Detail">

            <TextInput source="orderNo" label="Order No" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} disabled />
            <DateInput source="date" label="Date" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} disabled />
            <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="location" label="Location" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="from" label="From" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="to" label="To" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />


            <TextInput source="client" label="Client" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="purpose" label="Purpose" fullWidth={true} multiline validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="mode" label="Mode" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />


            <NumberInput source="amount" label="Amount" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
            <NumberInput source="advAmt" label="Adv Amt" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} initialValue={0} />
            <NumberInput source="balAmt" label="Bal Amt" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} initialValue={0} />


            <NumberInput source="paidAmount" label="Paid Amount" fullWidth={true} formClassName={classes.one_three_input} />
            <DateInput source="paidOn" label="Paid On" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />

            <TextInput source="remarks" label="Remarks" fullWidth={true} multiline formClassName={classes.last_three_input} />


            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
              formClassName={classes.one_three_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Approved By" source="approvedBy" reference="employees" fullWidth={true}
              validate={[required()]}
            >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => {

                return val && val.trim().length >= 1
              }} />
            </ReferenceInput>

          </FormTab>
          <FormTab label="Documents">
            <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddDocument(true); }}>Add New Document</Button>

            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="conv-order-documents"
              target="ordId">
              <Datagrid>
                {<CDeleteButton {...props} />}

                {/* <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField> */}
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>


        </TabbedForm>


      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isAddDocument} >
        {isAddDocument && <DocumentsCreate
          onClose={closeEdit}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}


      </Drawer>

    </React.Fragment>
  );
};
