import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";
import { FormToolbar } from "../../common/FormToolbar";

export const AreasEdit = (props) => {
  
  const propsObj = Authorizer(props);
  console.log(propsObj, "AREA propsObj===");
  return (
    <Edit {...propsObj}  title="Action Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/>
        <TextInput source="location" fullWidth={true}  />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>
      </SimpleForm>
    </Edit>
  );
};
