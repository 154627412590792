import React, { useState, useEffect, useRef } from 'react';
import * as apiClient from "../../../common/apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../../formStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const RefPayment = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    const [rateMaster, setRateMaster] = useState([]);

    // useEffect(() => { 
    //     loadRateMaster(); 

    // }, []);


     
    const loadRefAmount = (paymentId,refOn,refPer) => {
        apiClient.getdata_v1(`/orders/getRefAmount/${paymentId}/${refOn}/${refPer}`).then(res => { 
            form.change("refAmount", TwoDecimalPlaces(res[0].refAmount));
            calculateAmount(formData.odPremiumAmt, formData.tpPremiumAmt, res.taxpc);
        }).catch(err => {
            console.log(err);
        });
    }
    const loadTaxHead = (id) => {
        apiClient.getTaxHead(id).then(res => {

            form.change("taxPerc", TwoDecimalPlaces(res.taxpc));
            calculateAmount(0, formData.refAmount, res.taxpc);
        }).catch(err => {
            console.log(err);
        });
    }
    const TwoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).valueOf();
    }
    const onAmountChange = (e, field) => {

        if (field == "taxTypeId") {
            loadTaxHead(e.target.value)
        }
        if (field == "refPercentage" || field=='orderPaymentId' || field=='premiumTypeId' ) {
            form.change("refOn",null)
            form.change("refAmount",null)
            form.change("taxTypeId",null)
            form.change("taxPerc", null);
            if(field=='premiumTypeId')
            {
                form.change("orderPaymentId", null);
            }
            
            
        }
        
        if (field == "refOn") {
            loadRefAmount(formData.orderPaymentId,e.target.value,formData.refPercentage)
        }
        
        if (field == "odPremiumAmt") {
            calculateAmount(e.target.value, formData.tpPremiumAmt, formData.taxPerc);
        }
        if (field == "tpPremiumAmt") {
            calculateAmount(formData.odPremiumAmt, e.target.value, formData.taxPerc);
        }


    }
    const calculateAmount = (amount, tpAmount, percent) => {
        //debugger
        if (percent) {
            if (percent <= 0) {
                percent = 1
            }
        }
        else {
            percent = 0;
        }
        if (amount) {
            if (amount <= 0) {
                amount = 1
            }
        }
        else { amount = 0 }
        if (tpAmount) {
            if (tpAmount <= 0) {
                tpAmount = 1
            }
        }
        else { tpAmount = 0 }


        if (parseFloat(percent) == 0 || (parseFloat(amount) + parseFloat(tpAmount)) <= 0) {
            form.change("taxAmt", TwoDecimalPlaces(0));
        }
        else {
            form.change("taxAmt", parseFloat(TwoDecimalPlaces((parseFloat(amount) + parseFloat(tpAmount)) / parseFloat(percent))));
        }

    }


    return (
        <React.Fragment>
            <div >
                <NumberInput label="Ref Percentage " variant="outlined" fullWidth={true} validate={[required()]} source="refPercentage" initialValue={0}
                onChange={(e) => {
                    onAmountChange(e, "refPercentage");
                }}
                />
            </div>
            <div className={classes.one_three_input} >
                <ReferenceInput disabled={rest.isEdit} label="Premium Type" fullWidth={true} link={false} perPage={5000} source="premiumTypeId" filter={{ type: 'PRT' }} reference="lookups" formClassName={classes.one_three_input} 
                onChange={(e) => {
                    onAmountChange(e, "premiumTypeId");
                }}
                >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true} />
                </ReferenceInput>
            </div>
            <div className={classes.two_three_input} >
                <ReferenceInput disabled={rest.isEdit} label="Payment No" fullWidth={true} link={false} formClassName={classes.two_three_input} perPage={5000} source="orderPaymentId" filter={{ orderId: parseInt(rest.ordId),premiumTypeId:formData.premiumTypeId?parseInt(formData.premiumTypeId):0 }} reference="order-payments"  
                onChange={(e) => {
                    onAmountChange(e, "orderPaymentId");
                }}
                >
                    <SelectInput optionText="paymentNo" variant="outlined" fullWidth={true} />
                </ReferenceInput>
            </div>
            <div className={classes.last_three_input} >
                <ReferenceInput label="Type" variant="outlined" perPage={5000} source="refOn" filter={{ type: 'RefOn' }} sort={{ field: 'name', order: 'ASC' }} reference="lookups" fullWidth={true} formClassName={classes.last_three_input}
                
                onChange={(e) => {
                    onAmountChange(e, "refOn");
                }}
                >
                    <SelectInput optionText="name" initialValue={0} />
                </ReferenceInput>
            </div>
            
            <div className={classes.one_three_input} >
                <NumberInput label="Ref Amount" variant="outlined" fullWidth={true} validate={[required()]} source="refAmount"
                disabled={formData.refOn!=174}
                 initialValue={0} />
            </div>
            <div className={classes.two_three_input} >
                <ReferenceInput variant="outlined" label="Tax Type" source="taxTypeId" reference="tax-heads" link={false} fullWidth={true}
                    onChange={(e) => {
                        onAmountChange(e, "taxTypeId");
                    }}
                >
                    <SelectInput optionText="taxName" validate={[required()]} />
                </ReferenceInput>
            </div>
            <div className={classes.last_three_input} >
                <NumberInput label="Tax Perc" variant="outlined" fullWidth={true} validate={[required()]} source="taxPerc" disabled={true}
                    onChange={(e) => {
                        onAmountChange(e, "taxPerc");
                    }}
                />
            </div>
             <div className={classes.one_three_input} >
                <NumberInput label="Tax Amt" variant="outlined" fullWidth={true} validate={[required()]} source="taxAmt" disabled={true} formClassName={classes.two_three_input} />
            </div>
            <div className={classes.two_three_input} >
                <ReferenceInput variant="outlined" label="Payment Approved BY" fullWidth={true} source="paymentApprovedBy" reference="employees" link={false}   formClassName={classes.last_three_input}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

            </div>
            <div className={classes.last_three_input} >
                <DateInput label="Payment Date" variant="outlined" source="paymentDate" fullWidth={true} />
            </div>
            <div className={classes.one_three_input} >
                <ReferenceInput label="Payment Mode" source="paymentModeId"
                    filter={{ type: 'PT' }} 
                    reference="lookups" link={false}
                    fullWidth={true} formClassName={classes.two_three_input} >
                    <SelectInput variant="outlined" optionText="name" />
                </ReferenceInput>
            </div>
            <div className={classes.two_three_input} >
                <ReferenceInput label="Bank" source="bankId" reference="bank-masters"
                    link={false} fullWidth={true}
                    perPage={250000}
                    formClassName={classes.last_three_input}>
                    <SelectInput optionText="name" variant="outlined" />
                </ReferenceInput>
            </div>
            <div className={classes.last_three_input} >
                <TextInput source="bankBranch" variant="outlined" label="Bank Branch" fullWidth={true} formClassName={classes.first_inline_input}></TextInput>
            </div>
            <div className={classes.one_three_input} >
                <TextInput source="refNo" variant="outlined" label="Ref No" fullWidth={true} formClassName={classes.last_inline_input} />
            </div>
            <TextInput source="payRemarks" variant="outlined" label="Ref Amount Paid" fullWidth={true} />
        </React.Fragment>
    )
}
export default RefPayment;