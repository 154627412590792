import React, { useState, useCallback } from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  useNotify, useRedirect, useRefresh
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import MotorVariantCreate from './popup/MotorVariantCreate';
import MotorClassCreate from './popup/MotorClassCreate';
import MotorFuelCreate from './popup/MotorFuelCreate';
import MotorMakeCreate from './popup/MotorMakeCreate';
import MotorModelCreate from './popup/MotorModelCreate';
const OuterComponent = (props) => {

  return (
    props.children
  )
}
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1000: {
    zIndex: 100,
    marginTop: 50,
    width: 1000,
  },
}));
export const MotorCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isAddVariant, setIsAddVariant] = useState(false);
  const [isAddFuel, setIsAddFuel] = useState(false);
  const [isAddClass, setIsAddClass] = useState(false);
  const [isAddMake, setIsAddMake] = useState(false);
  const [isAddModel, setIsAddModel] = useState(false);
  const onCloseDocumentForm = () => {
    setIsAddVariant(false);
    setIsAddFuel(false);
    setIsAddClass(false);
    setIsAddMake(false);
    setIsAddModel(false);
     refresh();
  }
  const onCancel = () => {
    setIsAddVariant(false);
    setIsAddFuel(false);
    setIsAddClass(false);
    setIsAddMake(false);
    setIsAddModel(false);
     refresh();

  }
  const handleClose = useCallback(() => {
    setIsAddVariant(false);
    setIsAddFuel(false);
    setIsAddClass(false);
    setIsAddMake(false);
    setIsAddModel(false);
     refresh();

  }, []);
const defaultValues= {
  isActive:true
}
  return (
    <React.Fragment>
      <Create title="Motor  Create" {...props}>
        <SimpleForm redirect="list" 
        initialValues={defaultValues}
        toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >

          <TextInput source="make" label="Motor Make" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="model" label="Model" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
          <TextInput source="variant" label="Variant" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

          <TextInput source="fuel" label="Fuel" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <NumberInput label="CC/GVW" fullWidth={true} validate={[required()]} source="ccGvw" formClassName={classes.two_three_input} />
          <NumberInput label="Seating Capacity" fullWidth={true} validate={[required()]} source="seatingCapacity" formClassName={classes.last_three_input} />

          <TextInput source="rtoLoc" label="RTO Location" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="yearOfMfg" label="Year Of Manufacturing" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
          <NumberInput label="NCB Percentage" source="ncbPercentage" fullWidth={true} validate={[required()]}  formClassName={classes.last_three_input} />
          
          <TextInput source="regnNo" label="Registration Number" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="engineNo" label="Engine Number" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
          <TextInput source="chasisNo" label="Chasis Number" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

          <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} formClassName={classes.one_three_input} />

          {/* <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} fullWidth={true} formClassName={classes.one_three_input}>
            <ReferenceInput perPage={25} sort={{ field: 'class', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'class~like': searchText })}
              label="Motor Class - Create" source="class" reference="motor-classes" fullWidth={true} formClassName={classes.one_three_input}>
              <AutocompleteInput
              createLabel="Add New"
              createItemLabel="Add New Item"
              onCreate={() => {
                const newClass = prompt('Enter a new Class');
                const newCategory = { class: newClass };
                return newCategory;
            }}
              optionValue="class"
              optionText="class" 
              variant="outlined" 
              shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddClass(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} fullWidth={true} formClassName={classes.two_three_input}>
            <ReferenceInput perPage={25} sort={{ field: 'make', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'make~like': searchText })}
              label="Motor Make" source="motorMakeId" reference="motor-makes" fullWidth={true} formClassName={classes.two_three_input}>
              <AutocompleteInput optionText="make" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddMake(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_three_input}>
            <ReferenceInput perPage={25} sort={{ field: 'model', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'model~like': searchText })}
              label="Motor Model" source="motorModelId" reference="motor-models" fullWidth={true} formClassName={classes.last_three_input}>
              <AutocompleteInput optionText="model" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddModel(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>


          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_three_input}>
            <ReferenceInput perPage={25} sort={{ field: 'variant', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'variant~like': searchText })}
              label="Motor Variant" source="motorVariantId" reference="motor-variants" fullWidth={true} formClassName={classes.one_three_input}>
              <AutocompleteInput optionText="variant" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddVariant(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_three_input}>
            <ReferenceInput perPage={25} sort={{ field: 'fuel', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'fuel~like': searchText })}
              label="Motor Fuel" source="motorFuelId" reference="motor-fuels" fullWidth={true} formClassName={classes.two_three_input}>
              <AutocompleteInput optionText="fuel" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddFuel(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>
          <NumberInput label="GVW" fullWidth={true} validate={[required()]} source="gvw" formClassName={classes.last_three_input} />
          <TextInput label="RTO Location" fullWidth={true} source="rtoLoc" formClassName={classes.one_three_input} initialValue={""} /> */}
          
        </SimpleForm>
      </Create>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isAddVariant || isAddClass || isAddFuel || isAddModel || isAddMake} >

        {isAddVariant && <MotorVariantCreate
          onClose={onCloseDocumentForm}
          
        />}
        {isAddMake && <MotorMakeCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}

        {isAddClass && <MotorClassCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}

        {isAddModel && <MotorModelCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}

        {isAddFuel && <MotorFuelCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}


      </Drawer>
    </React.Fragment>
  );
};
