import React, { useState,useEffect } from 'react';
import GenericUploader from '../upload/GenericUploader';
import { FormDataConsumer, Create, SimpleForm, TextInput, DateInput, NumberInput, required, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { useStyles } from "../../formStyles";
import OtherFields from './OtherFields';


export const InvoiceCreate = props => {
  const classes = useStyles({});
  const [yearData, setYearData] = useState([{ id: 2020 }, { id: 2021 }, { id: 2022 }, { id: 2023 }, { id: 2024 }, { id: 2025 }, { id: 2026 }, { id: 2027 }, { id: 2028 }, { id: 2029 }, { id: 2030 }])
  const [invoiceTypeData, setInvoiceTypeData] = useState([{ id: 2020, name: "Free Hand" }, { id: 2021, name: "Policy Claim" }])
  const [billYearFrom, setBillYearFrom] = useState(0);
  const [billYearTo, setBillYearTo] = useState(0);
   

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const monthID = new Date().getMonth()+1;
    
    if (monthID === 1 || monthID === 2 || monthID === 3) {
      setBillYearFrom(currentYear - 1);
      setBillYearTo(currentYear + 1);
    } else {
      setBillYearFrom(currentYear);
      setBillYearTo(currentYear + 1);
    }
  }, []);
  return (
    <Create {...props}>
      <SimpleForm variant="outlined" redirect="list">



        <FormDataConsumer  >
          {formDataProps => (

            <OtherFields {...formDataProps} />
          )}
        </FormDataConsumer>
        <ReferenceInput label="Branch" fullWidth={true} link={false}
          perPage={5000} source="branchId"
          validate={[required()]}
          reference="configs"
          formClassName={classes.one_three_input}
        >
          <SelectInput optionText="mconame" variant="outlined" fullWidth={true} />
        </ReferenceInput>
        <ReferenceInput label="placeOfSupply" fullWidth={true} link={false}
          perPage={5000} source="placeOfSupply"
          validate={[required()]}
          reference="states"
          formClassName={classes.two_three_input}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" variant="outlined" fullWidth={true} />
        </ReferenceInput>

        <TextInput label="HSN No" source="hsnCode" fullWidth={true} initialValue={""} formClassName={classes.last_three_input} validate={[required()]} />
        <NumberInput disabled label="Tax Per" variant="outlined" source="taxPercentage" validate={[required()]}
          fullWidth={true} formClassName={classes.one_three_input} />
        {billYearFrom>0 && <SelectInput optionText="id" label="Bill Year From" source="billYearFrom" fullWidth={true} formClassName={classes.two_three_input} choices={yearData}   initialValue={billYearFrom}/>}
        {billYearTo>0 && <SelectInput optionText="id" label="Bill Year To" source="billYearTo" fullWidth={true} formClassName={classes.last_three_input} choices={yearData}  initialValue={billYearTo}/>}
        <DateInput validate={[required()]} label="Invoice Date" source="invoiceDate" fullWidth={true} formClassName={classes.one_three_input} initialValue={new Date()} />
        <DateInput validate={[required()]} label="From Date" source="fromDate" fullWidth={true} formClassName={classes.two_three_input} />
        <DateInput validate={[required()]} label="To Date" source="toDate" fullWidth={true} formClassName={classes.last_three_input} />
        <SelectInput optionText="name" label="Invoice Type" source="invoiceTypeId" fullWidth={true} formClassName={classes.one_three_input} choices={invoiceTypeData} initialValue={2020} disabled />

        <ReferenceInput label="Brokerage Type" fullWidth={true} link={false}
          perPage={5000} source="brokTypeId"
          filter={{ type: 'INVBT' }}
          reference="lookups"
          formClassName={classes.two_three_input}
        >
          <SelectInput optionText="name" variant="outlined" fullWidth={true} />
        </ReferenceInput>
        <NumberInput label="Amount" source="grossInvoiceAmount"
          fullWidth={true} formClassName={classes.last_three_input} />
        <TextInput multiline source="particulars" fullWidth={true} validate={[required()]} />
      </SimpleForm>
    </Create>
  );
}