import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { toast } from "react-toastify";
const CorporateCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  let defaultValues = {

    dealDate: moment().toISOString(),


  };
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();
    data.openDate = data.dealDate;
    data.areaId = 0;
    data.add2 = "";
    data.add3 = "";
    //"modifyBy":1,"createdBy":1,"modifyDate":"2021-02-28T04:49:33.182Z","createdDate":"2021-02-28T04:49:33.182Z"
    apiClient.addCorporate(data).then(res => {
      notify("Corporate is added succesffully")
      props.onClose();

    }).catch(err => {
      notify("unable to add Corporate", "warning");
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.log(err);
    });
  }
  //{"code":"Auto Generate","isActive":true,"dealDate":"2021-02-28","openDate":"2021-03-07","name":"Puneet Sharma","phoffice":"09266743743","state":"Delhi","city":"New Delhi","add1":"INDIA","add2":"Sagarpur","pincode":"110046","areaId":856,"employeeId":7,"modifyBy":1,"createdBy":1,"modifyDate":"2021-02-28T04:49:33.182Z","createdDate":"2021-02-28T04:49:33.182Z"}
  //alert(JSON.stringify(formdata));

  return (
    <React.Fragment>
      <h5>Add Corporate </h5>
      <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >

        <TextInput source="code" label="Code" formClassName={classes.one_three_input} />
        <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />

        <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.last_three_input} />
        <TextInput source="state" label="State" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.two_three_input} />
        <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.last_three_input} />
        <TextInput source="phmobile" label="Mobile" formClassName={classes.one_three_input} />
        <TextInput source="email" label="email" formClassName={classes.two_three_input} />
        <TextInput source="cpName1" label="Contact Person 1" formClassName={classes.last_three_input} />

        <ReferenceInput label="Handle By" source="employeeId" reference="employees" formClassName={classes.one_three_input}>
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>

        <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.two_three_input} />

      </SimpleForm>

    </React.Fragment>
  );
};
export default CorporateCreate;