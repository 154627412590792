import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,ShowButton,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Industry Type"
      source="name~like"
      alwaysOn
    />
  </Filter>
);
export const IndustryTypeList = (props) => {
 
  const propsObj = Authorizer(props);

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Industry Type List" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
        <TextField source="code" label="Code " />
        <TextField source="name" label="Industry Type " />
        

        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
