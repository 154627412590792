import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'


import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField, ShowButton,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Customer Name" source="customerName~like" alwaysOn />

    <ReferenceInput label="RM" source="rmId" reference="employees" alwaysOn
      fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput label="Lead Type " fullWidth={true} link={false} allowEmpty alwaysOn
      source="leadTypeId" filter={{ type: 'LT' }} reference="lookups" sort={{ field: 'name', order: 'ASC' }} >
      <SelectInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

  </Filter>
);
export const LeadOrderList = (props) => {

  let employeeId=0
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  let isAdmin=false
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
      if(p.OwnRecord)
      {
        employeeId = localStorage.getItem("userId");
        if (employeeId) {
            employeeId = parseInt(employeeId);
        }
      }
    }
  }




  return (

    <React.Fragment>
      <List {...propsObj} exporter={propsObj.hasExport}   filter={employeeId && isAdmin==false>0?{ createdBy: employeeId }:null}   bulkActionButtons={false} title="Lead Order List"
        sort={{ field: 'id', order: 'DESC' }}
        filters={<Filters />}  >
        <Datagrid rowClick={false}>
          {propsObj.hasEdit && <EditButton />}
          {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}

          <TextField source="leadOrdNo" label="Lead Ord No" />
          <ReferenceField source="leadTypeId" reference="lookups" link={false} label="Lead Type" >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="customerName" label="Customer" />
          <TextField source="contactNo" label="Contact No" />
          <TextField source="email" label="Email" />
          <DateField source="nextFollowupDate" label="Next Follow up Date" locales="fr-FR" />

          <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DateField source="createdDate" label="Created Date" locales="fr-FR" />
          <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
            <TextField source="userName" />
          </ReferenceField>
          <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />
        </Datagrid>
      </List>

    </React.Fragment>
  );
};
