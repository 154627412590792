import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const AskDocumentsCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        calimOrdId: props.calimOrdId
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let data = {
            calimOrdId: parseInt(formdata.calimOrdId),
            slno: formdata.slno,
            askDocs: formdata.askDocs,
            additionalDocument: formdata.additionalDocument,
            isVerified: false,
            invVerifyRemarks:"",
            docurl: "",
        }
        apiClient.addEntiyRecord("claim-ord-asks", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">
                <TextInput source="slno" label="SL No" fullWidth={ true } />
                <TextInput source="askDocs" label="Required Document" fullWidth={ true } />
                <TextInput label="Additional Details" source="additionalDocument" fullWidth={ true }/>
            </SimpleForm>
        </Create>
    );
}
export default AskDocumentsCreate;