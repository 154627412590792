import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'


import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput, ShowButton,
  SelectInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const Filters = props => (
  <Filter { ...props } variant="outlined">
    <SearchInput variant="outlined" label="Customer Name" source="name~like" alwaysOn />
  </Filter>
);
export const CustomerList = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (

    <React.Fragment>
      <List { ...propsObj } exporter={ propsObj.hasExport } bulkActionButtons={ false } title="Customer List" filters={ <Filters /> }  >
        <Datagrid rowClick={ false }>
          { propsObj.hasEdit && <EditButton /> }
          { !propsObj.hasEdit && propsObj.hasShow && <ShowButton /> }

          <TextField source="name" label="Customer Name" />
          <ReferenceField source="entryTypeId" reference="entry-types" link={ false } label="Customer Type" >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="custGroupId" reference="cust-groups" link={ false } label="Customer Group" >
            <TextField source="name" />
          </ReferenceField>


          <ReferenceField source="createdBy" reference="users" label="Created By" link={ false }>
            <TextField source="userName" />
          </ReferenceField>
          <DateField source="createdDate" label="Created Date" locales="fr-FR" />
          <ReferenceField source="modifyBy" reference="users" label="Updated By" link={ false }>
            <TextField source="userName" />
          </ReferenceField>
          <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />
        </Datagrid>
      </List>

    </React.Fragment>
  );
};
