import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,FormDataConsumer,
    Create, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";
import RefPayment from '../components/RefPayment';

const RefPaymentCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        payRemarks: "",
        refNo:"",
        bankBranch:"",
        bankId:0,
        paymentApprovedBy:0,
        paymentModeId:0,

    }
    const handleSubmit = (formdata) => {
        console.log(formdata);

        let user = localStorage.getItem("userId");
            if (user) {
            user = parseInt(user);
            } 
            formdata.createdBy= user;
            formdata.modifyBy= user;  
            formdata.createdDate= moment().toISOString();
            formdata.modifyDate= moment().toISOString();
            formdata.orderId= parseInt(props.ordId);
            if (formdata.hasOwnProperty("paymentDate") && formdata.paymentDate != null) {
                let m = moment(formdata.paymentDate );
                formdata.paymentDate = m.toISOString();  
              }
              if (formdata.hasOwnProperty("taxPerc") && formdata.taxPerc != null) {
                
                formdata.taxPerc = parseFloat(formdata.taxPerc);  
              }
              if (formdata.hasOwnProperty("taxAmt") && formdata.taxAmt != null) {
                
                formdata.taxAmt = parseFloat(formdata.taxAmt);  
              }
              if (formdata.hasOwnProperty("refAmount") && formdata.refAmount != null) {
                
                formdata.refAmount = parseFloat(formdata.refAmount);  
              }
              if (formdata.paymentDate === null) {
                
                
                formdata.paymentDate='1900-01-01';  
              }
              
              
        apiClient.addEntiyRecord("order-ref-payments", formdata).then(res => {
            
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
      
    }
    return (
        <Create {...props}>
            <SimpleForm
                onSubmit={handleSubmit} 
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} /> }
                variant="outlined" initialValues={initialValues}>
                    <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
              Ref Payment Detail
            </div>
            
            <FormDataConsumer>
              {formDataProps => 
             <RefPayment {...formDataProps}  {...props} />
              }
            </FormDataConsumer>
                
            </SimpleForm>
        </Create>
    );
}
export default RefPaymentCreate;