import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const DepartmentCreate = (props) => {
  return (
    <Create title="Department Edit" {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
       
       <TextInput
          source="name"
          fullWidth={true}
          validate={[required()]}
        />
        
        <TextInput
          source="description"
          fullWidth={true}
          validate={[required()]}
        />
        
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>
      </SimpleForm>
    </Create>
  );
};
