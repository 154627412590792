import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";

 
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const ContactCreate = props => {
    const classes = useStyles({});
    
    const handleSubmit = async (formdata) => {
         console.log(formdata);
         let user = localStorage.getItem("userId");
         if (user) {
         user = parseInt(user);
         }
        
        let data = {
            custId:parseInt(props.custId),
            name: formdata.fname,
            "shortName": formdata.fshortName,
            "designations": formdata.designations,
            "dob":  formdata.fdob,
            "phone":  formdata.phone,
            "mobile":  formdata.mobile,
            "email":  formdata.email,
            "createdBy":  user,
            "createdDate": moment().toISOString(),
            "modifyBy": user,
            "modifyDate": moment().toISOString(),
            "isActive": true
          }
          if(data.dob!=null)
          {
            data.dob=data.dob+"T10:40:14.968Z";
          }
          if(data.shortName==null ||data.shortName=="")
          {
            data.shortName=data.name;
          }
          
        apiClient.addEntiyRecord("/customer-contacts", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
        
    }
    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined">

                
                <TextInput source="fname" label="Name" validate={required()} fullWidth={true} />
                <TextInput source="fshortName" label="Short Name"  fullWidth={true} />
                <TextInput source="designations" validate={required()} fullWidth={true} />
                <DateInput fullWidth={true} source="fdob" validate={required()} label="Dob" /> 
                <TextInput fullWidth={true} source="phone" validate={required()} label="Phone" initialValue={""}/>
                <TextInput fullWidth={true} source="mobile" validate={required()} label="Mobile" initialValue={""} />
                <TextInput fullWidth={true} source="email" validate={required()} label="Email" initialValue={""}/>
                
                <BooleanInput source="isActive" label="Is Active" initialValue={true}/>
                
                 

                 

            </SimpleForm>
        </Edit>
    );
}
export default ContactCreate;