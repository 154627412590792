import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const AddressFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    return (
        <div className="address-field-parent">
            <ReferenceInput variant="outlined" label="Country" source="cCountryId" reference="countries"
                onChange={(v) => {
                    console.log(v);
                    form.change("cStateId", 0);
                    form.change("cCityId", 0);
                    form.change("cDistrictId", 0);
                }}
                validate={required()} 
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="State" source="cStateId"
                reference="states" perPage={500}
                filter={{countryId: formData.pCountryId}}
                onChange={(v) => {
                    //console.log(v);
                    form.change("cCityId", 0);
                    form.change("cDistrictId", 0);
                }}
                validate={required()} 
                sort={{ field: 'name', order: 'ASC' }}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} className={classes.two_4_input}>
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="District" source="cDistrictId"
                reference="districts" perPage={500}
                filter={{stateId: formData.pStateId}}
                onChange={(v) => {
                    //console.log(v);
                    form.change("cCityId", 0);
                }}
                validate={required()} 
                sort={{ field: 'name', order: 'ASC' }}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} className={classes.three_4_input}>
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>


            <ReferenceInput variant="outlined" label="City"
              filter={{districtId: formData.pDistrictId}}
              sort={{ field: 'name', order: 'ASC' }}
                validate={required()} source="cCityId" reference="cities" perPage={500}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} className={classes.last_4_input}>
                <SelectInput  validate={required()} optionText="name" />
            </ReferenceInput>
        </div>
    )
}
