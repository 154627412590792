import React, { useState, useCallback, useEffect } from 'react';

import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput, useNotify, useRedirect,
  ReferenceInput,
  SelectInput, DateField, AutocompleteInput,
  BoolenField,
  NumberField,
  FormDataConsumer, ReferenceField, useRefresh, RichTextField,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";

import EditIcon from '@material-ui/icons/Edit';
import { FormToolbar } from "./components/FormToolbar";
import { StateDistrict } from "./components/StateDistrict";
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import DocumentsCreate from "./popup/DocumentsCreate";
import BeneficieriesCreate from "./popup/BeneficieriesCreate";
import PremiumCreate from "./popup/PremiumCreate";
import * as apiClient from "../../common/apiClient";
import PremiumEdit from "./popup/PremiumEdit";
import PremiumPaymentCreate from "./popup/PremiumPaymentCreate";
import CustomerCreate from './popup/CustomerCreate';
import CustomerGroupCreate from './popup/CustomerGroupCreate';
import InsurerCreate from './popup/InsurerCreate';
import InsurerBranchCreate from './popup/InsurerBranchCreate';
import PolicyTypeCreate from './popup/PolicyTypeCreate';
import PolicyDepartmentCreate from './popup/PolicyDepartmentCreate';
import EmployeeCreate from './popup/EmployeeCreate';
import AddIcon from '@material-ui/icons/Add';
import OrderEmail from './popup/OrderEmail';
import EndorsementCreate from './popup/EndorsementCreate';
import EndorsementEdit from './popup/EndorsementEdit';
import MotorClassCreate from "./popup/MotorClassCreate";
import MotorClassVarient from './popup/MotorClassVarient';
import MotorClassMake from './popup/MotorClassMake';
import MotorClassFuel from './popup/MotorClassFuel';
import MotorClassModel from './popup/MotorClassModel';
import MotorClassDetails from './popup/MotorClassDetails';
import { TaxFields } from './components/TaxFields';
import RefCustomerCreate from './popup/RefCustomerCreate';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 750,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1100,
  },
}));


const BasicDetailsHeader = (props) => {
  return (
    <div className='form-header-container'>
      <div style={{ fontWeight: "bold", fontSize: "15px", flexGrow: 1, paddingBottom: 10, }} >
        Basic Detail
      </div>
      <div className='form-header-actions'>
        <Button
          className="generate-premium-button"
          variant="contained" color="primary"
          size="medium"
          onClick={() => { props.generateGeneralPremium(props.record.id); }}
        >Generate General Premium</Button>
      </div>
    </div>


  )
}

const OuterComponent = (props) => {

  return (
    props.children
  )
}

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}


const EmailStatus = (props) => {
  let { record } = props;


  return (
    <div>
      {
        record.isPending ? "Waiting for confirmation" : 'Sent'
      }

    </div>
  )

}

const EmailBody = (props) => {
  let { record } = props;

  if (record.body && record.body.length > 0)
    return (
      <div style={{ maxHeight: "150px", overflow: "auto" }}>
        <div
          dangerouslySetInnerHTML={{ __html: record.body }}
        />
      </div>
    )
  else {
    return null
  }
}
const UploadDocument = (props) => {
  let { record } = props;
  return <Button
    color="secondary" disableElevation
    size="medium"

    onClick={() => {
      props.onClick(record);
    }}
    startIcon={<EditIcon />}>Edit</Button>
}


const AddPayment = (props) => {
  let { record } = props;
  return <Button
    color="secondary" disableElevation
    size="medium"
    onClick={() => {
      props.onClick(record);
    }}
    startIcon={<AddIcon />}> Generate Payment</Button>
}

export const LifeOrderEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? false : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isaddPremiums, setIsAddPremiums] = useState(false);
  const [isEditPremium, setIsEditPremium] = useState(false);

  const [isaddBeneficieries, setIsAddBeneficieries] = useState(false);

  const [isAddEmail, setIsAddEmail] = useState(false);
  const [isEditPayment, setIsEditPayment] = useState(false);
  const [isEditEndorsement, setIsEditEndorsement] = useState(false);

  const [IsEditRecord, setIsEditRecord] = useState(null);


  const [isEditPremiums, setIsEditPremiums] = useState(0);
  const [isaddOtherDocuments, setIsAddOtherDocuments] = useState(false);
  const [isaddEndorsement, setIsAddEndorsement] = useState(false);

  const [selectedDocumentsId, setSelectedDocumentsId] = useState<any[]>([]);


  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [isAddCustomerGroup, setIsAddCustomerGroup] = useState(false);
  const [isAddInsurer, setIsAddInsurer] = useState(false);
  const [isAddInsurerBranch, setIsAddInsurerBranch] = useState(false);
  const [isAddPolicyDepartment, setIsAddPolicyDepartment] = useState(false);
  const [isAddPolicyType, setIsAddPolicyType] = useState(false);
  const [isAddMotorClass, setIsAddMotorClass] = useState(false);
  const [isAddMotorVarient, setIsAddMotorVarient] = useState(false);
  const [isAddMotorModel, setIsAddMotorModel] = useState(false);
  const [isAddMotorMake, setIsAddMotorMake] = useState(false);
  const [isAddMotorFuel, setIsAddMotorFuel] = useState(false);
  const [isAddMotorDetails, setIsAddMotorDetails] = useState(false);


  const [isAddRefCustomer, setIsAddRefCustomer] = useState(false);
  const [isAddEmployee, setIsAddEmployee] = useState(false);
  const [isAutoMotor, setIsAutoMotor] = useState(false);
  const [brokrage, setBrokrage] = useState([]);

  const getBrokrage = (oid: any) => {
    apiClient.getLifeOrderBrokrage(oid).then(res => {
      //console.log(res , "====getOrderBrokrage====");
      setBrokrage(res);
    }).catch(err => {
      console.log(err);
    });
  }

  const getOrder = (oid: any) => {
    apiClient.getEntiyRecord("life-orders", oid).then(res => {
      if (res.policyDepartmentId != "") {
        checkPolicyDepartment(res.policyDepartmentId);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const checkPolicyDepartment = (id: string) => {
    apiClient.getEntiyRecord("policy-departments", id).then(res => {
      if (res.isMotorDetails != undefined) {
        setIsAutoMotor(res.isMotorDetails);
      }
    }).catch(err => {
      console.log(err, "ERR")
    });
  }

  const [configValue, setConfigValue] = useState(0);

  const SelectDeselectDocument = (record) => {
    var ids: any[] = [...selectedDocumentsId];
    const id: any = record.id;
    const index = ids.indexOf(id);
    if (index > -1) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }
    setSelectedDocumentsId(ids);
  }
  const onDocumentDelete = async () => {
    var ids: any[] = [...selectedDocumentsId];
    if (ids.length == 0) {
      alert("Please select some documents.");
      return;
    }
    var cn = window.confirm("Are you sure you want to delete the selected documents?");
    if (!cn)
      return;
    ids.forEach(async item => {
      await apiClient.deleteRealizationDocument(item);
    });
    refresh();
  }

  const addFromSetup = (id) => {
    if (configValue == 0) {
      alert("Please select the config.");
      return;
    }

    apiClient.addFromSetup(id, configValue).then(res => {
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }
  const onCloseDocumentForm = () => {
    setIsAddPremiums(false);
    setIsEditPremium(false);
    setIsEditPayment(false);
    setIsEditEndorsement(false);
    setIsAddBeneficieries(false)
    setIsAddEmail(false);
    refresh();
  }

  const onCloseOtherDocumentForm = () => {
    setIsAddOtherDocuments(false);
    setIsAddEndorsement(false);
    refresh();
  }


  const onCancel = () => {
    setIsAddPremiums(false);
    setIsAddOtherDocuments(false);
    setIsAddEndorsement(false);
    setIsEditPremium(false);
    setIsEditPayment(false);
    setIsEditEndorsement(false);
    setIsAddEmail(false);
    setIsAddMotorClass(false);
    setIsAddMotorVarient(false);
    setIsAddMotorMake(false);
    setIsAddMotorFuel(false);
    setIsAddMotorModel(false);
    setIsAddMotorDetails(false);
    setIsAddBeneficieries(false);
    setIsAddRefCustomer(false);
  }
  const handleClose = useCallback(() => {
    setIsAddPremiums(false);
    setIsAddOtherDocuments(false);
    setIsAddEndorsement(false);
    setIsEditPremium(false);
    setIsEditPayment(false);
    setIsEditEndorsement(false);
    setIsAddCustomer(false);
    setIsAddCustomerGroup(false);
    setIsAddInsurer(false);
    setIsAddInsurerBranch(false);
    setIsAddPolicyDepartment(false);
    setIsAddPolicyType(false);
    setIsAddEmployee(false);
    setIsAddEmail(false);
    setIsAddMotorClass(false);
    setIsAddMotorVarient(false);
    setIsAddMotorMake(false);
    setIsAddMotorFuel(false);
    setIsAddMotorModel(false);
    setIsAddMotorDetails(false);
    setIsAddBeneficieries(false);
    setIsAddRefCustomer(false);

  }, []);

  const validateUserCreation = (values) => {
    const errors: any = {};
    //console.log(values);
    if (values.uDisAllAmt > 0 && values.rpriotiyId == 14) {
      errors.rpriotiyId = ["Please Change the Recl. Priority"];
    }
    return errors
  };

  useEffect(() => {
    getOrder(props.id);
    getBrokrage(props.id);
  }, []);

  const OrderAddPayment = (record: any) => {
    apiClient.addLifeOrderPaymentSch(record.id).then(res => {
      notify(res[0].message);
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }

  const generateGeneralPremium = (id: any) => {
    apiClient.createLifeOrderPremiumRecord(id).then(res => {
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }


  return (
    <React.Fragment>
      <Edit title="Life Order Edit"

        {...propsObj} undoable={false}>
        <TabbedForm
          validate={validateUserCreation}
          redirect={false} variant="outlined" toolbar={<FormToolbar {...propsObj} showDelete={false} />}>
          <FormTab label="Basic Details">
            <BasicDetailsHeader generateGeneralPremium={generateGeneralPremium}/>
            
            <ReferenceInput disabled={true} perPage={25} sort={{ field: 'mconame', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'mconame~like': searchText })}
              label="Booking Branch" source="configId" reference="configs" fullWidth={true} formClassName={classes.one_4_input}>
              <SelectInput optionText="mconame" />
            </ReferenceInput>
            <TextInput disabled={true} source="orderNo" label="Order No" fullWidth={true} formClassName={classes.two_4_input} />

            <DateInput disabled={true} validate={[required()]} label="Order Date" initialValue={new Date()} source="orderDate" fullWidth={true} formClassName={classes.three_4_input} />

            <ReferenceInput disabled={true} perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Biz Type" source="bizTypeId" reference="biz-types" fullWidth={true} formClassName={classes.last_4_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>


            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Customer Group" source="custGroupId" reference="cust-groups" fullWidth={true} formClassName={classes.one_4_input}>
                <AutocompleteInput options={{ disabled: true }} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddCustomerGroup(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.twice_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.three_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Customer" source="customerId" reference="customers" fullWidth={true} >
                <AutocompleteInput options={{ disabled: true }} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddCustomer(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>



            <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
              Policy Detail
            </div>
            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filter={{ insurerTypeId: 2 }}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Insurer" source="insurerId" reference="insurers" fullWidth={true} >
                <AutocompleteInput options={{ disabled: true }} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddInsurer(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.two_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Insurer Branch" source="insurerBrancheId" reference="insurer-branches" fullWidth={true} >
                <AutocompleteInput options={{ disabled: true }} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddInsurerBranch(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
              <ReferenceInput
                onChange={(data) => { checkPolicyDepartment(data); }}
                perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Policy Department" source="policyDepartmentId" reference="life-policy-departments" fullWidth={true} >
                <AutocompleteInput options={{ disabled: true }} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddPolicyDepartment(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Policy Type" source="policyTypeId" reference="life-policy-types" fullWidth={true}>
                <AutocompleteInput options={{ disabled: true }} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddPolicyType(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>


            <TextInput label="Product Name" disabled={true} source="productName" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput label="Proposal No / App No :" source="proposalNo" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput label="Commence (Start) Date" source="policyStartDate" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput label="Policy Maturity Date" source="policyMaturityDate" fullWidth={true} formClassName={classes.last_4_input} />


            <NumberInput label="Terms In Yrs" source="termsInYrs" fullWidth={true} formClassName={classes.one_4_input} />
            <NumberInput label="PPT In Yrs" source="pptInYrs" fullWidth={true} formClassName={classes.two_4_input} />
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Paid Mode" source="paymentModeId" reference="lookups" formClassName={classes.three_4_input}
              filter={{ type: 'LPM' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label="Policy No" source="policyNo" fullWidth={true} formClassName={classes.last_4_input} />

            <FormDataConsumer>
              {formDataProps => (
                <TaxFields {...formDataProps} />
              )}
            </FormDataConsumer>

            {/* <NumberInput label="Sum Insured" source="sumInsured" fullWidth={true} formClassName={classes.one_4_input} />
            <NumberInput label="Net Premium" source="premAmt" fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput label="Rider Sum Insured" source="riderSumInsured" fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="Rider Premium Amt" source="riderPremiumAmt" fullWidth={true} formClassName={classes.last_4_input} />

            <ReferenceInput
              perPage={500} sort={{ field: 'taxName', order: 'ASC' }}
              label="TAX Type" source="taxId" reference="tax-heads" formClassName={classes.one_4_input}
              fullWidth={true} >
              <SelectInput optionText="taxName" />
            </ReferenceInput>

            <NumberInput label="TAX Percentage" source="taxPerc" fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput label="TAX Amount" source="totalTaxAmt" fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="Gross Premium" source="grossPremAmt" disabled={true} fullWidth={true} formClassName={classes.last_4_input} /> */}


            <DateInput label="Premium Start Date" source="premiumStartDate" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Last Payment Date" source="lastPaidDate" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput label="Policy Issue Date" source="policyStartDate" fullWidth={true} formClassName={classes.three_4_input} />

            <DateInput label="Policy Received Date" source="policyReceiveDate" fullWidth={true} formClassName={classes.last_4_input} />
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Policy Rcvd Format Type" source="rcvdFormatTypeId" reference="lookups" formClassName={classes.one_4_input}
              filter={{ type: 'RFT' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Policy Dispatch Through" source="dispatchThroughId" reference="lookups" formClassName={classes.two_4_input}
              filter={{ type: 'PDT' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <DateInput validate={[required()]} label="Policy Dispatch Date" source="policyDispatchDate" fullWidth={true} formClassName={classes.three_4_input} />
            <div className="divider" />
            <TextInput multiline label="Dispatch Details" source="policyDispatchDetails" fullWidth={true} formClassName={classes.one_4_input} />

            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Camaign Type" source="camaignId" reference="lookups" formClassName={classes.two_4_input}
              filter={{ type: 'RFT' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label="Camaign Name" source="camaignName" fullWidth={true} formClassName={classes.three_4_input} />
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
              label="Status" source="statusId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'RO' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <div className="divider" />


            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.one_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="RM" source="rmid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.two_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.two_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="CSC" source="cscid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>


            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.three_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="TC" source="tcid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.last_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="REF" source="refid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddRefCustomer(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

          </FormTab>


          <FormTab label="Order Beneficieries">
            <div>
              <Button onClick={() => { setIsAddBeneficieries(true); }}>Add Beneficieries</Button>
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="life-order-beneficieries"
              target="orderId">

              <Datagrid >
                <TextField source="beneficieryName" label="Beneficiery Name" />
                <ReferenceField source="beneficieryRelId" reference="lookups" label="Beneficiery Rel" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <NumberField source="sharePercentage" />
                <TextField source="appointeeName" />

                <ReferenceField source="appointeeRelId" reference="lookups" label="Appointee Rel" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="remarks" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab label="Order Premiums">
            <div>
              <Button onClick={() => { setIsAddPremiums(true); }}>Add Premiums</Button>
            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="life-order-premiums"
              target="orderId">

              <Datagrid >

                {/* <CDeleteButton {...props} /> */}
                <UploadDocument onClick={(record) => { setIsEditPremiums(record); setIsEditPremium(true); }} label="Edit" />

                <AddPayment onClick={(record) => { OrderAddPayment(record) }} label="" />
                <ReferenceField source="insurerId" reference="insurers" label="Insurer" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="premiumTypeId" reference="lookups" label="Premium Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="policyDetailId" reference="policy-masters" label="Policy Detail" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <NumberField source="sumInsuredAmt" label=" Sum Ins Amt" />
                <NumberField source="premiumAmt" />
                <NumberField source="riderSumInsuredAmt" />
                <NumberField source="riderPremiumAmt" />
                <NumberField source="netPremiumAmt" />
                {/* <TextField source="remarks" /> */}
                <TextField source="isTaxable" label={"Taxable"} />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>

          <FormTab label="Payments and Brokrage Detail">


            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="life-order-payments"
              target="orderId">

              <Datagrid >


                <UploadDocument onClick={(record) => { setIsEditRecord(record); setIsEditPayment(true); }} label="Edit" />

                <DateField source="paymentDueDate" label="Payment Due Date" locales="fr-FR" />
                <ReferenceField source="premiumTypeId" reference="lookups" label="Premium Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <NumberField source="paymentNo" label="Payment No" />
                <NumberField source="premiumAmt" label="Premium" />
                <NumberField source="riderPremiumAmt" label="Rider Premium" />
                <NumberField source="totalPremiumAmount" label="Total Premium Amount" />
              </Datagrid>

            </ReferenceManyField>

          </FormTab>
          <FormTab label="Documents">
            <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddOtherDocuments(true); }}>Add New Document</Button>

            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="life-order-documents"
              target="ordId">
              <Datagrid>
                {isAdmin && <CDeleteButton {...props} />}

                <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />
                <TextField label=" Attach In Mail" source="mailAttachment" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Non Commercial Endorsement">
            <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddEndorsement(true); }}>Add Endorsement</Button>

            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="life-order-endorsements"
              target="ordId">
              <Datagrid>
                {isAdmin && <CDeleteButton {...props} />}
                <UploadDocument onClick={(record) => { setIsEditRecord(record); setIsEditEndorsement(true); }} label="Edit" />

                <ReferenceField label="Document " source="documentTypeId"

                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Endorsement Type" source="endorsementId"

                  reference="endorsement-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Status" source="statusId"

                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab label="Order Email">
            <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddEmail(true); }}>Add Email</Button>
            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="email-logs"
              target="linkId">
              <Datagrid>


                <TextField label="Subject" source="subject" />
                <EmailBody source="body" />
                <DateField showTime={true} label="Created Date" source="createdAt" locales="fr-FR" />


                <EmailStatus label="Email Status" source="isPending" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>


        </TabbedForm>

      </Edit >
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={(isEditPayment || isAddCustomer || isAddEmail) ? {
          paper: myclasses.drawerPaper1500,
        } : {
          paper: myclasses.drawerPaper,
        }}
        open={isaddPremiums || isaddOtherDocuments || isEditPremium || isEditPayment
          || isAddCustomer || isAddCustomerGroup || isAddEmployee || isAddRefCustomer ||
          isAddInsurer || isAddInsurerBranch || isAddPolicyDepartment ||
          isAddMotorVarient || isAddMotorMake || isAddMotorFuel || isAddMotorModel || isAddMotorDetails ||
          isAddPolicyType || isAddEmail || isaddEndorsement || isEditEndorsement ||
          isAddMotorClass || isaddBeneficieries
        } >


        {isaddOtherDocuments && <DocumentsCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}

        {isaddPremiums && <PremiumCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}

          {...props} />}

        {isaddBeneficieries && <BeneficieriesCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}

        {isEditPremium && <PremiumEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}

          record={isEditPremiums}
          {...props} />}


        {isEditPayment && <PremiumPaymentCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          record={IsEditRecord}
          {...props} />}

        {isEditEndorsement && <EndorsementEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          record={IsEditRecord}
          {...props} />}

        {isAddRefCustomer && <RefCustomerCreate onClose={onCancel} />}
        {isAddCustomer && <CustomerCreate onClose={handleClose} />}
        {isAddCustomerGroup && <CustomerGroupCreate onClose={handleClose} />}
        {isAddInsurer && <InsurerCreate onClose={handleClose} />}
        {isAddInsurerBranch && <InsurerBranchCreate onClose={handleClose} />}
        {isAddPolicyDepartment && <PolicyDepartmentCreate onClose={handleClose} />}
        {isAddPolicyType && <PolicyTypeCreate onClose={handleClose} />}
        {isAddEmployee && <EmployeeCreate onClose={handleClose} />}
        {isAddEmail && <OrderEmail onClose={onCloseDocumentForm} ordId={props.id} {...props} />}
        {isAddMotorClass && <MotorClassCreate onClose={handleClose}
          ordId={props.id}
          {...props} />}
        {isAddMotorVarient && <MotorClassVarient onClose={handleClose} />}
        {isAddMotorMake && <MotorClassMake onClose={handleClose} />}
        {isAddMotorFuel && <MotorClassFuel onClose={handleClose} />}
        {isAddMotorModel && <MotorClassModel onClose={handleClose} />}
        {isAddMotorDetails && <MotorClassDetails onClose={handleClose} />}
        {isaddEndorsement && <EndorsementCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}



      </Drawer>
    </React.Fragment>
  );
};
