import React, { useState } from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput, AutocompleteArrayInput, AutocompleteInput, SelectInput, ReferenceArrayInput,
  required,
  BooleanInput, useNotify
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import moment from "moment";
import * as apiClient from "../../common/apiClient";

export const SmsLogHdsCreate = (props) => {
  console.log(props, "====props=====");
  const classes = useStyles({});
  const notify = useNotify();
  const [isAllCustomers, setIsAllCustomers] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [mobileError, setMobileError] = useState<any>(null);

  let defaultValues = {

  };

  const getCustomersData = (ids: string[]) => {
    setMobileError(null);
    var customersData: any[] = [];
    ids.forEach((item, index) => {
      var cIndex = customers.findIndex((inner: any) => inner.id == item);
      if (cIndex >= 0) {
        customersData.push(customers[cIndex]);
      } else {
        apiClient.getEntiyRecord("customers", item).then(res => {
          console.log(res, "CUSTOMER");
          if (res.mobile == "") {
            setMobileError(res);
          } else {
            customersData.push(res);
          }
        }).catch(err => {
          console.log(err, "ERR");
        });
      }
      setCustomers(customersData);
    })

  }


  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let mobileNums = customers.map((item: any) => {
      return item.mobile;
    })

    let data: any = {};
    data.SmsType = "Generic";
    data.Subject = formdata.subject;
    data.Body = formdata.body;
    data.ToAddressType = (isAllCustomers) ? "ALL" : "SELECTED";
    data.ToCustomerID = (isAllCustomers) ? "" : mobileNums.join(",");
    data.CreatedBy = user;

    apiClient.addRecord(data, "send-sms").then(res => {
      notify("SMS Sent succesffully")
      props.history.goBack();
    }).catch(err => {
      notify("unable to add Customer", "warning")
      console.log(err);
    });
  }
  return (
    <Create title="Taxhead Create" {...props}>
      <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={() => { props.history.goBack();}} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >

      <BooleanInput 
      onChange={(data) => {
        setIsAllCustomers(data);
      }}
      source="all_customers" label="Send to All Customers" fullWidth={true} />

      {!isAllCustomers &&  <ReferenceArrayInput onChange={(data) => {
          getCustomersData(data);
        }} 
        perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Customer" source="customerId" reference="customers" fullWidth={true} >
          <AutocompleteArrayInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceArrayInput >}
        {mobileError && <div className="mobile-not-found">Mobile number doest not added for customer {mobileError.name}.</div>}

        
        <TextInput source="subject" label="Subject" fullWidth={true} validate={[required()]} />
        <TextInput source="body" label="SMS Text" multiline fullWidth={true} validate={[required()]} />

      </SimpleForm>
    </Create>
  );
};
