import React, { useCallback } from 'react';
import {
    required,Create,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,FormDataConsumer,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import moment from "moment";
import {Addressfields} from "./Addressfields";
 
const PostCreateToolbar = props => (
       
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const NomineeCreate = props => {
    const classes = useStyles({});
    let initialValue=
       {
            name: "",
            shortName: "",
            gender: "",
            relation: "", 
            address1: "",
            address2: "",
            cityId: 0,
            districtId: 0,
            stateId: 0,
            countryId: 0,
            pIn: "",
            phone: "",
            mobile: "",
            fax:"",
            email: "",
            pan: "",
            website:"",
            education: "",
            statusId: 0,
            createdBy: 0, 
            isActive: true
          }
    const handleSubmit = async (formdata) => {
         console.log(formdata);
         let user = localStorage.getItem("userId");
         if (user) {
         user = parseInt(user);
         }
        

          formdata.custId=parseInt(props.custId);
          formdata.createdBy=  user;
          formdata.createdDate= moment().toISOString();
          formdata.modifyBy= user;
          formdata.modifyDate= moment().toISOString();
          
        // data = {
        //     custId:parseInt(props.custId),
        //     name: formdata.fname,
        //     "shortName": formdata.fshortName, 
        //     "dob":  formdata.fdob,
        //     "phone":  formdata.phone,
        //     "mobile":  formdata.mobile,
        //     "email":  formdata.email,
        //     "createdBy":  user,
        //     "createdDate": moment().toISOString(),
        //     "modifyBy": user,
        //     "modifyDate": moment().toISOString(),
        //     "isActive": true
        //   }
          if(formdata.dob!=null)
          {
            formdata.dob=formdata.dob+"T10:40:14.968Z";
          }
          if(formdata.shortName==null ||formdata.shortName=="")
          {
            formdata.shortName=formdata.name;
          }
          
        apiClient.addEntiyRecord("/customer-nominees", formdata).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
        
    }
    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined" initialValues={initialValue}>

                          
           
           
            <TextInput source="name" label="Name" fullWidth={true}    validate={[required()]} formClassName={classes.one_4_input} />
            <TextInput source="shortName" label="Short Name"  fullWidth={true}  formClassName={classes.two_4_input} />
            
            
           
            <TextInput source="gender" label="Gender"  fullWidth={true}  validate={[required()]}   formClassName={classes.three_4_input} />
            <DateInput source="dob" label="Dob"  fullWidth={true}   formClassName={classes.last_4_input} />
            <TextInput source="relation" label="Relation"  fullWidth={true}   validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput multiline source="address1"  fullWidth={true}   label="Address 1" formClassName={classes.two_three_input} />
            <TextInput multiline source="address2"  fullWidth={true}   label="Address 2" formClassName={classes.last_three_input} />

            


            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <Addressfields {...formDataProps} />
              )}
            </FormDataConsumer>
            <TextInput source="pin" label="Pincode"  fullWidth={true}   formClassName={classes.one_4_input} />
            <TextInput source="phone" label="Phone"  fullWidth={true}  formClassName={classes.two_4_input} />
            <TextInput source="mobile" label="Mobile"  fullWidth={true}   formClassName={classes.three_4_input} />
            <TextInput source="fax" label="Fax"   fullWidth={true}  formClassName={classes.last_4_input} />
            <TextInput source="email" label="Email"  fullWidth={true}   formClassName={classes.one_4_input} />


            <TextInput source="pan" label="Pan"  fullWidth={true}  formClassName={classes.two_4_input} />
            <TextInput source="website" label="Website"   fullWidth={true}  formClassName={classes.three_4_input} />
             <TextInput source="education" label="Education"  fullWidth={true}   formClassName={classes.last_4_input} />
            

          <ReferenceInput  perPage={2000}  label="Status" fullWidth={true}  source="statusId" reference="lookups" formClassName={classes.one_4_input} sort={{ field: 'name', order: 'ASC' }} filter={{ type: 'RO' }} >
              <SelectInput optionText="name"/>
          </ReferenceInput> 
          <BooleanInput source="isActive" label="Is Active" initialValue={true} formClassName={classes.two_4_input} /> 

            </SimpleForm>
        </Create>
    );
}
export default NomineeCreate;