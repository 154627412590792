import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict"

export const CityEdit = (props) => {
  return (
    <Edit title="State Edit" {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
       
             <FormDataConsumer>
              {formDataProps => (
                <StateDistrict {...formDataProps} />
              )}
            </FormDataConsumer>
        <TextInput source="cityCode" fullWidth={true} validate={[required()]} />
        <TextInput source="cityName" fullWidth={true} validate={[required()]} />
        
          
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true}/>
        </SimpleForm>
    </Edit>
  );
};
