import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const IndustryTypeCreate = (props) => {
  let defaultValues = {
    
    isActive: true,
    code:"-",
    
    
    
  };
  return (
    <Create title="Industry Type Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" initialValues={defaultValues}> 
      <ReferenceInput label="Industry Class " fullWidth={true} source="industryClassId" reference="industry-classes" >
            <SelectInput optionText="name"  />
        </ReferenceInput>

       <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" />
      </SimpleForm>
    </Create>
  );
};
