import React from "react";
import { CreateToolbar } from "../../CreateToolbar";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  SimpleForm,
  TabbedForm,
  FormTab,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import moment from "moment";
export const TpaCreate = (props) => {
  const classes = useStyles({});
  let defaultValues = {
    
    dealDate: moment().toISOString(),
    openDate:moment().toISOString(),
    
  };
  return (
    <Create title="TPA Create" {...props}>
       <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />} initialValues={defaultValues}>
          
            <TextInput source="code" label="Code" formClassName={classes.one_three_input} disabled={true}  />
            <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />
            <DateInput source="openDate" label="Open Date" validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="phoffice" label="Phone(Off)."  formClassName={classes.two_three_input} />
            <TextInput source="email" label="email" formClassName={classes.last_three_input} />
            
            
            <TextInput source="phmobile" label="Mobile"  formClassName={classes.one_three_input} />
            <TextInput source="tin" label="Tin"   formClassName={classes.two_three_input} />
            <TextInput source="cstNo" label="CST Number"   formClassName={classes.last_three_input} />

            <TextInput source="tpt" label="tpt"  formClassName={classes.one_three_input} />
            <TextInput source="licno" label="LIC No"   formClassName={classes.two_three_input} />
            <TextInput source="panno" label="Pan Number"   formClassName={classes.last_three_input} />
          
            <TextInput source="state" label="State" validate={[required()]} formClassName={classes.one_three_input} />
          <ReferenceInput label="Area" source="areaId" reference="areas" formClassName={classes.two_three_input}>
              <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
          <TextInput source="city" label="City" validate={[required()]} formClassName={classes.last_three_input} />
          
          
          <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="add2" label="Address 2" formClassName={classes.two_three_input} />
          <TextInput source="add3" label="Address 3"  formClassName={classes.last_three_input} />

          <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.one_three_input} />

           
          <TextInput source="cpName1" label="Contact Person 1"  formClassName={classes.two_three_input} />
          <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.last_three_input} />

          <TextInput source="cpRes1" label="Contact Person 1 ResiID"  formClassName={classes.one_three_input} />
          <TextInput source="cpName2" label="Contact Person 2"  formClassName={classes.two_three_input} />
          <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.last_three_input} />
          
          <TextInput source="cpRes2" label="Contact Person 2 ResiID"  formClassName={classes.one_three_input} />
          <TextInput source="cpName3" label="Contact Person 3"  formClassName={classes.two_three_input} />
          <TextInput source="cpMob3" label="Contact Person 3 Mobile" formClassName={classes.last_three_input} />

          <TextInput source="cpRes3" label="Contact Person 3 ResiID"  formClassName={classes.one_three_input} />
          <TextInput source="cpName4" label="Contact Person 4"  formClassName={classes.two_three_input} />
          <TextInput source="cpMob4" label="Contact Person 4 Mobile" formClassName={classes.last_three_input} />

          <TextInput source="cpRes4" label="Contact Person 4 ResiID"  formClassName={classes.one_three_input} />
          <TextInput source="cpName5" label="Contact Person 5"  formClassName={classes.two_three_input} />
          <TextInput source="cpMob5" label="Contact Person 5 Mobile" formClassName={classes.last_three_input} />

          <TextInput source="cpRes5" label="Contact Person 5 ResiID"  formClassName={classes.one_three_input} />
          <ReferenceInput label="Handle By" source="employeeId" reference="employees" formClassName={classes.two_three_input}
            sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" validate={[required()]}  formClassName={classes.two_three_input} />
          </ReferenceInput>

            <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.last_three_input} />
            
             
          
       
          

        </SimpleForm>

    </Create>
  );
};
