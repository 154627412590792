import React, { useState, useCallback } from "react";
import AddIcon from '@material-ui/icons/Add';
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  AutocompleteInput,
  SimpleForm,
  FormTab,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";
import { Addressfields } from "./Addressfields";
import { CalcFields } from "./CalcFields";
import Button from '@material-ui/core/Button';
import EmployeeCreate from "./EmployeeCreate";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const OuterComponent = (props) => {

  return (
    props.children
  )
}
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));

export const LeadOrderCreate = (props) => {
  const [isAddEmployee, setIsAddEmployee] = useState(false);
  const classes = useStyles({});
  const myclasses = myStyles({});
  let defaultValues =
  {

    leadOrdNo: "",
    leadDate: new Date(),
    leadById: -1,
    "refById": 0,
    "insurerId": 0,
    "insurerBranchId": 0,
    "orderNo": "",
    "policyDepartmentId": 0,
    "policyTypeId": 0,
    "policyNo": "",
    "sumInsAmt": 0,
    "netPremium": 0,
    "taxTypeId": 0,
    "grossAmt": 0,
    "reportingId": 0,
    "reportingHeadRemarks": "",
    "rmId": 0,
    "statusId": 167,
    "isActive": true

  };


  const handleClose = useCallback(() => {
    setIsAddEmployee(false);

  }, []);
  return (
    <React.Fragment>
      <Create title="Lead Order Create" {...props}>
        <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />} initialValues={defaultValues}>

          <ReferenceInput perPage={2000} fullWidth={true} label="Branch" source="branchId" reference="configs" formClassName={classes.one_three_input} sort={{ field: 'mconame', order: 'ASC' }} >
            <SelectInput optionText="mconame" validate={[required()]} />
          </ReferenceInput>

          <TextInput source="leadOrdNo" label="Lead Order" fullWidth={true} formClassName={classes.two_three_input} disabled />
          <DateInput source="leadDate" label="Lead Date / Telecalling Date" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} disabled />

          <ReferenceInput label="Lead Type " validate={[required()]} fullWidth={true} link={false} formClassName={classes.one_three_input} perPage={5000} source="leadTypeId" filter={{ type: 'LT' }} reference="lookups" sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText="name" validate={[required()]} shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>

          <ReferenceInput label="By" source="leadById" reference="employees" validate={[required()]} fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText="name" validate={[required()]} shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>
          <TextInput source="customerName" label="Customer" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

          <TextInput multiline source="customerAddress" validate={[required()]} fullWidth={true} label="Address " formClassName={classes.one_three_input} />
          <TextInput source="email" fullWidth={true} validate={[required()]} label="Email" formClassName={classes.two_three_input} />
          <TextInput source="contactNo" label="Contact No" validate={[required()]} fullWidth={true} formClassName={classes.last_three_input} />



          {/* <FormDataConsumer fullWidth={true} >
            {formDataProps => (
              <Addressfields {...formDataProps} />
            )}
          </FormDataConsumer> */}

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_three_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.one_three_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Lead Referred By" source="refById" reference="ref-customers" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => {

                return val && val.trim().length >= 1
              }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>


          <ReferenceInput label="Insurer" source="insurerId" 
          filterToQuery={searchText => ({ "name~like": searchText })} 
          reference="insurers" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} perPage={250000} >
            {/* <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> */}
            <SelectInput optionText="name" variant="outlined" />
          </ReferenceInput>




          <ReferenceInput label="Insurer Branch " source="insurerBranchId" 
          filterToQuery={searchText => ({ "name~like": searchText })} 
          reference="insurer-branches" fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>

          <TextInput source="orderNo" label="Order Number" fullWidth={true} formClassName={classes.one_three_input} />
          <ReferenceInput label="Policy Department" source="policyDepartmentId"
           filterToQuery={searchText => ({ "name~like": searchText })} 
          reference="policy-departments" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>
          <ReferenceInput label="Policy Type " source="policyTypeId" reference="policy-types" 
          filterToQuery={searchText => ({ "name~like": searchText })} 
          fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>
          <TextInput source="policyNo" label="Policy No" fullWidth={true} formClassName={classes.one_three_input} />


          {/* <NumberInput source="netPremium" label="Net Premium "  fullWidth={true}  formClassName={classes.last_three_input} onChange={(event)=>handleChange(event,"netPremium")} />
          
            
          <ReferenceInput label="GST" source="taxTypeId" reference="tax-heads"  fullWidth={true}  formClassName={classes.one_three_input} sort={{ field: 'taxName', order: 'ASC' }} onChange={(event)=>handleChange(event,"taxTypeId")}>
              <SelectInput optionText="taxName" />
          </ReferenceInput>
           <NumberInput source="grossAmt" label="Gross Amt"  fullWidth={true}  formClassName={classes.two_three_input} initialValue={grossAmt} /> 
           */}
          <FormDataConsumer fullWidth={true} >
            {formDataProps => (
              <CalcFields {...formDataProps} />
            )}
          </FormDataConsumer>
          <DateInput source="startDate" label="Start Date" fullWidth={true} formClassName={classes.one_three_input} />

          <DateInput source="expiryDate" label="Expiry Date" fullWidth={true} formClassName={classes.two_three_input} />
          <DateInput source="nextFollowupDate" label="Next Followup Date" validate={[required()]} fullWidth={true} formClassName={classes.last_three_input} />

          <ReferenceInput label="Reporting To" source="reportingId" reference="employees" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput label="RM" source="rmId" reference="employees" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput label="Tele Caller" source="telecallerId" reference="employees" fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput label="Status " fullWidth={true} link={false} formClassName={classes.one_three_input} perPage={5000} source="statusId" filter={{ type: 'LTS' }} reference="lookups" sort={{ field: 'name', order: 'ASC' }} >
            <SelectInput optionText="name" variant="outlined" fullWidth={true} />
          </ReferenceInput>
          <div>
            <BooleanInput source="isSentUnderwriter" variant="outlined" label="Sent Under writer" initialValue={false} formClassName={classes.first_inline_input} />

            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
              {({ formData, ...rest }) => formData.isSentUnderwriter &&
                (
                  <DateInput source="underWriterDate" validate={[required()]} variant="outlined" label="UnderWriter Date" formClassName={classes.last_inline_input} />
                )
              }

            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
              {({ formData, ...rest }) => !formData.isSentUnderwriter &&
                (
                  <DateInput source="underWriterDate" variant="outlined" label="UnderWriter Date" disabled formClassName={classes.last_inline_input} />
                )
              }
            </FormDataConsumer>
          </div>

          <div>
            <BooleanInput source="isQuoteReleased" label="Quote Released" initialValue={false} formClassName={classes.first_inline_input} />
            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
              {({ formData, ...rest }) => formData.isQuoteReleased &&
                (
                  <DateInput source="quoteReleaseDate" validate={[required()]} variant="outlined" label="Quote Released Date" formClassName={classes.last_inline_input} />
                )
              }

            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
              {({ formData, ...rest }) => !formData.isQuoteReleased &&
                (
                  <DateInput source="quoteReleaseDate" variant="outlined" label="Quote Released Date" disabled formClassName={classes.last_inline_input} />
                )
              }
            </FormDataConsumer>
          </div>


          <BooleanInput source="isActive" label="Is Active" initialValue={true} formClassName={classes.one_three_input} />
          <TextInput multiline source="reportingHeadRemarks" fullWidth={true} label="Reporting Head Remarks " />




        </SimpleForm>


      </Create>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isAddEmployee} >


        {isAddEmployee && <EmployeeCreate onCancel={handleClose} onClose={handleClose} />}

      </Drawer>
    </React.Fragment>
  );
};
