import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter, SelectInput, AutocompleteInput,
  ReferenceInput,
  SearchInput, TextInput
} from "react-admin";
import { DisplayDate } from "../../common/DisplayDate";
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import { Authorizer } from "../../common/Authorizer";
import {
  useHistory
} from "react-router-dom";

const RenewPolicy = (props) => {
  // console.log(props, "===PROPS===");
  let history = useHistory();
  return (
    <Button variant="contained" color="primary" onClick={() => {
      history.push(`/orders/create?oid=${props.record.id}`);
    }}>
      Renew
    </Button>
  )
}
const Filters = props => (
  <Filter {...props} variant="outlined" className="filter-block">
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Customer" source="customerId" reference="customers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    {/* <ReferenceInput perPage={25} sort={{ field: 'mobile', order: 'ASC' }} filterToQuery={searchText => ({ 'mobile~like': searchText })}
      label="Mobile" source="customerId" reference="customers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="mobile" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput> */}
    <TextInput variant="outlined" label="Order No" source="orderNo~like" alwaysOn />
    <TextInput variant="outlined" label="Policy No." source="policyNo~like" alwaysOn />

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Insurer" source="insurerId" reference="insurers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Policy Type" source="policyTypeId" reference="policy-types" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput perPage={25} sort={{ field: 'regnNo', order: 'ASC' }} filterToQuery={searchText => ({ 'regnNo~like': searchText })}
      label="Motor Details" source="motorId" reference="motors" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="regnNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="RM" source="rmid" reference="employees" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="POSP" source="pospId" reference="ref-customers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>


    {/* <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput> */}
    {/* <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name"  shouldRenderSuggestions={(val) => { return val && val.trim().length   >= 1 }} />
    </ReferenceInput> */}
  </Filter>
);
export const OrderList = (props) => {
  const propsObj = Authorizer(props);
  let filter:any ;
  
  let EmployeeId:any;
  if(propsObj.nlfTCORcrd)
  {
    EmployeeId = localStorage.getItem("EmployeeId");  
    if(EmployeeId)
    {
      EmployeeId= parseInt(EmployeeId);
     // propsObj.filter={tcid: EmployeeId}
      filter = {
      where: {
        or: [
          {tcid: parseInt(EmployeeId)},
          // {price: {lt: 50}}
        ]
      }
    }
    }
    
    
  }

  return (
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} className="grid-list" classes={{ actions: "my-action", main: "main", content: "content", }}
      title="Order List"
      
      // filter = {{"or~tcId":12 ,"or~rmId":12}}
      filter = {{"or~":[{"rmId":12}]}}
        
      filters={<Filters />} sort={{ field: 'id', order: 'DESC' }} >
      <Datagrid rowClick={false}>
        {propsObj.hasEdit && <EditButton/>}
        {!propsObj.hasEdit && <EditButton label="View" />}
        {propsObj.nlfRenew && <RenewPolicy label="Renewal" />}
        <ReferenceField label="Booking Branch" source="configId" reference="configs" link={false}>
          <TextField source="mconame" />
        </ReferenceField>
        <TextField source="orderNo" label="Order no" />
        <DateField source="orderDate" label="order Date"  locales="fr-FR" />
        <TextField source="policyNo" label="Policy No" />
        <ReferenceField label="Biz Type" source="bizTypeId" reference="biz-types" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Customer" source="customerId" reference="customers" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Mobile" source="customerId" reference="customers" link={false}>
          <TextField source="mobile" />
        </ReferenceField>


        <ReferenceField label="Customer Group" source="custGroupId" reference="cust-groups" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Insurer" source="insurerId" reference="insurers" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Insurer Branch" source="insurerBrancheId" reference="insurer-branches" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Policy Department" source="policyDepartmentId" reference="policy-departments" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Policy Type" source="policyTypeId" reference="policy-types" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <BooleanField label="Is Cover note" source="isCoverNote" />

        <TextField source="coverNote" label="Cover Note" />

        <DateField source="policyStartDate" label="Policy Start Date"  locales="fr-FR" />
        <DateField source="policyEndDate" label="Policy End Date" locales="fr-FR" />

        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />

      </Datagrid>
    </List>
  );
};
