import React, { useCallback, useState } from "react";

import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput, AutocompleteInput,
  SelectInput, DateField, Create,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab, DateTimeInput,
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { Drawer } from '@material-ui/core';
import { FormToolbar } from "../../common/FormToolbar";
// import { StateDistrict } from "../realize-order/StateDistrict"
import { useStyles } from "../../formStyles";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
// import { RealizationOrderPatmentFields } from "../realize-order/RealizationOrderPatmentFields";
// import { RealizationOrderOtherFields } from "../realize-order/RealizationOrderOtherFields";
import { InsurerAddress } from './InsurerAddress';
import { makeStyles } from '@material-ui/core/styles';
// import CorporateCreate from '../realize-order/CorporateCreate';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { AddressFields } from "./AddressFields";
import { HospitalAddress } from "./HospitalAddress";
import HospitalCreate from './HospitalCreate';
import * as apiClient from "../../common/apiClient";

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,

  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));


const SectionHeading = (props) => {
  const { caption } = props;
  return (
    <div className="heading">
      <h3>{caption}</h3>
    </div>
  )
}

export const ClaimOrderCreate = (props) => {
  const [isAddCorportate, setIsAddCorportate] = useState(false);
  const [isAddHospital, setIsAddHospital] = useState(false);
  const [isOrderNoSelected, setIsorderNoSelected] = useState(false);
  const [orderData, setOrderData] = useState<any>(null);
  const [insurer, setInsurer] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const refresh = useRefresh();
  const classes = useStyles({});
  const myclasses = myStyles({});

  const getOrderData = (selectedOrderNo: any) => {
    apiClient.getEntiyRecord("orders", selectedOrderNo).then(async res => {
      //console.log(res, "====ORDER======");
      let ins = await apiClient.getEntiyRecord("insurers", res.insurerId);
      let cust = await apiClient.getEntiyRecord("customers", res.customerId);
      //console.log(ins, "====insurers======");
      //console.log(cust, "====customers======");
      setCustomer(cust);
      setInsurer(ins);
      setOrderData(res);
      setIsorderNoSelected(true);
    }).catch(err => {
      toast.error(err);
    });
  }
  const getOrderDataByPolicyNo = (policyNo: any) => {
    apiClient.getEntiyRecordByColumn("orders", "policyNo", policyNo).then(async res => {
      //console.log(res, "====ORDER======");
      let ins = await apiClient.getEntiyRecord("insurers", res.insurerId);
      let cust = await apiClient.getEntiyRecord("customers", res.customerId);
      //console.log(ins, "====insurers======");
      //console.log(cust, "====customers======");
      setCustomer(cust);
      setInsurer(ins);
      setOrderData(res);
      setIsorderNoSelected(true);
    }).catch(err => {
      toast.error(err);
    });
  }

  let defaultValues: any = {};
  if (orderData) {
    defaultValues = {
      claimId: "CID000",
      claimno: "",
      iclaimno: "",
      lobTypeId: 0,
      sourceCatId: 0,
      claimTypeId: 0,
      claimAmt: 0,
      initiateOn: moment().toISOString(),
      initiateAt: "",
      initiatedViaId: 0,
      insurerId: orderData.insurerId,
      tpaid: 0,
      policyNo: orderData.policyNo,
      policyStartDate: orderData.policyStartDate,
      policyEndDate: orderData.policyEndDate,
      policyTypeId: orderData.policyTypeId,
      cName: (customer && customer.name) ? customer.name : "",
      cAdd1: (customer && customer.address1) ? customer.address1 : "",
      cAdd2: (customer && customer.address2) ? customer.address2 : "",
      cCountryId: (customer && customer.countryId) ? customer.countryId : 0,
      cDistrictId: (customer && customer.districtId) ? customer.districtId : 0,
      cStateId: (customer && customer.stateId) ? customer.stateId : 0,
      cCityId: (customer && customer.cityId) ? customer.cityId : 0,
      cpin: (customer && customer.pin) ? customer.pin : 0,
      cMobNo: (customer && customer.mobile) ? customer.mobile : "",
      cEmail: (customer && customer.email) ? customer.email : "",
      cProposer: "",
      cProposerContNo: "",
      refName: "",
      refCompany: "",
      refCompanyDesig: "",
      refVia: "",
      refDate: moment().toISOString(),
      isEmpannelled: false,
      doa: moment().toISOString(),
      dod: moment().toISOString(),
      treatDoctor: "",
      treatDoctorMob: "",
      provDiagnosis: "",
      finalDiagnosis: "",
      statusId: 0,
      configId: 0,
      forRework: "",
      reworkBy: 0,
      grossFinding: "",
      recommendationId: 0,
      recommendationUnder: "",
      reason1: "",
      reason2: "",
      reason3: "",
      insurerEmail: (insurer && insurer.email) ? insurer.email : "",
      tpaEmail: "",
      isAccepted: false,
      isReallotment1: false,
      reallot1Date: moment().toISOString(),
      reallot1Reason: "",
      isReallotment2: false,
      reallot2Date: moment().toISOString(),
      reallot2Reason: "",
      finalClosureTat: "",
      finalClosureDate: moment().toISOString(),
      createdBy: 0,
      createdDate: moment().toISOString(),
      modifyBy: 0,
      modifyDate: moment().toISOString()
    };
  }

  const handleClose = useCallback(() => {
    setIsAddCorportate(false);
    setIsAddHospital(false);
  }, []);

  if (isOrderNoSelected) {
    return (
      <React.Fragment>
        <Create title="Add Claim Order" {...props} >
          <SimpleForm
            initialValues={defaultValues}
            redirect="edit"
            toolbar={<FormToolbar {...props} showDelete={false} />}
            variant="outlined">

            <ReferenceInput
              onChange={(v) => {
                if (v != "")
                  getOrderData(v);
                else
                  setIsorderNoSelected(false);
              }}
              perPage={25} sort={{ field: 'orderNo', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Order Number" source="orderId" reference="orders" fullWidth={true} formClassName={classes.first_inline_input}>
              <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <ReferenceInput
              onChange={(v) => {
                console.log(v);
                if (v != "") {
                  getOrderData(v);
                }
                else
                  setIsorderNoSelected(false);
              }}
              perPage={25} sort={{ field: 'policyNo', order: 'ASC' }}
              filterToQuery={searchText => ({ 'policyNo~like': searchText })}
              label="Policy Number" source="policyNo" reference="orders" fullWidth={true} formClassName={classes.last_inline_input}>
              <AutocompleteInput optionText="policyNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <SectionHeading caption="POLICY DETAILS" />
            <TextInput disabled={true} source="claimId" label="Claim ID" fullWidth={true} formClassName={classes.one_4_input} />
            <ReferenceInput
              validate={[required("Please select "), minValue(1, "Please select ")]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Type of LOB" source="lobTypeId" reference="lookups" formClassName={classes.two_4_input}
              filter={{ type: 'TLOB' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              validate={[required("Please select "), minValue(1, "Please select ")]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Category of source" source="sourceCatId" reference="lookups" formClassName={classes.three_4_input}
              filter={{ type: 'SRC' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              validate={[required("Please select "), minValue(1, "Please select ")]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Type of Claim" source="claimTypeId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'TCLM' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput
              validate={[required("Please select ")]}
              source="claimno" label="TPA Claim No" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput validate={[required()]} source="iclaimno" label="Insurance Claim No" fullWidth={true} formClassName={classes.two_4_input} />

            <DateInput validate={[required("Please select ")]} label="Initiate On" source="initiateOn" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput type="time" label="Initiate At" source="initiateAt" fullWidth={true} formClassName={classes.last_4_input} />


            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Intimated Via" source="initiatedViaId" reference="lookups" formClassName={classes.one_4_input}
              filter={{ type: 'INVIA' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>


            <ReferenceInput

              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.two_4_input}>
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <ReferenceInput

              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="TPA Name" source="tpaid" reference="tpas" fullWidth={true} formClassName={classes.three_4_input}>
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <div className="input-divider"></div>
            <FormDataConsumer>
              {formDataProps => (
                <div className="insurer-address"><InsurerAddress {...formDataProps} /></div>
              )}
            </FormDataConsumer>
            <TextInput source="insurerEmail" label="Insurer Email" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput source="tpaEmail" label=" TPAEmail" fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput validate={[required(), minValue(1)]} label="Claim Amount" source="claimAmt" fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="Excess Amount" source="excessAmt" fullWidth={true} formClassName={classes.last_4_input} />

            <ReferenceInput
              validate={[required(), minValue(1)]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Type of Policy" source="policyTypeId" reference="policy-types" formClassName={classes.one_4_input}
              fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>


            <TextInput validate={[required()]} source="policyNo" label="Policy No" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput validate={[required()]} label="Policy Start Date" source="policyStartDate" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput validate={[required()]} label="Policy End Date" source="policyEndDate" fullWidth={true} formClassName={classes.last_4_input} />

            {/* <FormDataConsumer formClassName={classes.last_4_input}>
              {formDataProps => {
                let pType = formDataProps.formData.policyTypeId;
                return (
                  <div style={{ display: "flex", flex: "rows" }}>
                    {pType == 61 && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                      validate={[required(), minValue(1)]}
                      filterToQuery={searchText => ({ 'name~like': searchText })}
                      label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_4_input}>
                      <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>}
                    {pType == 61 && <Button onClick={() => { setIsAddCorportate(true) }}   ><AddIcon /></Button>}
                  </div>
                )
              }}
            </FormDataConsumer> */}

            <SectionHeading caption="INSURED DETAILS" />

            <TextInput validate={[required(), minLength(3)]} source="cName" label="Customer Name" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput validate={[required(), minLength(3)]} source="cMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput source="cEmail" label="Customer e-Mail" fullWidth={true} formClassName={classes.three_4_input} />
            {/* <TextInput source="cAge" label="Customer Age" fullWidth={true} formClassName={classes.last_4_input} />

            <SelectInput label="Customer Sex" source="cSex" fullWidth={true} formClassName={classes.one_4_input}
              choices={[
                { id: 'Male', name: 'Male' },
                { id: 'Female', name: 'Female' },
                { id: 'Other', name: 'Other' }
              ]} /> */}

            <TextInput source="cProposer" label="Proposer Name" fullWidth={true} formClassName={classes.last_4_input} />
            <TextInput source="cProposerContNo" label="Proposer Contact no." fullWidth={true} formClassName={classes.one_4_input} />

            <div className="input-divider"></div>

            <TextInput validate={[required()]} source="cAdd1" label="Address 1" fullWidth={true} />

            <TextInput source="cAdd2" label="Address 2" fullWidth={true} />

            <FormDataConsumer>
              {formDataProps => (
                <AddressFields {...formDataProps} />
              )}
            </FormDataConsumer>
            <NumberInput validate={[required(), minValue(1)]} source="cpin" label="Zipcode" fullWidth={true} formClassName={classes.one_4_input} />


            {/* <BooleanInput label="Empanelled with HDC" source="isEmpannelled" fullWidth={true} formClassName={classes.one_4_input} />
            <BooleanInput label="Investgation allotment accepted by Veritas" source="isAccepted" fullWidth={true} formClassName={classes.twice_4_input} /> */}
            <div className="input-divider"></div>


            {/* <DateInput label="Date of admission" source="doa" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Date of discharge" source="dod" fullWidth={true} formClassName={classes.two_4_input} />

            <TextInput source="treatDoctor" label="Treating Doctor Name" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput source="treatDoctorMob" label="Treating Doctor Contact No." fullWidth={true} formClassName={classes.last_4_input} /> */}

            {/* <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="provDiagnosis" label="Provisional Diagnosis" fullWidth={true} formClassName={classes.first_inline_input} />
            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="finalDiagnosis" label="Final Diagnosis" fullWidth={true} formClassName={classes.last_inline_input} /> */}

            <SectionHeading caption="Recommendation, if any" />


            <TextInput source="refName" label="Reference Name" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput source="refCompany" label="Reference Company" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput source="refCompanyDesig" label="Reference Designation" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput source="refVia" label="Reference Via" fullWidth={true} formClassName={classes.last_4_input} />
            <DateInput label="Reference Date" source="refDate" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput multiline source="claimDetail" label="Claim Detail" fullWidth={true} />
          </SimpleForm>
        </Create>
        <Drawer
          anchor="right"
          onClose={handleClose}
          classes={{
            paper: myclasses.drawerPaper,
          }}
          open={isAddCorportate || isAddHospital} >
          {/* {isAddCorportate && <CorporateCreate onClose={handleClose} />} */}
          {isAddHospital && <HospitalCreate onClose={handleClose} />}

        </Drawer>
      </React.Fragment>

    );
  }
  else {

    return (
      <React.Fragment>
        <Create title="Add Claim Order" {...props} >
          <SimpleForm
            redirect="edit"
            toolbar={<FormToolbar {...props} hideSave={true} showDelete={false} />}
            variant="outlined">

            <ReferenceInput
              onChange={(v) => {
                console.log(v);
                if (v != "") {
                  getOrderData(v);
                }
                else
                  setIsorderNoSelected(false);
              }}
              perPage={25} sort={{ field: 'orderNo', order: 'ASC' }}
              filterToQuery={searchText => ({ 'orderNo~like': searchText })}
              label="Order Number" source="orderId" reference="orders" fullWidth={true} formClassName={classes.first_inline_input}>
              <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <ReferenceInput
              onChange={(v) => {
                console.log(v);
                if (v != "") {
                  getOrderData(v);
                }
                else
                  setIsorderNoSelected(false);
              }}
              perPage={25} sort={{ field: 'policyNo', order: 'ASC' }}
              filterToQuery={searchText => ({ 'policyNo~like': searchText })}
              label="Policy Number" source="policyNo" reference="orders" fullWidth={true} formClassName={classes.last_inline_input}>
              <AutocompleteInput optionText="policyNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
          </SimpleForm>
        </Create>
      </React.Fragment>

    );

  }
};
