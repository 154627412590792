import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import {
  useHistory
} from "react-router-dom";

const ViewDetails = (props) => {
 // console.log(props, "===PROPS===");
 let history = useHistory();
  return (
    <Button variant="contained" color="primary" onClick={() => {
      history.push(`/sms-logs?hid=${props.record.id}`);
    }}>
      View Details
    </Button>
  )
}
const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="SMS Type" source="smsType~like" alwaysOn />
    <SearchInput variant="outlined" label="subject" source="subject~like" alwaysOn />
    <SearchInput variant="outlined" label="Phone" source="toAddress~like" alwaysOn />
  </Filter>
);
export const SmsLogHdsList = (props) => {
  //console.log(props, "===SmsLogHdsList PROPS===");
  return (
    <List {...props} title="SMS Send List" bulkActionButtons={false} filters={<Filters />}  >
      <Datagrid rowClick={false}>
        <TextField source="smsType" label="Type" />
        <TextField source="subject" label="Subject" />
        <TextField source="body" label="SMS Text" />
        <TextField source="toAddressType" label="Customers" />
        <DateField source="createdAt" label="Date" />
        <ViewDetails label="SMS Details"/>
      </Datagrid>
    </List>
  );
};
