import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import InvoicePrint from './InvoicePrint/InvoicePrint';


export const EmployeeSalaryView = props => {
    let location = useLocation();

    const values = queryString.parse(location.search);
    let token = (values.token) ? values.token : '';
    //console.log(token, "=====TOKEN=====");
    const [currentItem, setCurrentItem] = useState<any>(null);

    const getEntiyRecordByColumn = (id) => {
        console.log("calling api");
        setCurrentItem(null);
        apiClient.getEntiyRecordByColumn("employee-sal-dets", "token", id).then(res => {
            //console.log(res, "=====getEntiyRecordByColumn====");
            if (res.length > 0)
                setCurrentItem(res[0]);
        }).catch(err => {
            toast.error(err);
        });
    }

    useEffect(() => {
        if (!token || token == "") return;
        getEntiyRecordByColumn(token);
    }, [token]);

    return (
        <div className='alert-container'>
            {currentItem && <InvoicePrint
                invoiceId={currentItem.id}
                {...props} />}
        </div>
    );


}