import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'
import {
  useHistory
} from "react-router-dom";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,ShowButton,
  SearchInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import InvoicePrint from "./InvoicePrint/InvoicePrint";
import InvoicePrint2 from "./InvoicePrint2/InvoicePrint";
import PolicyMap from './PolicyMap';
const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      marginRight: 5,
      
      width: 650,
  },
}));
const Filters = props => (
  <Filter {...props} variant="outlined">
  <SearchInput variant="outlined" label="Invoice No" source="invoiceNo~like" alwaysOn /> 
  <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}  filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true}  allowEmpty alwaysOn>
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
</Filter>
);
export const InvoiceList = (props) => {
  const [isPrint, setIsPrint] = useState(false);
  const [isPrintWithOutHeader, setIsPrintWithOutHeader] = useState(false);
  const [isPolicyMap, setIsPolicyMap] = useState(false);
  const [invoiceId, setinvoiceId] = useState(0);
  const [insurerId, setinsurerId] = useState(0);
  
  
  const myclasses = myStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const closeEdit = () => {
    setIsPrint(false)
    setIsPrintWithOutHeader(false);
    setIsPolicyMap(false)
  }
   
const PrintButton=(props)=>( 
  <Button onClick={props.click}  > Print With Header </Button>
) 
const PrintButtonWithoutHeader=(props)=>( 
  <Button onClick={props.click}  > Print  </Button>
) 
  const onCancel = () => {
    setIsPrint(false)
    setIsPolicyMap(false)
  }
  const handleClose = useCallback(() => {
    //setIsAddTpa(false);
}, []);
function showPopup(id){
    
  setinvoiceId(id)
  setIsPrint(true);
  setIsPrintWithOutHeader(false);
  setIsPolicyMap(false)
}
function showPopupWithoutHeader(id){
    
  setinvoiceId(id)
  setIsPrint(false);
  setIsPrintWithOutHeader(true);
  setIsPolicyMap(false)
}

const DisplayFileLink = (props) => {
  let { record } = props;

  return (
    <PrintButton click={(e)=>showPopup(record.id)}   />
    
  )
}
const DisplayFileLink1 = (props) => {
  let { record } = props;

  return (
    <PrintButtonWithoutHeader click={(e)=>showPopupWithoutHeader(record.id)}   />
    
  )
}
const MapPolicy = (props) => {
  // console.log(props, "===PROPS===");
  
  //  return (
  //    <Button variant="contained" color="primary" onClick={() => {
  //     setIsPolicyMap(true); setinvoiceId(props.record.id) ;setinsurerId(props.record.insurerId)
  //      //history.push(`/orders/create?oid=${props.record.id}`);
  //    }}>
  //      Reco
  //    </Button>
  //  )
   let history = useHistory();
   return (
     <Button variant="contained" color="primary" onClick={() => {
       history.push(`/policy-invs/${props.record.id}/1`);
     }}>
       Reconcile
     </Button>
   )
 }
  return (
    
    <React.Fragment>
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Invoice List" filters={<Filters />}  >
      <Datagrid >
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
        {<MapPolicy label="Reco"/>}
        <DisplayFileLink label="" source="id" />
        <DisplayFileLink1 label="" source="id" />
        
        <TextField source="invoiceNo" label="Invoice No" />
        <DateField  source="invoiceDate" label="Invoice Date" locales="fr-FR" />
        <ReferenceField label="Insurer" source="insurerId" reference="insurers" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Brokerage Type" source="brokTypeId" reference="lookups" link={false}>
          <TextField source="name" />
        </ReferenceField>
      
         <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date" locales="fr-FR" />
            </Datagrid>      
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper, }}
    open={isPrint || isPrintWithOutHeader || isPolicyMap }>
      { isPrint && <InvoicePrint
                      onClose={closeEdit}
                      onCancel={onCancel}
                      invoiceId={invoiceId}
                      isOpen={isPrint}
                      {...props} />}
      { isPrintWithOutHeader && <InvoicePrint2
                      onClose={closeEdit}
                      onCancel={onCancel}
                      invoiceId={invoiceId}
                      isOpen={isPrint}
                      {...props} />}
      { isPolicyMap && <PolicyMap
                      onClose={closeEdit}
                      onCancel={onCancel}
                      invoiceId={invoiceId}
                      insurerId={insurerId}
                      isOpen={isPolicyMap}
                      {...props} />}
      </Drawer>
      </React.Fragment>
  );
};
