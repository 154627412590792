import React, { useState } from "react";
import moment from "moment";
import * as apiClient from "../../common/apiClient";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  useNotify,
  BooleanInput,
  DateInput, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, useRedirect
} from "react-admin";
import Customfields from "./Customfields";
import { FormToolbar } from "../../common/FormToolbar";
import CustomSelectInput from "./CustomSelectInput";

export const RateMasterCreate = (props) => {
  const [isAllInsurer, setIsAllInsurer] = useState(false);
  const [isAllPolicyType, setIsAllPolicyType] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();
  const handleSubmit = async (formdata) => {

    console.log(formdata);
    let policyTypes: any = [];
    let insurers: any = [];
    let m = moment();

    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let data = { ...formdata };
    if (data.isAllPolicyType) {
      data.policyTypeId = 0;
    }
    else {
      policyTypes = [...data.policyTypeId]
      data.policyTypeId = 0;
    }
    if (data.isAlInsurer) {
      data.insurerId = 0;
    }
    else {
      insurers = [...data.insurerId]
      data.insurerId = 0;
    }

    if (data.hasOwnProperty("effectiveFrom") && data.effectiveFrom != null) {
      if (!data.effectiveFrom.toString().includes('T03:09:32.033Z')) {
        data.effectiveFrom = data.effectiveFrom + 'T03:09:32.033Z';
      }

    }
    if (data.hasOwnProperty("effectiveFrom") && data.effectiveFrom == null) {
      data.effectiveFrom = "1900-01-01T03:09:32.033Z";
    }
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();
    await apiClient.addEntiyRecord("rate-masters", data).then(res => {
      //{"id":14,"name":"ass","rateTypeId":101,"policyDepartmentId":12,"policyTypeId":0,"insurerId":0,"tpRatePercentage":1,"brokRatePercentage":1,"effectiveFrom":"2022-12-17T03:09:32.033Z","createdBy":1,"createdDate":"2022-12-18T08:53:53.500Z","modifyBy":1,"modifyDate":"2022-12-18T08:53:53.500Z","isAllPolicyType":true,"isAlInsurer":true,"isActive":true}

      if (policyTypes.length == 0 && insurers.length == 0) {
        redirect("/rate-masters");
        notify("Added Succesfully");
      }
      else {


        insurers.forEach(element => {
          let _data =
          {
            "rateId": res.id,
            "insurerId": element,
            "createdBy": user,
            "createdDate": m.toISOString(),
            "modifyBy": user,
            "modifyDate": m.toISOString(),
            "isActive": true
          }
          apiClient.addEntiyRecord("rate-insurers", _data).then(ires => { })
        });



        policyTypes.forEach(element => {
          let _data =
          {
            "rateId": res.id,
            "policyTypeId": element,
            "createdBy": user,
            "createdDate": m.toISOString(),
            "modifyBy": user,
            "modifyDate": m.toISOString(),
            "isActive": true
          }
          apiClient.addEntiyRecord("rate-policy-types", _data).then(ires => { })
        })

        redirect("/rate-masters");
        notify("Added Succesfully");
      }

    })
      .catch(err => {
        console.log(err);
      });

    // debugger
    //     apiClient.UploadFile("docUrl", formdata.docUrl).then(res => {
    //         let user = localStorage.getItem("userId");
    //         if (user) {
    //         user = parseInt(user);
    //         }
    //         let documentUrl = res;
    //         let data = {
    //             id:parseInt(props.record.id),
    //             ordId: parseInt(props.ordId),
    //              documentTypeId: 0,
    //              typeId:0,
    //             endorsementId: parseInt(formdata.endorsementId),
    //              remarks: formdata.remarks,
    //              endorsementNo: formdata.endorsementNo,

    //             docUrl: documentUrl, 
    //             createdBy: parseInt(formdata.createdBy),
    //             modifyBy: user,
    //             statusId:parseInt(formdata.statusId),
    //             createdDate: formdata.createdDate,
    //             modifyDate: moment().toISOString(),

    //         }
    //         if(formdata.documentTypeId && parseInt(formdata.documentTypeId)!=undefined)
    //         {
    //             data.documentTypeId=parseInt(formdata.documentTypeId);

    //         }
    //         if(formdata.typeId && parseInt(formdata.typeId)!=undefined)
    //         {
    //             data.typeId=parseInt(formdata.typeId);

    //         }

    //         apiClient.updateRecord("order-endorsements", data).then(res => {

    //             //console.log(res);
    //             props.onClose();
    //         }).catch(err => {
    //             console.log(err);
    //         });

    //     }).catch(err => {
    //         console.log(err);
    //     });

  }
  return (
    <Create title="Rate Master Create" {...props}>
      {/* <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" > */}
      <SimpleForm
        redirect="list"
        onSubmit={handleSubmit}

        toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
        variant="outlined">
        <TextInput source="name" fullWidth={true} label="Code" validate={[required()]} />
        <ReferenceInput label="Rate Type" validate={[required()]} fullWidth={true} source="rateTypeId" reference="lookups" perPage={5000}
          filter={{ type: 'RT' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        {/* <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <Customfields {...formDataProps} />
              )}
            </FormDataConsumer>  */}

        <FormDataConsumer fullWidth={true} >
          {formDataProps => (
            <CustomSelectInput reference="policy-departments"
              selectedText={"--All--"} source={"policyDepartmentId"}
              optionText={"name"} label={"Policy Department"}
              fullWidth={true} {...formDataProps}

              dependentFields={["policyTypeId"]}
            />
          )}
        </FormDataConsumer>
        
        <BooleanInput
          onChange={(data) => {
            setIsAllPolicyType(data);
          }}
          source="isAllPolicyType" label="All Policy Type" fullWidth={true} initialValue={false} />


        {!isAllPolicyType && <FormDataConsumer fullWidth={true} >{formDataProps => (<ReferenceArrayInput
          perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          filters={[{ "policyDepartmentId": formDataProps.policyDepartmentId }]}
          label="Policy Types" source="policyTypeId" reference="policy-types" fullWidth={true} >
          <AutocompleteArrayInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceArrayInput >
        )}
        </FormDataConsumer>
        }
        <BooleanInput
          onChange={(data) => {
            setIsAllInsurer(data);
          }}
          source="isAlInsurer" label="All Insurer" fullWidth={true}  initialValue={false}/>


        {!isAllInsurer && <ReferenceArrayInput
          perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="insurers" source="insurerId" reference="insurers" fullWidth={true} >
          <AutocompleteArrayInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceArrayInput >
        }
        <NumberInput variant="outlined" label="TP Rate Percentage" source="tpRatePercentage" fullWidth={true} />
        <NumberInput variant="outlined" label="Brok Rate Percentage" source="brokRatePercentage" fullWidth={true} />
        <DateInput label="Effective From" source="effectiveFrom" fullWidth={true} />
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};
