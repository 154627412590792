import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter, ShowButton,
  ReferenceInput,
  SearchInput
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";


const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="City Name" source="cityName~like" alwaysOn />
  </Filter>
);
export const CityList = (props) => {
  const propsObj: any = Authorizer(props);
  return (
    <List {...props} bulkActionButtons={false} title="City List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}

        <TextField source="cityCode" label="City Code" />
        <TextField source="cityName" label="City Name" />
        <ReferenceField source="stateId" reference="states" link={false} label="State" >
          <TextField source="stateName" />
        </ReferenceField>

        <ReferenceField source="insertedBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="insertedAt" label="Created Date" />
        <ReferenceField source="updatedBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="updatedAt" label="Updated Date" />
      </Datagrid>
    </List>
  );
};
