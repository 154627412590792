import React, { useEffect,useCallback,useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';

import moment from 'moment';
import * as apiClient from "../../../common/apiClient";
import  PopupCreateToolbar  from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const PremiumEdit = props => {
    const classes = useStyles({});
    const[tpPremiumAmt,setTpPremiumAmt]=useState(0);
    const[odPremiumAmt,setOdPremiumAmt]=useState(0);
    const[IsMultiPolicy,setIsMultiPolicy]=useState(true);
    const[IsinsurerId,setinsurerId]=useState(-1);
    
    const[net,setNet]=useState(0);
    useEffect(() => {
        getOrderHeaderData();
    }, [IsinsurerId]); 
    const getOrderHeaderData = () => {
        apiClient.getOrderHeaderData(parseInt(props.ordId)).then(res => {
            if(res.isMultiPolicy==true)
            {
                setIsMultiPolicy(true);
            }
            else
            {
                setIsMultiPolicy(false);
            }
            setinsurerId(res.insurerId)
            
        }).catch(err => {
            console.log(err);
        });
    }
    console.log(props);
    const TwoDecimalPlaces = (value) => {
        
        if (isNaN(value))
        {
            value=0;
        }
        return Number(value).toFixed(2).valueOf();
    }
    const onAmountChangeAmt = (e, field) => {
        let inputVal = parseFloat(e.target.value);  
        let odPremiumAmt_In=odPremiumAmt
        let tpPremiumAmt_In=tpPremiumAmt
        if (field == "odPremiumAmt") {
            odPremiumAmt_In = inputVal;
            setOdPremiumAmt(inputVal);
        }
         
        if (field == "tpPremiumAmt") {
            tpPremiumAmt_In = inputVal;
            setTpPremiumAmt(inputVal)
        }
        if (isNaN(odPremiumAmt_In)) {
            odPremiumAmt_In = 0;
        }

        if (isNaN(tpPremiumAmt_In)) {
            tpPremiumAmt_In = 0;
        }
       setNet(odPremiumAmt_In+  tpPremiumAmt_In)


    }

    const handleSubmit = async (formdata) => {
        
        // let documentUrl = await apiClient.UploadFile("docUrl", formdata.docUrl);
        // let data = props.record;
        // data.docUrl = documentUrl;
        // data.docType = formdata.docType;
        // data.docValue = parseInt(formdata.docValue);
        // data.modifyBy = 1;
        // data.modifyDate = data.createdDate;
        // apiClient.updateRealizationDocument(data).then(res => {
        //     props.onClose();
        // }).catch(err => {
        //     console.log(err);
        // });


        let m = moment(new Date());
        let data = {
            id:parseInt(props.record.id),
            orderId: parseInt(formdata.orderId),
            insurerId: parseInt(formdata.insurerId),
            policyDetailId: parseInt(formdata.policyDetailId),
            premiumTypeId: parseInt(formdata.premiumTypeId),
            
            odSumInsuredAmt: parseFloat(formdata.odSumInsuredAmt),
            odPremiumAmt: parseFloat(formdata.odPremiumAmt),
            tpSumInsuredAmt: parseFloat(formdata.tpSumInsuredAmt),
            tpPremiumAmt: parseFloat(formdata.tpPremiumAmt),
            netPremiumAmt:parseFloat(formdata.odPremiumAmt)+parseFloat(formdata.tpPremiumAmt),
            netSumInsuredAmt:parseFloat(formdata.odSumInsuredAmt)+parseFloat(formdata.tpSumInsuredAmt),
            sharePerc:parseFloat(formdata.sharePerc),
            policyNo: formdata.policyNo,
            remarks: formdata.remarks,
            isTaxable: formdata.isTaxable,
            createdBy:0,
            modifyBy:0,
            createdDate:  m.toISOString(),
            modifyDate:  m.toISOString(),
           
        }
        if(data.policyNo==null)
        {data.policyNo=""}
        apiClient.updateRecord("order-premiums", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="order-premiums" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">
                    <ReferenceInput label="Premium Type" fullWidth={true} link={false}  formClassName={classes.first_inline_input} 
                    perPage={5000} source="premiumTypeId"
                    filter={{ type: 'PRT' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} />
                </ReferenceInput>
  { <ReferenceInput perPage={250000} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}  
                    filterToQuery={searchText => ({ 'name~like': searchText })}  formClassName={classes.last_inline_input} 
                    label="Insurer" source="insurerId" reference="insurers" fullWidth={true} > 
                    <SelectInput optionText="name"  initialValue={IsinsurerId}/>
                </ReferenceInput>}
                {/* {IsinsurerId>0 && IsMultiPolicy && IsMultiPolicy===false && 
                <ReferenceInput perPage={250000} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} initialValue={IsinsurerId}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    filter={{ id: parseInt(IsinsurerId) }}
                    label="Insurer" source="insurerId" reference="insurers" fullWidth={true} disabled={true}> 
                    <SelectInput optionText="name"  />
                </ReferenceInput> } */}
                {/* <ReferenceInput label="Policy Detail" source="policyDetailId" reference="policy-masters" fullWidth={true} link={false}  >  
                    <SelectInput optionText="name" validate={[required()]} />
                </ReferenceInput> */}


                <NumberInput label="OD Sum Insured" validate={[required()]} source="odSumInsuredAmt" fullWidth={true}  formClassName={classes.first_inline_input} 
                  
                />
                <NumberInput label="OD Premium" validate={[required()]} source="odPremiumAmt" fullWidth={true}   formClassName={classes.last_inline_input} 
                  onChange={(e) => {
                    onAmountChangeAmt(e,"odPremiumAmt");
                }}
                />
                <NumberInput label="TP Sum Insured Amt" validate={[required()]} source="tpSumInsuredAmt" fullWidth={true}  formClassName={classes.first_inline_input} />
                <NumberInput label="TP Premium Amt" validate={[required()]} source="tpPremiumAmt" fullWidth={true}   formClassName={classes.last_inline_input} 
                  onChange={(e) => {
                    onAmountChangeAmt(e,"tpPremiumAmt");
                }}
                
                
                />

<FormDataConsumer>
              {formDataProps => 
              //( setTPAID((formDataProps.formData.tpaId)) )
              <div>
              <div style={{fontWeight:"bold", fontSize:"15px",minWidth:"100%",borderBottom:"1px dashed lightgrey"}} >
                    <div style={{float:'right'}}>
                        Net Premium: {TwoDecimalPlaces(parseFloat(formDataProps.formData.odPremiumAmt)+parseFloat(formDataProps.formData.tpPremiumAmt))}
                </div>
                <br/>
                <div style={{fontWeight:"bold", fontSize:"15px",minWidth:"100%",borderBottom:"1px dashed lightgrey"}} >
                <div style={{float:'right'}}>
                        Net Sum Insured : {TwoDecimalPlaces(parseFloat(formDataProps.formData.odSumInsuredAmt)+parseFloat(formDataProps.formData.tpSumInsuredAmt))}
                        
                </div>
               </div>
               </div>
               </div>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {formDataProps => 
              //( setTPAID((formDataProps.formData.tpaId)) )
                formDataProps.formData.premiumTypeId==136  && IsMultiPolicy &&   <NumberInput disabled={true} disable={true} validate={[required(), number(), minValue(0),maxValue(100),maxLength(4)]} label="Share Perc" source="sharePerc" variant="outlined"
                 
                fullWidth={true} 
                 onChange={(e) => {
                   onAmountChangeAmt(e,"sharePerc");
               }}/>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {formDataProps => 
              //( setTPAID((formDataProps.formData.tpaId)) )
                formDataProps.formData.premiumTypeId==136  && IsMultiPolicy &&   
                <TextInput label="Policy No"  variant="outlined" source="policyNo" fullWidth={true} initialValue={""} />
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {formDataProps => 
              //( setTPAID((formDataProps.formData.tpaId)) )
                formDataProps.formData.premiumTypeId==137  && 
                <TextInput label="Endorsement No"  variant="outlined" source="policyNo" fullWidth={true} initialValue={""} />
              }
            </FormDataConsumer>


                <TextInput label="Remarks" source="remarks" fullWidth={true} />
                <BooleanInput variant="outlined" label="Is Taxable" source="isTaxable" fullWidth={true} initialValue={false} />
            
            </SimpleForm>
        </Edit>
    );
}
export default PremiumEdit;