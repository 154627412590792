import React, { useState, useEffect } from 'react';
import { useNotify } from "react-admin";
import Switch from '@material-ui/core/Switch';
import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import CountBlock from "./CountBlock";
import HolidayList from "./HolidayList";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomCalendar from './customcalender/CustomCalendar';

const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center", flexWrap: "wrap" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    locationPrompt: { 
        padding: "10px", 
        backgroundColor: "#ffcc00", 
        color: "#333", 
        borderRadius: "5px", 
        marginBottom: "10px",
        textAlign: "center" 
    },
    button: { 
        marginTop: '10px', 
        padding: '5px 10px', 
        backgroundColor: '#f4a261', 
        color: '#fff', 
        border: 'none', 
        cursor: 'pointer' 
    }
});

const Dashboard = () => {
    const [db, setdb] = useState([]);
    const [fetchingAttedanceStatus, setFetchingAttedanceStatus] = useState(true);
    const [attType, setAttType] = useState("");
    const [workLocation, setWorkLocation] = useState("");
    const [attOnOff, setattOnOff] = useState(false);
    const [holiday, setHoliday] = useState([]);
    const [isLocationEnabled, setIsLocationEnabled] = useState<any>(null);
    const [showLocationPrompt, setShowLocationPrompt] = useState(false);
    const [latitude, setLatitude] = useState<number | null>(null);  // Store latitude
    const [longitude, setLongitude] = useState<number | null>(null);  // Store longitude
    const notify = useNotify();
    const styles = useStyles({});

    const loadDashboard = () => {
        let userid = localStorage.getItem("userId");
        apiClient.get_dashboard(userid).then(res => {
            setdb(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const GetDashboardHoliday = () => {
        apiClient.GetDashboardHoliday().then(res => {
            setHoliday(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleAttendance = (event) => {
        if (!isLocationEnabled) {
            notify("Please enable location to mark attendance", "warning");
            setShowLocationPrompt(true); // Show prompt to enable location
            return;
        }
        if (workLocation === null || workLocation === "") {
            notify("Please Select Work location", "warning");
            return;
        }
        if (attType === "ATT_END" && window.confirm('Do you really want to end your working?')) {
            updateAttedance();
        }
        if (attType === "ATT_START" && window.confirm('Do you really want to Start your working?')) {
            updateAttedance();
        }
    }

    const updateAttedance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        let formBody = {
            userId: userid,
            attType: attType,
            location: workLocation,
            latitude: latitude,  // Add latitude
            longitude: longitude  // Add longitude
        }
        apiClient.updateAttendance(formBody).then(res => {
            notify(res[0].Msg)
            loadAttendance();
        }).catch(err => {
            console.log(err);
        });
    }

    const loadAttendance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        apiClient.get_attendancestatus(userid).then(res => {
            setAttType(res[0].Msg);
            setWorkLocation(res[0].location)
            if (res[0].Msg === 'ATT_START') {
                setattOnOff(false)
            }
            if (res[0].Msg === 'ATT_END') {
                setattOnOff(true)
            }
            setFetchingAttedanceStatus(false);
        }).catch(err => {
            console.log(err);
        });
    }

    // Function to check if location is enabled
    const checkLocationPermission = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setIsLocationEnabled(true);  // Location is enabled
                    setLatitude(position.coords.latitude);  // Store latitude
                    setLongitude(position.coords.longitude);  // Store longitude
                    setShowLocationPrompt(false); // Hide prompt
                },
                (error) => {
                    setIsLocationEnabled(false);  // Location is not enabled
                    setShowLocationPrompt(true);  // Show prompt to enable location
                }
            );
        } else {
            setIsLocationEnabled(false); // Geolocation not supported
            setShowLocationPrompt(true); // Show prompt
        }
    }

    // Function to recheck location permission
    const recheckLocationPermission = () => {
        checkLocationPermission();  // Recheck the location permission
        notify("Rechecked location permission.");
    }

    useEffect(() => {
        checkLocationPermission();  // Check for location permission on component mount
        loadDashboard();
        loadAttendance();
        GetDashboardHoliday();
    }, []);

    return (
        <div>
            {showLocationPrompt && (
                <div className={styles.locationPrompt}>
                    <strong>Location Permission is required to mark attendance. Please enable location services in your browser.</strong>
                    <button 
                        className={styles.button} 
                        onClick={recheckLocationPermission}
                    >
                        Recheck Location
                    </button>
                    <br />
                    <small>
                        You can enable location services in your browser settings.
                    </small>
                </div>
            )}

            {!fetchingAttedanceStatus && (
                <div style={{ display: "flex", marginTop: "6px", padding: "6px", fontSize: "12px", color: "blue", border: "1px solid lightgray" }}>
                    {attType !== "ATT_DONE" && <div>Mark {attType === 'ATT_START' ? ' In ' : ' Out '} Attendance  {attType === 'ATT_END' ? '. You are working from ' : ""}</div>}
                    {attType !== "ATT_DONE" && (
                        <div style={{ paddingLeft: 10 }}>
                            <select
                                style={{ height: 30 }}
                                onChange={(event) => setWorkLocation(event.target.value)}
                                disabled={attType === "ATT_END" || attType === "ATT_DONE"}
                            >
                                <option value="" selected={workLocation === null || workLocation === ""}>Select Work Location</option>
                                <option value="WFH" selected={workLocation === "WFH"}>WFH</option>
                                <option value="WFO" selected={workLocation === "WFO"}>WFO</option>
                                <option value="WFF" selected={workLocation === "WFF"}>WFF</option>
                            </select>
                        </div>
                    )}
                    {attType !== "ATT_DONE" && (
                        <div>
                            <FormControlLabel
                                value="start"
                                control={<Switch color="primary" checked={attOnOff} onChange={(event) => handleAttendance(event)} />}
                                label={attType === "ATT_START" ? "Start Your Day" : "End Your Working"}
                                labelPlacement="start"
                            />
                        </div>
                    )}
                </div>
            )}

            <div className={styles.flexCenter}>
                {db && db.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <CountBlock title={item.Descriptions} value={item.TotalCount} icon={item.IconUrl} />
                        </div>
                    )
                })}
            </div>

            <div className={styles.flex} style={{ margin: 30 }}>
                <div>
                    <h2 style={{ textAlign: "center" }}>Attendance</h2>
                    <CustomCalendar />
                </div>
                <div style={{ paddingLeft: 30 }}>
                    <h2 style={{ textAlign: "center" }}>Holiday Details</h2>
                    {holiday && <HolidayList data={holiday} />}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
