import React,{useState,useCallback} from "react";
import AddIcon from '@material-ui/icons/Add';
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  AutocompleteInput,
  SimpleForm,
  FormTab,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";


import Button from '@material-ui/core/Button';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const OuterComponent = (props) => {

  return (
    props.children
  )
}
const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 850,
  },
}));
 
export const AcitivityCreate = (props) => {
  const [isAddEmployee, setIsAddEmployee] = useState(false); 
  const classes = useStyles({});
  const myclasses = myStyles({});
  let defaultValues = 
    {
      
      orderNo: "-", 
      date:new Date(), 
      isActive:true,
      amount:0,
      advAmt:0,
      balAmt:0,
      remarks:"",
      purpose:"",
    
    
  }  ;
   

  const handleClose = useCallback(() => {
setIsAddEmployee (false);
    
}, []);
  return (
    <React.Fragment>
    <Create title="Conv Order Create" {...props}>
       <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}  initialValues={defaultValues}>
          
           
            <TextInput source="orderNo" label="Order No" fullWidth={true}    validate={[required()]} formClassName={classes.one_three_input}  disabled />
            <DateInput source="date" label="Date"  fullWidth={true}  validate={[required()]}   formClassName={classes.two_three_input}  disabled />
            <TextInput source="name" label="Name" fullWidth={true}    validate={[required()]} formClassName={classes.last_three_input}   />

            <TextInput source="location" label="Location" fullWidth={true}    validate={[required()]} formClassName={classes.one_three_input}   />
            <TextInput source="from" label="From" fullWidth={true}    validate={[required()]} formClassName={classes.two_three_input}   />
            <TextInput source="to" label="To" fullWidth={true}    validate={[required()]} formClassName={classes.last_three_input}   />
            

            <TextInput source="client" label="Client" fullWidth={true}    validate={[required()]} formClassName={classes.one_three_input}   />
            <TextInput source="purpose" label="Purpose" fullWidth={true}  multiline    validate={[required()]} formClassName={classes.two_three_input}   />
            <TextInput source="mode" label="Mode" fullWidth={true}    validate={[required()]} formClassName={classes.last_three_input}   />
            
            
            <NumberInput source="amount" label="Amount" fullWidth={true}    validate={[required()]} formClassName={classes.one_three_input} />
            <NumberInput source="advAmt" label="Adv Amt" fullWidth={true}      validate={[required()]} formClassName={classes.two_three_input}     />
            <NumberInput source="balAmt" label="Bal Amt" fullWidth={true}    validate={[required()]} formClassName={classes.last_three_input}     />
            
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} 
            formClassName={classes.one_three_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Approved By" source="approvedBy" reference="employees" fullWidth={true} 
                validate={[required()]} 
                >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => {

                  return val && val.trim().length >= 1
                }} />
              </ReferenceInput>
              <DateInput source="paidOn" label="Paid On"  fullWidth={true}  validate={[required()]}   formClassName={classes.two_three_input}  /> 
              
              <TextInput source="remarks" label="Remarks" fullWidth={true}  multiline      formClassName={classes.last_three_input}  />
           
           
           
        </SimpleForm>
        

    </Create>
     
      </React.Fragment>
  ); 
};
