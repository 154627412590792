import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import  PopupCreateToolbar  from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const TriggerDocumentEdit = props => {
    const classes = useStyles({});
    //console.log(props);
    const handleSubmit = async (formdata) => {
      //  console.log(formdata);
      let m = moment(new Date());
        let documentUrl = await apiClient.UploadFile("docurl", formdata.docurl);
        let data = props.record;
        data.docurl = documentUrl;
        data.trigDocs = formdata.trigDocs;
        data.additionalDocument = formdata.additionalDocument;
        data.isVerified = formdata.isVerified;
        data.invVerifyRemarks = formdata.invVerifyRemarks;
        if(!data.createdBy) data.createdBy = 1;
        if(!data.createdDate) data.createdDate = m.toISOString();
        data.modifyBy = 1;
        data.modifyDate = m.toISOString();
       
        apiClient.updateEntiyRecord("claim-ord-invs",data).then(res => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="claim-ord-invs" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">

                <TextInput source="trigDocs" label="Trigger Detail" fullWidth={true} />
                <TextInput label="Additional Detail" source="additionalDocument" fullWidth={ true }/>
                <BooleanInput source="isVerified" label="Is Verified" fullWidth={true} />
                <TextInput source="invVerifyRemarks" label="Remarks" fullWidth={true} />

                <FileInput fullWidth={true} source="docurl" label="Document">
                    <FileField source="docurl" title="Document" />
                </FileInput>
            </SimpleForm>
        </Edit>
    );
}
export default TriggerDocumentEdit;