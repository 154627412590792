import React from "react";
import queryString from 'query-string';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput, ShowButton,
  SearchInput,
  SelectInput
} from "react-admin";
import { DisplayDate } from "../../common/DisplayDate";


const Filters = props => (

  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Insurer Code" source="code~like" alwaysOn />
    <SearchInput variant="outlined" label="Insurer Name" source="name~like" alwaysOn />
    <ReferenceInput label="Area" source="areaId" reference="areas" >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export const InsurerList = (props) => {
  let typeId:any = new URLSearchParams(props.location.search).get("type")

  if (typeId) {
    typeId = typeId;
    localStorage.setItem("insurerTypeId", typeId);
  }
  else {
    typeId = localStorage.getItem("insurerTypeId");
  }

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false}
      title={parseInt(typeId) === 1 ? "Non Life Insurer List" : "Life Insurer List"}
      filters={<Filters />} filter={{ insurerTypeId: parseInt(typeId) }} >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' : false}>
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}

        <TextField source="code" label="Insurer Code" />
        <TextField source="name" label="Insurer Name" />
        <ReferenceField source="areaId" reference="areas" link={false} label="Area" >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDate source="modifydate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
