import React, { useState, useCallback } from "react";
import Button from '@material-ui/core/Button'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,ShowButton,
  DeleteButton,
  ImageField,
  Filter, SelectInput, AutocompleteInput,
  ReferenceInput,
  SearchInput, TextInput
} from "react-admin";
import { DisplayDate } from "../../common/DisplayDate";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import Print from "./InvoicePrint/InvoicePrint";
import Print2 from "./InvoicePrint2/InvoicePrint2";
import { Authorizer } from "../../common/Authorizer";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    marginRight: 5,

    width: 650,
  },
}));

const Filters = props => (
  <Filter {...props} variant="outlined" className="filter-block">
    <TextInput variant="outlined" label="Claim No" source="claimId~like" alwaysOn />
    <TextInput variant="outlined" label="Policy No" source="policyNo~like" alwaysOn/>
    <TextInput variant="outlined" label="Mobile Number" source="pMobNo~like" />
    <TextInput variant="outlined" label="Claim no" source="claimno~like" />
    <ReferenceInput perPage={25} sort={{ field: 'orderNo', order: 'ASC' }}  filterToQuery={searchText => ({ 'orderNo~like': searchText })}
          label="Order Number" source="orderId" reference="orders" fullWidth={true}  allowEmpty alwaysOn>
          <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}  filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true}  allowEmpty>
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
  </Filter>
);

export const ClaimOrderList = (props) => {
  const myclasses = myStyles({});
  const [isPrint, setIsPrint] = useState(false);
  const [isPrint2, setIsPrint2] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const propsObj = Authorizer(props);
  const closeEdit = () => {
    setIsPrint(false)
    setIsPrint2(false)
  }


  const PrintButton = (props) => (

    <Button onClick={props.click}  > Print  </Button>
  )
  const PrintButton2 = (props) => (

    <Button onClick={props.click}  > Print Without Header </Button>
  )

  const onCancel = () => {
    setIsPrint(false)
    setIsPrint2(false)
  }
  function showPopup(id) {

    setSelectedId(id)
    setIsPrint(true);
  }
  function showPopup2(id) {

    setSelectedId(id)
    setIsPrint2(true);
  }
  const DisplayFileLink = (props) => {
    let { record } = props;

    return (
      <PrintButton click={(e) => showPopup(record.id)} />

    )
  }
  const DisplayFileLink2 = (props) => {
    let { record } = props;

    return (
      <PrintButton2 click={(e) => showPopup2(record.id)} />

    )
  }
  const handleClose = useCallback(() => {
    //setIsAddTpa(false);
  }, []);

  return (
    <React.Fragment>

      <List {...propsObj} exporter={false} bulkActionButtons={false} className="grid-list" classes={{ actions: "my-action", main: "main", content: "content", }}
        title="Claim Order List" filters={<Filters />} sort={{ field: 'id', order: 'DESC' }} >
        <Datagrid rowClick={false}>
          {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
          {/* <DisplayFileLink label="" source="id" />
          <DisplayFileLink2 label="" source="id" /> */}
          <ReferenceField label="Order No" source="orderId" reference="orders" link={false}>
            <TextField source="orderNo" />
          </ReferenceField>
          <TextField source="claimId" label="Claim ID" />
          <TextField source="claimno" label="TPA Claim No" />
          <TextField source="iclaimno" label="Insurer Claim No" />
          <DisplayDate source="initiateOn" label="Initiate On" locales="fr-FR" />
          <TextField source="initiateAt" label="Initiate At" />
          <ReferenceField source="insurerId" reference="insurers" link={false}>
            <TextField source="name" />
          </ReferenceField>
          {/* <TextField source="policyNo" label="Policy No" />
          <DisplayDate source="policyStartDate" label="Policy Start Date" locales="fr-FR" />
          <DisplayDate source="policyEndDate" label="Policy End Date" locales="fr-FR" /> */}
          <TextField source="cName" label="Customer Name" />
          {/* <TextField source="pMobNo" label="Mobile Number" /> */}
          {/* <TextField source="pEmail" label="Email address" /> */}

          {/* <TextField source="pProposer" label="Proposer Name" />
          <TextField source="pProposerContNo" label="Proposer Contact Number" /> */}
          <ReferenceField label="Corporate" source="corporateId" reference="corporates" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField link={false} label="Status" source="statusId" reference="lookups" fullWidth={true} >
            <TextField source="name" />
          </ReferenceField>

          {/*         
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} className="grid-list" classes={{ actions: "my-action", main: "main", content: "content", }}
      title="Claim Order List" filters={<Filters />} sort={{ field: 'id', order: 'DESC' }} >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' : false}>
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
        <TextField source="vid" label="VID" />
        <TextField source="claimno" label="Claim No" />
        <DisplayDate source="initiateOn" label="Initiate On"  locales="fr-FR" />
        <TextField source="initiateAt" label="Initiate At" />
        <ReferenceField source="insurerId" reference="insurers" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="policyNo" label="Policy No" />
        <DisplayDate source="policyStartDate" label="Policy Start Date"  locales="fr-FR" />
        <DisplayDate source="policyEndDate" label="Policy End Date"  locales="fr-FR" />
        <TextField source="pName" label="Name" />
        <TextField source="pMobNo" label="Mobile Number" />
        <TextField source="pEmail" label="Email address" />

        <TextField source="pProposer" label="Proposer Name" />
        <TextField source="pProposerContNo" label="Proposer Contact Number" />
        <ReferenceField label="Corporate" source="corporateId" reference="corporates" link={false}>
          <TextField source="name" />
        </ReferenceField>
{/*         
        <DisplayDate source="date" label="Entry Date"  locales="fr-FR" />
        <TextField source="hBillNo" label="Hospital Bill no." />
        <NumberField source="hBillAmt" label="Hospital bill Amount" />
        <DisplayDate source="doa" label="DOA"  locales="fr-FR" />
        <DisplayDate source="dod" label="DOD"  locales="fr-FR" />
        <DisplayDate source="frecDate" label="Received At HDC"  locales="fr-FR" />
        <DisplayDate source="fSubDate" label="File Submission Date"  locales="fr-FR" />
        <ReferenceField label="Hospital" source="hospitalId" reference="hospitals" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="TPA" source="tpaId" reference="tpas" link={false}>
          <TextField source="name" />
        </ReferenceField>
       
        <TextField source="cardNo" label="Card No" />
       

        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date" locales="fr-FR" /> */}
          {/*         
        <TextField source="cityCode" label="City Code" />
        <TextField source="cityName" label="City Name" />
        <ReferenceField source="stateId" reference="states" link={false} label="State" >
                        <TextField source="stateName" />
                    </ReferenceField>
      
      <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="insertedAt" label="Created Date"/>
            <ReferenceField source="updatedBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="updatedAt" label="Updated Date"/> */}
        </Datagrid>
      </List>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isPrint || isPrint2}>
        {isPrint && <Print
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={selectedId}
          isOpen={isPrint}
          {...props} />}
        {isPrint2 && <Print2
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={selectedId}
          isOpen={isPrint2}
          {...props} />}

      </Drawer>
    </React.Fragment>
  );
};
