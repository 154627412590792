import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import { MyDataProvider } from "./providers/MyDataProvider";
import VSDataProvider from "./providers/VSDataProvider";

import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { MainHome } from "./layout/Home";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import roletypes from './screens/roletypes';

import areas from './screens/areas';
import users from './screens/users';
import employee from './screens/employee';
import tpa from './screens/tpa';
import insurer from './screens/insurer';
import corporate from './screens/corporate';

import order from './screens/order';
import lifeorder from './screens/life-order';
import refcustomer from "./screens/ref-customer";


import leavetype from './screens/leavetype';
import holidaylist from './screens/holidaylist';
import employeeleavelist from './screens/employeeleavelist'; 
import employeeleaveapprove from './screens/employeeleaveapprove'; 
import employeesalary from './screens/employeesalary'; 
import dsr from './screens/dsr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configs from './screens/configs';
import bankmaster from "./screens/bankmaster";
import biztype from "./screens/biztype";
import customergroup from "./screens/customergroup";
import motorfuel from "./screens/motorfuel";
import motormodel from "./screens/motormodel";
import motorvariant from "./screens/motorvariant";
import policycategory from "./screens/policycategory";
import policydepartment from "./screens/policydepartment";
import lifepolicydepartment from "./screens/lifepolicydepartment";
import policytype from "./screens/policytype";
import lifepolicytype from "./screens/lifepolicytype";
import department from "./screens/department";
import ratemaster from "./screens/ratemaster";
import motormake from "./screens/motormake";
import motorclass from "./screens/motorclass";
import motor from "./screens/motor";
import industryclass from "./screens/industryclass";
import industrytype from "./screens/industrytype";
import entrytype from "./screens/entrytype";
import customer from "./screens/customer";
import policymaster from "./screens/policymaster";
import documenttype from "./screens/documenttype";
import taxhead from "./screens/taxhead";
import smslogs from "./screens/smslogs";
import smsloghds from "./screens/smsloghds";
import endorsementtype from "./screens/endorsementtype";
import invoice from "./screens/Policyinvoice";
import claimorder from "./screens/claim-order";
import leadorder from "./screens/leadorder";
import convorder from "./screens/convorder";
import city from "./screens/city";
import state from "./screens/state";
import employeeattendancereqlist from "./screens/employeeattendancereqlist";
import employeeattendancereqapprove from "./screens/employeeattendancereqapprove";




const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);

  }, []);

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource title="Area" name="areas" {...areas} />
        <Resource title="User" name="users" {...users} />
        <Resource title="Employee" name="employees" {...employee} />
        <Resource title="TPA" name="tpas" {...tpa} />
        <Resource title="Insurer" name="insurers" {...insurer} />
        <Resource title="Corporate" name="corporates" {...corporate} />
        
        <Resource title="order" name="orders"   {...order} />
        
        <Resource name="life-orders" title="life-order"    {...lifeorder} />
        <Resource name="life-order-premiums" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="life-order-payments" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="life-order-endorsements" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="life-order-documents" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="life-order-brokrages" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="life-order-beneficieries" list={ListGuesser} edit={EditGuesser}  />

        <Resource name="lookups" list={ListGuesser} edit={EditGuesser} />
        
        <Resource title="Role Type" name="role-types" {...roletypes} />
         
        <Resource name="hospital-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configdets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configs" {...configs} />
        <Resource name="dsrs" {...dsr} />
        
        <Resource name="leave-types" {...leavetype}/>
        <Resource name="holiday-lists" {...holidaylist}/>
        <Resource name="employee-leaves" {...employeeleavelist}/>
        <Resource name="employee-leaves-approve"  {...employeeleaveapprove}/>

        <Resource name="employee-attendance-regularizes" {...employeeattendancereqlist}/>
        <Resource name="employee-attendance-regularizes-approve" {...employeeattendancereqapprove}/>

        <Resource name="employee-sal"  {...employeesalary}/>
        <Resource name="employee-sal-dets"  list={ListGuesser} edit={EditGuesser}  />
        <Resource name="bank-masters" {...bankmaster}/>
        <Resource name="biz-types" {...biztype}/> 
        
        <Resource name="cust-groups" {...customergroup} />

        <Resource name="employee-documents" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="customer-contacts" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="customer-nominees" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="motor-fuels" {...motorfuel}  />
        <Resource name="motor-models" {...motormodel} />
        <Resource name="motor-makes" {...motormake} />
        <Resource name="motor-classes" {...motorclass} />
        <Resource name="motor-variants" {...motorvariant} /> 
        <Resource name="motors" {...motor} />
        <Resource name="policy-categories" {...policycategory} />
        <Resource name="policy-departments" {...policydepartment}  />
        <Resource name="industry-classes" {...industryclass}  />
        <Resource name="industry-types" {...industrytype}  />
        <Resource name="entry-types" {...entrytype}  />
        <Resource name="policy-types" {...policytype}  />
        <Resource name="rate-masters" {...ratemaster} />
        <Resource name="customers" {...customer} />
        <Resource name="countries" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="states" {...state} />
        <Resource name="districts" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="cities" {...city}  />
        <Resource name="insurer-branches" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="order-premiums" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="order-payments" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="email-logs" list={ListGuesser} edit={EditGuesser}  />
        
        <Resource name="order-documents" list={ListGuesser} edit={EditGuesser}  /> 
        <Resource name="policy-masters" {...policymaster} />
        <Resource name="document-types" {...documenttype} />
        <Resource name="tax-heads" {...taxhead} />
        <Resource name="enquiries" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="sms-logs" {...smslogs}  />
        <Resource name="sms-log-hds" {...smsloghds}  />
        <Resource name="endorsement-types" {...endorsementtype}  />
        <Resource name="order-endorsements" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="policy-inv-dets" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="rate-insurers" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="rate-policy-types" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="ref-customers" {...refcustomer}  />
        
        <Resource name="departments" {...department}  />

        <Resource name="policy-invs" {...invoice} />

        <Resource name="claim-ords" {...claimorder} />
        <Resource name="claim-ord-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-post-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-asks" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-invs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="claim-ord-allots" list={ListGuesser} edit={EditGuesser} />
        <Resource name="lead-order-documents" list={ListGuesser} edit={EditGuesser} />
        <Resource name="lead-order-events" list={ListGuesser} edit={EditGuesser} />
        <Resource name="order-ref-payments" list={ListGuesser} edit={EditGuesser} />
        
        <Resource name="lead-orders" {...leadorder}/>

        <Resource name="life-policy-types" {...lifepolicytype}  />
        <Resource name="life-policy-departments" {...lifepolicydepartment}  />
        <Resource name="conv-orders" {...convorder}  />
        <Resource name="conv-order-documents" list={ListGuesser} edit={EditGuesser} />
        <Resource name="policy-inv-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="customer-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="ref-customer-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="insurer-docs" list={ListGuesser} edit={EditGuesser} />
        
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;
