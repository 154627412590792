import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const PolicyTypeEdit = (props) => {
  
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  
  return (
    <Edit {...propsObj}  title="Policy Type Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/>
       <ReferenceInput label="Policy Category " fullWidth={true} source="policyCategoryId" reference="policy-categories" >
            <SelectInput optionText="name"  />
        </ReferenceInput>

       <ReferenceInput label="Policy Department " fullWidth={true} source="policyDepartmentId" reference="policy-departments" >
            <SelectInput optionText="name"  />
        </ReferenceInput>

        
        <BooleanInput source="isNonTarrif" validate={[required()]}  label="Is Non Tarrif" />
        <BooleanInput source="isNonPOS" validate={[required()]}  label="Is Non Pos" />
        <BooleanInput source="isTPA" validate={[required()]}  label="Is TPA" />
        <BooleanInput source="isRenewalMail" validate={[required()]}  label="is Renewal Mail" />
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>
      </SimpleForm>
    </Edit>
  );
};
