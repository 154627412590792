import React, { useState, useCallback, useEffect } from 'react';
import { PDFViewer, Document, Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../../common/apiClient";
import GrandTotal from './GrandTotal'
import AmountInWords from './AmountInWords'
import BankDetail from './BankDetail'
import BankAccountDetail from './BankAccountDetail'
import Signature from './Signature'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import logo from '../../../images/hd-logo.png'
import { grey } from '@material-ui/core/colors';


const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingBottom: 50,
    paddingHorizontal: 5,
    paddingRight: 15,
    paddingLeft: 30,

  },
  headercontainer: {
    height: 100,
    flexDirection: "row",
  },
  headercontainer1: {
    height: 50,
    flexDirection: "row",
  },
  logocontainer: {
    height: 100,
    width: "50%",

  },
  logocontainer1: {
    height: 0,
    width: "0%",

  },
  companyaddress: {
    height: 100,
    width: "50%",
    textAlign: 'left',
    fontSize: 10,
    marginLeft: 10,

  },
  companyaddress1: {
    height: 50,
    width: "100%",
    textAlign: 'center',
    fontSize: 10,
    marginLeft: 10,

  },
  invoiceHeader: {
    fontSize: 14,
    height: 20,
    textAlign: 'center',
    fontWeight: "bold",
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    flexDirection: "column",

  },
  section1: {
    fontSize: 14,
    height: 25,
    textAlign: 'center',
    fontWeight: "bold",
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    flexDirection: "row",

  },
  section1Label1:
  {
    width: "50%",
    borderRight: 1,
    paddingTop: 5
  },
  section1Label2:
  {
    width: "25%",
    borderRight: 1,
    paddingTop: 5
  },
  section1Label3:
  {
    width: "25%",
    paddingTop: 5

  },

  section2: {
    fontSize: 14,
    height: 30,
    textAlign: 'center',
    fontWeight: "bold",
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    flexDirection: "row",


  },
  section2Label1: {
    fontSize: 14,
    width: "50%",
    borderRight: 1,
    paddingTop: 5
  },
  section2Label2: {
    fontSize: 14,
    width: "50%",
    paddingTop: 5,
    marginLeft: 5,
    textAlign: "left"
  },
  section3: {
    fontSize: 13,
    height: 30,
    textAlign: 'center',
    fontWeight: "bold",
    borderBottom: 1,
    borderTop: 0,
    borderLeft: 1,
    borderRight: 1,
    flexDirection: "row",


  },
  section3Blank: {
    fontSize: 14,
    height: "20",
  },

  sectionBlankWithBorder: {
    fontSize: 14,
    height: "20",
    borderBottom: 1,
    borderLeft: 1,
    borderRight: 1,
    borderTop: 0,
  },
  section3Label1: {
    fontSize: 12,
    width: "25%",
    borderRight: 1,
    paddingTop: 5
  },
  section3Label2: {
    fontSize: 12,
    width: "25%",
    borderRight: 1,
    paddingTop: 5
  },
  section3Label3: {
    fontSize: 12,
    width: "25%",
    borderRight: 1,
    paddingTop: 5
  },
  section3Label4: {
    fontSize: 12,
    width: "25%",

    paddingTop: 5
  },


  section4: {
    fontSize: 14,
    height: 25,
    textAlign: 'center',
    fontWeight: "bold",
    borderBottom: 1,
    borderTop: 0,
    borderLeft: 1,
    borderRight: 1,
    flexDirection: "row",


  },
  section4Label1: {
    fontSize: 11,
    width: "25%",
    borderRight: 1,
    paddingTop: 5,
    textAlign: "left",

  },
  section4Label1full: {
    fontSize: 11,
    width: "75%",
    borderRight: 0,
    paddingTop: 5,
    textAlign: "left",
    marginLeft: 5

  },
  section4Label2: {
    fontSize: 11,
    width: "25%",
    borderRight: 1,
    paddingTop: 5,
    textAlign: "right",


  },
  section4Label2left: {
    fontSize: 11,
    width: "25%",
    borderRight: 1,
    paddingTop: 5,
    textAlign: "left",
    paddingLeft: 5,
    display: "flex",
    flexdirection: "row",
    flexWrap: "wrap"



  },
  section4Label3: {
    fontSize: 11,
    width: "25%",
    borderRight: 1,
    paddingTop: 5,
    textAlign: "left",

  },
  section4Label4: {
    fontSize: 11,
    width: "25%",
    paddingTop: 5,
    textAlign: "right",
  },
  section4Label4left: {
    fontSize: 11,
    width: "25%",
    paddingTop: 5,
    textAlign: "left",
    paddingLeft: 5
  },

  section5: {
    fontSize: 14,
    height: 25,
    textAlign: 'center',
    fontWeight: "bold",
    borderBottom: 1,
    borderTop: 0,
    borderLeft: 1,
    borderRight: 1,
    flexDirection: "row",


  },
  section5Label1: {
    fontSize: 11,
    width: "25%",
    borderRight: 0,
    paddingTop: 5,
    textAlign: "left",

  },
  section5Label2: {
    fontSize: 11,
    width: "25%",
    borderRight: 1,
    paddingTop: 5,
    textAlign: "right",


  },
  section5Label3: {
    fontSize: 11,
    width: "25%",
    borderRight: 1,
    paddingTop: 5,
    textAlign: "left",

  },
  section5Label4: {
    fontSize: 11,
    width: "25%",
    paddingTop: 5,
    textAlign: "right",

  },
  billingMonth:
  {
    height: "30%",
    width: "100%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 10

  },
  invoiceDetail:
  {
    flexDirection: "column",
    height: "100",
    borderLeft: 1,
    width: "30%",
    fontSize: 11
  },
  invoiceDetailbox1:
  {
    height: "20%",
    borderBottom: 1,
    textAlign: "center",
  },
  invoiceDetailbox2:
  {
    height: "20%",
    textAlign: "center",
  },
  invoiceDetailDate:
  {
    height: "30%",
    textAlign: "center",
  },

  tableHeaderStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    height: "5%",
    width: "100%",
    fontSize: 11,
    borderBottom: 1,
    borderLeft: 1

  },
  tableHeaderStylebox1:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableHeaderStylebox2:
  {
    paddingTop: 3,
    textAlign: "center",
    height: "10%",
    width: "70%",

  },

  tableHeaderStylebox3:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "20%",
    borderLeft: 1,
    borderRight: 1
  },
  tableRow:
  {

    flexDirection: "column",
    width: "100%",
    borderBottom: 1,

  },
  tableRowStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: "3%"

  },
  tableRowStylebox1:
  {
    paddingRight: 5,
    paddingTop: 5,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableRowStylebox2:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "left",
    width: "70%",

  },
  tableRowStylebox3:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    paddingRight: 3,
    paddingTop: 5
  },
  tableRowStylebox2Total:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox3TotalAmount:
  {
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    paddingRight: 3,
    paddingTop: 5,
    fontSize: 13

  },
  tableRowStylebox2Tax:
  {
    flexDirection: "row",
    paddingRight: 0,
    paddingTop: 0,
    paddingLeft: 0,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2TaxEmptyBox:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2Taxdetail:
  {
    paddingRight: 3,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 11,
    borderLeft: 1,
    borderRight: 0,
    borderTop: 1,
    borderBottom: 1,


  },
  tableRowStylebox3Taxamount:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    paddingRight: 3,
    paddingTop: 50
  },
  tableRowStylewith5height:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: 5

  },
  tableHeaderStylebox2GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    height: "10%",
    width: "70%",
    fontSize: 13

  },
  tableHeaderStylebox3GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    fontSize: 13
  },
  boxAmountinWords:
  {
    flexDirection: "row",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    height: 30,
    fontSize: 11
  },
  bankDetailBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 30,
    fontSize: 11
  },
  bankAccountDetailBox:
  {
    flexDirection: "row",

    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 15,
    fontSize: 11
  },
  bankAccountDetailBox1:
  {

    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "35%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox2:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox3:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "25%",
    borderRight: 1,
    borderTop: 0,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox4:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  signatureBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 100,
    fontSize: 11
  },

});


const InvoicePrint = props => {

  const [isLoading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState({ partyName: "RedPlus Society,", address: "Baghanjatin Station Road Kolkata-700032 West Bengal", invoiceNo: "HDFC/20-21/JAN/0392R", invoiceDate: "11.11.2021", billMonth: "Month Of December 2020", taxableAmount: "1000.00" });
  const [detailData, setDetailData] = useState([{ particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims .", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }]);
  const [footerDetail, setFooterDetail] = useState("");
  const [blankRow, setBlankRow] = useState([]);
  const [footerDetail1, setFooterDetail1] = useState("");
  useEffect(() => {
    if (!props.invoiceId || props.invoiceId == "") return;
    LoadData(props.invoiceId);
  }, [props.invoiceId]);
  const LoadData = async (id) => {

    await apiClient.getpayslipPrint(id).then(res => {
      //debugger
      setHeaderData(res[0]);

      setLoading(false);
    }).catch(err => {
      setLoading(false);
      console.log(err);
    });

  }


  return (
    <React.Fragment>
      {props.onClose && <div>
        <div style={ { float: "right" } } onClick={ props.onClose }><CancelSharpIcon /></div>
      </div>}
      { isLoading === false ?
        <PDFViewer width="100%" height="750" >
          <Document title="Salary Slip" >
            <Page size="A4" style={ styles.page }>
              <View>

                <View style={ headerData.ReportFooter && headerData.ReportFooter.length > 0 ? styles.headercontainer : styles.headercontainer1 }>
                  <View style={ headerData.ReportFooter ? styles.logocontainer : styles.logocontainer1 }>
                    <Image src={ headerData.ReportFooter ? logo : "" } />
                  </View>
                  { headerData.ReportFooter ?
                    <View style={ headerData.ReportFooter ? styles.companyaddress : styles.companyaddress1 }>
                      <Text>{ headerData.addresses1 }</Text>
                      <Text>{ headerData.addresses2 }</Text>
                      <Text>{ headerData.addresses3 }</Text>
                      <Text>{ "Phone:" + headerData.Phone }</Text>
                      <Text>{ "CIN:" + headerData.CIN }</Text>
                      <Text style={ { marginTop: 10 } }>{ "GSTNo:" + headerData.GSTNo }</Text>
                      <Text>{ "PANNO:" + headerData.PANNO }</Text>

                    </View>
                    :
                    <View style={ headerData.ReportFooter && headerData.ReportFooter.length > 0 ? styles.companyaddress : styles.companyaddress1 }>
                      <Text>{ headerData.addresses1 }</Text>
                      <Text>{ headerData.addresses2 }</Text>
                      <Text>{ headerData.addresses3 }</Text>
                      <Text>{ "Phone:" + headerData.Phone }</Text>
                    </View>
                  }
                  {/* <Image src={logo} /> */ }
                </View>

                <View style={ styles.invoiceHeader } >
                  <Text>{ headerData.ReportName }</Text>
                </View>
                <View style={ styles.section1 } >
                  <View style={ styles.section1Label1 } >
                    {/* <Text>Salary</Text> */ }
                  </View>
                  <View style={ styles.section1Label2 } >
                    {/* <Text>Month</Text> */ }
                  </View>
                  <View style={ styles.section1Label3 } >
                    {/* <Text>Jul-29</Text> */ }
                  </View>
                </View>



                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "Employee Name " } </Text>
                  </View>
                  <View style={ styles.section4Label2left } >
                    <View style={ { flexDirection: 'row' } }>
                      <Text style={ { marginleft: 5, flex: 1, flexWrap: 'wrap', fontSize: 10 } }>{ headerData.EmployeeName.toString().trim() }</Text>
                    </View>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>Date of Joining </Text>
                  </View>
                  <View style={ styles.section4Label4left } >
                    <Text style={ { marginleft: 5 } }>{ "  " }{ headerData.DOJ }</Text>
                  </View>
                </View>



                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "Employee Code " } </Text>
                  </View>
                  <View style={ styles.section4Label2left } >
                    <Text style={ { marginleft: 15 } }>{ headerData.Code }</Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>Total Days </Text>
                  </View>
                  <View style={ styles.section4Label4left } >
                    <Text style={ { marginleft: 5 } }>{ "  " }{ headerData.MonthDays }</Text>
                  </View>
                </View>

                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "Designation" } </Text>
                  </View>
                  <View style={ styles.section4Label1full }  >
                    <Text style={ { marginleft: 5 } }>{ "  " }{ headerData.Designation }</Text>
                  </View>

                </View>

                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "UAN" } </Text>
                  </View>
                  <View style={ styles.section4Label2left } >
                    <Text style={ { marginleft: 1, fontSize: 10 } }>{ headerData.Uan }</Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>Salary Calculated Days </Text>
                  </View>
                  <View style={ styles.section4Label4left } >
                    <Text style={ { marginleft: 5 } }>{ headerData.WorkingDays }</Text>
                  </View>
                </View>

                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "PAN" } </Text>
                  </View>
                  <View style={ styles.section4Label2left } >
                    <Text style={ { marginleft: 5 } }>{ headerData.PAN }</Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>LOP(Leave -2) </Text>
                  </View>
                  <View style={ styles.section4Label4left } >
                    <Text style={ { marginleft: 5 } }>{ headerData.MonthDays - headerData.WorkingDays }</Text>
                  </View>
                </View>

                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "Bank Account Number" } </Text>
                  </View>
                  <View style={ styles.section4Label2left } >
                    <Text style={ { marginleft: 5 } }>{ headerData.BankAcNo }</Text>
                  </View>

                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>Bank Name </Text>
                  </View>
                  <View style={ styles.section4Label4left } >
                    <Text style={ { marginleft: 5 } }>{ headerData.BankName }</Text>
                  </View>

                </View>
                {/* <View style={styles.section4} >
                  <View style={styles.section4Label1} >
                    <Text style={{marginLeft:10}} >{"UAN"} </Text>
                  </View>
                  <View style={styles.section4Label2left} >
                    <Text style={{marginleft:5,flexShrink:1}}>{headerData.Uan}</Text>
                    
                  </View> 
                  
                  <View style={styles.section4Label3} >
                    <Text style={{marginLeft:10}}> </Text>
                  </View>
                  <View style={styles.section4Label4left} >
                    <Text style={{marginleft:5}}>{""}</Text>
                  </View>
                  
                </View> */}

                <View style={ styles.section3Blank } >
                  <Text>{ "" }</Text>
                </View>
                <View style={ styles.section2 } >
                  <View style={ styles.section2Label1 } >
                    <Text>Income</Text>
                  </View>
                  <View style={ styles.section2Label2 } >
                    <Text>Deductions</Text>
                  </View>
                </View>


                <View style={ styles.section3 } >
                  <View style={ styles.section3Label1 } >
                    <Text>Particulars</Text>
                  </View>
                  <View style={ styles.section3Label2 } >
                    <Text>Amount</Text>
                  </View>
                  <View style={ styles.section3Label3 } >
                    <Text>Particulars</Text>
                  </View>
                  <View style={ styles.section3Label4 } >
                    <Text>Amount</Text>
                  </View>
                </View>


                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >Basic and DA </Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.BasicAmt).toFixed(2) } </Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>PF { headerData.IsAbry ? "(ABRY)" : "" }</Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.PFAmt).toFixed(2) }</Text>
                  </View>
                </View>



                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >HRA </Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.HraAmt).toFixed(2) } </Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>Professional Tax </Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.ProfTaxAmt).toFixed(2) }</Text>
                  </View>
                </View>



                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >Conveyance Allowance</Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.ConvAmt).toFixed(2) } </Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>E.S.I </Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.ESIAmt).toFixed(2) } </Text>
                  </View>
                </View>


                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "Child Allowance " } </Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.ChildAllowance).toFixed(2)  } </Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>TDS </Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.TdsAmt).toFixed(2) }</Text>
                  </View>
                </View>

                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } > Medical Allowance  </Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{headerData. MedicalAllowance?parseFloat(headerData. MedicalAllowance).toFixed(2):"0.00" }</Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>Advance Amount </Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.AdvAmt).toFixed(2) }</Text>
                  </View>
                </View>
                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } > Special Allowance  </Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{headerData.SpecialAllowance?parseFloat(headerData.SpecialAllowance).toFixed(2):"0.00" }</Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>{""}</Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ ""}</Text>
                  </View>
                </View>
                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } > LTA   </Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{headerData.LTAAmt?parseFloat(headerData.LTAAmt).toFixed(2):"0.00" }</Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }></Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ }</Text>
                  </View>
                </View>


                <View style={ styles.section4 } >
                  <View style={ styles.section4Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ "Total " } </Text>
                  </View>
                  <View style={ styles.section4Label2 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.TotalGrossSalAmt).toFixed(2) }</Text>
                  </View>
                  <View style={ styles.section4Label3 } >
                    <Text style={ { marginLeft: 10 } }>Total </Text>
                  </View>
                  <View style={ styles.section4Label4 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.TotalDeductAmt).toFixed(2) }</Text>
                  </View>
                </View>

                <View style={ styles.section5 } >
                  <View style={ styles.section5Label1 } >
                    <Text style={ { marginLeft: 10 } } >{ " " } </Text>
                  </View>
                  <View style={ styles.section5Label2 } >
                    <Text style={ { marginRight: 10 } }>{ " " }</Text>
                  </View>
                  <View style={ styles.section5Label3 } >
                    <Text style={ { marginLeft: 10 } }>Net Salary </Text>
                  </View>
                  <View style={ styles.section5Label4 } >
                    <Text style={ { marginRight: 10 } }>{ parseFloat(headerData.NetSalary).toFixed(2) }</Text>
                  </View>
                </View>
                <View style={ styles.sectionBlankWithBorder } >
                  <Text>{ "" }</Text>
                </View>

                <AmountInWords styles={ styles } amountInWord={ headerData.amountinWord } />
                {/* <Signature styles={styles} data={headerData} /> */ }
                <View style={ { fontSize: 10, textAlign: "center" } }>
                  <Text style={ { fontSize: 10 } }>**This is system generated payslip and do not require signature</Text>
                </View>

              </View>
            </Page>
          </Document>
        </PDFViewer> : null }
    </React.Fragment>
  );
}

export default InvoicePrint;