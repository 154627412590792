import React, { useCallback, useState } from "react";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required, ReferenceField,
  BooleanInput,
  useRefresh,
  DateField,
  ReferenceManyField,
  TextField,
  FormTab,
  Datagrid,
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import BranchCreate from './BranchCreate';
import BranchEdit from "./BranchEdit";
import VSDeleteButton from "../../VSDeleteButton";
import { Authorizer } from "../../common/Authorizer";
import CDeleteButton from "../../common/CDeleteButton";
import DocumentsCreate from "./DocumentsCreate";
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}

export const InsurerEdit = (props) => {
  const [isaddBranch, setIsAddBranch] = useState(false);
  const [isEditBranch, setIsEditBranch] = useState(false);
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(0);
  const [isaddDocument, setIsaddDocument] = useState(false);

  const refresh = useRefresh();
  const myclasses = myStyles({});
  const handleClose = useCallback(() => {
    setIsAddBranch(false);
    setIsEditBranch(false);
    setIsaddDocument(false);

  }, []);

  const closeEdit = () => {
    setIsAddBranch(false);
    setIsEditBranch(false);
    setIsaddDocument(false);
    refresh();
  }


  const EditButton = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<EditIcon />}>Edit</Button>

  }
  const onCancel = () => {
    setIsAddBranch(false);
    setIsEditBranch(false);
    setIsaddDocument(false);
  }
  const classes = useStyles({});
  let typeId = localStorage.getItem("insurerTypeId");

  if (typeId) {
    typeId = typeId;
  }
  else {
    typeId = '0';
  }
  const propsObj = Authorizer(props);
  return (
    <React.Fragment>
      <Edit title={typeId && parseInt(typeId) > 0 && parseInt(typeId) === 1 ? "Non Life Insurer Edit" : "Life Insurer Edit"} {...propsObj}>
        <TabbedForm variant="outlined" redirect={"/insurers?type=" + typeId} toolbar={<CreateToolbar {...propsObj} />}>
          <FormTab value="DETAILS" label="Detail">

            <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.one_three_input} />
            <DateInput source="openDate" label="Open Date" validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="phoffice" label="Phone(Off)." formClassName={classes.one_three_input} />
            <TextInput source="email" label="email" formClassName={classes.two_three_input} />
            <TextInput source="phmobile" label="Mobile" formClassName={classes.last_three_input} />

            <TextInput source="tin" label="Tin" formClassName={classes.one_three_input} />
            <TextInput source="cstNo" label="CST Number" formClassName={classes.two_three_input} />
            <TextInput source="tpt" label="tpt" formClassName={classes.last_three_input} />

            <TextInput source="licno" label="LIC No" formClassName={classes.one_three_input} />
            <TextInput source="panno" label="Pan Number" formClassName={classes.two_three_input} />
            <TextInput source="state" label="State" validate={[required()]} formClassName={classes.last_three_input} />

            <ReferenceInput label="Area" source="areaId" reference="areas" formClassName={classes.one_three_input}>
              <SelectInput optionText="name" validate={[required()]} />
            </ReferenceInput>
            <TextInput source="city" label="City" validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="add2" label="Address 2" formClassName={classes.one_three_input} />
            <TextInput source="add3" label="Address 3" formClassName={classes.two_three_input} />
            <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.last_three_input} />


            <TextInput source="cpName1" label="Contact Person 1" formClassName={classes.one_three_input} />
            <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.two_three_input} />
            <TextInput source="cpRes1" label="Contact Person 1 ResiID" formClassName={classes.last_three_input} />

            <TextInput source="cpName2" label="Contact Person 2" formClassName={classes.one_three_input} />
            <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.two_three_input} />
            <TextInput source="cpRes2" label="Contact Person 2 ResiID" formClassName={classes.last_three_input} />

            <TextInput source="cpName3" label="Contact Person 3" formClassName={classes.one_three_input} />
            <TextInput source="cpMob3" label="Contact Person 3 Mobile" formClassName={classes.two_three_input} />
            <TextInput source="cpRes3" label="Contact Person 3 ResiID" formClassName={classes.last_three_input} />

            <TextInput source="cpName4" label="Contact Person 4" formClassName={classes.one_three_input} />
            <TextInput source="cpMob4" label="Contact Person 4 Mobile" formClassName={classes.two_three_input} />
            <TextInput source="cpRes4" label="Contact Person 4 ResiID" formClassName={classes.last_three_input} />

            <TextInput source="cpName5" label="Contact Person 5" formClassName={classes.one_three_input} />
            <TextInput source="cpMob5" label="Contact Person 5 Mobile" formClassName={classes.two_three_input} />
            <TextInput source="cpRes5" label="Contact Person 5 ResiID" formClassName={classes.last_three_input} />

            <ReferenceInput label="Handle By" source="employeeId" reference="employees" formClassName={classes.one_three_input}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" validate={[required()]} formClassName={classes.one_three_input} />
            </ReferenceInput>

            <BooleanInput source="isCentral" label="Central GST" initialValue={true} formClassName={classes.two_three_input} />
            <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.last_three_input} />


          </FormTab>
          <FormTab value="Branch" label="Branches">
            <Button variant="contained" onClick={() => { setIsAddBranch(true); }}
              color="secondary">
              Add Branch
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="insurer-branches"
              target="insurerId">
              <Datagrid>
                <VSDeleteButton {...props} />
                <EditButton onClick={(record) => { setIsEditDocumentRecord(record); setIsEditBranch(true); }} />
                <TextField fullWidth={true} source="name" label="Name" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab label="Documents">
            <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsaddDocument(true); }}>Add  Document</Button>

            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="insurer-docs"
              target="insurerId">
              <Datagrid>
                {<CDeleteButton {...props} />}

                <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Document No" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>

      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isaddBranch || isEditBranch || isaddDocument} >
        {isaddBranch && <BranchCreate
          onClose={closeEdit}
          onCancel={onCancel}
          recId={props.id}
          {...props} />}
        {isEditBranch && <BranchEdit
          onClose={closeEdit}
          onCancel={onCancel}
          record={isEditDocumentRecord}
          {...props} />}
        {isaddDocument && <DocumentsCreate
          onClose={closeEdit}
          onCancel={onCancel}
          insurerId={props.id}
          {...props} />}

      </Drawer>
    </React.Fragment>
  );
};
