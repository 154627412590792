import React, { useState, useCallback, useEffect } from 'react';

import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput, useNotify, useRedirect,
  ReferenceInput,
  SelectInput, DateField, AutocompleteInput,
  BoolenField,
  NumberField,
  FormDataConsumer, ReferenceField, useRefresh, RichTextField,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";
import EditIcon from '@material-ui/icons/Edit';
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./components/StateDistrict";
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DocumentsCreate from "./popup/DocumentsCreate";
import PremiumCreate from "./popup/PremiumCreate";
import * as apiClient from "../../common/apiClient";
import PremiumEdit from "./popup/PremiumEdit";
import PremiumPaymentCreate from "./popup/PremiumPaymentCreate";
import CustomerCreate from './popup/CustomerCreate';
import CustomerGroupCreate from './popup/CustomerGroupCreate';
import InsurerCreate from './popup/InsurerCreate';
import InsurerBranchCreate from './popup/InsurerBranchCreate';
import PolicyTypeCreate from './popup/PolicyTypeCreate';
import PolicyDepartmentCreate from './popup/PolicyDepartmentCreate';
import EmployeeCreate from './popup/EmployeeCreate';
import AddIcon from '@material-ui/icons/Add';


import OrderEmail from './popup/OrderEmail';
import EndorsementCreate from './popup/EndorsementCreate';
import EndorsementEdit from './popup/EndorsementEdit';
import MotorClassCreate from "./popup/MotorClassCreate";
import MotorClassVarient from './popup/MotorClassVarient';
import MotorClassMake from './popup/MotorClassMake';
import MotorClassFuel from './popup/MotorClassFuel';
import MotorClassModel from './popup/MotorClassModel';
import MotorClassDetails from './popup/MotorClassDetails';
import RefPaymentCreate from './popup/RefPaymentCreate';
import RefPaymentEdit from './popup/RefPaymentEdit';
import RefCustomerCreate from './popup/RefCustomerCreate';
import { RecordVoiceOver } from '@material-ui/icons';


const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 750,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1100,
  },
}));

const OuterComponent = (props) => {

  return (
    props.children
  )
}

const DisplayMotorDetails = (props) => {
  const { record, motorDetail } = props;

  useEffect(() => {
    if (record.motorId && record.motorId != "") {
      props.getMotorDetails(record.motorId);
    }
  }, [record.motorId]);
  if (motorDetail)
    return (
      <div className='motor-details'>
        <div className='motor-detail'><strong>Make : </strong>{motorDetail.make}</div>
        <div className='motor-detail'><strong>model : </strong>{motorDetail.model}</div>
        <div className='motor-detail'><strong>variant : </strong>{motorDetail.variant}</div>
        <div className='motor-detail'><strong>fuel : </strong>{motorDetail.fuel}</div>
        <div className='motor-detail'><strong>CC/GVW : </strong>{motorDetail.ccGvw}</div>
        <div className='motor-detail'><strong>seatingCapacity : </strong>{motorDetail.seatingCapacity}</div>
        <div className='motor-detail'><strong>RTO Location : </strong>{motorDetail.rtoLoc}</div>
        <div className='motor-detail'><strong>Year Of Manufacturing : </strong>{motorDetail.yearOfMfg}</div>
        <div className='motor-detail'><strong>NCB Percentage : </strong>{motorDetail.ncbPercentage}</div>
        <div className='motor-detail'><strong>Registration Number : </strong>{motorDetail.regnNo}</div>
        <div className='motor-detail'><strong>Engine Number : </strong>{motorDetail.engineNo}</div>
        <div className='motor-detail'><strong>Chasis Number : </strong>{motorDetail.chasisNo}</div>

      </div>
    )
  else
    return null;
}

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}


const EmailStatus = (props) => {
  let { record } = props;


  return (
    <div>
      {
        record.isPending ? "Waiting for confirmation" : 'Sent'
      }

    </div>
  )

}

const EmailBody = (props) => {
  let { record } = props;

  if (record.body && record.body.length > 0)
    return (
      <div style={{ maxHeight: "150px", overflow: "auto" }}>
        <div
          dangerouslySetInnerHTML={{ __html: record.body }}
        />
      </div>
    )
  else {
    return null
  }
}
const UploadDocument = (props) => {
  let { record } = props;
  return <Button
    color="secondary" disableElevation
    size="medium"

    onClick={() => {
      props.onClick(record);
    }}
    startIcon={<EditIcon />}>Edit</Button>
}

const AddPayment = (props) => {
  let { record } = props;
  return <Button
    color="secondary" disableElevation
    size="medium"
    onClick={() => {
      props.onClick(record);
    }}
    startIcon={<AddIcon />}> Generate Payment</Button>
}


const ViewButton = (props) => {
  let { record } = props;
  return <Button
    color="secondary" disableElevation
    size="medium"
    onClick={() => {
      props.onClick(record);
    }}
  > View Detail</Button>
}




export const OrderEdit = (props) => {

  const propsObj = Authorizer(props);
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isaddPremiums, setIsAddPremiums] = useState(false);
  const [isEditPremium, setIsEditPremium] = useState(false);

  const [isAddEmail, setIsAddEmail] = useState(false);
  const [isEditPayment, setIsEditPayment] = useState(false);
  const [isEditEndorsement, setIsEditEndorsement] = useState(false);

  const [IsEditRecord, setIsEditRecord] = useState(null);

  const [isEditPremiums, setIsEditPremiums] = useState(0);
  const [isaddOtherDocuments, setIsAddOtherDocuments] = useState(false);
  const [isaddRefPayment, setIsAddRefPayment] = useState(false);
  const [isEditRefPayment, setIsEditRefPayment] = useState(false);
  const [isaddEndorsement, setIsAddEndorsement] = useState(false);

  const [selectedDocumentsId, setSelectedDocumentsId] = useState<any[]>([]);


  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [isAddCustomerGroup, setIsAddCustomerGroup] = useState(false);
  const [isAddInsurer, setIsAddInsurer] = useState(false);
  const [isAddInsurerBranch, setIsAddInsurerBranch] = useState(false);
  const [isAddPolicyDepartment, setIsAddPolicyDepartment] = useState(false);
  const [isAddPolicyType, setIsAddPolicyType] = useState(false);
  const [isAddMotorClass, setIsAddMotorClass] = useState(false);
  const [isAddMotorVarient, setIsAddMotorVarient] = useState(false);
  const [isAddMotorModel, setIsAddMotorModel] = useState(false);
  const [isAddMotorMake, setIsAddMotorMake] = useState(false);
  const [isAddMotorFuel, setIsAddMotorFuel] = useState(false);
  const [isAddMotorDetails, setIsAddMotorDetails] = useState(false);


  const [isAddEmployee, setIsAddEmployee] = useState(false);
  const [isAddRefCustomer, setIsAddRefCustomer] = useState(false);
  const [isAutoMotor, setIsAutoMotor] = useState(true);
  const [motorDetails, setMotorDetails] = useState(null);
  const [brokrage, setBrokrage] = useState([]);
  const [brokrageInsurer, setBrokrageInsurer] = useState([]);
  const [brokrageDetail, setBrokrageDetail] = useState([]);

  const getMotorDetails = (id: string) => {
    apiClient.getEntiyRecord("motors", id).then(res => {
      //console.log(res, "====MOTORS======");
      setMotorDetails(res);
    }).catch(err => {
      console.log(err, "ERR")
    });
  }

  const getBrokrage = (oid: any) => {
    apiClient.getOrderBrokrage(oid).then(res => {
      //console.log(res , "====getOrderBrokrage====");
      setBrokrage(res);
    }).catch(err => {
      console.log(err);
    });
  }
  const getbrokrageSummary = (oid: any) => {
    apiClient.getbrokrageSummary(oid).then(res => {
      //console.log(res , "====getOrderBrokrage====");
      setBrokrageInsurer(res);
    }).catch(err => {
      console.log(err);
    });
  }
  const getbrokrageInsurerDetail = (oid: any) => {
    apiClient.getbrokrageInsurerDetail(oid.id).then(res => {
      //console.log(res , "====getOrderBrokrage====");
      setBrokrageDetail(res);
    }).catch(err => {
      console.log(err);
    });
  }

  const getOrder = (oid: any) => {
    apiClient.getEntiyRecord("orders", oid).then(res => {
      if (res.policyDepartmentId != "") {
        checkPolicyDepartment(res.policyDepartmentId);
      }
    }).catch(err => {
      console.log(err);
    });
  }
  const OrderAddPayment = (record: any) => {
    apiClient.addPaymentSch(record.id).then(res => {
      notify(res[0].message);
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }

  const LifeOrderAddPayment = (record: any) => {
    apiClient.addLifeOrderPaymentSch(record.id).then(res => {
      notify(res[0].message);
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }


  const checkPolicyDepartment = (id: string) => {
    apiClient.getEntiyRecord("policy-departments", id).then(res => {
      if (res.isMotorDetails != undefined) {
        setIsAutoMotor(res.isMotorDetails);
      }
    }).catch(err => {
      console.log(err, "ERR")
    });
  }


  const [configValue, setConfigValue] = useState(0);

  const SelectDeselectDocument = (record) => {
    var ids: any[] = [...selectedDocumentsId];
    const id: any = record.id;
    const index = ids.indexOf(id);
    if (index > -1) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }
    setSelectedDocumentsId(ids);
  }
  const onDocumentDelete = async () => {
    var ids: any[] = [...selectedDocumentsId];
    if (ids.length == 0) {
      alert("Please select some documents.");
      return;
    }
    var cn = window.confirm("Are you sure you want to delete the selected documents?");
    if (!cn)
      return;
    ids.forEach(async item => {
      await apiClient.deleteRealizationDocument(item);
    });
    refresh();
  }

  const addFromSetup = (id) => {
    if (configValue == 0) {
      alert("Please select the config.");
      return;
    }

    apiClient.addFromSetup(id, configValue).then(res => {
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }
  const onCloseDocumentForm = () => {
    setIsAddPremiums(false);
    setIsEditPremium(false);
    setIsEditPayment(false);
    setIsEditEndorsement(false);
    setIsEditRefPayment(false);
    setIsAddEmail(false);
    setIsAddRefPayment(false);
    refresh();
    getBrokrage(props.id);
    getbrokrageSummary(props.id);
  }

  const onCloseOtherDocumentForm = () => {
    setIsAddOtherDocuments(false);
    setIsAddEndorsement(false);
    setIsAddRefPayment(false);
    refresh();
  }


  const onCancel = () => {
    setIsAddPremiums(false);
    setIsAddOtherDocuments(false);
    setIsAddEndorsement(false);
    setIsEditPremium(false);
    setIsEditPayment(false);
    setIsEditEndorsement(false);
    setIsEditRefPayment(false);
    setIsAddEmail(false);
    setIsAddMotorClass(false);
    setIsAddMotorVarient(false);
    setIsAddMotorMake(false);
    setIsAddMotorFuel(false);
    setIsAddMotorModel(false);
    setIsAddMotorDetails(false);
    setIsAddRefCustomer(false);
    setIsAddRefPayment(false);

  }
  const handleClose = useCallback(() => {
    setIsAddPremiums(false);
    setIsAddOtherDocuments(false);
    setIsAddEndorsement(false);
    setIsEditPremium(false);
    setIsEditPayment(false);
    setIsEditEndorsement(false);
    setIsEditRefPayment(false);
    setIsAddCustomer(false);
    setIsAddCustomerGroup(false);
    setIsAddInsurer(false);
    setIsAddInsurerBranch(false);
    setIsAddPolicyDepartment(false);
    setIsAddPolicyType(false);
    setIsAddEmployee(false);
    setIsAddRefCustomer(false);
    setIsAddEmail(false);
    setIsAddMotorClass(false);
    setIsAddMotorVarient(false);
    setIsAddMotorMake(false);
    setIsAddMotorFuel(false);
    setIsAddMotorModel(false);
    setIsAddMotorDetails(false);
    setIsAddRefPayment(false);

  }, []);

  const validateUserCreation = (values) => {
    const errors: any = {};
    //console.log(values);
    if (values.uDisAllAmt > 0 && values.rpriotiyId == 14) {
      errors.rpriotiyId = ["Please Change the Recl. Priority"];
    }
    return errors
  };

  useEffect(() => {
    getOrder(props.id);
    getBrokrage(props.id);
    getbrokrageSummary(props.id);
  }, []);

  let isEdit = propsObj.Edit;

  return (
    <React.Fragment>
      <Edit title="Order Edit"

        {...propsObj} undoable={false}>
        <TabbedForm
          validate={validateUserCreation}
          redirect={false} variant="outlined" toolbar={<FormToolbar {...propsObj} showDelete={false} />}>
          {propsObj.nlfBasDetV && <FormTab label="Basic Details">

            <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
              Basic Detail
            </div>
            <ReferenceInput perPage={25} sort={{ field: 'mconame', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'mconame~like': searchText })}
              label="Booking Branch" source="configId" reference="configs" fullWidth={true} formClassName={classes.one_4_input}>
              <SelectInput optionText="mconame" />
            </ReferenceInput>
            <TextInput disabled={true} source="orderNo" label="Order No" fullWidth={true} formClassName={classes.two_4_input} />

            <DateInput disabled={true} validate={[required()]} label="Order Date" source="orderDate" fullWidth={true} formClassName={classes.three_4_input} />

            {/* <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} 
            filterToQuery={searchText => ({ 'name~like': searchText })}
            label="Enquiry" source="enquiryId" reference="enquiries" fullWidth={true} formClassName={classes.last_4_input}>
             <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> 
            
          </ReferenceInput> */}

            <ReferenceInput disabled={true} perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Biz Type" source="bizTypeId" reference="biz-types" fullWidth={true} formClassName={classes.last_4_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <OuterComponent disabled={true} source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
              <ReferenceInput disabled={true} perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Customer Group" source="custGroupId" reference="cust-groups" fullWidth={true} formClassName={classes.one_4_input}>
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddCustomerGroup(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.twice_4_input}>
              <ReferenceInput disabled={true} perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.twice_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Customer" source="customerId" reference="customers" fullWidth={true} >
                <AutocompleteInput   optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddCustomer(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>


            <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
              Policy Detail
            </div>
            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.one_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Insurer" source="insurerId" reference="insurers" fullWidth={true} >
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddInsurer(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.two_4_input}>
              <ReferenceInput  perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.two_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Insurer Branch" source="insurerBrancheId" reference="insurer-branches" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddInsurerBranch(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>



            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
              <ReferenceInput
                onChange={(data) => { checkPolicyDepartment(data); }}
                perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Policy Department" source="policyDepartmentId" reference="policy-departments" fullWidth={true} >
                <AutocompleteInput  optionText="name" variant="outlined"
                  shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddPolicyDepartment(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Policy Type" source="policyTypeId" reference="policy-types" fullWidth={true}>
                <AutocompleteInput  optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddPolicyType(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>

            {isAutoMotor && <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
              <ReferenceInput
                onChange={(data) => { getMotorDetails(data); }}
                perPage={500} sort={{ field: 'regnNo', order: 'ASC' }} validate={[required()]}
                filterToQuery={searchText => ({ 'regnNo~like': searchText })}
                label="Motor Details" source="motorId" reference="motors"
                fullWidth={true} >
                <AutocompleteInput optionText="regnNo" variant="outlined"
                  shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddMotorDetails(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>}

            <DisplayMotorDetails formClassName={classes.twice_4_input} motorDetail={motorDetails} getMotorDetails={getMotorDetails} />

            <Divider />

            <BooleanInput label="is Cover Note" source="isCoverNote" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput multiline label="Cover Note" source="coverNote" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput validate={[required()]} label="Policy Start Date" source="policyStartDate" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput validate={[required()]} label="Policy End Date" source="policyEndDate" fullWidth={true} formClassName={classes.last_4_input} />


            <BooleanInput label="Is Hypothecation" source="isHypothecation" fullWidth={true} formClassName={classes.one_4_input} />
            <ReferenceInput perPage={500} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Hypthecated Bank Name" source="hypothecatedBankId" reference="bank-masters" fullWidth={true} formClassName={classes.two_4_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label="Project Name" source="projectName" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput label="Policy Excess" source="policyExcess" fullWidth={true} formClassName={classes.last_4_input} />


            <TextInput label="Risk Location" source="insuredLocation" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput label="Policy No" source="policyNo" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput label="Policy Remarks" source="policyRemarks" fullWidth={true} formClassName={classes.three_4_input} />
            <BooleanInput disabled={true} label="Is Co-Insurance" source="isMultiPolicy" fullWidth={true} formClassName={classes.last_4_input} />

            <NumberInput label="Sum Insured" source="odSumInsured" disabled={true} fullWidth={true} formClassName={classes.one_4_input} />
            <NumberInput label="Net Premium / OD Premium" source="odPremAmt" disabled={true} fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput label="Terrorism Insured/ TP Sum Insured" source="tpSumInsured" disabled={true} fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="Terrorism Premium/ TP Premium " source="tpPremiumAmt" disabled={true} fullWidth={true} formClassName={classes.last_4_input} />

            {/* <NumberInput label="OD Sum Insured" source="odSumInsured" disabled={true} fullWidth={true} formClassName={classes.one_4_input} />
            <NumberInput label="OD Premium" source="odPremAmt" disabled={true} fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput label="TP Sum Insured" source="tpSumInsured" disabled={true} fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="TP Premium" source="tpPremiumAmt" disabled={true} fullWidth={true} formClassName={classes.last_4_input} /> */}

            <NumberInput label="Stamp Duty" source="stampDutyAmt" fullWidth={true} formClassName={classes.one_4_input} />
            <NumberInput label="Gross Premium" source="grossPremAmt" disabled={true} fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput label="Gross Sum Insured" source="grossSumInsuredAmt" disabled={true} fullWidth={true} formClassName={classes.three_4_input} />
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
              label="Paid Mode" source="paymentModeId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'PM' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>


            <NumberInput label="Paid Premium" source="totalPaidPremiumAmt" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Policy Issue Date" source="policyIssueDate" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput label="Policy Receive Date" source="policyReceuveDate" fullWidth={true} formClassName={classes.three_4_input} />
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Policy Rcvd Format Type" source="rcvdFormatTypeId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'RFT' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Policy Dispatch Through" source="dispatchThroughId" reference="lookups" formClassName={classes.one_4_input}
              filter={{ type: 'PDT' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <DateInput label="Policy Dispatch Date" source="policyDispatchDate" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput multiline label="Policy Dispatch Detail" source="policyDispatchDetails" fullWidth={true} formClassName={classes.three_4_input} />
            <BooleanInput label="Is Renewable" source="isRenewable" fullWidth={true} formClassName={classes.last_4_half_input} />
            <BooleanInput label="Is Old Policy" source="isOldPolicyNo" fullWidth={true} formClassName={classes.last_4_half_input} />


            <TextInput label="Old Policy No" source="oldPolicyNo" fullWidth={true} formClassName={classes.one_4_input} />
            <BooleanInput label="Is QC Done" source="isQcDone" fullWidth={true} formClassName={classes.two_4_input} />
            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.three_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="QC Done By" source="qcDoneBy" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>
            <DateInput label="QC Date" source="qcDate" fullWidth={true} formClassName={classes.last_4_input} />

            <BooleanInput disabled={true} label="Is Co-Broker" source="isPartnerBroker" fullWidth={true} formClassName={classes.one_4_input} />
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Camaign Type" source="camaignId" reference="lookups" formClassName={classes.two_4_input}
              filter={{ type: 'CPT' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label="Camaign Name" source="camaignName" fullWidth={true} formClassName={classes.three_4_input} />

            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
              label="Status" source="statusId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'RO' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <Divider />
            <TextInput label="Risk Occupancy" source="riskOccupancy" multiline formClassName={classes.one_4_input} />
            <TextInput label="Risk Code" source="riskCode" formClassName={classes.two_4_input} />



            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.three_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="RM" source="rmid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.last_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="CSC" source="cscid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>
            {/* <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.one_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="RM" source="rmid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.two_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.two_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="CSC" source="cscid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent> */}

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.three_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="TC" source="tcid" reference="employees" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.last_4_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="REF" source="refid" reference="ref-customers" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => {

                  return val && val.trim().length >= 1
                }} />
              </ReferenceInput>
              {isEdit && <Button onClick={() => { setIsAddRefCustomer(true) }} className="form-add-button"><AddIcon /></Button>}
            </OuterComponent>

            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.last_4_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="POSP" source="pospId" reference="ref-customers" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            {isEdit && <Button onClick={() => { setIsAddRefCustomer(true) }} className="form-add-button"><AddIcon /></Button>}
          </OuterComponent>

            <TextInput multiline label="Edit Remarks" source="editRemarks" fullWidth={true} />

            {/* {brokrage && <div className='brokrage-container'>
              {brokrage.map((item: any, index) => {
                return (
                  <div className='brokrage-item' key={index}>
                    <div className='brokrage-item-name'>{item.borkrageGroup}</div>
                    <div className='brokrage-item-value'>{item.brokrageAmount}</div>
                  </div>
                )
              })}
            </div>} */}

          </FormTab>
          }

         {propsObj.nlfOrdPremV  && <FormTab label="Order Premiums">
           {propsObj.nlfOrdPremA &&  <div>
              <Button onClick={() => { setIsAddPremiums(true); }}>Add Premiums</Button>
            </div>
           }



            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="order-premiums"
              perPage={2000}
              target="orderId">

              <Datagrid >

                {/* <CDeleteButton {...props} /> */}
                {propsObj.nlfOrdPremE && <UploadDocument onClick={(record) => { setIsEditPremiums(record); setIsEditPremium(true); }} label="Edit" />}
                {propsObj.nlfOrdPremA && <AddPayment onClick={(record) => { OrderAddPayment(record) }} label="" />}

                <ReferenceField source="insurerId" reference="insurers" label="Insurer" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="premiumTypeId" reference="lookups" label="Premium Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                {/* <ReferenceField source="policyDetailId" reference="policy-masters" label="Policy Detail" link={false}>
                  <TextField source="name" />
                </ReferenceField> */}
                <NumberField source="sharePerc" label="Share Perc" />
                <NumberField source="odSumInsuredAmt" label="Net/ OD Sum Ins Amt" />
                <NumberField source="odPremiumAmt" label="Net / OD Premium Amt" />
                <NumberField source="tpSumInsuredAmt" label="Terrorisim / TP Ins Amt" />
                <NumberField source="tpPremiumAmt" label="Terrorisim / TP Preimum Amt" />
                <NumberField source="netPremiumAmt" />
                {/* <TextField source="remarks" /> */}
                <TextField source="isTaxable" label={"Taxable"} />

              </Datagrid>

            </ReferenceManyField>
          </FormTab>
              }
          {propsObj.nlfPmBrokV && <FormTab label="Payments and Brokrage Detail">


            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="order-payments"
              target="orderId">

              <Datagrid >


                {propsObj.nlfPmBrokE && <UploadDocument onClick={(record) => { setIsEditRecord(record); setIsEditPayment(true); }} label="Edit" />}
                {propsObj.nlfPmBrokV && <ViewButton onClick={(record) => { getbrokrageInsurerDetail(record) }} label="" />}
                <DateField source="paymentDueDate" label="Payment Due Date" locales="fr-FR" />
                <TextField source="paymentNo" label={"Payment No"} />

                <ReferenceField source="premiumTypeId" reference="lookups" label="Premium Type" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <NumberField source="odPremiumAmt" label="Net / OD Premium" />
                <NumberField source="tpPremiumAmt" label="Terrorisim /TP Premium" />
                <NumberField source="netPremiumAmt" label="Net " />

              </Datagrid>




            </ReferenceManyField>
            {brokrageDetail.length > 0 && <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey", marginTop: "10px" }} >
              Brokerage Summary
            </div>
            }
            {brokrageDetail && <div className='brokrage-container-summary'>
              {brokrageDetail.map((item: any, index) => {
                return (
                  <div className='brokrage-item-summary' key={index}>
                    <div className='brokrage-item-name-summary'>{item.name}</div>
                    <div className='brokrage-item-name-summary'>{item.brokerageName}</div>
                    <div className='brokrage-item-percent-summary'>{item.ratePercentage.toFixed(2)}{"%"}</div>
                    <div className='brokrage-item-value-summary'>{item.amount.toFixed(2)}</div>
                  </div>
                )
              })}
            </div>}
            <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey", marginTop: "10px" }} >
              Summary
            </div>
            {brokrageInsurer && <div className='brokrage-container-summary'>
              {brokrageInsurer.map((item: any, index) => {
                return (
                  <div className='brokrage-item-summary' key={index}>
                    <div className='brokrage-item-name-summary'>{item.name}</div>
                    <div className='brokrage-item-percent-summary'>{item.ratePercentage.toFixed(2)}{"%"}</div>
                    <div className='brokrage-item-value-summary'>{item.amount.toFixed(2)}</div>
                  </div>
                )
              })}
            </div>}
          </FormTab>}

          {propsObj.nlfDocmtV && <FormTab label="Documents">
           {propsObj.nlfDocmtA && <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddOtherDocuments(true); }}>Add New Document</Button>

            </div>}
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="order-documents"
              target="ordId">
              <Datagrid>
                {propsObj.nlfDocmtD && <CDeleteButton {...props} />}

                <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />
                <TextField label=" Attach In Mail" source="mailAttachment" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          }

          {propsObj.nlfEnrsmtV && <FormTab label="Non Commercial Endorsement">
           {propsObj.nlfEnrsmtA && <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddEndorsement(true); }}>Add Endorsement</Button>

            </div>}
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="order-endorsements"
              target="ordId">
              <Datagrid>
                {propsObj.nlfEnrsmtD && <CDeleteButton {...props} />}
                {propsObj.nlfEnrsmtE && <UploadDocument onClick={(record) => { setIsEditRecord(record); setIsEditEndorsement(true); }} label="Edit" />}

                <ReferenceField label="Document " source="documentTypeId"
                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Endorsement Type" source="endorsementId"

                  reference="endorsement-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Status" source="statusId"

                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <TextField label="Ends.No" source="endorsementNo" />
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          }

          {propsObj.nlfOrdEmlV && <FormTab label="Order Email">
          {propsObj.nlfOrdEmlA &&  <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddEmail(true); }}>Add Email</Button>
            </div>
}
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="email-logs"
              target="linkId">
              <Datagrid>
                <TextField label="Subject" source="subject" />
                <EmailBody source="body" />
                <DateField showTime={true} label="Created Date" source="createdAt" locales="fr-FR" />
                <EmailStatus label="Email Status" source="isPending" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          }
          {propsObj.nlfRefPymV && <FormTab label="Ref Payment Orders">
          {propsObj.nlfRefPymA &&  <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddRefPayment(true); }}>Add Ref Payment </Button>

            </div>
        }
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="order-ref-payments"
              target="orderId">
              <Datagrid>
                {propsObj.nlfRefPymD && <CDeleteButton {...props} />}
                {propsObj.nlfRefPymE && <UploadDocument onClick={(record) => { setIsEditRecord(record); setIsEditRefPayment(true); }} label="Edit" />}
                <ReferenceField label="Premium Type" source="premiumTypeId"
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Payment No" source="orderPaymentId"
                  reference="order-payments" link={false}>
                  <TextField source="paymentNo" />
                </ReferenceField>
                <ReferenceField label="Ref On" source="refOn"

                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>

                <TextField label="Ref Percentage" source="refPercentage" />
                <TextField label="Ref Amount" source="refAmount" />

                <TextField label="Remarks" source="remarks" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>


          </FormTab>
          }



        </TabbedForm>

      </Edit >
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={(isEditPayment || isAddCustomer || isAddEmail || isaddRefPayment || isEditRefPayment) ? {
          paper: myclasses.drawerPaper1500,
        } : {
          paper: myclasses.drawerPaper,
        }}
        open={isaddPremiums || isaddOtherDocuments || isEditPremium || isEditPayment
          || isAddCustomer || isAddCustomerGroup || isAddEmployee || isAddRefCustomer ||
          isAddInsurer || isAddInsurerBranch || isAddPolicyDepartment ||
          isAddMotorVarient || isAddMotorMake || isAddMotorFuel || isAddMotorModel || isAddMotorDetails ||
          isAddPolicyType || isAddEmail || isaddEndorsement || isEditEndorsement ||
          isAddMotorClass || isaddRefPayment || isEditRefPayment
        } >


        {isaddOtherDocuments && <DocumentsCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}

        {isaddPremiums && <PremiumCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}

          {...props} />}

        {isEditPremium && <PremiumEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}

          record={isEditPremiums}
          {...props} />}


        {isEditPayment && <PremiumPaymentCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          record={IsEditRecord}
          {...props} />}

        {isEditEndorsement && <EndorsementEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          record={IsEditRecord}
          {...props} />}


        {isAddCustomer && <CustomerCreate onClose={handleClose} />}
        {isAddCustomerGroup && <CustomerGroupCreate onClose={handleClose} />}
        {isAddInsurer && <InsurerCreate onClose={handleClose} />}
        {isAddInsurerBranch && <InsurerBranchCreate onClose={handleClose} />}
        {isAddPolicyDepartment && <PolicyDepartmentCreate onClose={handleClose} />}
        {isAddPolicyType && <PolicyTypeCreate onClose={handleClose} />}
        {isAddEmployee && <EmployeeCreate onClose={handleClose} />}
        {isAddEmail && <OrderEmail onClose={onCloseDocumentForm} ordId={props.id} {...props} />}
        {isAddMotorClass && <MotorClassCreate onClose={handleClose}
          ordId={props.id}
          {...props} />}
        {isAddMotorVarient && <MotorClassVarient onClose={handleClose} />}
        {isAddMotorMake && <MotorClassMake onClose={handleClose} />}
        {isAddMotorFuel && <MotorClassFuel onClose={handleClose} />}
        {isAddMotorModel && <MotorClassModel onClose={handleClose} />}
        {isAddMotorDetails && <MotorClassDetails onClose={handleClose} />}
        {isaddEndorsement && <EndorsementCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}

        {isAddRefCustomer && <RefCustomerCreate onClose={onCancel} />}
        {isaddRefPayment && <RefPaymentCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}
        {isEditRefPayment && <RefPaymentEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          ordId={props.id}
          record={IsEditRecord}
          {...props} />}


      </Drawer>
    </React.Fragment>
  );
};
