import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter, 
  ReferenceInput,ShowButton,
  SearchInput
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";


const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Department Name" source="name~like" alwaysOn />
  </Filter>
);
export const DepartmentList = (props) => {
  const propsObj: any = Authorizer(props);
  return (
    <List {...props} bulkActionButtons={false} title="Department List" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}

        <TextField source="name" label="Department Name" />
        <TextField source="description" label="Department Description " />
        <ReferenceField source="insertedBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="insertedAt" label="Created Date" />
        <ReferenceField source="updatedBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="updatedAt" label="Updated Date" />
      </Datagrid>
    </List>
  );
};
