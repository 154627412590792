import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import React from 'react'
const LicDetail = (props) => {
    let styles = props.styles
    return <View style={styles.bankDetailBox} >
        <Text style={{ fontWeight: "bold", textAlign: "center"}}>
        Direct Broker (Life & General)                     IRDA License No:865                                    License Valid Upto:07/12/2025
            
            </Text>
    </View>
}
export default LicDetail
