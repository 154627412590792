import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput, NumberInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { toast } from "react-toastify";

const MotorClassVarient = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  let defaultValues = {
    isActive: true,
  };
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();

    apiClient.addRecord(data, "motor-variants").then(res => {
      notify("Motor variant is added succesffully");
      props.onClose();

    }).catch(err => {
      notify("unable to add Motor variant ", "warning");
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      <h5>Add Motor Varient </h5>
      <SimpleForm variant="outlined" basePath="motor-classes" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >
        <TextInput source="variant" fullWidth={true} validate={[required()]}/>
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true}/>

      </SimpleForm>

    </React.Fragment>
  );
};
export default MotorClassVarient;