import React, { useState, useCallback } from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  useNotify, useRedirect, useRefresh
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import MotorVariantCreate from './popup/MotorVariantCreate';
import MotorClassCreate from './popup/MotorClassCreate';
import MotorFuelCreate from './popup/MotorFuelCreate';
import MotorMakeCreate from './popup/MotorMakeCreate';
import MotorModelCreate from './popup/MotorModelCreate';
const OuterComponent = (props) => {

  return (
    props.children
  )
}
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 400,
  },
  drawerPaper1000: {
    zIndex: 100,
    marginTop: 50,
    width: 1000,
  },
}));
export const MotorEdit = (props) => {


  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isAddVariant, setIsAddVariant] = useState(false);
  const [isAddFuel, setIsAddFuel] = useState(false);
  const [isAddClass, setIsAddClass] = useState(false);
  const [isAddMake, setIsAddMake] = useState(false);
  const [isAddModel, setIsAddModel] = useState(false);
  const onCloseDocumentForm = () => {
    setIsAddVariant(false);
    setIsAddFuel(false);
    setIsAddClass(false);
    setIsAddMake(false);
    setIsAddModel(false);
    refresh();
  }
  const onCancel = () => {
    setIsAddVariant(false);
    setIsAddFuel(false);
    setIsAddClass(false);
    setIsAddMake(false);
    setIsAddModel(false);
    refresh();

  }
  const handleClose = useCallback(() => {
    setIsAddVariant(false);
    setIsAddFuel(false);
    setIsAddClass(false);
    setIsAddMake(false);
    setIsAddModel(false);
    refresh();

  }, []);
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <React.Fragment>
      <Edit {...propsObj} title="Motor   Edit">
        <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">

        <TextInput source="make" label="Motor Make" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="model" label="Model" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
          <TextInput source="variant" label="Variant" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

          <TextInput source="fuel" label="Fuel" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <NumberInput label="CC/GVW" fullWidth={true} validate={[required()]} source="ccGvw" formClassName={classes.two_three_input} />
          <NumberInput label="Seating Capacity" fullWidth={true} validate={[required()]} source="seatingCapacity" formClassName={classes.last_three_input} />

          <TextInput source="rtoLoc" label="RTO Location" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="yearOfMfg" label="Year Of Manufacturing" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
          <NumberInput label="NCB Percentage" source="ncbPercentage" fullWidth={true} validate={[required()]}  formClassName={classes.last_three_input} />
          
          <TextInput source="regnNo" label="Registration Number" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="engineNo" label="Engine Number" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
          <TextInput source="chasisNo" label="Chasis Number" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

          <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true} formClassName={classes.one_three_input} />
        </SimpleForm>
      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isAddVariant || isAddClass || isAddFuel || isAddModel || isAddMake} >

        {isAddVariant && <MotorVariantCreate
          onClose={onCloseDocumentForm}

        />}
        {isAddMake && <MotorMakeCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}

        {isAddClass && <MotorClassCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}

        {isAddModel && <MotorModelCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}

        {isAddFuel && <MotorFuelCreate
          onClose={onCloseDocumentForm}
          onCancel={onCloseDocumentForm}
        />}


      </Drawer>
    </React.Fragment>
  );
};
