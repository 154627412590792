import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Motor  "
      source="make~like"
      alwaysOn
    />
    <SearchInput
      variant="outlined"
      label="Registration Number  "
      source="regnNo~like"
      alwaysOn
    />
     <SearchInput
      variant="outlined"
      label="Engine Number  "
      source="engineNo~like"
      alwaysOn
    />
      <SearchInput
      variant="outlined"
      label="Chasis Number  "
      source="chasisNo~like"
      alwaysOn
    />
    <SearchInput
      variant="outlined"
      label="RTO"
      source="rtoLoc~like"
      
    />
  </Filter>
            
  
);
export const MotorList = (props) => {
 
  const propsObj = Authorizer(props);
  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Motor Class List" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
        
        <TextField source="make" label="Make" />
        <TextField source="regnNo" label="Registration Number " />
        <TextField source="engineNo" label="Engine Number   " />
        <TextField source="chasisNo" label="Chasis Number   " />
        <TextField source="rtoLoc" label="RTO   " />
        
        
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
