import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,AutocompleteInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { toast } from "react-toastify";

const InsurerBranchCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  let defaultValues = {

    dealDate: moment().toISOString(),


  };
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();
    data.openDate = data.dealDate;
    data.areaId = 0;
    data.add2 = "";
    data.add3 = "";

    apiClient.addRecord(data, "insurer-branches").then(res => {
      notify("Insurer -Branch is added succesffully")
      props.onClose();

    }).catch(err => {
      notify("unable to add Branch", "warning");;
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        });
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      <h5>Add Insurer Branch </h5>
      <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >

        <ReferenceInput perPage={500} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} 
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Insurer" source="insurerId" reference="insurers" fullWidth={true} >
          {/* <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> */}
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput source="code" label="Code" validate={[required()]} formClassName={classes.one_three_input} />
        <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />

        <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.last_three_input} />
        <TextInput source="state" label="State" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.two_three_input} />
        <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.last_three_input} />
        <TextInput source="phmobile" label="Mobile" formClassName={classes.one_three_input} />
        <TextInput source="email" label="email" formClassName={classes.two_three_input} />
        <TextInput source="cpName1" label="Contact Person 1" formClassName={classes.last_three_input} />

        <ReferenceInput label="Handle By" source="employeeId" reference="employees" formClassName={classes.one_three_input}>
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>

        <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.two_three_input} />

      </SimpleForm>

    </React.Fragment>
  );
};
export default InsurerBranchCreate;