import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const PostDocumentEdit = props => {
    const classes = useStyles({});
    console.log(props);
    const handleSubmit = async (formdata) => {
        let m = moment(new Date());
        let documentUrl = await apiClient.UploadFile("docUrl", formdata.docUrl);
        let data = props.record;
        data.docUrl = documentUrl;
        data.docName = formdata.docName;
        data.isVerified = formdata.isVerified;
        data.docVerifyRemarks = formdata.docVerifyRemarks;
        if (!data.createdBy) data.createdBy = 1;
        if (!data.createdDate) data.createdDate = m.toISOString();
        data.modifyBy = 1;
        data.modifyDate = m.toISOString();

        apiClient.updateEntiyRecord("claim-ord-post-docs", data).then(res => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit { ...props } id={ props.record.id } resource="claim-ord-post-docs" redirect={ false }>
            <SimpleForm
                onSubmit={ handleSubmit }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">

                <FileInput fullWidth={ true } source="docUrl" label="Document">
                    <FileField source="docUrl" title="File" />
                </FileInput>

                <TextInput source="slno" label="SL No" fullWidth={ true } />
                <TextInput source="docName" label="Document name" fullWidth={ true } />
                <ReferenceInput
                    perPage={ 500 } sort={ { field: 'name', order: 'ASC' } }
                    label="Document Type" source="docTypeId" reference="lookups"
                    filter={ { type: 'DT' } } fullWidth={ true } >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput source="isVerified" label="Is Verified" fullWidth={ true } />
                <TextInput source="docVerifyRemarks" label="Verify Remarks" fullWidth={ true } />
            </SimpleForm>
        </Edit>
    );
}
export default PostDocumentEdit;