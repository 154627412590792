import React from 'react';
import {  Toolbar, SaveButton, DeleteButton } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
export const useStyles = makeStyles(theme => ({
    cancel_button: {
        marginLeft: 20,
        marginRight: 20,
    },
    main: {
        display: "flex", width: "100%",
        justifyContent: "space-between",
    },
    btnparent: {
        flex: 1,
    },
}));

export const FormToolbar = props => {
    const classes = useStyles({});
    const history = useHistory();
    const cancelForm = () => {
        history.goBack();
    }
    let showSaveButton = true;

    const getResourceName = (url) => {
        if(url.indexOf("?") == -1)
            return url;
        const regex = /\/(.*?)\?/;
        const match = url.match(regex);
        if(match && match.length > 0) {
            return '/' + match[1];
        }
        return url;
    }
    //console.log(props, "====props====");
    if (props.permissions) {
        let resourcename = `/${props.resource}`;
        let myPermissions = props.permissions.filter(item => getResourceName(item.NavigateUrl.toLowerCase().trim()) == resourcename);
        if (myPermissions.length > 0) {
            let p = myPermissions[0];
            if ((p.Edit == 1 && props.record.id) || (p.Create == 1 && !props.record.id)) {
                showSaveButton = true;
            }else{
                showSaveButton = false;
            }
        }
    }

    let showCancel = false;
    
    let showDelete = false;
    if (props.hideSave) {
        showSaveButton = false;
    }
    if (props.showCancel) {
        showCancel = true;
    }
    if (props.hasDelete) {
        showDelete = props.hasDelete;
    }
    return (
        <Toolbar {...props} >
            <div className={classes.main}>
                <div className={classes.btnparent}>
                    {showSaveButton && <SaveButton disabled={showSaveButton ? false : true}  {...props} />}
                    {!showCancel && <Button
                        className={classes.cancel_button}
                        variant="contained" color="primary"
                         size="medium"
                        onClick={cancelForm}
                    >Back</Button>}
                    {showCancel && <Button
                        className={classes.cancel_button}
                        variant="contained" color="primary"
                         size="medium"
                        onClick={props.onCancel}
                    >Cancel</Button>}
                </div>
                { (showDelete)? <DeleteButton   {...props} />:null}
                
            </div>
        </Toolbar>
    )
};