import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";
import { FormToolbar } from "../../common/FormToolbar";

export const BizTypeEdit = (props) => {
  
  const propsObj = Authorizer(props);
  
  return (
    <Edit {...propsObj}  title="Biz Type Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
      <TextInput source="code"  fullWidth={true}  validate={[required()]}/>
      <TextInput source="name"  fullWidth={true}  validate={[required()]}/> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>
      </SimpleForm>
    </Edit>
  );
};
