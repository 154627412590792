import React, { useState, useCallback, useEffect } from 'react';
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput, useNotify, useRedirect,
  ReferenceInput, BooleanField,
  SelectInput, DateField, AutocompleteInput,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { FormToolbar } from "../../common/FormToolbar";
// import { StateDistrict } from "../realize-order/StateDistrict";
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
// import RealizationOtherDocumentsCreate from "../realize-order/RealizationOtherDocumentsCreate";
// import RealizationDocumentsCreate from "../realize-order/RealizationDocumentsCreate";
import * as apiClient from "../../common/apiClient";
// import RealizationDocumentEdit from "../realize-order//RealizationDocumentEdit";
// import { RealizationOrderPatmentFields } from "../realize-order/RealizationOrderPatmentFields";
// import { RealizationOrderOtherFields } from "../realize-order/RealizationOrderOtherFields";
// import { RealizationPrint } from "../realize-order/RealizationPrint";
// import { PrintTPAChecklist } from "../realize-order/PrintTPAChecklist";

// import CorporateCreate from '../realize-order/CorporateCreate'
import AddIcon from '@material-ui/icons/Add';
// import DispatchFields from '../realize-order/DispatchFields';
import { AddressFields } from './AddressFields';
import TriggerDocumentsCreate from './trigger/TriggerDocumentsCreate';
import AskDocumentsCreate from './ask/AskDocumentsCreate';
import ClaimDocumentsCreate from './docs/ClaimDocumentsCreate';
import PostDocumentsCreate from './post-docs/PostDocumentsCreate';
import AllotmentCreate from './allotment/AllotmentCreate';
import { InsurerAddress } from './InsurerAddress';
import { HospitalAddress } from "./HospitalAddress";
import TriggerDocumentEdit from './trigger/TriggerDocumentEdit';
import ClaimDocumentEdit from './docs/ClaimDocumentEdit';
import AskDocumentEdit from './ask/AskDocumentEdit';
import PostDocumentEdit from './post-docs/PostDocumentEdit';
import AllotmentEdit from './allotment/AllotmentEdit';
import HospitalCreate from './HospitalCreate';
import { DisplayDate } from '../../common/DisplayDate';
import { Authorizer } from '../../common/Authorizer';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));


const SecondaryButton = (props) => {
  const { caption, onClick } = props
  return (
    <div style={{ flex: 1 }}>
      <Button variant="contained" color="secondary" style={{ marginRight: "10" }} onClick={onClick}>{caption}</Button>
    </div>
  )
}

const DisplayFileLink = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <a href={record[source]}>View File</a>
    )
  else {
    return null
  }
}

const SectionHeading = (props) => {
  const { caption } = props;
  return (
    <div className="heading">
      <h3>{caption}</h3>
    </div>
  )
}
const DisplayEmployee = (props) => {
  let { record } = props;
  const [employee, setEmployee] = useState<any>(null);

  useEffect(() => {
    if (!record.employeeId) return;
    apiClient.getEmployeeData(record.employeeId).then(res => {
      setEmployee(res);
    }).catch(err => {
      console.log(err);
    });
  }, [record.employeeId]);

  return (
    <div className='employee-data'>
      {employee && employee.designation && <div className='empolyee-mobile'>
        <strong>Designation :</strong> {employee.designation}
      </div>}
      {employee && <div className='empolyee-mobile'>
        <strong>Mobile :</strong> {employee.mobileNo}, {employee.altMobileNo}
      </div>}
      {employee && <div className='employee-email'>
        <strong>Email :</strong>  {employee.email}
      </div>}
    </div>
  )
}

const UploadDocument = (props) => {
  let { record } = props;
  return (
    <div className="upload-document" onClick={() => {
      props.onClick(record);
    }}>Edit Document</div>
  )
}

const DeleteDocumentButton = (props) => {
  let { record } = props;
  return (
    <input type="checkbox" onChange={(e) => {
      props.onSelectedDocument(record);
    }} />
  )
}

const EditButton = (props) => {
  let { record } = props;
  return (
    <div className='edit-button' onClick={(e) => {
      props.onSelectRecord(record);
    }}>Edit</div>
  )
}

export const ClaimOrderEdit = (props) => {

  const propsObj = Authorizer(props);
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles({});
  const myclasses = myStyles({});

  const [isAddAllotment, setIsAddAllotment] = useState(false);
  const [isEditAllotment, setIsEditAllotment] = useState(false);
  const [allotmentRecord, setAllotmentRecord] = useState(false);
  const [isaddDocuments, setIsAddDocuments] = useState(false);


  const [isAddPostDocuments, setIsAddPostDocuments] = useState(false);

  const [isaddTriggerDocuments, setIsAddTriggerDocuments] = useState(false);
  const [isEditTriggerDocuments, setIsEditTriggerDocuments] = useState(false);
  const [triggerDocumentRecord, setTriggerDocumentRecord] = useState(0);

  const [isaddAskDocuments, setIsAddAskDocuments] = useState(false);
  const [isEditAskDocuments, setIsEditAskDocuments] = useState(false);
  const [askDocumentRecord, setAskDocumentRecord] = useState(0);

  const [isEditDocuments, setIsEditDocuments] = useState(false);
  const [documentRecord, setDocumentRecord] = useState(0);

  const [isEditPostDocuments, setIsEditPostDocuments] = useState(false);
  const [postDocumentRecord, setPostDocumentRecord] = useState(0);

  const [isAddCorportate, setIsAddCorportate] = useState(false);
  const [isAddHospital, setIsAddHospital] = useState(false);
  const [selectedDocumentsId, setSelectedDocumentsId] = useState<any[]>([]);
  const [isPrint, setPrint] = useState(false);
  const [isPrintTPA, setPrintTPA] = useState(false);
  const [tpaId, setTPAID] = useState(0);
  const [configValue, setConfigValue] = useState(0);

  const SelectDeselectDocument = (record) => {
    var ids: any[] = [...selectedDocumentsId];
    const id: any = record.id;
    const index = ids.indexOf(id);
    if (index > -1) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }
    setSelectedDocumentsId(ids);
  }
  const onDocumentDelete = async () => {
    var ids: any[] = [...selectedDocumentsId];
    if (ids.length == 0) {
      alert("Please select some documents.");
      return;
    }
    var cn = window.confirm("Are you sure you want to delete the selected documents?");
    if (!cn)
      return;
    ids.forEach(async item => {
      await apiClient.deleteRealizationDocument(item);
    });
    refresh();
  }

  const addFromSetup = (id) => {
    if (configValue == 0) {
      alert("Please select the config.");
      return;
    }

    apiClient.addFromSetup(id, configValue).then(res => {
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }
  const onCloseDocumentForm = () => {
    setIsAddDocuments(false);
    setIsEditDocuments(false);
    setPrint(false);
    setPrintTPA(false);
    refresh();
  }
  const onClosePostDocumentForm = () => {
    setIsAddPostDocuments(false);
    refresh();
  }
  const onCloseAllotmentForm = () => {
    setIsAddAllotment(false);
    refresh();
  }
  const Print = (id) => {
    setPrint(true);
  }
  const PrintTPA = (id) => {

    setPrintTPA(true);
  }
  const onCloseTriggerDocumentForm = () => {
    setIsAddTriggerDocuments(false);
    refresh();
  }
  const onCloseAskDocumentForm = () => {
    setIsAddAskDocuments(false);
    refresh();
  }
  const onCancel = () => {
    setIsAddAllotment(false);
    setIsAddTriggerDocuments(false);
    setIsAddAskDocuments(false);
    setIsEditAskDocuments(false);
    setIsAddDocuments(false);
    setIsAddPostDocuments(false);
    setIsEditDocuments(false);
    setIsAddCorportate(false);
    setIsAddHospital(false);
    setIsEditTriggerDocuments(false);
    setIsEditPostDocuments(false);
    setPrint(false);
    setPrintTPA(false);
    setIsEditAllotment(false);
  }
  const handleClose = useCallback(() => {
    onCancel();
  }, []);
  const redirectMe = (basePath, id, data) => {
    console.log(basePath);
    console.log(id);
    console.log(data);
  };
  const validateUserCreation = (values) => {
    const errors: any = {};
    //console.log(values);
    if (values.uDisAllAmt > 0 && values.rpriotiyId == 14) {
      errors.rpriotiyId = ["Please Change the Recl. Priority"];
    }
    return errors
  };
  return (
    <React.Fragment>
      <Edit title="Claim Order Edit"

        {...propsObj} undoable={false}>
        <TabbedForm
          validate={validateUserCreation}
          redirect={false} variant="outlined" toolbar={<FormToolbar {...propsObj} showDelete={false} />}>
          <FormTab label="Basic Details">

            <ReferenceInput
              perPage={25} sort={{ field: 'orderNo', order: 'ASC' }}
              filterToQuery={searchText => ({ 'orderNo~like': searchText })}
              label="Order Number" source="orderId" reference="orders" fullWidth={true} formClassName={classes.first_inline_input}>
              <AutocompleteInput optionText="orderNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <ReferenceInput
              perPage={25} sort={{ field: 'orderNo', order: 'ASC' }}
              filterToQuery={searchText => ({ 'policyNo~like': searchText })}
              label="Policy Number" source="orderId" reference="orders" fullWidth={true} formClassName={classes.last_inline_input}>
              <AutocompleteInput optionText="policyNo" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <SectionHeading caption="POLICY DETAILS" />

            <TextInput disabled={true} source="claimId" label="Claim ID" fullWidth={true} formClassName={classes.one_4_input} />
            <ReferenceInput
              validate={[required("Please select "), minValue(1, "Please select ")]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Type of LOB" source="lobTypeId" reference="lookups" formClassName={classes.two_4_input}
              filter={{ type: 'TLOB' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              validate={[required("Please select "), minValue(1, "Please select ")]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Category of source" source="sourceCatId" reference="lookups" formClassName={classes.three_4_input}
              filter={{ type: 'SRC' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              validate={[required("Please select "), minValue(1, "Please select ")]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Type of Claim" source="claimTypeId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'TCLM' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput
              validate={[required("Please select ")]}
              source="claimno" label="TPA Claim No" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput validate={[required()]} source="iclaimno" label="Insurance Claim No" fullWidth={true} formClassName={classes.two_4_input} />

            <DateInput validate={[required("Please select ")]} label="Initiate On" source="initiateOn" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput type="time" label="Initiate At" source="initiateAt" fullWidth={true} formClassName={classes.last_4_input} />

            <div className="input-divider"></div>
            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Intimated Via" source="initiatedViaId" reference="lookups" formClassName={classes.one_4_input}
              filter={{ type: 'INVIA' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>


            <ReferenceInput
              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.two_4_input}>
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <ReferenceInput

              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="TPA Name" source="tpaid" reference="tpas" fullWidth={true} formClassName={classes.three_4_input}>
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <div className="input-divider"></div>
            <FormDataConsumer>
              {formDataProps => (
                <div className="insurer-address"><InsurerAddress {...formDataProps} /></div>
              )}
            </FormDataConsumer>
            <TextInput source="insurerEmail" label="Insurer Email" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput source="tpaEmail" label=" TPAEmail" fullWidth={true} formClassName={classes.two_4_input} />
            <NumberInput validate={[required(), minValue(1)]} label="Claim Amount" source="claimAmt" fullWidth={true} formClassName={classes.three_4_input} />
            <NumberInput label="Excess Amount" source="excessAmt" fullWidth={true} formClassName={classes.last_4_input} />

            <ReferenceInput
              validate={[required(), minValue(1)]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Type of Policy" source="policyTypeId" reference="policy-types" formClassName={classes.one_4_input}
              fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>


            <TextInput validate={[required()]} source="policyNo" label="Policy No" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput validate={[required()]} label="Policy Start Date" source="policyStartDate" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput validate={[required()]} label="Policy End Date" source="policyEndDate" fullWidth={true} formClassName={classes.last_4_input} />

            {/* <FormDataConsumer formClassName={classes.last_4_input}>
              {formDataProps => {
                let pType = formDataProps.formData.policyTypeId;
                return (
                  <div style={{ display: "flex", flex: "rows" }}>
                    {pType == 61 && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                      validate={[required(), minValue(1)]}
                      filterToQuery={searchText => ({ 'name~like': searchText })}
                      label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_4_input}>
                      <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>}
                    {pType == 61 && <Button onClick={() => { setIsAddCorportate(true) }}   ><AddIcon /></Button>}
                  </div>
                )
              }}
            </FormDataConsumer> */}

            <SectionHeading caption="INSURED DETAILS" />

            <TextInput validate={[required(), minLength(3)]} source="cName" label="Customer Name" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput validate={[required(), minLength(3)]} source="cMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput source="cEmail" label="Customer e-Mail" fullWidth={true} formClassName={classes.three_4_input} />
            {/* <TextInput source="cAge" label="Customer Age" fullWidth={true} formClassName={classes.last_4_input} />

            <SelectInput label="Customer Sex" source="cSex" fullWidth={true} formClassName={classes.one_4_input}
              choices={[
                { id: 'Male', name: 'Male' },
                { id: 'Female', name: 'Female' },
                { id: 'Other', name: 'Other' }
              ]} /> */}

            <TextInput source="cProposer" label="Proposer Name" fullWidth={true} formClassName={classes.last_4_input} />
            <TextInput source="cProposerContNo" label="Proposer Contact no." fullWidth={true} formClassName={classes.one_4_input} />

            <div className="input-divider"></div>

            <TextInput validate={[required()]} source="cAdd1" label="Address 1" fullWidth={true} />
            {/* // formClassName={classes.address_1} /> */}
            <TextInput source="cAdd2" label="Address 2" fullWidth={true} />
            {/* // formClassName={classes.address_2} /> */}
            <FormDataConsumer>
              {formDataProps => (
                <AddressFields {...formDataProps} />
              )}
            </FormDataConsumer>
            <NumberInput validate={[required(), minValue(1)]} source="cpin" label="Zipcode" fullWidth={true} formClassName={classes.one_4_input} />



            {/* <FormDataConsumer formClassName="address-field">
              {formDataProps => (
                <div className="hospital-address"><HospitalAddress {...formDataProps} /></div>
              )}
            </FormDataConsumer> */}
            <div className="input-divider"></div>
            {/* <BooleanInput label="Empanelled with HDC" source="isEmpannelled" fullWidth={true} formClassName={classes.one_4_input} />
            <BooleanInput label="Investgation allotment accepted by Veritas" source="isAccepted" fullWidth={true} formClassName={classes.twice_4_input} />
            <div className="input-divider"></div> */}


            {/* <DateInput label="Date of admission" source="doa" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Date of discharge" source="dod" fullWidth={true} formClassName={classes.two_4_input} />

            <TextInput source="treatDoctor" label="Treating Doctor Name" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput source="treatDoctorMob" label="Treating Doctor Contact No." fullWidth={true} formClassName={classes.last_4_input} />

            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="provDiagnosis" label="Provisional Diagnosis" fullWidth={true} formClassName={classes.first_inline_input} />
            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="finalDiagnosis" label="Final Diagnosis" fullWidth={true} formClassName={classes.last_inline_input} /> */}

            <SectionHeading caption="Recommendation, if any" />


            <TextInput source="refName" label="Reference Name" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput source="refCompany" label="Reference Company" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput source="refCompanyDesig" label="Reference Designation" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput source="refVia" label="Reference Via" fullWidth={true} formClassName={classes.last_4_input} />
            <DateInput label="Reference Date" source="refDate" fullWidth={true} formClassName={classes.one_4_input} />
            <div className="input-divider"></div>
            <BooleanInput label="Is Reallotment 1" source="isReallotment1" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Reallotment 1 Date" source="reallot1Date" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput label="Reallotment 1 Reason" source="reallot1Reason" fullWidth={true} formClassName={classes.twice_4_input} />
            <div className="input-divider"></div>
            <BooleanInput label="Is Reallotment 2" source="isReallotment2" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Reallotment 2 Date" source="reallot2Date" fullWidth={true} formClassName={classes.two_4_input} />
            <TextInput label="Reallotment 2 Reason" source="reallot2Reason" fullWidth={true} formClassName={classes.twice_4_input} />

            <TextInput source="claimDetail" label="Claim Detail" fullWidth={true} />

          </FormTab>

          <FormTab label="Investigations">
            {/* <BooleanInput label="Empanelled with Veritus" source="isEmpannelled" fullWidth={true} />

            <DateInput label="Date of admission" source="doa" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Date of discharge" source="dod" fullWidth={true} formClassName={classes.two_4_input} />

            <TextInput source="treatDoctor" label="Treating Doctor Name" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput source="treatDoctorMob" label="Contact No." fullWidth={true} formClassName={classes.last_4_input} />

            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="provDiagnosis" label="Provisional Diagnosis" fullWidth={true} />
            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="finalDiagnosis" label="Final Diagnosis" fullWidth={true} /> */}

            <SectionHeading caption="Trigger  Details of Insurer/TPA" />
            <SecondaryButton formClassName="secondary-action-btn" caption="Add Trigger Details" onClick={() => setIsAddTriggerDocuments(true)} />
            {/* <div style={{ flex: 1 }}>
              <Button variant="contained" color="secondary" style={{ marginRight: "10" }} onClick={() => { setIsAddTriggerDocuments(true); }}>Add Trigger Document</Button>
            </div> */}
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-invs"
              target="calimOrdId">
              <Datagrid>
                {<CDeleteButton {...props} cellClassName="delete-column" />}
                {<EditButton label="Modify" onSelectRecord={(record) => {
                  setIsEditTriggerDocuments(true);
                  setTriggerDocumentRecord(record);
                }} />}
                <TextField label="SL Number" source="slno" />
                <TextField label="Trigger Details" source="trigDocs" />
                <TextField label="Additional Details" source="additionalDocument" />

                {/* <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="entryDt" locales="fr-FR" />
                <BooleanField label="Is Verified" source="isVerified" />
                <TextField label="Verify Remarks" source="invVerifyRemarks" /> */}
              </Datagrid>
            </ReferenceManyField>

            <SectionHeading formClassName="section-heading" caption="Required Document" />
            <SecondaryButton formClassName="secondary-action-btn" caption="Add Required Document" onClick={() => setIsAddAskDocuments(true)} />
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-asks"
              target="calimOrdId">
              <Datagrid>
                {<CDeleteButton {...props} cellClassName="delete-column" />}
                {<EditButton label="Modify" onSelectRecord={(record) => {
                  setIsEditAskDocuments(true);
                  setAskDocumentRecord(record);
                }} />}
                <TextField label="SL Number" source="slno" />
                <TextField label="Required Document" source="askDocs" />
                <TextField label="Additional Details" source="additionalDocument" />
                {/* <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="entryDt" locales="fr-FR" />
                <BooleanField label="Is Verified" source="isVerified" />
                <TextField label="Verify Remarks" source="invVerifyRemarks" /> */}
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Documents">

            <SectionHeading caption="Documents shared by Insurer" />
            <SecondaryButton formClassName="secondary-action-btn" caption="Add Documents shared by Insurer" onClick={() => setIsAddDocuments(true)} />
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-docs"
              target="calimOrdId">
              <Datagrid>
                {isAdmin && <CDeleteButton {...props} cellClassName="delete-column" />}
                {/* {<EditButton label="Modify" onSelectRecord={(record) => {
                  SelectDeselectDocument(record);
                }} />} */}
                <TextField label="SL No" source="slno" />
                <ReferenceField label="Document Type" source="docTypeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Name" source="docName" />
                <DisplayFileLink label="Document URL" source="docUrl" />
              </Datagrid>
            </ReferenceManyField>

            <SectionHeading formClassName="section-heading" caption="POST Investgation Doc" />
            <SecondaryButton formClassName="secondary-action-btn" caption="Add POST Investgation Doc" onClick={() => setIsAddPostDocuments(true)} />
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-post-docs"
              target="calimOrdId">
              <Datagrid>
                {isAdmin && <CDeleteButton {...props} cellClassName="delete-column" />}
                {/* {<EditButton label="Modify" onSelectRecord={(record) => {
                  SelectDeselectDocument(record);
                }} />} */}
                <TextField label="SL No" source="slno" />
                <ReferenceField label="Document Type" source="docTypeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Name" source="docName" />
                <DisplayFileLink label="Document URL" source="docUrl" />
              </Datagrid>
            </ReferenceManyField>

          </FormTab>

          <FormTab label="Communication">
            <SecondaryButton formClassName="secondary-action-btn" caption="Add" onClick={() => setIsAddAllotment(true)} />
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-allots"
              target="calimOrdId">
              <Datagrid className="grid-with-delete">
                {isAdmin && <CDeleteButton {...props} cellClassName="delete-column" />}
                {/* {<EditButton label="Modify" onSelectRecord={(record) => {
                  setIsEditAllotment(true);
                  setAllotmentRecord(record);
                }} />} */}
                <ReferenceField label="Employee" source="employeeId"
                  reference="employees" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <DisplayEmployee label="Employee details" />
                <TextField label="Remarks" source="otherDetails" />
                {/* <BooleanField label="Insured" source="isInsured" />
                <BooleanField label="Hospitalised" source="isHospitalised" />
                <BooleanField label="Completed" source="isCompleted" />
                <BooleanField label="Reallotment 1" source="IsReallotment1" />
                <BooleanField label="Reallotment 2" source="IsReallotment2" /> */}
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab label="Final Scrutiny">
            <SectionHeading caption="Trigger  Details of Insurer/TPA :" />
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-invs"
              target="calimOrdId">
              <Datagrid>
                {<EditButton label="Modify" onSelectRecord={(record) => {
                  setIsEditTriggerDocuments(true);
                  setTriggerDocumentRecord(record);
                }} />}
                <TextField label="SL Number" source="slno" />
                <TextField label="Trigger Details" source="trigDocs" />
                <TextField label="Additional Details" source="additionalDocument" />
                <DisplayFileLink label="Document" source="docurl" />
                <BooleanField label="Is Verified" source="isVerified" />
                <TextField label="Verify Remarks" source="invVerifyRemarks" />
              </Datagrid>
            </ReferenceManyField>

            <SectionHeading caption="Required Documents :" />
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-asks"
              target="calimOrdId">
              <Datagrid>
                {<EditButton label="Modify" onSelectRecord={(record) => {
                  setIsEditAskDocuments(true);
                  setAskDocumentRecord(record);
                }} />}
                <TextField label="SL Number" source="slno" />
                <TextField label="Required Document" source="askDocs" />
                <TextField label="Additional Details" source="additionalDocument" />
                <DisplayFileLink label="Document" source="docurl" />
                <BooleanField label="Is Verified" source="isVerified" />
                <TextField label="Verify Remarks" source="invVerifyRemarks" />
              </Datagrid>
            </ReferenceManyField>


            <SectionHeading caption="Documents :" />

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-docs"
              target="calimOrdId">
              <Datagrid>
                {<EditButton label="Modify" onSelectRecord={(record) => {
                  setIsEditDocuments(true);
                  setDocumentRecord(record);
                }} />}
                <TextField label="SL No" source="slno" />
                <ReferenceField label="Document Type" source="docTypeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Name" source="docName" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <BooleanField label="Is Verified" source="isVerified" />
                <TextField label="Verify Remarks" source="docVerifyRemarks" />
              </Datagrid>
            </ReferenceManyField>

            <SectionHeading caption="Post Documents :" />

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="claim-ord-post-docs"
              target="calimOrdId">
              <Datagrid>
                {<EditButton label="Modify" onSelectRecord={(record) => {
                  setIsEditPostDocuments(true);
                  setPostDocumentRecord(record);
                }} />}
                <TextField label="SL No" source="slno" />
                <ReferenceField label="Document Type" source="docTypeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Name" source="docName" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <BooleanField label="Is Verified" source="isVerified" />
                <TextField label="Verify Remarks" source="docVerifyRemarks" />
              </Datagrid>
            </ReferenceManyField>

            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="forRework" label="For Rework" fullWidth={true} />
            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="grossFinding" label="Gross findings" fullWidth={true} />
            <ReferenceInput label="Recomandation Reason" source="recommendationId" fullWidth={true}
              filter={{ type: 'RECOM' }}
              reference="lookups" link={false}>
              <SelectInput source="name" />
            </ReferenceInput>
            <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="recommendationUnder" label="Recommendation Under" fullWidth={true} />
            <TextInput label="Reason 1" source="reason1" fullWidth={true} />
            <TextInput label="Reason 2" source="reason2" fullWidth={true} />
            <TextInput label="Reason 3" source="reason3" fullWidth={true} />
            <ReferenceInput
              validate={[required("Please select "), minValue(1, "Please select ")]}
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Status" source="statusId" reference="lookups" formClassName={classes.one_4_input}
              filter={{ type: 'RO' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <DateInput label="Final Closure Date " source="finalClosureDate" fullWidth={true} formClassName={classes.two_4_input} />
          </FormTab>
        </TabbedForm>
      </Edit >
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isaddTriggerDocuments || isaddAskDocuments
          || isaddDocuments || isAddPostDocuments || isAddAllotment || isEditAllotment
          || isEditTriggerDocuments || isEditDocuments || isEditAskDocuments || isEditPostDocuments
          || isPrint || isAddCorportate || isAddHospital || isPrintTPA} >

        {isEditTriggerDocuments && <TriggerDocumentEdit
          onClose={() => { setIsEditTriggerDocuments(false); refresh(); }}
          onCancel={onCancel}
          calimOrdId={props.id}
          record={triggerDocumentRecord}
          {...props} />}

        {isEditAskDocuments && <AskDocumentEdit
          onClose={() => { setIsEditAskDocuments(false); refresh(); }}
          onCancel={onCancel}
          calimOrdId={props.id}
          record={askDocumentRecord}
          {...props} />}

        {isEditDocuments && <ClaimDocumentEdit
          onClose={() => { setIsEditDocuments(false); refresh(); }}
          onCancel={onCancel}
          calimOrdId={props.id}
          record={documentRecord}
          {...props} />}

        {isEditAllotment && <AllotmentEdit
          onClose={() => { setIsEditAllotment(false); refresh(); }}
          onCancel={onCancel}
          calimOrdId={props.id}
          record={allotmentRecord}
          {...props} />}

        {isEditPostDocuments && <PostDocumentEdit
          onClose={() => { setIsEditPostDocuments(false); refresh(); }}
          onCancel={onCancel}
          calimOrdId={props.id}
          record={postDocumentRecord}
          {...props} />}


        {isaddTriggerDocuments && <TriggerDocumentsCreate
          onClose={onCloseTriggerDocumentForm}
          onCancel={onCancel}
          calimOrdId={props.id}
          {...props} />}
        {isaddAskDocuments && <AskDocumentsCreate
          onClose={onCloseAskDocumentForm}
          onCancel={onCancel}
          calimOrdId={props.id}
          {...props} />}
        {isaddDocuments && <ClaimDocumentsCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          calimOrdId={props.id}
          {...props} />}
        {isAddAllotment && <AllotmentCreate
          onClose={onCloseAllotmentForm}
          onCancel={onCancel}
          calimOrdId={props.id}
          {...props} />}

        {isAddPostDocuments && <PostDocumentsCreate
          onClose={onClosePostDocumentForm}
          onCancel={onCancel}
          calimOrdId={props.id}
          {...props} />}

        {/* {isPrint && <RealizationPrint
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />} */}
        {/* {isPrintTPA && <PrintTPAChecklist
          tpaId={tpaId}
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />} */}
        {/* {isAddCorportate && <CorporateCreate onClose={handleClose} />} */}
        {isAddHospital && <HospitalCreate onClose={handleClose} />}
      </Drawer>
    </React.Fragment>
  );
};
