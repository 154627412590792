import React, { useState, useCallback, useEffect } from 'react';
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../../common/formStyles";
import * as apiClient from "../../../common/apiClient";
import { toast } from 'react-toastify';

export const TaxFields = ({ formData, ...rest }) => {
    const form: any = useForm();
    const classes = containerStyles({});
    const [taxHead, setTaxHead] = useState<any>(null);

    const calculateTaxAndPremium = (premAmt, riderPremiumAmt, taxPerc) => {
        if (isNaN(premAmt))
            premAmt = 0;
        if (isNaN(riderPremiumAmt))
            premAmt = 0;
        if (isNaN(taxPerc))
            premAmt = 0;

        let taxAmt = ((premAmt + riderPremiumAmt) / 100) * taxPerc;
        if (isNaN(taxAmt))
            taxAmt = 0;
        form.change("totalTaxAmt", taxAmt);
        let grossPremAmt = premAmt + riderPremiumAmt + taxAmt;
        if (isNaN(grossPremAmt))
            grossPremAmt = 0;
        form.change("grossPremAmt", grossPremAmt);
        console.log(taxAmt, "====taxAmt====");
        console.log(grossPremAmt, "====grossPremAmt====");

    }

    const getTaxType = (id) => {
        apiClient.getEntiyRecord("tax-heads", id).then(res => {
            // console.log(res, "=======TAX=======");
            setTaxHead(res);
            if (res) {
                form.change("taxPerc", res.taxpc);
                calculateTaxAndPremium(formData.premAmt, formData.riderPremiumAmt, res.taxpc);
            }

        }).catch(err => {
            toast.error(err);
        });
    }

    useEffect(() => {
        if (formData.taxId && formData.taxId != "") {
            getTaxType(formData.taxId);
        }
    }, []);

    return (
        <>
            <div className="tax-field-parent">
                <NumberInput variant="outlined" label="Sum Insured" source="sumInsured" fullWidth={true} />
                <NumberInput onChange={(ev) => { calculateTaxAndPremium(parseInt(ev.target.value), formData.riderPremiumAmt, formData.taxPerc); }}
                    variant="outlined" label="Net Premium" source="premAmt" fullWidth={true} />
                <NumberInput variant="outlined" label="Rider Sum Insured" source="riderSumInsured" fullWidth={true} />
                <NumberInput onChange={(ev) => { calculateTaxAndPremium(formData.premAmt, parseInt(ev.target.value), formData.taxPerc); }}
                    variant="outlined" label="Rider Premium Amt" source="riderPremiumAmt" fullWidth={true} />
            </div>
            <div className="tax-field-parent">
                <ReferenceInput variant="outlined"
                    onChange={(ev) => {
                        getTaxType(ev.target.value);
                    }}
                    perPage={500} sort={{ field: 'taxName', order: 'ASC' }}
                    label="TAX Type" source="taxId" reference="tax-heads"
                    fullWidth={true} >
                    <SelectInput optionText="taxName" />
                </ReferenceInput>
                <NumberInput variant="outlined" label="TAX Percentage" source="taxPerc" fullWidth={true} disabled={true}/>
                <NumberInput variant="outlined" label="TAX Amount" source="totalTaxAmt" fullWidth={true} disabled={true} />
                <NumberInput variant="outlined" label="Gross Premium" source="grossPremAmt" disabled={true} fullWidth={true} />

                {/* <ReferenceInput variant="outlined" label="State" source="stateId" reference="states"
                onChange={(v) => {                    
                    form.change("districtId", 0);
                }}
                validate={required()}
                fullWidth={true} perPage={500} >
                <SelectInput optionText="stateName" />
            </ReferenceInput>            
            <ReferenceInput variant="outlined" label="District" source="districtId"
                reference="districts" perPage={500}
                filter={{ "stateId": formData.stateId }}
                validate={required()}
                fullWidth={true} >
                <SelectInput validate={required()} optionText="districtName" />
            </ReferenceInput> */}
            </div>
        </>
    )
}
