import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  FormDataConsumer,
  Edit, TabbedForm, TextInput, DateInput, ReferenceInput, AutocompleteInput,
  SelectInput, required, BooleanInput, FormTab, useRefresh, 
  ReferenceManyField, ReferenceField, Datagrid, NumberInput, TextField,
  DateField
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CDeleteButton from "../../common/CDeleteButton";

import DocumentsCreate from './DocumentsCreate';
import { FormToolbar } from "../../common/FormToolbar";


const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));
const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={ record.docUrl }>View File</a>
    )
  else {
    return null
  }
}
export const RefCustomerEdit = (props) => {
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(0);
  const [isaddDocument, setIsaddDocument] = useState(false);
  const refresh = useRefresh();
  const myclasses = myStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const onCancel = () => {
    refresh();
    setIsaddDocument(false);
  }

  return (
    <>
    <Edit title="RefCustomer Edit" {...propsObj}>
      {/* <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined"> */}
      <TabbedForm variant="outlined" redirect="list" toolbar={ <FormToolbar { ...props } /> }>
      <FormTab value="DETAILS" label="Customer Detail">
        <TextInput source="code" disabled={false} fullWidth={true} validate={[required()]} />
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} validate={[required()]} sort={{ field: 'userName', order: 'ASC' }}>
          <SelectInput optionText="userName" />
        </ReferenceInput>
        <ReferenceInput label="Type" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}
          perPage={5000} source="typeId"
          filter={{ type: 'ET' }}
          reference="lookups" >
          <SelectInput optionText="name" variant="outlined" />
        </ReferenceInput>
        <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
        <TextInput source="designation" label="Designation" fullWidth={true} />
        <TextInput source="pan" label="PAN" fullWidth={true} />
        <TextInput source="adharNo" label="Adhaar No" fullWidth={true} />
        <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true} />
        <TextInput source="bankName" label="Bank Name" fullWidth={true} />
        <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
        <TextInput source="uan" label="UAN" fullWidth={true} />

        <DateInput source="dol" label="Date of Leaving" fullWidth={true} />
        <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true} />
        <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} />
        <BooleanInput source="isAbry" label="is Abry" fullWidth={true} validate={[required()]} />
        <BooleanInput source="isPf" label="Is Pf" fullWidth={true} validate={[required()]} />
        <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} />
        </FormTab> 
        <FormTab label="Documents">
            <div style={ { flex: 1 } }>
              <Button style={ { marginRight: "10" } } onClick={ () => { setIsaddDocument(true); } }>Add  Document</Button>

            </div>
            <ReferenceManyField fullWidth={ true }
              addLabel={ false }
              sort={ { field: 'id', order: 'ASC' } }
              reference="ref-customer-docs"
              target="customerId">
              <Datagrid>
                { <CDeleteButton { ...props } /> }

                <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={ false }>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={ { type: 'DT' } }
                  reference="lookups" link={ false }>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Document No" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={ true } label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>

      </TabbedForm>
    </Edit>
    <Drawer
    anchor="right"
    onClose={ onCancel }
    classes={ { paper: myclasses.drawerPaper, } }
    open={ isaddDocument } >
    
     
    { isaddDocument && <DocumentsCreate
      onClose={ onCancel }
      onCancel={ onCancel }
      custId={ props.id }
      { ...props } /> }
  </Drawer>
  </>
  );
};
