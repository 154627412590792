import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
  FormDataConsumer, AutocompleteInput,
  Edit, TabbedForm, TextInput, DateInput, ReferenceInput, SelectInput, required, BooleanInput, FormTab, useRefresh, ReferenceManyField, ReferenceField, Datagrid, NumberInput, TextField,
  DateField
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContactEdit from "./ContactEdit";
import CDeleteButton from "../../common/CDeleteButton";
import ContactCreate from "./ContactCreate";
import { Addressfields } from "./Addressfields";
import EventCreate from './EventCreate';
import EventEdit from './EventEdit';
import DocumentsCreate from "./DocumentsCreate";
import { CalcFields } from "./CalcFields";
import EmployeeCreate from './EmployeeCreate';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));



const OuterComponent = (props) => {

  return (
    props.children
  )
}
export const userStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: 100,
  },
}));

// export const EditButton = props => {

//   const setHospitalDetId = () => {
//       props.setHospitalDetEdit(props.record.id);
//   }
//   return (
//       <Button
//           color="secondary" disableElevation
//           size="medium"

//           onClick={setHospitalDetId}
//           startIcon={<EditIcon />}>Edit</Button>
//   )
// };

export const LeadOrderEdit = (props) => {
  const [isAddEmployee, setIsAddEmployee] = useState(false);
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const classes = useStyles({});

  const [isAddDocument, setIsAddDocument] = useState(false);
  const [isAddEvent, setisAddEvent] = useState(false);
  const [isEditEvent, setisEditEvent] = useState(false);
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(null);

  const myclasses = myStyles({});
  const refresh = useRefresh();

  const DisplayFileLink = (props) => {
    let { record } = props;

    if (record.docUrl && record.docUrl.length > 0)
      return (
        <a href={record.docUrl}>View File</a>
      )
    else {
      return null
    }
  }

  const EditButton = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<EditIcon />}>Edit</Button>

  }
  const closeEdit = () => {
    setIsAddDocument(false);
    setisEditEvent(false);
    setisAddEvent(false);
    setIsEditDocumentRecord(null);
    refresh();
  }


  const onCancel = () => {
    setIsAddDocument(false);
    setisEditEvent(false);
    setisAddEvent(false);
    setIsEditDocumentRecord(null);
    setIsAddEmployee(false);

  }

  const handleClose = useCallback(() => {
    setIsAddDocument(false);
    setisEditEvent(false);
    setisAddEvent(false);
    setIsEditDocumentRecord(null);
    setIsAddEmployee(false);

  }, []);

  return (
    <React.Fragment>
      <Edit title="Lead Order Edit" {...propsObj}>

        <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}>
          <FormTab value="DETAILS" label=" Detail">
            <ReferenceInput perPage={2000} fullWidth={true} label="Branch" source="branchId" reference="configs" formClassName={classes.one_three_input} sort={{ field: 'mconame', order: 'ASC' }} >
              <SelectInput optionText="mconame" validate={[required()]} />
            </ReferenceInput>

            <TextInput source="leadOrdNo" label="Lead Order" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} disabled />
            <DateInput source="leadDate" label="Lead Date / Telecalling Date" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} disabled />

            <ReferenceInput label="Lead Type " validate={[required()]} fullWidth={true} link={false} formClassName={classes.one_three_input} perPage={5000} source="leadTypeId" filter={{ type: 'LT' }} reference="lookups" sort={{ field: 'name', order: 'ASC' }} >
              <AutocompleteInput optionText="name" validate={[required()]} shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <ReferenceInput label="By" source="leadById" reference="employees" validate={[required()]} fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <AutocompleteInput optionText="name" validate={[required()]} shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <TextInput source="customerName" label="Customer" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput multiline source="customerAddress" validate={[required()]} fullWidth={true} label="Address " formClassName={classes.one_three_input} />
            <TextInput source="email" fullWidth={true} validate={[required()]} label="Email" formClassName={classes.two_three_input} />
            <TextInput source="contactNo" label="Contact No" validate={[required()]} fullWidth={true} formClassName={classes.last_three_input} />


{/* 
            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <Addressfields {...formDataProps} />
              )}
            </FormDataConsumer> */}

            {/* <ReferenceInput label="Lead Referred By " source="refById" reference="customers"  fullWidth={true}  formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput> */}
            <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_three_input}>
              <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.one_three_input}
                filterToQuery={searchText => ({ 'name~like': searchText })}
                label="Lead Referred By" source="refById" reference="ref-customers" fullWidth={true} >
                <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => {

                  return val && val.trim().length >= 1
                }} />
              </ReferenceInput>
              <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
            </OuterComponent>


            {/* <ReferenceInput label="Insurer" source="insurerId" reference="insurers"  fullWidth={true}  formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput> */}
            <ReferenceInput label="Insurer" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} perPage={250000} >
              {/* <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> */}
              <SelectInput optionText="name" variant="outlined" />
            </ReferenceInput>


            <ReferenceInput label="Insurer Branch " source="insurerBranchId" reference="insurer-branches" fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <TextInput source="orderNo" label="Order Number" fullWidth={true} formClassName={classes.one_three_input} />
            <ReferenceInput label="Policy Department" source="policyDepartmentId" reference="policy-departments" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <ReferenceInput label="Policy Type " source="policyTypeId" reference="policy-types" fullWidth={true} formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <TextInput source="policyNo" label="Policy No" fullWidth={true} formClassName={classes.one_three_input} />



            <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CalcFields {...formDataProps} />
              )}
            </FormDataConsumer>
            <DateInput source="startDate" label="Start Date" fullWidth={true} formClassName={classes.one_three_input} />

            <DateInput source="expiryDate" label="Expiry Date" fullWidth={true} formClassName={classes.two_three_input} />
            <DateInput source="nextFollowupDate" label="Next Followup Date" validate={[required()]} fullWidth={true} formClassName={classes.last_three_input} />

            <ReferenceInput label="Reporting To" source="reportingId" reference="employees" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput label="RM" source="rmId" reference="employees" fullWidth={true} formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Tele Caller" source="telecallerId" reference="employees"  fullWidth={true}  formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <SelectInput optionText="name" />
          </ReferenceInput>
          
          <ReferenceInput label="Status "  fullWidth={true} link={false}  formClassName={classes.one_three_input}  perPage={5000} source="statusId" filter={{ type: 'LTS' }} reference="lookups"  sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" variant="outlined"   fullWidth={true} />
            </ReferenceInput>
            <div>
            <BooleanInput source="isSentUnderwriter" variant="outlined" label="Sent Under writer" initialValue={false} formClassName={classes.first_inline_input} />

            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
              {({ formData, ...rest }) => formData.isSentUnderwriter &&
                (
                  <DateInput source="underWriterDate" validate={[required()]} variant="outlined" label="UnderWriter Date" formClassName={classes.last_inline_input} />
                )
              }
              
            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
            {({ formData, ...rest }) => !formData.isSentUnderwriter &&
                (
                  <DateInput source="underWriterDate" variant="outlined" label="UnderWriter Date" disabled  formClassName={classes.last_inline_input} />
                )
              }
            </FormDataConsumer>
            </div>

            <div>
            <BooleanInput source="isQuoteReleased" label="Quote Released"  initialValue={false} formClassName={classes.first_inline_input} />
            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
              {({ formData, ...rest }) => formData.isQuoteReleased &&
                (
                  <DateInput source="quoteReleaseDate" validate={[required()]} variant="outlined" label="Quote Released Date" formClassName={classes.last_inline_input} />
                )
              }
              
            </FormDataConsumer>
            <FormDataConsumer fullWidth={true} formClassName={classes.last_inline_input} >
            {({ formData, ...rest }) => !formData.isQuoteReleased &&
                (
                  <DateInput source="quoteReleaseDate"  variant="outlined" label="Quote Released Date" disabled  formClassName={classes.last_inline_input} />
                )
              }
            </FormDataConsumer>
            </div>
            
            
            <BooleanInput source="isActive" label="Is Active" initialValue={true} formClassName={classes.one_three_input} />
            <TextInput multiline source="reportingHeadRemarks" fullWidth={true} label="Reporting Head Remarks " />




          </FormTab>
          <FormTab label="Documents">
            <div style={{ flex: 1 }}>
              <Button style={{ marginRight: "10" }} onClick={() => { setIsAddDocument(true); }}>Add New Document</Button>

            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="lead-order-documents"
              target="ordId">
              <Datagrid>
                {<CDeleteButton {...props} />}

                <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="EVENT DETAILS">
            <Button variant="contained" onClick={() => { setisAddEvent(true); }}
              color="secondary">
              Add Event
            </Button>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="lead-order-events"
              target="orderId">
              <Datagrid>

                <EditButton onClick={(record) => { setIsEditDocumentRecord(record); setisEditEvent(true); }} />
                <TextField fullWidth={true} source="id" label="Event No" />
                <TextField fullWidth={true} source="remarks" label="Remarks" />
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                <DateField source="createdDate" label="Event Date" locales="fr-FR" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>

        </TabbedForm>


      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isAddDocument || isAddEvent || isEditEvent || isAddEmployee} >
        {isAddEvent && <EventCreate
          onClose={closeEdit}
          onCancel={onCancel}
          custId={props.id}
          {...props} />}
        {isEditEvent && <EventEdit
          onClose={closeEdit}
          onCancel={onCancel}
          record={isEditDocumentRecord}
          {...props} />}

        {isAddDocument && <DocumentsCreate
          onClose={closeEdit}
          onCancel={onCancel}
          ordId={props.id}
          {...props} />}

        {isAddEmployee && <EmployeeCreate onClose={onCancel} onCancel={onCancel} />}

      </Drawer>

    </React.Fragment>
  );
};
