import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  useNotify
} from "react-admin";
import VideoUpload from "../../upload/FileUploader";
import { FormToolbar } from "../../../common/FormToolbar";
import * as apiClient from "../../../common/apiClient";
import moment from "moment";
 const MotorVariantCreate = (props) => {
  const notify = useNotify();
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString(); 
    apiClient.addRecord(data, "motor-variants").then(res => {
      notify("Added succesffully")
      props.onClose();

    }).catch(err => {
      notify("unable to add ", "warning")
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      <h5>Add Motor Variant </h5>
      <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} >
      <TextInput source="variant"  fullWidth={true}  validate={[required()]}/> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>
      </SimpleForm>
      </React.Fragment>
  );
};

export default MotorVariantCreate;
