import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

import "./report.css";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const SalaryReport = (props: any) => {
    // const classes = useStyles();
    const classes = useStyles({});
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');

    const [employees, setemployees] = useState<any>([]);
    const [employee, setemployee] = useState<any>(0);




    const getEmployees = () => {
        apiClient.getdataWithOrder("employees", 'name', 'ASC').then(res => {
            setemployees(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const generateReport = () => {

        setFectched(false);
        let sDate = moment(startdate).format('YYYY-MM-DD');
        let eDate = moment(enddate).format('YYYY-MM-DD');
        if (startdate === "") {
            sDate = "";
        }
        if (enddate === "") {
            eDate = "";
        }
        let user: any = localStorage.getItem("userId");
        if (user) {
          user = parseInt(user);
        }
        let data = {
            fromDate: sDate,
            toDate: eDate,
            employeeId: employee ,
            userId:user

        };

        setLoading(true);

        apiClient.loadReport("/report/empsalreport", data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    useEffect(() => {

        
        getEmployees();

    }, []);


    return (
        <div >
            <div className="report-heading">
                {props.rptTtile}
            </div>

            <div className="filters">
                <div className="filters-input">
                    <div className="start-date">Start Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div className="end-date"> End Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>


                </div>
                <div style={{ textAlign: "left", marginLeft: "80px" }}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Employee</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Employee" onChange={(e) => { setemployee(e.target.value); }} value={employee} >
                            <MenuItem value={0}>{"-All-"}</MenuItem>
                            {employees && employees.map((item: any) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </div>






                {fectched && data.length > 0 && <div>Total {data.length} Records found</div>}
                {fectched && data.length == 0 && <div>No Record found</div>}
                <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
                </div>
            </div>
            <div className="filter-action">
                <Button variant="contained"
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Generate Report
                </Button>
            </div>
            <div className="report-data">

                {isLoading && <CircularProgress />}

                <div className="w3-responsive">

                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
};