import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import { Create, SimpleForm, TextInput, BooleanInput, DateInput, useNotify, NumberInput, required, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

import "./report.css";
import policytype from '../policytype';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const MasterReport3 = (props: any) => {
    const notify = useNotify();
    // const classes = useStyles();
    const classes = useStyles({});
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState(null);
    const [enddate, setEndDate] = useState(null);
    const [transactionTypeData, setTransactionTypeData] = useState([{ id: 1, name: "Life" }, { id: 2, name: "Non Life" }])
    const [roletypeId, setRoletypeId] = useState<any>("");
    const [allCustomers, setAllCustomers] = useState(true);
    const [allCustomerGroup, setAllCustomerGroup] = useState(true);
    const [allInsurer, setAllInsurer] = useState(true);
    const [allDepartment, setAllDepartment] = useState(true);
    const [allPolicyType, setAllPolicyType] = useState(true);

    const [transactionTypeId, setTransactionTypeId] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [customerGroupId, setCustomerGroupId] = useState(null);
    
    const [InsurerId, setInsurerId] = useState(null);
    const [policyDepartmentId, setPolicyDepartmentId] = useState(null);
    const [policyTypeId, setPolicyTypeId] = useState(null);


    const [allRM, setAllRM] = useState(true);
    const [allRefCustomer, setAllRefCustomer] = useState(true);
    const [allTC, setAllTc] = useState(true);

    const [rmId, setRMId] = useState(null);
    const [refCustomerId, setRefCustomerId] = useState(null);
    const [tcId, setTCId] = useState(null);





    const generateReport = () => {
        if ((startdate == undefined || startdate == null)) {

            notify("Please select Start Date", "warning");
            return;
        }
        if ((enddate == undefined || enddate == null)) {
            notify("Please select End Date", "warning");
            return;
        }
        if ((transactionTypeId == undefined || transactionTypeId == null)) {
            notify("Please select Transaction Type", "warning");
            return;
        }
        // if (!allCustomers && (customerGroupId == undefined || customerGroupId == null)) {
        //     notify("Please select Customer Group", "warning");
        //     return;
        // }
        if (!allInsurer && (InsurerId == undefined || InsurerId == null)) {
            notify("Please select Insurer", "warning");
            return;
        }

        if (!allDepartment && (policyDepartmentId == undefined || policyDepartmentId == null)) {
            notify("Please select Policy Department", "warning");
            return;
        }
        
        if (!allPolicyType && (policyTypeId == undefined || policyTypeId== null)) {
            notify("Please select Policy Type", "warning");
            return;
        }
        setFectched(false);
        let sDate = moment(startdate).format('YYYY-MM-DD');
        let eDate = moment(enddate).format('YYYY-MM-DD');
        if (startdate === "") {
            sDate = "";
        }
        if (enddate === "") {
            eDate = "";
        }
        let user: any = localStorage.getItem("userId");
        if (user) {
          user = parseInt(user);
        }

        let pol = policyTypeId;
        let trans = transactionTypeId;
        let data: any = {};
        data = {
            fromDate: sDate,
            toDate: eDate,
            customerId: 0,
            insurerId: 0,
            policyDepartmentId: 0,
            policyTypeId: 0,
            transactionTypeId: 0,
            rmId:0,
            refCustomerId:0,
            tcId:0,
            customerGroupId:0,
            rpt: props.rpt,
            userId:user


        };
        if (!allCustomers) {
            data.customerId = customerId;
        }
        if (!allInsurer) {
            data.insurerId = InsurerId;
        }
        if (!allDepartment) {
            data.policyDepartmentId = policyDepartmentId;
        }
        if (!allPolicyType) {
            data.policyTypeId  = policyTypeId ;
        }
        if (!allRM) {
            data.rmId  = rmId ;
        }
        if (!allRefCustomer) {
            data.refCustomerId  = refCustomerId ;
        }
        if (!allTC) {
            data.tcId  = tcId ;
        }
        //if (!allCustomerGroup) {
            data.customerGroupId  = customerGroupId ;
       // }
        
        
        data.transactionTypeId = trans;
        



        setLoading(true);
        apiClient.loadReport("/report/masterreport", data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    useEffect(() => {
        let roletype = localStorage.getItem("RoleTypeId");
        setRoletypeId(roletype)
    }, []);


    return (
        <SimpleForm toolbar={null}>
            <div className="report-heading">
                {props.rptTtile}
            </div>

            <div className="filters">
                {/* <div className="filters-input">
                    <div className="start-date">Start Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div className="end-date"> End Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>
                </div>
                 */}
                <div className="filter-row" >
                    <div className="filter-column" >
                        <DateInput source="startDate" variant="outlined" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} />
                    </div>
                    <div className="filter-column" >
                        <DateInput source="endDate" variant="outlined" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} />
                    </div>
                    <div className="filter-column" >
                        <SelectInput optionText="name" variant="outlined" label="Transaction Type" source="transactionType" fullWidth={true} choices={transactionTypeData}

                            onChange={(data) => { setTransactionTypeId(data.target.value); }}
                        />
                    </div>
                    <div className="filter-column" >
                        <BooleanInput onChange={(data) => { setAllCustomers(data);  setAllCustomerGroup(true); setCustomerGroupId(null); }} source="all_customers" label=" All Customers" fullWidth={true} initialValue={allCustomers} />
                        {!allCustomers &&<BooleanInput onChange={(data) => { setAllCustomerGroup(data); setCustomerGroupId(null);  }} source="all_customergropup" label=" All Customer Group" fullWidth={true} initialValue={allCustomerGroup} />}
                        {!allCustomers && allCustomerGroup && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            label="Customer Groups" source="customerGroupId" reference="cust-groups" fullWidth={true}
                            onChange={(data) => { setCustomerGroupId(data); }}
                        >
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                        {!allCustomers && allCustomerGroup &&  customerGroupId!=null &&<ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            filter={{ custGroupId: customerGroupId }}
                            label="Customer.." source="customerId" reference="customers" fullWidth={true}
                            onChange={(data) => { setCustomerId(data); }}
                        >
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                        {!allCustomers &&  allCustomerGroup &&  customerGroupId==null && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            label="Customer...." source="customerId" reference="customers" fullWidth={true}
                            onChange={(data) => { setCustomerId(data); }}
                        >
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                    </div>
                    <div className="filter-column" >
                        <BooleanInput onChange={(data) => { setAllInsurer(data); }} source="all_Insurer" label=" All Insurer" fullWidth={true} initialValue={allInsurer} />
                        {!allInsurer && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            onChange={(data) => { setInsurerId(data); }}
                            label="Insurer" source="insurerId" reference="insurers" fullWidth={true} >
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                    </div>
                    <div className="filter-column" >
                        <BooleanInput onChange={(data) => { setAllDepartment(data); }} source="all_separtments" label=" All Policy Departments" fullWidth={true} initialValue={allDepartment} />
                        {!allDepartment && <ReferenceInput disabled={true}
                            onChange={(data) => { setPolicyDepartmentId(data); }}
                            perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            label="Policy Department" source="policyDepartmentId" reference="policy-departments" fullWidth={true} >
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                    </div>
                    <div className="filter-row" >
                    <div className="filter-column" >
                    <BooleanInput onChange={(data) => { setAllPolicyType(data); }} source="all_policytype" label=" All Policy Type" fullWidth={true} initialValue={allPolicyType} />
                        {!allPolicyType  && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            onChange={(data) => { setPolicyTypeId(data); }}
                            label="Policy Type" source="policyTypeId" reference="policy-types" fullWidth={true}>
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                    </div>
                    <div className="filter-column" >
                        <BooleanInput onChange={(data) => { setAllRM(data); }} source="all_rm" label=" All Relation Ship Manager." fullWidth={true} initialValue={allRM} />
                        {!allRM  && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            onChange={(data) => { setRMId(data); }}
                            label="RM" source="rmId" reference="employees" fullWidth={true}>
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        } 
                       
                    </div>
                    <div className="filter-column" >
                    <BooleanInput onChange={(data) => { setAllRefCustomer(data); }} source="all_refCustomer" label=" All Ref Customer" fullWidth={true} initialValue={allRefCustomer} />
                    
                        {!allRefCustomer  && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            onChange={(data) => { setRefCustomerId(data); }}
                            label="Ref Customer" source="refCusotmerId" reference="ref-customers" fullWidth={true}>
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                        
                    </div>
                    <div className="filter-column" >
                    <BooleanInput onChange={(data) => { setAllTc(data); }} source="all_tc" label=" All Tele Caller" fullWidth={true} initialValue={allTC} />
                        {!allTC  && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                            filterToQuery={searchText => ({ 'name~like': searchText })}
                            onChange={(data) => { setTCId(data); }}
                            label="Tele Caller" source="tcId" reference="employees" fullWidth={true}>
                            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                        </ReferenceInput>
                        }
                    </div>
                    {/* Add RM, CSC and TC in all reports as extra parameter like All Customer or Selected Customer */}
                    </div>
                </div>

                {fectched && data.length > 0 && <div>Total {data.length} Records found</div>}
                {fectched && data.length == 0 && <div>No Record found</div>}
                {roletypeId != "5" && <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
                </div>}
            </div>
            <div className="filter-action">
                <div>
                    <Button variant="contained"
                        onClick={() => {
                            generateReport();
                        }}
                        startIcon={<AssessmentIcon />}
                        color="primary">
                        Generate Report
                    </Button>
                </div>

            </div>
            <div className="report-data">

                {isLoading && <CircularProgress />}

                <div className="w3-responsive">

                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </SimpleForm>
    );
};