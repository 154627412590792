import React, { useState, useEffect } from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  ImageField,

  SimpleForm,
  FormTab,
  useNotify,
  AutocompleteInput,
  DateInput,
  useRedirect
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Button } from 'react-admin';
import * as apiClient from "../../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import InvoicePrint from "./InvoicePrint/InvoicePrint";
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    marginRight: 5,

    width: 650,
  },
}));
export const SalaryEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles({});
  const [salaryData, setSalaryData] = useState([])
  const [headerData, setHeaderData] = useState([])
  const [id, setId] = useState(0)
  const [salaryYear, setSalaryYear] = useState('')
  const [salaryMonth, setSalaryMonth] = useState('')
  const [isPrint, setIsPrint] = useState(false);
  const [isDataLoading, setDataLoading] = useState(true);
  const [printId, setPrintId] = useState(0);
  const myclasses = myStyles({});
  useEffect(() => {
    //  alert(JSON.stringify(props.id))
    setId(props.id);
    loaddata(props.id);
  }, []);
  const onCancel = () => {
    setIsPrint(false);
    setPrintId(0);
  }

  function showPopup(item) {

    setPrintId(item.ESDID)
    setIsPrint(true);

  }
  const loaddata = (id) => {

    apiClient.getsalary(id, 0, 0).then(res => {
      //debugger
      setSalaryData(res);
      let d = JSON.parse(res[0]["HeaderDeatils"])
      setHeaderData({ ...d[0] });
      setSalaryMonth(d[0].SalMonthID)
      setSalaryYear(d[0].SalYear)
      setDataLoading(false);
    }).catch(err => {
      alert(err)
      console.log(err);
      setDataLoading(false);
    });
  }

  const sendEmailToEmployees = () => {
    apiClient.sendEmailToEmployee(props.id).then(res => {
      notify("Email send to all employees");
    }).catch(err => {
      notify(err);
    });
  }

  const loadSalaryDays = async (item) => {

    return item["MonthDays"];
  }



  const handleChange = async (event, identifier, index) => {
    if (index === undefined) {
      if (identifier === "salaryYear") {
        setSalaryYear(event.target.value)
        loaddata(event.target.value, salaryMonth);
      }
      if (identifier === "salaryMonth") {
        setSalaryMonth(event.target.value)
        loaddata(salaryYear, event.target.value);
      }
    }
    else {
      debugger
      let data = [...salaryData];
      if (identifier === "LeaveDays" && (event.target.value === null || event.target.value === undefined || event.target.value === "" || event.target.value < 0)) {
        data[index][identifier] = 0;
      }
      else if (identifier === "LeaveDays" && event.target.value > parseInt(data[index]["MonthDays"])) {
        data[index][identifier] = data[index]["MonthDays"];
      }

      else {
        data[index][identifier] = event.target.value;
      }


      if (parseInt(data[index]["LeaveDays"]) > parseInt(data[index]["LeaveGraceDays"])) {
        let leaveday=parseInt(data[index]["LeaveDays"])-parseInt(parseInt(data[index]["LeaveGraceDays"]));
        let hraAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["HRAPerc"])) / 100;
        let perDayHRAAmount = hraAmount / parseInt(data[index]["MonthDays"]);        
        data[index]["HRAAmt"] = Math.round(hraAmount - (perDayHRAAmount * leaveday),2);

        // let ConvAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["ConvPerc"])) / 100;
        // let perDayConvAmount = ConvAmount / parseInt(data[index]["MonthDays"]);
        // data[index]["ConvAmt"] = Math.round(ConvAmount - (perDayConvAmount * leaveday),2);


        let basicAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["DAPerc"])) / 100;
        let perDayBasicAmount = basicAmount / parseInt(data[index]["MonthDays"]);
        data[index]["BasicAmt"] =Math.round(basicAmount - (perDayBasicAmount * leaveday),2);

        let ConvFixAmt=data[index]["ConvFixAmt"] 
        if(!ConvFixAmt)
        {
          ConvFixAmt=0;
        }
        ConvFixAmt=parseFloat(ConvFixAmt);
        ConvFixAmt=(ConvFixAmt/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ;
        let BasicAmt=  parseFloat((data[index]["BasicAmt"]))
        data[index]["ConvAmt"] =(BasicAmt*(ConvFixAmt*100/BasicAmt))/100;


        let CCAAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["CCAPerc"])) / 100;
        let perDayCCAAmount = CCAAmount / parseInt(data[index]["MonthDays"]);
        data[index]["CCAAmt"] = Math.round(CCAAmount - (perDayCCAAmount * leaveday),2);
        //debugger
        if (parseFloat(data[index]["BasicAmt"]) > 15001) {
          if (data[index]["IsPfApplicable"] ) {

            //let pfAmount=  (parseFloat(data[index]["BasicAmt"]) * parseFloat(data[index]["PFPerc"]))/100; 
            data[index]["PFAmt"] = 1800;
            data[index]["EPFAmt"] = 1800;
          }
         
          else {
            data[index]["PFAmt"] = 0;
            data[index]["EPFAmt"] = 0;
          }  
        }
        else if (parseFloat(data[index]["BasicAmt"])  <= 15000) {
          if (data[index]["IsPfApplicable"] )  {

            let pfAmount = (parseFloat(data[index]["BasicAmt"]) * parseFloat(data[index]["PFPerc"])) / 100;
            data[index]["PFAmt"] = Math.round(pfAmount,2);
            let EPFAmt = (parseFloat(data[index]["BasicAmt"]) * parseFloat(data[index]["EPFPerc"])) / 100; 
            data[index]["EPFAmt"] = Math.round(EPFAmt,2);
          }
          else {
            data[index]["PFAmt"] = 0;
            data[index]["EPFAmt"] = 0;
          }    
        }

        if (
          parseFloat((data[index]["BasicAmt"]) 
          +
          parseFloat((data[index]["HRAAmt"]))
          +
          parseFloat((data[index]["MedicalAllowance"]))
          +
          parseFloat((data[index]["ConvAmt"]))
          +
          parseFloat((data[index]["ChildAllowance"]))
          +
          parseFloat((data[index]["LTAAmt"]))
          +
          parseFloat(data[index]["OvertimeAmt"]) 
          +
          parseFloat(data[index]["CCAAmt"])

        ) < 21000) {
          let ESIAmt = (parseFloat(data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(data[index]["ESIPerc"]) / 100;
          data[index]["ESIAmt"] = Math.round(ESIAmt, 2);
          

          //let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + parseFloat(data[index]["OvertimeAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(data[index]["EESIPerc"]) / 100;
          let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) +  parseFloat(data[index]["CCAAmt"])) * parseFloat(data[index]["EESIPerc"]) / 100;
          data[index]["EESIAmt"] = Math.round(EESIAmt, 2);

        }
        else {
          data[index]["ESIAmt"] = 0;
          
          data[index]["EESIAmt"] = 0;
        }
        if (parseFloat((data[index]["BasicAmt"])) > 15000) {
          let EPFAmt = (parseFloat(data[index]["BasicAmt"]) * parseFloat(data[index]["EPFPerc"])) / 100;

          data[index]["EPFAmt"] = 0;
          if (data[index]["IsPfApplicable"] ) {
            data[index]["EPFAmt"] = Math.round(EPFAmt, 2);
          }

          let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + parseFloat(data[index]["OvertimeAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(data[index]["EESIPerc"]) / 100;
          data[index]["EESIAmt"] = Math.round(EESIAmt, 2);
        }
        
        

        let ChildFixAmt=data[index]["ChildFixAmt"];//parseFloat(ChildFixAmt);
        ChildFixAmt=parseFloat(ChildFixAmt);
        if(!ChildFixAmt)
        {
          ChildFixAmt=0;
        }
        ChildFixAmt=(ChildFixAmt/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ; 
        data[index]["ChildAllowance"] =(BasicAmt*(ChildFixAmt*100/BasicAmt))/100;

        let MedFixAmt=data[index]["MedFixAmt"] 
        MedFixAmt=parseFloat(MedFixAmt);
        if(!MedFixAmt)
        {
          MedFixAmt=0;
        }
        MedFixAmt=(MedFixAmt/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ; 
        data[index]["MedicalAllowance"] =(BasicAmt*(MedFixAmt*100/BasicAmt))/100;

        let LTAPerc=data[index]["LTAPerc"]  
        LTAPerc=parseFloat(LTAPerc);
        if(!LTAPerc)
        {
          LTAPerc=0;
        }
        data[index]["LTAAmt"] =(BasicAmt*LTAPerc)/100;
        

        let BasicSalary=data[index]["BasicSalary"] 
        let SpecialAllowance=(BasicSalary/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ; 
        data[index]["SpecialAllowance"]=parseFloat(SpecialAllowance)-(
          BasicAmt
          +
          parseFloat((data[index]["HRAAmt"]))
          +
          parseFloat((data[index]["MedicalAllowance"]))
          +
          parseFloat((data[index]["ConvAmt"]))
          +
          parseFloat((data[index]["ChildAllowance"]))
          +
          parseFloat((data[index]["LTAAmt"]))

        )

          if(!parseFloat(data[index]["CCAAmt"]))
          {
            data[index]["CCAAmt"]=0;
          }
        
        data[index]["TotalGrossSalAmt"] = parseFloat(data[index]["BasicAmt"])  
        +parseFloat((data[index]["HRAAmt"]))
        +
        parseFloat((data[index]["MedicalAllowance"]))
        +
        parseFloat((data[index]["ConvAmt"]))
        +
        parseFloat((data[index]["ChildAllowance"]))
        +
        parseFloat((data[index]["LTAAmt"]))
        +
        parseFloat(data[index]["OvertimeAmt"]) 
        +
        parseFloat(data[index]["CCAAmt"])
        +
        parseFloat((data[index]["SpecialAllowance"])) ;
        data[index]["LeaveDeductAmt"] =
          (
            (parseFloat(data[index]["TotalGrossSalAmt"]) - parseFloat(data[index]["OvertimeAmt"]))
            / (parseInt(data[index]["MonthDays"]))) * parseInt(data[index]["LeaveDays"]);

        data[index]["LeaveDeductAmt"] = 0
        if (data[index]["IsAbry"])
        {
          data[index]["TotalDeductAmt"] = parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"])
        }
        else
        {
          data[index]["TotalDeductAmt"] = parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + + parseFloat(data[index]["PFAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"])
          
        }
        
        data[index]["NetSalary"] = parseFloat(data[index]["TotalGrossSalAmt"]) - parseFloat(data[index]["TotalDeductAmt"]);
      }
      else {
        let hraAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["HRAPerc"])) / 100;
        data[index]["HRAAmt"] = Math.round(hraAmount,2);
        let leaveday=0;

        let ConvAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["ConvPerc"])) / 100;
        data[index]["ConvAmt"] = Math.round(ConvAmount,2);
        let CCAAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["CCAPerc"])) / 100;
        let perDayCCAAmount = Math.round(CCAAmount,2);
        data[index]["CCAAmt"] = Math.round(CCAAmount)// - (perDayCCAAmount * parseInt(data[index]["LeaveDays"])),2);

        let basicAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(data[index]["DAPerc"])) / 100;
        data[index]["BasicAmt"] = Math.round(basicAmount,2);

        let ConvFixAmt=data[index]["ConvFixAmt"] 
        if(!ConvFixAmt)
        {
          ConvFixAmt=0;
        }
        ConvFixAmt=parseFloat(ConvFixAmt);
        ConvFixAmt=(ConvFixAmt/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ;
        let BasicAmt=  parseFloat((data[index]["BasicAmt"]))
        data[index]["ConvAmt"] =(BasicAmt*(ConvFixAmt*100/BasicAmt))/100;

        
        //debugger
        if (parseFloat(data[index]["BasicAmt"]) > 15001) {
          if (data[index]["IsPfApplicable"] ) {

            //let pfAmount=  (parseFloat(data[index]["BasicAmt"]) * parseFloat(data[index]["PFPerc"]))/100; 
            data[index]["PFAmt"] = 1800;
            data[index]["EPFAmt"] = 1800;
          }
         
          else {
            
           data[index]["PFAmt"] = 0;
           data[index]["EPFAmt"] = 0;
          }
        }
        else if (parseFloat(data[index]["BasicAmt"])  <= 15000) {
          if (data[index]["IsPfApplicable"]) { 
            let pfAmount = (parseFloat(data[index]["BasicAmt"]) * parseFloat(data[index]["PFPerc"])) / 100;
            let EPFAmt = (parseFloat(data[index]["BasicAmt"]) * parseFloat(data[index]["EPFPerc"])) / 100;
            data[index]["PFAmt"] = Math.round(pfAmount,2);
            data[index]["EPFAmt"] = Math.round(EPFAmt, 2); 
          }
        }
        if (
                  parseFloat((data[index]["BasicAmt"]) 
                  +
                  parseFloat((data[index]["HRAAmt"]))
                  +
                  parseFloat((data[index]["MedicalAllowance"]))
                  +
                  parseFloat((data[index]["ConvAmt"]))
                  +
                  parseFloat((data[index]["ChildAllowance"]))
                  +
                  parseFloat((data[index]["LTAAmt"]))
                  +
                  parseFloat(data[index]["OvertimeAmt"]) 
                  +
                  parseFloat(data[index]["CCAAmt"])) < 21000) {
          let ESIAmt = (parseFloat(data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(data[index]["ESIPerc"]) / 100;
          data[index]["ESIAmt"] = Math.round(ESIAmt, 2);
          let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(data[index]["EESIPerc"]) / 100;
          data[index]["EESIAmt"] = Math.round(EESIAmt, 2); 
        }
        else {
          data[index]["ESIAmt"] = 0; 
          data[index]["EESIAmt"] = 0;
        }

        let ChildFixAmt=data[index]["ChildFixAmt"];//parseFloat(ChildFixAmt);
        ChildFixAmt=parseFloat(ChildFixAmt);
        if(!ChildFixAmt)
        {
          ChildFixAmt=0;
        }
        ChildFixAmt=(ChildFixAmt/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ; 
        data[index]["ChildAllowance"] =(BasicAmt*(ChildFixAmt*100/BasicAmt))/100;

        let MedFixAmt=data[index]["MedFixAmt"] 
        MedFixAmt=parseFloat(MedFixAmt);
        if(!MedFixAmt)
        {
          MedFixAmt=0;
        }
        MedFixAmt=(MedFixAmt/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ; 
        data[index]["MedicalAllowance"] =(BasicAmt*(MedFixAmt*100/BasicAmt))/100;

        let LTAPerc=data[index]["LTAPerc"]  
        LTAPerc=parseFloat(LTAPerc);
        if(!LTAPerc)
        {
          LTAPerc=0;
        }
        
        data[index]["LTAAmt"] =(BasicAmt*LTAPerc)/100;


        let BasicSalary=data[index]["BasicSalary"] 
        let SpecialAllowance=(BasicSalary/parseInt(data[index]["MonthDays"]))*(parseInt(data[index]["MonthDays"])-parseInt(leaveday)) ; 
        data[index]["SpecialAllowance"]=parseFloat(SpecialAllowance)-(
          BasicAmt
          +
          parseFloat((data[index]["HRAAmt"]))
          +
          parseFloat((data[index]["MedicalAllowance"]))
          +
          parseFloat((data[index]["ConvAmt"]))
          +
          parseFloat((data[index]["ChildAllowance"]))
          +
          parseFloat((data[index]["LTAAmt"]))

        )
        
          if(!parseFloat(data[index]["CCAAmt"]))
          {
            data[index]["CCAAmt"]=0;
          }

          data[index]["TotalGrossSalAmt"] = parseFloat(data[index]["BasicAmt"])  
          +parseFloat((data[index]["HRAAmt"]))
          +
          parseFloat((data[index]["MedicalAllowance"]))
          +
          parseFloat((data[index]["ConvAmt"]))
          +
          parseFloat((data[index]["ChildAllowance"]))
          +
          parseFloat((data[index]["SpecialAllowance"]))
          + 
          parseFloat((data[index]["LTAAmt"]))
          + 
          parseFloat(data[index]["OvertimeAmt"]) 
          + 
          parseFloat(data[index]["CCAAmt"]) ;
            
          data[index]["LeaveDeductAmt"] =
            (
              (parseFloat(data[index]["TotalGrossSalAmt"]) - parseFloat(data[index]["OvertimeAmt"]))
              / (parseInt(data[index]["MonthDays"]))) * parseInt(data[index]["LeaveDays"]);
  
          data[index]["LeaveDeductAmt"] = 0
          if (data[index]["IsAbry"])
          {
            data[index]["TotalDeductAmt"] = parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"])
          }
          else
          {
            data[index]["TotalDeductAmt"] = parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + + parseFloat(data[index]["PFAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"])
            
          }
          
          data[index]["NetSalary"] = parseFloat(data[index]["TotalGrossSalAmt"]) - parseFloat(data[index]["TotalDeductAmt"]);
        
      }

      setSalaryData(data);
      
    }

  }

  const handleSubmit = async (formdata) => {
    //debugger
    //     console.log(formdata);
    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    if (salaryYear === '') {
      notify("Please Select Salary Year", "warning")
      return;
    }
    if (salaryMonth === '') {
      notify("Please Select Salary Year", "warning")
      return;
    }
    if (salaryData.length === 0) {
      notify("Please load Salary ", "warning")
      return;
    }
    let data = {};
    data.headerData = [{ id: id, monthnumber: salaryMonth, year: salaryYear, "createdby": user, "createddate": "2021-07-16", "modifiedby": 1, "modifieddate": "2021-07-16" }]
    data.salaryData = [...salaryData];

    apiClient.createupdatesalary(data).then(res => {
      if (res[0].result) {
        notify(res[0].message)
        redirect("employee-sal");
      }
      else {
        notify(res[0].message)
      }


    }).catch(err => {
      notify("unable to add Invoice", "warning")
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      { isDataLoading === false && <Create title="Employee Salary Edit " { ...props }>
        <SimpleForm variant="outlined" redirect="list" toolbar={ <CreateToolbar { ...props } onSave={ handleSubmit } /> } onSubmit={ handleSubmit } >
          <div style={ { fontSize: "20px" } } >Edit Employee Salary<br /></div>
          <SelectInput source="salaryYear" label="Salary Year" validate={ [required()] } fullWidth={ true } formClassName={ classes.one_4_input } choices={ [{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }] } onChange={ (event) => handleChange(event, "salaryYear") } disabled initialValue={ salaryYear.toString() } />
          <SelectInput source="salaryMonth" label="Salary Month" validate={ [required()] } fullWidth={ true } formClassName={ classes.two_4_input } choices={ [{ id: '1', name: 'Jan' }, { id: '2', name: 'Feb' }, { id: '3', name: 'March' }, { id: '4', name: 'April' }, { id: '5', name: 'May' }, { id: '6', name: 'June' }, { id: '7', name: 'July' }, { id: '8', name: 'August' }, { id: '9', name: 'Sept' }, { id: '10', name: 'Oct' }, { id: '11', name: 'Nov' }, { id: '12', name: 'Dec' }] } onChange={ (event) => handleChange(event, "salaryMonth") } disabled initialValue={ salaryMonth.toString() } />
          <div style={ { fontSize: "19px", marginTop: "29px" } } >Salary Detail<br /></div>
          { salaryData && salaryData.length > 0 && <div style={ { width: "100%", overflow: "scroll" } }>
            <Table className={ classes.customtable1 } size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell >   </TableCell>
                  <TableCell >Employee </TableCell>
                <TableCell>Month Days</TableCell>
                <TableCell>Working Days</TableCell>
                <TableCell>Present Days</TableCell>
                <TableCell>Leave Days</TableCell>
                <TableCell>Sal Calc Days </TableCell>
                <TableCell>Gross Salary</TableCell>
                <TableCell>Basic (+) </TableCell>
                <TableCell>HRA (+) </TableCell>
                <TableCell>Child Allw </TableCell>
                <TableCell>Conv. Allw (+) </TableCell>
                <TableCell>Med. Allw. </TableCell>
                  <TableCell>Spcl Allw </TableCell>
                  <TableCell>LTA </TableCell>
                  
                {/* <TableCell>CCA (+) </TableCell> */}
                <TableCell>Overtime Amt (+) </TableCell>
                <TableCell>Gross Amt </TableCell>
                <TableCell>Proff. Tax Amt(-) </TableCell>
                <TableCell>PF Amt(-) </TableCell>
                <TableCell>ESI Amt(-)</TableCell>
                <TableCell>Adv. Amt(-)</TableCell>
                <TableCell>Leave Ded Amt </TableCell>
                <TableCell>TDS Amt </TableCell>
                <TableCell>Tot. Ded.</TableCell>
                <TableCell>Tot. Pay Amt.</TableCell>
                <TableCell>EEPF Amt.</TableCell>
                <TableCell>EESI AMT.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                { salaryData.map((item, index) => {
                  return <tr>
                    <TableCell ><button type="button" onClick={ (event) => showPopup(item) }>Print</button> </TableCell>
                    <TableCell >{item.EmployeeName} </TableCell>
                  <TableCell>{item.MonthDays}</TableCell>
                  <TableCell>{item.WorkingDays}</TableCell>
                  <TableCell>{item.PresentDays}</TableCell>
                  <TableCell><input type="number" style={{ width: "63px", textAlign: "right" }} value={item.LeaveDays} onChange={(event) => handleChange(event, "LeaveDays", index)} ></input></TableCell>
                  <TableCell>{parseInt(item.LeaveDays) > parseInt(headerData["LeaveGraceDays"]) ? parseInt(item.MonthDays) - (parseInt(item.LeaveDays) - parseInt(headerData["LeaveGraceDays"])) : parseInt(item.MonthDays)}</TableCell>
                  <TableCell>{parseFloat(item.BasicSalary).toFixed(2)} </TableCell>
                  <TableCell>{parseFloat(item.BasicAmt).toFixed(2)} </TableCell>
                  <TableCell>{parseFloat(item.HRAAmt).toFixed(2)}</TableCell>
                  <TableCell>{parseFloat(item.ChildAllowance).toFixed(2)}</TableCell>                  
                  <TableCell>{parseFloat(item.ConvAmt).toFixed(2)} </TableCell>
                  <TableCell >{item.MedicalAllowance?parseFloat(item. MedicalAllowance).toFixed(2):"0.00" }</TableCell>
                  <TableCell >{item.SpecialAllowance?parseFloat(item. SpecialAllowance).toFixed(2):"0.00" }</TableCell>
                  <TableCell >{item.LTAAmt?parseFloat(item.LTAAmt).toFixed(2):"0.00" }</TableCell>
                  
                  
                  {/* <TableCell>{parseFloat(item.CCAAmt).toFixed(2)} </TableCell> */}
                    <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "OvertimeAmt", index)} value={parseFloat(item.OvertimeAmt)}></input>  </TableCell>
                  <TableCell>{parseFloat(item.TotalGrossSalAmt).toFixed(2)} </TableCell>
                  <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "ProfTaxAmt", index)} value={parseFloat(item.ProfTaxAmt) }></input>  </TableCell>
                  <TableCell>{parseFloat(item.PFAmt).toFixed(2)} </TableCell>
                  <TableCell>{parseFloat(item.ESIAmt).toFixed(2)}</TableCell>
                  <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "AdvAmt", index)} value={parseFloat(item.AdvAmt) }></input>  </TableCell>
                  <TableCell>{parseFloat(item.LeaveDeductAmt).toFixed(2)}</TableCell>
                  <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "TdsAmt", index)} value={parseFloat(item.TdsAmt)}></input>  </TableCell>
                  <TableCell>{parseFloat(item.TotalDeductAmt).toFixed(2)}</TableCell>
                  <TableCell>{parseFloat(item.NetSalary).toFixed(2)}</TableCell>
                  <TableCell>{parseFloat(item.EPFAmt).toFixed(2)}</TableCell>
                  <TableCell>{item.EESIAmt?parseFloat(item.EESIAmt).toFixed(2):0.00}</TableCell>
                  </tr>
                }) }
              </TableBody>
            </Table>

          </div>
          }

          { salaryData && salaryData.length > 0 &&

            <div className="salarySummary">
              <div className="salaryContainer">
                <div className="salaryLabel">
                  Total Gross:
                </div>
                <div className="salaryValue">
                  { parseFloat((salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.TotalGrossSalAmt); }, 0))).toFixed(2) }

                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  Total Deduction :
                </div>
                <div className="salaryValue">

                  { parseFloat(salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.TotalDeductAmt); }, 0)).toFixed(2) }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  Net Salary:
                </div>
                <div className="salaryValue">

                  { parseFloat(salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.NetSalary); }, 0)).toFixed(2) }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  Emp Contribution :
                </div>
                <div className="salaryValue">
                  { parseFloat(salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.EESIAmt); }, 0)).toFixed(2) }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  DA %:
                </div>
                <div className="salaryValue">
                  { headerData["DAPerc"] }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  Conv. %:
                </div>

                <div className="salaryValue">
                  { headerData["ConvPerc"] }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  CCA Per. %:
                </div>

                <div className="salaryValue">
                  { headerData["CCAPerc"] }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  PF %:
                </div>
                <div className="salaryValue">
                  { headerData["PFPerc"] }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  ESI %:
                </div>
                <div className="salaryValue">
                  { headerData["ESIPerc"] }
                </div>
              </div>


              <div className="salaryContainer">
                <div className="salaryLabel">
                  HRA % :
                </div>
                <div className="salaryValue">
                  { headerData["HRAPerc"] }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  EPF % :
                </div>
                <div className="salaryValue">
                  { headerData["EpfPerc"] }
                </div>
              </div>
              <div className="salaryContainer">
                <div className="salaryLabel">
                  EESI % :
                </div>
                <div className="salaryValue">
                  { headerData["EEsiPerc"] }
                </div>
              </div>
            </div>


          }
          <Button
            className="send-email-button"
            variant="contained" color="primary"
            label="Send Email" size="medium"
            startIcon={ <EmailRoundedIcon /> }
            onClick={ () => {
              sendEmailToEmployees();
            } }
          />
        </SimpleForm>

      </Create> }
      { isDataLoading === true && <CircularProgress /> }
      <Drawer
        anchor="right"
        onClose={ onCancel }
        classes={ { paper: myclasses.drawerPaper, } }
        open={ isPrint }>
        { isPrint && <InvoicePrint
          onClose={ onCancel }
          onCancel={ onCancel }
          invoiceId={ printId }
          isOpen={ isPrint }
          { ...props } /> }

      </Drawer>
    </React.Fragment>
  );
};
