import React, { useCallback, useState } from "react";
import {
  Edit,
  SimpleForm, TabbedForm, FormTab,
  TextInput, Datagrid, TextField,
  NumberInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required, useRefresh,
  BooleanInput, ReferenceManyField, ReferenceField,AutocompleteInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { Divider, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import EmployeeDocumentEdit from "./docs/EmployeeDocumentEdit";
import EmployeeDocumentsCreate from "./docs/EmployeeDocumentsCreate";
import { Authorizer } from "../../common/Authorizer";

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 750,
  },
  drawerPaper1500: {
    zIndex: 100,
    marginTop: 50,
    width: 1100,
  },
}));

const SecondaryButton = (props) => {
  const { caption, onClick } = props
  return (
    <div style={{ flex: 1 }}>
      <Button variant="contained" color="secondary" style={{ marginRight: "10" }} onClick={onClick}>{caption}</Button>
    </div>
  )
}

const DisplayFileLink = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <a href={record[source]}>View File</a>
    )
  else {
    return null
  }
}

const SectionHeading = (props) => {
  const { caption } = props;
  return (
    <div className="heading">
      <h3>{caption}</h3>
    </div>
  )
}
const EditButton = (props) => {
  let { record } = props;
  return (
    <div className='edit-button' onClick={(e) => {
      props.onSelectRecord(record);
    }}>Edit</div>
  )
}

export const EmployeeEdit = (props) => {
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const [selectedDocumentsId, setSelectedDocumentsId] = useState<any[]>([]);
  const [isEditDocuments, setIsEditDocuments] = useState(false);
  const [documentRecord, setDocumentRecord] = useState(0);

  const propsObj = Authorizer(props);
  
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }

  const SelectDeselectDocument = (record) => {
    var ids: any[] = [...selectedDocumentsId];
    const id: any = record.id;
    const index = ids.indexOf(id);
    if (index > -1) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }
    setSelectedDocumentsId(ids);
  }

  const onCloseDocumentForm = () => {
    setIsAddDocuments(false);
    setIsEditDocuments(false);
    refresh();
  }

  const onCancel = () => {
    setIsAddDocuments(false);
    setIsEditDocuments(false);
  }

  const handleClose = useCallback(() => {
    setIsAddDocuments(false);
    setIsEditDocuments(false);
  }, []);
  return (
    <React.Fragment>
      <Edit title="Employee Edit" {...propsObj}>
        <TabbedForm toolbar={<FormToolbar {...props} />} variant="outlined">
          <FormTab label="Basic Details">
            <TextInput source="code" disabled={false} fullWidth={true} validate={[required()]} />
            <TextInput source="name" fullWidth={true} validate={[required()]} />
            <TextInput source="email" fullWidth={true} validate={[required()]} />
            <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} validate={[required()]} sort={{ field: 'userName', order: 'ASC' }}>
              <SelectInput optionText="userName" />
            </ReferenceInput>
            <ReferenceInput label="Type" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}
              perPage={5000} source="typeId"
              filter={{ type: 'ET' }}
              reference="lookups" >
              <SelectInput optionText="name" variant="outlined" />
            </ReferenceInput>
            <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
            <TextInput source="designation" label="Designation" fullWidth={true} />
            <ReferenceInput perPage={2000} label="Department" source="departmentId" reference="departments" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="pan" label="PAN" fullWidth={true} />
            <TextInput source="adharNo" label="Adhaar No" fullWidth={true} />
            <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true} />
            <TextInput source="bankName" label="Bank Name" fullWidth={true} />
            <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
            <TextInput source="uan" label="UAN" fullWidth={true} />

            <DateInput source="dol" label="Date of Leaving" fullWidth={true} />
            <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true} />
                    <NumberInput source="childFixAmt" label="Child Allowance" fullWidth={true} /> 
                    <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} />
                    <NumberInput source="basicPerc" label="Basic Percent" fullWidth={true} />                    
                    <NumberInput source="hraPerc" label="HRA Percent" fullWidth={true} />                    
                    <NumberInput source="convPerc" label="Conv Percent" fullWidth={true} />                    
                    <NumberInput source="convFixAmt" label="Conv Fix Amount" fullWidth={true} />                    
                    <NumberInput source="medFixAmt" label="Med Fix Amount" fullWidth={true} />                    
                    <NumberInput source="ltaPerc" label="LTA Percent" fullWidth={true} />

            <BooleanInput source="isAbry" label="is Abry" fullWidth={true} validate={[required()]} />
            <BooleanInput source="isPf" label="Is Pf" fullWidth={true} validate={[required()]} />
            <ReferenceInput perPage={2000} label="Reportee" source="reportingId" reference="employees" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}>
                        {/* <SelectInput optionText="name" /> */}
                        <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>
            <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} />
          </FormTab>
          <FormTab label="Documents">
            <SecondaryButton formClassName="secondary-action-btn" caption="Add Document" onClick={() => setIsAddDocuments(true)} />
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="employee-documents"
              target="employeeId">
              <Datagrid>
                {isAdmin && <CDeleteButton {...props} cellClassName="delete-column" />}
                {<EditButton label="Modify" onSelectRecord={(record) => {
                   setIsEditDocuments(true);
                   setDocumentRecord(record);
                }} />}
                <ReferenceField label="Document Type" source="documentTypeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <DisplayFileLink label="Document URL" source="docUrl" />
                <TextField label="Remarks" source="remarks" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper }}
        open={isaddDocuments || isEditDocuments} >

        {isEditDocuments && <EmployeeDocumentEdit
          onClose={() => { setIsEditDocuments(false); refresh(); }}
          onCancel={onCancel}
          employeeId={props.id}
          record={documentRecord}
          {...props} />}

        {isaddDocuments && <EmployeeDocumentsCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          employeeId={props.id}
          {...props} />}

      </Drawer>
    </React.Fragment>
  );
};
