import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  FormDataConsumer,
  Edit, TabbedForm, TextInput, DateInput, ReferenceInput, AutocompleteInput,
  SelectInput, required, BooleanInput, FormTab, useRefresh, 
  ReferenceManyField, ReferenceField, Datagrid, NumberInput, TextField,
  DateField
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContactEdit from "./ContactEdit";
import CDeleteButton from "../../common/CDeleteButton";
import ContactCreate from "./ContactCreate";
import { Addressfields } from "./Addressfields";
import NomineeCreate from './NomineeCreate';
import NomineeEdit from './NomineeEdit';
import DocumentsCreate from './DocumentsCreate';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));


export const userStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: 100,
  },
}));

// export const EditButton = props => {

//   const setHospitalDetId = () => {
//       props.setHospitalDetEdit(props.record.id);
//   }
//   return (
//       <Button
//           color="secondary" disableElevation
//           size="medium"

//           onClick={setHospitalDetId}
//           startIcon={<EditIcon />}>Edit</Button>
//   )
// };
const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={ record.docUrl }>View File</a>
    )
  else {
    return null
  }
}
export const CustomerEdit = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const classes = useStyles({});
  const [isaddContact, setIsAddContact] = useState(false);
  const [isaddNominee, setIsAddNominee] = useState(false);



  const [isEditContact, setIsEditContact] = useState(false);
  const [isEditNominee, setIsEditNominee] = useState(false);
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(0);
  const [isaddDocument, setIsaddDocument] = useState(false);

  const myclasses = myStyles({});
  const refresh = useRefresh();


  const EditButton = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={ () => {
        props.onClick(record);
      } }
      startIcon={ <EditIcon /> }>Edit</Button>

  }
  const closeEdit = () => {
    setIsAddContact(false);
    setIsEditContact(false);
    setIsAddNominee(false);
    setIsEditNominee(false);
    setIsaddDocument(false);
    refresh();
  }


  const onCancel = () => {
    setIsAddContact(false);
    setIsEditContact(false);
    setIsAddNominee(false);
    setIsEditNominee(false);
    setIsaddDocument(false);
  }

  const handleClose = useCallback(() => {
    setIsAddContact(false);
    setIsEditContact(false);
    setIsAddNominee(false);
    setIsEditNominee(false);
    setIsaddDocument(false);
  }, []);

  return (
    <React.Fragment>
      <Edit title="Customer Edit" { ...propsObj }>

        <TabbedForm variant="outlined" redirect="list" toolbar={ <CreateToolbar { ...props } /> }>
          <FormTab value="DETAILS" label="Customer Detail">
            <ReferenceInput perPage={ 2000 } fullWidth={ true } label="Customer Type" source="entryTypeId" reference="entry-types" formClassName={ classes.one_three_input } sort={ { field: 'name', order: 'ASC' } } >
              <SelectInput optionText="name" validate={ [required()] } />
            </ReferenceInput>

            <TextInput source="name" label="Name" fullWidth={ true } validate={ [required()] } formClassName={ classes.two_three_input } />
            <TextInput source="shortName" label="Short Name" fullWidth={ true } validate={ [required()] } formClassName={ classes.last_three_input } />



            <TextInput source="gender" label="Gender" fullWidth={ true } formClassName={ classes.one_three_input } />
            <DateInput source="dob" label="Dob" fullWidth={ true } formClassName={ classes.two_three_input } />
            <TextInput source="pin" label="Pincode" fullWidth={ true } formClassName={ classes.last_three_input } />
            <TextInput multiline source="address1" fullWidth={ true } label="Address 1" formClassName={ classes.one_three_input } />
            <TextInput multiline source="address2" fullWidth={ true } label="Address 2" formClassName={ classes.two_three_input } />
            <TextInput source="phone" label="Phone" fullWidth={ true } formClassName={ classes.last_three_input } />



            <FormDataConsumer fullWidth={ true } >
              { formDataProps => (
                <Addressfields { ...formDataProps } />
              ) }
            </FormDataConsumer>
            <TextInput source="mobile" label="Mobile" fullWidth={ true } formClassName={ classes.one_three_input } />
            <TextInput source="email" label="Email" fullWidth={ true } formClassName={ classes.two_three_input } />


            <TextInput source="pan" label="Pan" fullWidth={ true } formClassName={ classes.last_three_input } />



            <ReferenceInput perPage={ 2000 } label="Industry Type" fullWidth={ true }
              source="industryTypeId" reference="industry-types" formClassName={ classes.one_three_input }
              sort={ { field: 'name', order: 'ASC' } } >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput perPage={ 2000 } label="Industry Class" fullWidth={ true }
              source="industryClassId" reference="industry-classes" formClassName={ classes.two_three_input }
            >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput label="RM" source="rmid" reference="employees" fullWidth={ true } formClassName={ classes.last_three_input } sort={ { field: 'name', order: 'ASC' } } >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput label="TC" source="tcid" reference="employees" fullWidth={ true } formClassName={ classes.one_three_input } sort={ { field: 'name', order: 'ASC' } } >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="CSC" source="cscid" reference="employees" fullWidth={ true } formClassName={ classes.two_three_input } sort={ { field: 'name', order: 'ASC' } } >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput label="Ref Customer" source="refCustomerId" reference="customers" fullWidth={ true } formClassName={ classes.last_three_input } sort={ { field: 'name', order: 'ASC' } } >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput source="gstNo" label="Gst No" fullWidth={ true } formClassName={ classes.one_three_input } />
            <TextInput source="adhaarNo" label="Adhaar No" fullWidth={ true } formClassName={ classes.two_three_input } />

            <BooleanInput source="isMultiBranch" label="Is Multi Branch" initialValue={ true } formClassName={ classes.last_three_input } />
            <BooleanInput source="isActive" label="Is Active" initialValue={ true } formClassName={ classes.one_three_input } />

            <ReferenceInput perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
              filterToQuery={ searchText => ({ 'name~like': searchText }) }
              label="Customer Group" source="custGroupId" reference="cust-groups" fullWidth={ true }
            >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
            </ReferenceInput>

          </FormTab>
          <FormTab label="Contacts ">
            <Button variant="contained" onClick={ () => { setIsAddContact(true); } }
              color="secondary">
              Add Contact
            </Button>
            <ReferenceManyField fullWidth={ true }
              addLabel={ false }
              sort={ { field: 'id', order: 'ASC' } }
              reference="customer-contacts"
              target="custId">
              <Datagrid>
                <VSDeleteButton { ...props } />
                <EditButton onClick={ (record) => { setIsEditDocumentRecord(record); setIsEditContact(true); } } />
                <TextField fullWidth={ true } source="name" label="Name" />
                <TextField fullWidth={ true } source="shortName" />
                <DateField fullWidth={ true } source="dob" locales="fr-FR" />
                <TextField fullWidth={ true } source="designations" />
                <TextField fullWidth={ true } source="phone" />
                <TextField fullWidth={ true } source="mobile" />
                <TextField fullWidth={ true } source="email" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab label="Nominee ">
            <Button variant="contained" onClick={ () => { setIsAddNominee(true); } }
              color="secondary">
              Add Nominee
            </Button>
            <ReferenceManyField fullWidth={ true }
              addLabel={ false }
              sort={ { field: 'id', order: 'ASC' } }
              reference="customer-nominees"
              target="custId">
              <Datagrid>
                <VSDeleteButton { ...props } />
                <EditButton onClick={ (record) => { setIsEditDocumentRecord(record); setIsEditNominee(true); } } />
                <TextField fullWidth={ true } source="name" label="Name" />
                <TextField fullWidth={ true } source="shortName" />
                <DateField fullWidth={ true } source="dob" locales="fr-FR" />
                <TextField fullWidth={ true } source="phone" />
                <TextField fullWidth={ true } source="mobile" />
                <TextField fullWidth={ true } source="email" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Documents">
            <div style={ { flex: 1 } }>
              <Button style={ { marginRight: "10" } } onClick={ () => { setIsaddDocument(true); } }>Add  Document</Button>

            </div>
            <ReferenceManyField fullWidth={ true }
              addLabel={ false }
              sort={ { field: 'id', order: 'ASC' } }
              reference="customer-docs"
              target="customerId">
              <Datagrid>
                { <CDeleteButton { ...props } /> }

                <ReferenceField label="Document Type" source="documentTypeId"

                  reference="document-types" link={ false }>
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Document Type" source="typeId"
                  filter={ { type: 'DT' } }
                  reference="lookups" link={ false }>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Document No" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={ true } label="Entry Date" source="createdDate" locales="fr-FR" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>

        </TabbedForm>


      </Edit>
      <Drawer
        anchor="right"
        onClose={ handleClose }
        classes={ { paper: myclasses.drawerPaper, } }
        open={ isaddContact || isEditContact || isaddNominee || isEditNominee || isaddDocument } >
        { isaddContact && <ContactCreate
          onClose={ closeEdit }
          onCancel={ onCancel }
          custId={ props.id }
          { ...props } /> }
        { isEditContact && <ContactEdit
          onClose={ closeEdit }
          onCancel={ onCancel }
          record={ isEditDocumentRecord }
          { ...props } /> }
        { isaddNominee && <NomineeCreate
          onClose={ closeEdit }
          onCancel={ onCancel }
          custId={ props.id }
          { ...props } /> }
        { isEditNominee && <NomineeEdit
          onClose={ closeEdit }
          onCancel={ onCancel }
          record={ isEditDocumentRecord }
          { ...props } /> }
        { isaddDocument && <DocumentsCreate
          onClose={ closeEdit }
          onCancel={ onCancel }
          custId={ props.id }
          { ...props } /> }
      </Drawer>

    </React.Fragment>
  );
};
