import React, { useState, useEffect, useRef } from 'react';
import * as apiClient from "../../common/apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const OtherFields = ({ formData, ...rest }) => {
    const form = useForm();

    const scrollEndRef = useRef(null);
    const classes = useStyles({});




    const loadTaxHead = (id) => {
        debugger
        
        apiClient.getTaxHead(id).then(res => {

            form.change("taxPercentage", parseFloat(TwoDecimalPlaces(res.taxpc)));
            // calculateAmount(formData.odPremiumAmt, formData.tpPremiumAmt, res.taxpc);
        }).catch(err => {
            console.log(err);
        });
    }
    const TwoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).valueOf();
    }



    return (
        <div className="payment-field-parent">
            
            <div className={classes.one_three_input} >
                <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                    formClassName={classes.one_three_input}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    label="Insurer" source="insurerId" reference="insurers" fullWidth={true} >
                    <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                </ReferenceInput>
            </div>
            <div className={classes.two_three_input} >
                <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                    formClassName={classes.two_three_input}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    filter={{ "insurerId": formData.insurerId }}
                    label="Insurer Branch" source="insurerBranchId" reference="insurer-branches" fullWidth={true} >
                    <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                </ReferenceInput>
            </div>
            <div className={classes.last_three_input} >
                <ReferenceInput perPage={25} sort={{ field: 'taxName', order: 'ASC' }}
                    validate={[required()]} formClassName={classes.last_three_input}
                    fullWidth={true}
                    label="Tax Type" source="taxTypeId" reference="tax-heads"    
                    onChange={(e) => {
                        loadTaxHead(e);
                    }}
                    >
                    <AutocompleteInput optionText="taxName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                </ReferenceInput>
            </div>
            


        </div>
    )
}
export default OtherFields;