import React, { useCallback, useState, useEffect } from "react";

import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput, AutocompleteInput,
  SelectInput, DateField, Create,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import { Drawer } from '@material-ui/core';
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./components/StateDistrict"
import { useStyles } from "../../formStyles";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import CorporateCreate from './popup/CorporateCreate'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CustomerCreate from "./popup/CustomerCreate";
import CustomerGroupCreate from "./popup/CustomerGroupCreate";
import InsurerCreate from "./popup/InsurerCreate";
import InsurerBranchCreate from "./popup/InsurerBranchCreate";
import PolicyDepartmentCreate from "./popup/PolicyDepartmentCreate";
import PolicyTypeCreate from "./popup/PolicyTypeCreate";
import EmployeeCreate from "./popup/EmployeeCreate";
import * as apiClient from "../../common/apiClient";
import { useLocation } from "react-router-dom";
import {
  useHistory
} from "react-router-dom";
import queryString from 'query-string';
import MotorClassCreate from "./popup/MotorClassCreate";
import MotorClassVarient from "./popup/MotorClassVarient";
import MotorClassMake from "./popup/MotorClassMake";
import MotorClassFuel from "./popup/MotorClassFuel";
import MotorClassModel from "./popup/MotorClassModel";
import MotorClassDetails from "./popup/MotorClassDetails";
import { TaxFields } from "./components/TaxFields";
import RefCustomerCreate from "./popup/RefCustomerCreate";


const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,

  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 750,
  },
}));

const OuterComponent = (props) => {
  return (
    props.children
  )
}

export const LifeOrderCreate = (props) => {

  let location = useLocation();

  const [isAddCorportate, setIsAddCorportate] = useState(false);
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [isAddCustomerGroup, setIsAddCustomerGroup] = useState(false);
  const [isAddInsurer, setIsAddInsurer] = useState(false);
  const [isAddInsurerBranch, setIsAddInsurerBranch] = useState(false);
  const [isAddPolicyDepartment, setIsAddPolicyDepartment] = useState(false);
  const [isAddPolicyType, setIsAddPolicyType] = useState(false);
  const [isAddMotorClass, setIsAddMotorClass] = useState(false);
  const [isAddMotorVarient, setIsAddMotorVarient] = useState(false);
  const [isAddMotorModel, setIsAddMotorModel] = useState(false);
  const [isAddMotorMake, setIsAddMotorMake] = useState(false);
  const [isAddMotorFuel, setIsAddMotorFuel] = useState(false);
  const [isAddMotorDetails, setIsAddMotorDetails] = useState(false);

  const [isAddRefCustomer, setIsAddRefCustomer] = useState(false);
  const [isAddEmployee, setIsAddEmployee] = useState(false);
  const [isAutoMotor, setIsAutoMotor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setdefaultValues] = useState<any>({
    isRenewable: false,
    isOldPolicyNo: false,
    taxPerc:0,
    sumInsured:0,
    premAmt:0,
    riderSumInsured:0,
    riderPremiumAmt:0,
    totalTaxAmt:0,
    grossPremAmt:0
  });
  const refresh = useRefresh();
  const classes = useStyles({});
  const myclasses = myStyles({});

  const getOrder = (oid: any) => {
    apiClient.getEntiyRecord("orders", oid).then(res => {
      //if(res) {
      delete res.id;
      setdefaultValues(res);
      //console.log(defaultValues, "======ORDER======");
      if(res.policyDepartmentId != ""){
        checkPolicyDepartment(res.policyDepartmentId);
      }
      setIsLoading(false);
      //   }
    }).catch(err => {
      console.log(err);
    });
  }


  const checkPolicyDepartment = (id: string) => {
    apiClient.getEntiyRecord("policy-departments", id).then(res => {
      console.log(res, "RES====")
      if (res.isMotorDetails != undefined) {
        setIsAutoMotor(res.isMotorDetails);
      }
    }).catch(err => {
      console.log(err, "ERR")
    });
  }

  const handleClose = useCallback(() => {
    setIsAddCorportate(false);
    setIsAddCustomer(false);
    setIsAddCustomerGroup(false);
    setIsAddInsurer(false);
    setIsAddInsurerBranch(false);
    setIsAddPolicyDepartment(false);
    setIsAddPolicyType(false);
    setIsAddEmployee(false);
    setIsAddMotorClass(false);
    setIsAddMotorVarient(false);
    setIsAddMotorMake(false);
    setIsAddMotorFuel(false);
    setIsAddMotorModel(false);
    setIsAddMotorDetails(false);
    setIsAddRefCustomer(false);
  }, []);


  useEffect(() => {
    const values = queryString.parse(location.search);
    let oid = (values.oid) ? values.oid : '';
    if (oid != "") {
      getOrder(oid);
    } else {
      setIsLoading(false);
    }

  }, []);

  return (
    <React.Fragment>
      {!isLoading && <Create title="Add Life Order" {...props} >
        <SimpleForm
          className="ignite-form"
          initialValues={defaultValues}
          redirect="edit"
          toolbar={<FormToolbar {...props} showDelete={false} />}
          variant="outlined">
            
          <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
            Basic Detail
          </div>
          <ReferenceInput perPage={25} sort={{ field: 'mconame', order: 'ASC' }} validate={[required()]}
            filterToQuery={searchText => ({ 'mconame~like': searchText })}
            label="Booking Branch" source="configId" reference="configs" fullWidth={true} formClassName={classes.one_4_input}>
            <SelectInput optionText="mconame" />
          </ReferenceInput>
          <TextInput disabled={true} source="orderNo" label="Order No" fullWidth={true} formClassName={classes.two_4_input} />

          <DateInput validate={[required()]} label="Order Date"  initialValue={new Date()} source="orderDate" fullWidth={true} formClassName={classes.three_4_input} />
        
          <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
            filterToQuery={searchText => ({ 'name~like': searchText })}
            label="Biz Type" source="bizTypeId" reference="biz-types" fullWidth={true} formClassName={classes.last_4_input}>
            <SelectInput optionText="name" />
          </ReferenceInput>


          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Customer Group" source="custGroupId" reference="cust-groups" fullWidth={true} formClassName={classes.one_4_input}>
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddCustomerGroup(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.twice_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.three_4_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Customer" source="customerId" reference="customers" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddCustomer(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>



          <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
            Policy Detail
          </div>
          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} 
            filter={{ insurerTypeId: 2 }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Insurer" source="insurerId" reference="insurers" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddInsurer(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.two_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} 
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Insurer Branch" source="insurerBrancheId" reference="insurer-branches" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddInsurerBranch(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
            <ReferenceInput
              onChange={(data) => { checkPolicyDepartment(data); }}
              perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} 
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Policy Department" source="policyDepartmentId" reference="life-policy-departments" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddPolicyDepartment(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>


          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Policy Type" source="policyTypeId" reference="life-policy-types" fullWidth={true}>
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddPolicyType(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>


          <TextInput label="Product Name" validate={[required()]} source="productName" fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput label="Proposal No / App No :" validate={[required()]} source="proposalNo" fullWidth={true} formClassName={classes.two_4_input} />
          <DateInput label="Commence (Start) Date" source="policyStartDate" fullWidth={true} formClassName={classes.three_4_input} />
          <DateInput  label="Policy Maturity Date" source="policyMaturityDate" fullWidth={true} formClassName={classes.last_4_input} />

          <NumberInput label="Terms In Yrs" source="termsInYrs" fullWidth={true} formClassName={classes.one_4_input} />
          <NumberInput label="PPT In Yrs" source="pptInYrs"  fullWidth={true} formClassName={classes.two_4_input} />
          <ReferenceInput
            perPage={500} sort={{ field: 'name', order: 'ASC' }} 
            label="Paid Mode" source="paymentModeId" reference="lookups" formClassName={classes.three_4_input}
            filter={{ type: 'LPM' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput label="Policy No" source="policyNo" fullWidth={true} formClassName={classes.last_4_input} />

          
         
          <FormDataConsumer>
              {formDataProps => (
                <TaxFields {...formDataProps} />
              )}
            </FormDataConsumer>

          {/* <ReferenceInput
            perPage={500} sort={{ field: 'taxName', order: 'ASC' }} 
            label="TAX Type" source="taxId" reference="tax-heads" formClassName={classes.one_4_input}
            fullWidth={true} >
            <SelectInput optionText="taxName" />
          </ReferenceInput>
          <NumberInput label="TAX Percentage" source="taxPerc" fullWidth={true} formClassName={classes.two_4_input} />
          <NumberInput label="TAX Amount" source="totalTaxAmt" fullWidth={true} formClassName={classes.three_4_input} />
          <NumberInput label="Gross Premium" source="grossPremAmt" disabled={true} fullWidth={true} formClassName={classes.last_4_input} /> */}

          <DateInput  label="Premium Start Date" source="premiumStartDate" fullWidth={true} formClassName={classes.one_4_input} />
          <DateInput  label="Last Payment Date" source="lastPaidDate" fullWidth={true} formClassName={classes.two_4_input} />
          <DateInput label="Policy Issue Date" source="policyStartDate" fullWidth={true} formClassName={classes.three_4_input} />

          <DateInput label="Policy Received Date" source="policyReceiveDate" fullWidth={true} formClassName={classes.last_4_input} />
          <ReferenceInput
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Policy Rcvd Format Type" source="rcvdFormatTypeId" reference="lookups" formClassName={classes.one_4_input}
            filter={{ type: 'RFT' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Policy Dispatch Through" source="dispatchThroughId" reference="lookups" formClassName={classes.two_4_input}
            filter={{ type: 'PDT' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <DateInput label="Policy Dispatch Date" source="policyDispatchDate" fullWidth={true} formClassName={classes.last_4_input} />
          <div className="divider" />
          
          <TextInput multiline label="Dispatch Details" source="policyDispatchDetails" fullWidth={true}  formClassName={classes.one_4_input} />
          
          <ReferenceInput
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Camaign Type" source="camaignId" reference="lookups" formClassName={classes.two_4_input}
            filter={{ type: 'RFT' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput label="Camaign Name" source="camaignName" fullWidth={true} formClassName={classes.three_4_input} />
          <ReferenceInput
            perPage={500} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
            label="Status" source="statusId" reference="lookups" formClassName={classes.last_4_input}
            filter={{ type: 'RO' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>
     
          <div className="divider"/>
 

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.one_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} formClassName={classes.one_4_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="RM" source="rmid" reference="employees" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.two_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}  validate={[required()]} formClassName={classes.two_4_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="CSC" source="cscid" reference="employees" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>


          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.three_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.three_4_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="TC" source="tcid" reference="employees" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddEmployee(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>

          <OuterComponent source="add-with-block" style={{ display: "flex", flex: "rows" }} formClassName={classes.last_4_input}>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} formClassName={classes.last_4_input}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="REF" source="refid" reference="employees" fullWidth={true} >
              <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <Button onClick={() => { setIsAddRefCustomer(true) }} className="form-add-button"><AddIcon /></Button>
          </OuterComponent>


        </SimpleForm>
      </Create>}
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isAddCorportate || isAddCustomer || isAddCustomerGroup || isAddEmployee || isAddMotorClass || isAddRefCustomer ||
          isAddMotorVarient || isAddMotorMake || isAddMotorFuel || isAddMotorModel || isAddMotorDetails ||
          isAddInsurer || isAddInsurerBranch || isAddPolicyDepartment || isAddPolicyType} >
        {isAddCorportate && <CorporateCreate onClose={handleClose} />}
        {isAddCustomer && <CustomerCreate onClose={handleClose} />}
        {isAddCustomerGroup && <CustomerGroupCreate onClose={handleClose} />}
        {isAddInsurer && <InsurerCreate onClose={handleClose} />}
        {isAddInsurerBranch && <InsurerBranchCreate onClose={handleClose} />}
        {isAddPolicyDepartment && <PolicyDepartmentCreate onClose={handleClose} />}
        {isAddPolicyType && <PolicyTypeCreate onClose={handleClose} />}
        {isAddEmployee && <EmployeeCreate onClose={handleClose} />}
        {isAddMotorClass && <MotorClassCreate onClose={handleClose}  />} 
        {isAddRefCustomer && <RefCustomerCreate onClose={handleClose} />}
        {isAddMotorVarient && <MotorClassVarient onClose={handleClose}  />} 
        {isAddMotorMake && <MotorClassMake onClose={handleClose}  />} 
        {isAddMotorFuel && <MotorClassFuel onClose={handleClose}  />} 
        {isAddMotorModel && <MotorClassModel onClose={handleClose}  />} 
        {isAddMotorDetails && <MotorClassDetails onClose={handleClose}  />} 
      </Drawer>
    </React.Fragment>

  );
};
