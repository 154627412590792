import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import {Addressfields} from "./Addressfields";
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const EventEdit = props => {
    const classes = useStyles({});
    
    const handleSubmit = (formdata) => {
        console.log(formdata);
        apiClient.updateRecord("lead-order-events", formdata).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
        
    }
    return (
        <Edit {...props} id={props.record.id} resource="lead-order-events" redirect="false">
            <SimpleForm
                onSubmit={handleSubmit}
                
                toolbar={
                <PostCreateToolbar label="Save " onSave={handleSubmit} onClose={props.onClose}  onCancel={props.onCancel} 
                 />}
                variant="outlined">
                
                       
           
                <TextInput source="remarks" label="Remarks" fullWidth={true}    validate={[required()]} multiline/>  


            </SimpleForm>
        </Edit>
    );
}
export default EventEdit;