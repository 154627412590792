import React from 'react';
import { Create, SimpleForm, TextInput,BooleanInput,NumberInput, required } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";

export const LeaveTypeCreate = props => {
    return (
        <React.Fragment>
            <Create label="Create Leave Type" {...props}>
                <SimpleForm redirect="list" toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                    {/* <TextInput source="id" /> */}
                    <TextInput source="name" label='Leave Type' fullWidth={true} validate={[required()]} />
                    <TextInput source="description" label='Description ' fullWidth={true} validate={[required()]} />
                    <NumberInput source="totalAllowed" label='Total Allowed' fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true}/>
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}