import React, {useEffect, useCallback,useState } from 'react';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton,FormDataConsumer
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";


    export const CustomEmail = ({ formData, ...rest }) => {
    const classes = useStyles({});
    const form = useForm();
    
    
    
    useEffect(() => {
      ///  getOrederEmailData();
    }, [] )
     
    const getOrederEmailData = (e) => {
        
        apiClient.getOrederEmailData(parseInt(rest.ordId),parseInt(e.target.value)).then(res => {   
            //debugger
            form.change("toAddress",res[0].toAddress);
            form.change("subject",res[0].subject);
            form.change("ccAddress",res[0].ccAddress);
            form.change("body",res[0].body);
            
        }).catch(err => {
            console.log(err);
            
        });
    }

    
    return (
        
        <React.Fragment>
            <ReferenceInput label="Email Template"  variant="outlined"  validate={[required()]} fullWidth={true} source="templateId" reference="lookups" perPage={5000} 
            onChange={(e) => {
                getOrederEmailData(e);
            }}
                    filter={{ type: 'OET' }}
                    >
            <SelectInput optionText="name"  />
        </ReferenceInput>
      
                 <TextInput label="To" source="toAddress" fullWidth={true}    validate={[required()]} disabled  variant="outlined"/>
                <TextInput label="CC" source="ccAddress" fullWidth={true}       variant="outlined"/>
                <TextInput label="Subject" source="subject" fullWidth={true}  validate={[required()]} variant="outlined"/>
                <div style={{maxHeight:"300px",overflow:"auto"}} >
                <RichTextInput label="Email Body" source="body" fullWidth={true} validate={[required()]}   variant="outlined" />
                </div>
                
                {/* //initialValue={"<p>     <a href='www.google.com' rel='noopener noreferrer' target='_blank'>dssd</a></p>"} />  */}
         
        </React.Fragment>
    );
}
export default CustomEmail;