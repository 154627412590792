import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  SimpleForm,
  FormTab,
  DateInput, AutocompleteInput,
  useNotify
} from "react-admin";
import { useStyles } from "../../../formStyles";
import { FormToolbar } from "../../../common/FormToolbar";
import moment from "moment";
import * as apiClient from "../../../common/apiClient";
import { toast } from "react-toastify";

const InsurerCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  let defaultValues = {
    isActive: true
  };
  const handleSubmit = async (formdata) => {
    let user: any = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    let m = moment();

    let data = { ...formdata };

    data.insurerTypeId = 1;
    data.policyTypeId = -1;
    data.modifyBy = user;
    data.createdBy = user;
    data.modifyDate = m.toISOString();
    data.createdDate = m.toISOString();

    apiClient.addRecord(data, "insurers").then(res => {
      notify("insurer is added succesffully")
      props.onClose();

    }).catch(err => {
      notify("unable to add insurer", "warning");
      toast.error("Validation issue : " + err, {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.log(err);
    });
  }

  return (
    <React.Fragment>
      <h5>Add Insurer </h5>
      <SimpleForm variant="outlined" toolbar={<FormToolbar {...props} showDelete={false} showCancel={true} onCancel={props.onClose} onSave={handleSubmit} />} redirect="list" onSubmit={handleSubmit} initialValues={defaultValues} >

        <TextInput source="code" fullWidth={true} validate={[required()]} />
        <TextInput source="name" fullWidth={true} validate={[required()]} />

        <DateInput source="dealDate" label="Ent. Date" fullWidth={true} validate={[required()]} formClassName={classes.first_inline_input} />
        <DateInput source="openDate" label="Open Date" fullWidth={true} validate={[required()]} formClassName={classes.last_inline_input} />
        
        <TextInput source="state" label="State" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input}/>
        <ReferenceInput label="Area" source="areaId" fullWidth={true} reference="areas" formClassName={classes.two_three_input}>
            <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="city" label="City" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} /> 

        <TextInput source="add1" label="Address 1" fullWidth={true}  validate={[required()]}  />
        <TextInput source="add2" label="Address 2"  fullWidth={true}  />
        <TextInput source="add3" label="Address 3"  fullWidth={true}  />  

        <TextInput source="pincode" label="Pincode" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
        <BooleanInput source="isActive" label="Active" fullWidth={true} formClassName={classes.two_three_input} />
      </SimpleForm>

    </React.Fragment>
  );
};
export default InsurerCreate;