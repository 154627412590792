import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,ShowButton,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter, SelectInput, AutocompleteInput,
  ReferenceInput,
  SearchInput, TextInput
} from "react-admin";
import { DisplayDate } from "../../common/DisplayDate";
import Button from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import {
  useHistory
} from "react-router-dom";

const RenewPolicy = (props) => {
  // console.log(props, "===PROPS===");
  let history = useHistory();
   return (
     <Button variant="contained" color="primary" onClick={() => {
       history.push(`/orders/create?oid=${props.record.id}`);
     }}>
       Renew
     </Button>
   )
 }

 const Filters = props => (
  <Filter {...props} variant="outlined" className="filter-block">
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Customer" source="customerId" reference="customers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    <ReferenceInput perPage={25} sort={{ field: 'mobile', order: 'ASC' }} filterToQuery={searchText => ({ 'mobile~like': searchText })}
      label="Mobile" source="customerId" reference="customers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="mobile" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    <TextInput variant="outlined" label="Order No" source="orderNo~like" alwaysOn />
    <TextInput variant="outlined" label="Policy No." source="policyNo~like" alwaysOn />

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Insurer" source="insurerId" reference="insurers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Insurer Branch" source="insurerBrancheId" reference="insurer-branches" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    {/* <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput> */}
    {/* <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name"  shouldRenderSuggestions={(val) => { return val && val.trim().length   >= 1 }} />
    </ReferenceInput> */}
  </Filter>
);
export const LifeOrderList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} className="grid-list" classes={{ actions: "my-action", main: "main", content: "content", }}
      title="Life Order List" filters={<Filters />} sort={{ field: 'id', order: 'DESC' }} >
      <Datagrid rowClick={false}>
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />}
        {<RenewPolicy label="Renewal"/>}
        <ReferenceField label="Booking Branch" source="configId" reference="configs" link={false}>
          <TextField source="mconame" />
        </ReferenceField>
        <TextField source="orderNo" label="Order no" />
        <DateField source="orderDate" label="Order Date" />
        <TextField source="policyNo" label="Policy No" />
        <ReferenceField label="Biz Type" source="bizTypeId" reference="biz-types" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Customer" source="customerId" reference="customers" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Mobile" source="customerId" reference="customers" link={false}>
          <TextField source="mobile" />
        </ReferenceField>

        <ReferenceField label="Customer Group" source="custGroupId" reference="cust-groups" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Insurer" source="insurerId" reference="insurers" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Insurer Branch" source="insurerBrancheId" reference="insurer-branches" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Policy Department" source="policyDepartmentId" reference="life-policy-departments" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="Policy Type" source="policyTypeId" reference="life-policy-types" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <TextField source="productName" label="Product Name" />
        <TextField source="proposalNo" label="Proposal No / App No" />
        <DateField source="policyStartDate" label="Commence Start Date" />
        <DateField source="policyMaturityDate" label="Policy Maturity Date" />

        <ReferenceField label="Status" source="statusId" reference="lookups" link={false}>
          <TextField source="name" />
        </ReferenceField>


        <ReferenceField label="RM" source="rmid" reference="employees" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="CSC" source="cscid" reference="employees" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="TC" source="tcid" reference="employees" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField label="REF" source="refid" reference="employees" link={false}>
          <TextField source="name" />
        </ReferenceField>
      
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />

      </Datagrid>
    </List>
  );
};
