import React, { useState, useEffect, useRef } from 'react';
import * as apiClient from "../../../common/apiClient";
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../../formStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const PaymentFields = ({ formData, ...rest }) => {
    const form = useForm();

    const scrollEndRef = useRef(null);
    const classes = useStyles({});
    const [tpPremiumAmt, setTpPremiumAmt] = useState(0);
    const [odPremiumAmt, setOdPremiumAmt] = useState(0);
    const [brokrageData, setBrokrageData] = useState([]);
    const [brokrageType, setBrokrageType] = useState([]);
    const [rateMaster, setRateMaster] = useState([]);
    const [insurerData, setInsurerData] = useState([]);
    const [net, setNet] = useState(0);
    const [IsMultiPolicy, setIsMultiPolicy] = useState(false);

    useEffect(() => {
        loadBrokrageType();
        loadRateMaster();
        loadBrokrageData();
        getOrderHeaderData();
        loadInsurerData();
    }, []);
    const handleRemove = async (index) => {
        let data = [...brokrageData]
        data.splice(index, 1);
        setBrokrageData([...data])
        rest.onBrokrageDataChange([...data])

    }
    const loadInsurerData = () => {
        apiClient.getdata_v1('insurers?filter[offset]=0&filter[limit]=200000&filter[order]=id%20ASC&filter[where][insurerTypeId]=1').then(res => {
            setInsurerData(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const getOrderHeaderData = () => {

        apiClient.getOrderHeaderData(parseInt(formData.orderId)).then(res => {

            if (res.isMultiPolicy == true) {
                setIsMultiPolicy(true);
            }
            else {
                setIsMultiPolicy(false);
            }
            //setinsurerId(res.insurerId)

        }).catch(err => {
            console.log(err);
        });
    }
    const handleAdd = async (index) => {
        let data = [...brokrageData]
        let user = localStorage.getItem("userId");
        if (user) {
            user = parseInt(user);
        }
        data.push(
            {
                id: 0,
                orderId: parseInt(formData.orderId),
                orderPaymentId: parseInt(formData.id),
                partnerBrokerName: "",
                insurerId: 0,
                bokrageTypeId: 0,
                rateId: 0,
                ratePercentage: 0,
                brokrageAmount: 0,
                reconciledamt:0,
                brokrageRemarks: null,
                isInvoiceGenerated: false,
                invoiceGeneratedOn: "2022-09-24T14:47:09.958Z",
                isPaymentReceived: false,
                paymentReceivedOn: "2022-09-24T14:47:09.958Z",
                brokrageRemarks: "",
                statusId: 0,
                createdBy: user,
                createdDate: "2022-09-24T14:47:09.958Z",
                modifyBy: user,
                modifyDate: "2022-09-24T14:47:09.958Z"
            }
        )
        setBrokrageData([...data])
        rest.onBrokrageDataChange([...data])

    }
    const handleChange = async (event, index, identifier) => {

        let data = [...brokrageData]
        data[index][identifier] = event.target.value;
        debugger
        if (identifier == "ratePercentage") {

            let brokRatePercentage = 1;
            if (!isNaN(event.target.value)) {
                brokRatePercentage = parseFloat(event.target.value)
            }
            if (brokRatePercentage > 100) {
                data[index][identifier] = 100;
                brokRatePercentage = 100;
            }
            let bokrageTypeId = data[index]["bokrageTypeId"]
             
            if (bokrageTypeId == '128' || bokrageTypeId == '129' || bokrageTypeId == '130' 
                || bokrageTypeId == '132' || bokrageTypeId == '131') {
                if (bokrageTypeId == '128' || bokrageTypeId == '132'  || bokrageTypeId == '130') {
                    let Amt = (parseFloat(formData.odPremiumAmt) / 100) * parseFloat(brokRatePercentage);
                    data[index]["brokrageAmount"] = TwoDecimalPlaces(Amt)

                }
               
                else {
                    let Amt = (parseFloat(formData.tpPremiumAmt) / 100) * parseFloat(brokRatePercentage);

                    data[index]["brokrageAmount"] = TwoDecimalPlaces(Amt)
                }

            }
            else if (bokrageTypeId == '153' ) 
                     {
                        let Amt = ((parseFloat(formData.odPremiumAmt)+parseFloat(formData.tpPremiumAmt)) / 100) * parseFloat(brokRatePercentage);
                        data[index]["brokrageAmount"] = TwoDecimalPlaces(Amt)
    
                    }
        }
        if (identifier == "brokrageAmount") {
            let brokrageAmount = 0;
            let brokRatePercentage = 0;
            if (!isNaN(event.target.value)) {
                brokrageAmount = parseFloat(event.target.value)
            }

            let bokrageTypeId = data[index]["bokrageTypeId"]

            if (bokrageTypeId == '128' || bokrageTypeId == '129' || bokrageTypeId == '130' 
                || bokrageTypeId == '132' || bokrageTypeId == '131') {
                if (bokrageTypeId == '128' || bokrageTypeId == '132'  || bokrageTypeId == '130') {
                    if (brokrageAmount > formData.odPremiumAmt) {
                        data[index][identifier] = formData.odPremiumAmt;
                        brokRatePercentage = 100;
                    }
                    brokRatePercentage = (parseFloat(brokrageAmount / formData.odPremiumAmt) * 100);
                    data[index]["ratePercentage"] = TwoDecimalPlaces(brokRatePercentage)

                }
                
                else {
                    if (brokrageAmount > formData.tpPremiumAmt) {
                        data[index][identifier] = formData.tpPremiumAmt;
                        brokRatePercentage = 100;
                    }
                    brokRatePercentage = (parseFloat(brokrageAmount / formData.tpPremiumAmt) * 100); 
                    data[index]["ratePercentage"] = TwoDecimalPlaces(brokRatePercentage)
                }

            }
            else if (bokrageTypeId == '153' ) 
                     {
                        let Amt = ((parseFloat(formData.odPremiumAmt)+parseFloat(formData.tpPremiumAmt)) / 100) * parseFloat(brokRatePercentage);
                        data[index]["brokrageAmount"] = TwoDecimalPlaces(Amt)
    
                    }
        }
        if (identifier == "bokrageTypeId") {  
            data[index]["rateId"] = 0;
            data[index]["ratePercentage"] = 0;
            data[index]["brokrageAmount"] = 0;
        }
        if (identifier == "rateId") {
            let rdata = [...rateMaster]
            for (let i = 0; i < rdata.length; i++) {
                const element = rdata[i];
                if (event.target.value == element.id) {
                    data[index]["ratePercentage"] = TwoDecimalPlaces(parseFloat(element.brokRatePercentage));
                }

            }

            let bokrageTypeId = data[index]["bokrageTypeId"]
            let ratePercentage = data[index]["ratePercentage"]
            if (bokrageTypeId == '128' || bokrageTypeId == '129' || bokrageTypeId == '130' || bokrageTypeId == '131') {
                if (bokrageTypeId == '128' || bokrageTypeId == '131') {
                    let Amt = parseFloat(formData.odPremiumAmt) / parseFloat(ratePercentage);
                    data[index]["brokrageAmount"] = TwoDecimalPlaces(Amt)

                }
                else {
                    let Amt = parseFloat(formData.tpPremiumAmt) / parseFloat(ratePercentage);
                    data[index]["brokrageAmount"] = TwoDecimalPlaces(Amt)
                }

            }
            else if (bokrageTypeId == '153' ) 
                     {
                        let ratePercentage = data[index]["ratePercentage"]
                        let Amt = ((parseFloat(formData.odPremiumAmt)+parseFloat(formData.tpPremiumAmt)) / 100) * parseFloat(ratePercentage);
                        data[index]["brokrageAmount"] = TwoDecimalPlaces(Amt)
    
                    }
            
        }
        setBrokrageData([...data])
        rest.onBrokrageDataChange([...data])
    }
    const loadBrokrageType = () => {
        apiClient.getBrokrageType().then(res => {
            setBrokrageType(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const loadRateMaster = () => {
        apiClient.getRateMaster().then(res => {
            setRateMaster(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const loadBrokrageData = () => {
        apiClient.getBrokrageData(parseInt(formData.id)).then(res => {
            if (res.length > 0) {
                setBrokrageData(res);
                rest.onBrokrageDataChange(res)
            }
            else {
                let user = localStorage.getItem("userId");
                if (user) {
                    user = parseInt(user);
                }
                let data = [{
                    id: 0,
                    orderId: parseInt(formData.orderId),
                    orderPaymentId: parseInt(formData.id),
                    partnerBrokerName: "",
                    insurerId: 0,
                    bokrageTypeId: 0,
                    rateId: 0,
                    ratePercentage: 0,
                    brokrageAmount: 0,
                    brokrageRemarks: null,
                    isInvoiceGenerated: false,
                    invoiceGeneratedOn: "2022-09-24T14:47:09.958Z",
                    isPaymentReceived: false,
                    paymentReceivedOn: "2022-09-24T14:47:09.958Z",
                    brokrageRemarks: "",
                    statusId: 0,
                    createdBy: user,
                    createdDate: "2022-09-24T14:47:09.958Z",
                    modifyBy: user,
                    modifyDate: "2022-09-24T14:47:09.958Z"
                }]
                setBrokrageData(data);
                rest.onBrokrageDataChange(data)
            }

        }).catch(err => {
            console.log(err);
        });
    }
    const loadTaxHead = (id) => {
        apiClient.getTaxHead(id).then(res => {

            form.change("taxPerc", TwoDecimalPlaces(res.taxpc));
            calculateAmount(formData.odPremiumAmt, formData.tpPremiumAmt, res.taxpc);
        }).catch(err => {
            console.log(err);
        });
    }
    const TwoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).valueOf();
    }
    const onAmountChange = (e, field) => {

        if (field == "taxTypeId") {
            loadTaxHead(e.target.value)
        }
        if (field == "odPremiumAmt") {
            calculateAmount(e.target.value, formData.tpPremiumAmt, formData.taxPerc);
        }
        if (field == "tpPremiumAmt") {
            calculateAmount(formData.odPremiumAmt, e.target.value, formData.taxPerc);
        }


    }
    const calculateAmount = (amount, tpAmount, percent) => {
        debugger
        if (percent) {
            if (percent <= 0) {
                percent = 1
            }
        }
        else {
            percent = 0;
        }
        if (amount) {
            if (amount <= 0) {
                amount = 1
            }
        }
        else { amount = 0 }
        if (tpAmount) {
            if (tpAmount <= 0) {
                tpAmount = 1
            }
        }
        else { tpAmount = 0 }


        if (parseFloat(percent) == 0 || (parseFloat(amount) + parseFloat(tpAmount)) <= 0) {
            form.change("taxAmt", TwoDecimalPlaces(0));
        }
        else {
            form.change("taxAmt", TwoDecimalPlaces(((parseFloat(amount) + parseFloat(tpAmount))/100) * parseFloat(percent)));
            
        }

    }


    return (
        <div className="payment-field-parent">
            <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
                Payment  Detail
            </div>
            <DateInput validate={[required()]} label="Premium Due Date" variant="outlined" source="paymentDueDate" fullWidth={true} className={classes.one_4_input} disabled={true} />
            <NumberInput label="OD Premium Amt" variant="outlined" validate={[required()]} source="odPremiumAmt" fullWidth={true} className={classes.two_4_input} disabled={true}
                onChange={(e) => {
                    onAmountChange(e, "odPremiumAmt");
                }}
            />
            <NumberInput label="Tp Premium Amt" variant="outlined" validate={[required()]} source="tpPremiumAmt" fullWidth={true} className={classes.two_4_input} disabled={true}
                onChange={(e) => {
                    onAmountChange(e, "tpPremiumAmt");
                }}
            />
            <ReferenceInput variant="outlined" label="Tax Type" source="taxTypeId" reference="tax-heads" link={false} fullWidth={true} className={classes.last_4_input}
                onChange={(e) => {
                    onAmountChange(e, "taxTypeId");
                }}
            >
                <SelectInput optionText="taxName" validate={[required()]} />
            </ReferenceInput>
            <NumberInput label="Tax Perc" variant="outlined" validate={[required()]} source="taxPerc" disabled={true}
                onChange={(e) => {
                    onAmountChange(e, "taxPerc");
                }}
                fullWidth={true}
                className={classes.one_4_input}
            />

            <NumberInput label="Tax Amt" variant="outlined" validate={[required()]} source="taxAmt"
                disabled={true}
                fullWidth={true}
                className={classes.two_4_input}
            />
            <DateInput variant="outlined" validate={[required()]} label="Payment Date" source="paymentDate" fullWidth={true}
                className={classes.three_4_input} />

            <ReferenceInput label="Payment Mode" source="paymentModeId"
                filter={{ type: 'PT' }} validate={[required()]}
                fullWidth={true}
                className={classes.last_4_input}
                reference="lookups" link={false}>
                <SelectInput variant="outlined" optionText="name" validate={[required()]} />
            </ReferenceInput>
            <TextInput source="refNo" variant="outlined" label="Payment Ref No"

                fullWidth={true}
                className={classes.one_4_input} />
            <ReferenceInput label="Bank" source="bankId" reference="bank-masters"
                link={false} fullWidth={true}
                perPage={250000}
                className={classes.two_4_input}>
                <SelectInput optionText="name" variant="outlined"/>
            </ReferenceInput>


            <TextInput label="Bank Branch" variant="outlined" source="bankBranch" perPage={250000}
                fullWidth={true}
                className={classes.three_4_input} />
            <TextInput label="Remarks" variant="outlined" source="payRemarks"
                fullWidth={true}
                className={classes.last_4_input}
            /> 
            <div style={{ fontWeight: "bold", fontSize: "15px", width: "100%", borderBottom: "1px dashed lightgrey" }} >
                Brokrage Details
            </div>
            <div ref={scrollEndRef} style={{ width: "100%", minHeight: "250px", maxHeight: "250px", overflow: "auto", border: "1px dashed lightgrey" }} >
                <Table className={classes.customtable} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell >Partner Broker Name</TableCell>
                            {IsMultiPolicy && <TableCell>Insurer</TableCell>}
                            <TableCell >Brokrage Type</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Percentage</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Reco Amount</TableCell>
                             
                            
                            <TableCell>Remarks</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {brokrageData.map((item, index) => {
                            return <tr >
                                <TableCell>
                                    <input style={{ width: "100%", textAlign: "left", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="text" variant="outlined" label="" source="ratePercentage" validate={[required()]} rowsMax={2} value={item.partnerBrokerName} onChange={(event) => handleChange(event, index, "partnerBrokerName")} source={"Col5" + index} />
                                </TableCell>
                                {IsMultiPolicy && <TableCell>

                                    <select style={{ width: "100%", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.insurerId} onChange={(event) => handleChange(event, index, "insurerId")} variant="outlined" source={"Col1" + index} label="Invoice Type" fullWidth={true} formClassName={classes.one_4_input}  >
                                        <option value={'0'}>Select </option>
                                        {insurerData.map((item) => {
                                            return <option selected={item.id === item.insurerId} value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </TableCell>}
                                <TableCell> 
                                    <select style={{ width: "100%", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} 
                                    value={item.bokrageTypeId} onChange={(event) => handleChange(event, index, "bokrageTypeId")} variant="outlined" source={"Col1" + index} label="Invoice Type" fullWidth={true} formClassName={classes.one_4_input}  >
                                        <option value={'0'}>Select </option>
                                        {brokrageType.map((item) => {
                                            return <option selected={item.id === item.bokrageTypeId} value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </TableCell>
                                <TableCell>  
                                    <select style={{ width: "100%", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.rateId} onChange={(event) => handleChange(event, index, "rateId")} variant="outlined" source={"Col1" + index} label="Invoice Type" fullWidth={true} formClassName={classes.one_4_input} disabled={item.bokrageTypeId == '0' || item.bokrageTypeId == '132' || item.bokrageTypeId == '133'}  >
                                        <option value={'0'}>Select </option>
                                        {rateMaster.map((item) => {
                                            return <option selected={item.id === item.rateId} value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </TableCell>


                                <TableCell>
                                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="ratePercentage" validate={[required()]} rowsMax={2} value={item.ratePercentage} onChange={(event) => handleChange(event, index, "ratePercentage")} source={"Col5" + index} />
                                </TableCell>
                                
                                <TableCell>
                                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="totalAmount" validate={[required()]} rowsMax={2} value={item.brokrageAmount} onChange={(event) => handleChange(event, index, "brokrageAmount")} source={"Col5" + index}  disabled={ (item.bokrageTypeId == '132' || item.bokrageTypeId == '133')==true?false:true}/>
                                </TableCell>
                                <TableCell>
                                    <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="totalAmount" validate={[required()]} rowsMax={2} value={item.reconciledamt} onChange={(event) => handleChange(event, index, "reconciledamt")} source={"Col10" + index}  disabled={ true}/>
                                </TableCell>
                                <TableCell>
                                    <textarea style={{ height: "50px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.brokrageRemarks} onChange={(event) => handleChange(event, index, "brokrageRemarks")} aria-label="maximum height" placeholder="Remarks" defaultValue="" source={"Col4" + index} />
                                </TableCell>
                                <TableCell>
                                    {" "} {index != 0 ? <button type="button" onClick={() => handleRemove(index)} >-</button> : null} {"  "}
                                    {(brokrageData.length - 1) === index ? <button button type="button" onClick={() => handleAdd(index)}>+</button> : null}

                                </TableCell>

                            </tr>
                        })}
                    </TableBody>
                </Table>

            </div>
        </div>
    )
}
export default PaymentFields;