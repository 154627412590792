import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'


import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,ShowButton,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const Filters = props => (
  <Filter {...props} variant="outlined">
  <SearchInput variant="outlined" label=" Name" source="name~like" alwaysOn />
  
  
</Filter>
);
export const AcitivityList = (props) => {
  let employeeId=0
  let RoleTypeId = localStorage.getItem("RoleTypeId");
  let isAdmin=false
  if (RoleTypeId != null && RoleTypeId == "1") {
    isAdmin = true;
  }
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
      if(p.OwnRecord)
      {
        employeeId = localStorage.getItem("userId");
        if (employeeId) {
            employeeId = parseInt(employeeId);
        }
      }
    }
  }
   
  
 

  return (
    
    <React.Fragment>
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} filter={employeeId && isAdmin==false >0?{ createdBy: employeeId }:null} title="Conv Order List" filters={<Filters />}  >
      <Datagrid rowClick={ false}>
        {propsObj.hasEdit && <EditButton />}
        {!propsObj.hasEdit && propsObj.hasShow && <ShowButton />} 
        
        <TextField source="orderNo" label="Conv Ord No" />
        <ReferenceField source="approvedBy" reference="employees" link={false} label="Approved By" >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="name" label="Name" />
                    <TextField source="amount" label="Amount" />
                    <TextField source="advAmt" label="Adv Amt" />
                    <TextField source="balAmt" label="Bal Amt" />
                    
      
      <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date" locales="fr-FR"/>
            </Datagrid>      
    </List>
     
      </React.Fragment>
  );
};
